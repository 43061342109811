import { Auth0Provider } from '@auth0/auth0-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React, { FC, useState, useLayoutEffect, useCallback } from 'react';
import { updateLastLoginDate } from '../../api/authenticated/um/updateLastLoginDate';
import { getAuthClientInstance } from '../instances/authClientInstance';
import { AuthType } from '../models/AuthType';
import { IAuth0Config } from '../models/IAuth0Config';

interface IAuthProviderProps {
  type: AuthType;
}
export const AuthProvider: FC<IAuthProviderProps> = ({ type, children }) => {
  const [component, setComponent] = useState<JSX.Element>();

  const renderProvider = useCallback(async () => {
    if (type === 'msal') {
      const instance = await getAuthClientInstance().getInstance<IPublicClientApplication>();
      setComponent(<MsalProvider instance={instance}>{children}</MsalProvider>);
    } else {
      const config = getAuthClientInstance().getConfig<IAuth0Config>();
      setComponent(
        <Auth0Provider
          domain={config.domain}
          clientId={config.clientId}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: config.audience,
            scope: config.scope,
          }}
          useRefreshTokens={true}
          cacheLocation="localstorage"
          onRedirectCallback={(appState) => {
            updateLastLoginDate();
            window.location.replace(`${window.location.origin}${appState?.returnTo || window.location.pathname}`);
          }}>
          {children}
        </Auth0Provider>
      );
    }
  }, [children, type]);

  useLayoutEffect(() => {
    renderProvider();
  }, [renderProvider]);

  return <>{component}</>;
};
