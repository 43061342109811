import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ComboBox } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/ListFilter.module.scss';
import { IListFilter } from '../../common/interfaces/TableColumn';
import classNames from 'classnames';

export interface IListFilterProps {
  listFilter: IListFilter;
  disabled?: boolean;
  className?: string;
  onSelectItem?: (value?: string) => void;
}

const ListFilter: FC<IListFilterProps> = ({ listFilter, disabled, className, onSelectItem }) => {
  const [selected, setSelected] = useState('');
  useEffect(() => {
    if (listFilter.filter) {
      setSelected(listFilter.filter);
    }
  }, [listFilter, onSelectItem]);

  const handleOnChange = (val) => {
    setSelected(val);
    onSelectItem && onSelectItem(val);
  };

  const handleOnClear = () => {
    setSelected('');
    onSelectItem && onSelectItem();
  };

  return (
    <ComboBox
      cssClass={classNames(Style.list, className)}
      placeholder="-"
      options={listFilter.fieldValues.map((v) => ({ id: v, value: v || '' }))}
      selected={selected}
      showIcon={false}
      onSelect={(value) => handleOnChange(value?.id.toString())}
      onClear={handleOnClear}
      disabled={disabled}></ComboBox>
  );
};

export default observer(ListFilter);
