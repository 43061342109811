import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/Loading.module.scss';
import Overlay from './Overlay';
import { Loader } from '@aurecon-creative-technologies/styleguide';
import CentreOnPage from './CentreOnPage';

interface ILoadingProps {
  isLoading: boolean;
  label?: string;
}

const Loading: FC<ILoadingProps> = (props) => {
  if (!props.isLoading) return null;
  return (
    <>
      <CentreOnPage>
        <Overlay className={Style.modalOverlay}></Overlay>
        <Loader cssClass={Style.loadingSpinner} label={props.label || ''} size="small" />
      </CentreOnPage>
    </>
  );
};

export default observer(Loading);
