import React, { useEffect, useRef, useState } from 'react';
import WidgetProperties from '@arcgis/core/widgets/Widget';
import VisualisationStore from '../VisualisationStore';
import SceneView from '@arcgis/core/views/SceneView';
import MapView from '@arcgis/core/views/MapView';

import Style from './styles/WidgetBase.module.scss';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IWidgetBase extends WidgetProperties {
  view?: MapView | SceneView;
  nextBasemap?: any;
  viewModel?: any;
  unit?: any;
  layers?: any;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

interface IHasViewModelClear {
  viewModel: { clear: () => void };
}
export default function WidgetBase<T extends Partial<IWidgetBase>>(
  widgetActivator: (props: Partial<IWidgetBase>) => T,
  className?: string
): React.ReactElement {
  const mapView: SceneView | MapView = VisualisationStore.mapView;
  const [widgetInstance, setWidgetInstance] = useState<T>();
  const widgetRef = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function hasViewModel(object: any): object is IHasViewModelClear {
      return 'viewModel' in object;
    }

    return () => {
      if (widgetInstance && hasViewModel(widgetInstance)) {
        widgetInstance.viewModel.clear && widgetInstance.viewModel.clear();
      }
    };
  }, [widgetInstance]);

  useEffect(() => {
    if (!mapView || !widgetRef.current) return;
    if (widgetInstance) {
      if (widgetInstance.view !== mapView) widgetInstance.view = mapView;
      return;
    }
    const createProps = {
      visible: true,
      container: widgetRef.current,
      view: mapView,
    };
    setWidgetInstance(widgetActivator(createProps));
  }, [widgetInstance, mapView, widgetActivator]);

  return <div className={className || Style.widgetWrapper} ref={widgetRef}></div>;
}
