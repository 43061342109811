import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TeamChart.module.scss';
import Icon from '../shared/Icon';
import { Button, Container, Pill } from '@aurecon-creative-technologies/styleguide';
import TeamChartStore from './TeamChartStore';

const TeamChartSelectedUsers: FC = () => {
  const renderExternalPillItems = () => {
    return (
      <>
        {TeamChartStore.externalPillItems.map((user) => (
          <Pill
            key={`EXUser-${user.id}`}
            colour={11}
            onClose={() => TeamChartStore.removeSelectedExUsers(user)}
            closeIcons={['highlight_off', 'highlight_off']}
            closeTitle="Close"
            cssClass={Style.selectedUserPill}>
            <>
              <div className={Style.selectedUserPillContent}>
                <span>{user.name}</span>
                <span>
                  <Icon name="people"></Icon>
                </span>
              </div>
            </>
          </Pill>
        ))}
      </>
    );
  };

  const renderDeliveryPillItems = () => {
    return (
      <>
        {TeamChartStore.deliveryTeamPillItems.map((dtUser) => (
          <Pill
            key={`DTU-${dtUser.deliveryTeamId}`}
            colour={11}
            onClose={() => TeamChartStore.removeSelectedDTUsers(dtUser)}
            closeIcons={['highlight_off', 'highlight_off']}
            closeTitle="Close"
            cssClass={Style.selectedUserPill}>
            <>
              {dtUser.count > 1 && (
                <div className={Style.selectedUserPillContent}>
                  <span>
                    {dtUser.deliveryTeamTitle} | {dtUser.deliveryTeamCode}
                  </span>
                  <span>
                    <Icon name="people"></Icon>
                  </span>
                  <span>{dtUser.count}</span>
                </div>
              )}
              {dtUser.count === 1 && (
                <div className={Style.selectedUserPillContent}>
                  <span>
                    {dtUser.users[0].name} | {dtUser.deliveryTeamCode}
                  </span>
                  <span>
                    <Icon name="people"></Icon>
                  </span>
                </div>
              )}
            </>
          </Pill>
        ))}
      </>
    );
  };

  const renderTaskTeamPillItems = () => {
    return (
      <>
        {TeamChartStore.taskTeamPillItems.map((group) => (
          <Pill
            key={`TT-${group.taskTeamId}`}
            colour={11}
            onClose={() => TeamChartStore.removeSelectedGroupTaskTeamUsers(group)}
            closeIcons={['highlight_off', 'highlight_off']}
            closeTitle="Close"
            cssClass={Style.selectedUserPill}>
            <>
              {group.count > 1 && (
                <div className={Style.selectedUserPillContent}>
                  <span>
                    {group.deliveryTeamTitle} | {group.taskTeamCode}
                  </span>
                  <span>
                    <Icon name="people"></Icon>
                  </span>
                  <span>{group.count}</span>
                </div>
              )}
              {group.count === 1 && (
                <div className={Style.selectedUserPillContent}>
                  <span>
                    {group.users[0].name} | {group.deliveryTeamCode} | {group.taskTeamCode}
                  </span>
                  <span>
                    <Icon name="people"></Icon>
                  </span>
                </div>
              )}
            </>
          </Pill>
        ))}
      </>
    );
  };

  return (
    <div className={Style.selectedUsersWrapper}>
      <div className={Style.selectedUsersLabel}>Selected Users</div>
      <div className={Style.selectedUsers}>
        <Container>
          <>
            {renderExternalPillItems()}
            {renderDeliveryPillItems()}
            {renderTaskTeamPillItems()}
          </>
        </Container>
      </div>
      <Button type="text" label="Clear Selection" onClick={TeamChartStore.clearAllSelectedUsers} />
    </div>
  );
};

export default observer(TeamChartSelectedUsers);
