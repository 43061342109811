import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TransmittalTabContent.module.scss';
import TransmittalsStore from './TransmittalsStore';
import { TabFilter } from './Types';
import TransmittalTabContainerActionBar from './TransmittalTabContainerActionBar';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../common/constants/Pages';
import TransmittalTable from './transmittalTable/TransmittalTable';
import { Loader } from '@aurecon-creative-technologies/styleguide';

const TransmittalTabContent: FC = () => {
  const navigate = useNavigate();
  const handleNavigateToAddNewTransmittal = () => {
    TransmittalsStore.abortRequests();
    navigate(`${Pages.Transmittals.Route}/new`);
  };

  return (
    <>
      <div className={Style.tabContainer}>
        <TransmittalTabContainerActionBar />

        {TransmittalsStore.isLoading && (
          <div className={Style.loadingSpinnerWrapper}>
            <Loader size="small" cssClass={Style.loadingSpinner} />
          </div>
        )}

        {(TransmittalsStore.isLoading ||
          TransmittalsStore.isSearchedOrFiltered ||
          !!TransmittalsStore.filteredTransmittals.length) && (
          <div className={Style.transmittalList}>
            <TransmittalTable
              tableColumns={TransmittalsStore.tableColumns}
              tableTransmittals={TransmittalsStore.filteredTransmittals ?? []}
              onFilter={(column, filter) => {
                TransmittalsStore.applyFilter(column, filter);
              }}
              onSortColumnChange={(column, direction) => {
                TransmittalsStore.applySort(column, direction);
              }}
            />
          </div>
        )}

        {!TransmittalsStore.searchTransmittals.length &&
          !TransmittalsStore.isLoading &&
          !TransmittalsStore.isSearchedOrFiltered && (
            <div className={Style.noDataWrapper}>
              {!TransmittalsStore.isSearchedOrFiltered &&
                TransmittalsStore.currentTab === TabFilter.MyNotifications && (
                  <div>There are no active Transmittals that require your actions.</div>
                )}
              {!TransmittalsStore.isSearchedOrFiltered && TransmittalsStore.currentTab === TabFilter.InitiatedByMe && (
                <div className={Style.noDataMessage}>
                  There are no Transmittals initiated by you. <br></br>
                  Click{' '}
                  <a className={Style.pointer} onClick={handleNavigateToAddNewTransmittal}>
                    here
                  </a>{' '}
                  to create new Transmittals.
                </div>
              )}
              {!TransmittalsStore.isSearchedOrFiltered && TransmittalsStore.currentTab === TabFilter.VisibleToMe && (
                <div className={Style.noDataMessage}>
                  There are no Transmittals visible to you.<br></br>
                  Click{' '}
                  <a className={Style.pointer} onClick={handleNavigateToAddNewTransmittal}>
                    here
                  </a>{' '}
                  to create new Transmittals.
                </div>
              )}
            </div>
          )}
      </div>
    </>
  );
};

export default observer(TransmittalTabContent);
