import { FileContainerState } from '../../../common/enums/FileContainerState';
import { serverAxios } from '../api';
import { INamingConventionAssistance } from './interface/namingConventionAssistance';

export async function getNamingConventionAssistance(
  projectNumber: string,
  taskTeamId: number,
  fileContainerStateId: FileContainerState,
  abortSignal?: AbortSignal
): Promise<INamingConventionAssistance> {
  const result = await serverAxios.get<INamingConventionAssistance>('api/cms/getNamingConventionAssistance', {
    params: {
      projectNumber: projectNumber,
      taskTeamId: taskTeamId,
      fileContainerStateId: fileContainerStateId,
    },
    signal: abortSignal,
  });

  return result.data ?? null;
}
