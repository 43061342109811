import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ITreeNodeData } from '../../../shared/TreeView';
import Style from '../../../../styles/components/settings/systemAdminSettings/systemSettings/SystemSettings.module.scss';
import { SystemSettingKeys, SystemSettingLabels } from '../../../../common/enums/SystemSettingKeys';
import GeneralSettings from './GeneralSettings';
import SystemSettingsStore from '../../../../stores/settings/systemAdminSettings/systemSettings/SystemSettingsStore';
import Loading from '../../../shared/Loading';
import { SystemSettingsPath } from '../../../../common/constants/SystemSettingsPath';

const SystemSettings: FC = () => {
  const treeData: ITreeNodeData<string>[] = [
    {
      id: `${SystemSettingKeys.General}`,
      type: `${SystemSettingKeys.General}`,
      key: `${SystemSettingKeys.General}`,
      title: SystemSettingLabels[SystemSettingKeys.General],
      path: '',
      disabled: false,
      childrenNodes: [],
      selectable: true,
    },
    {
      id: `${SystemSettingKeys.THEME}`,
      type: `${SystemSettingKeys.THEME}`,
      key: `${SystemSettingKeys.THEME}`,
      title: SystemSettingLabels[SystemSettingKeys.THEME],
      path: '',
      disabled: false,
      childrenNodes: [],
      selectable: true,
    },
    {
      id: `${SystemSettingKeys.LOGINPAGE}`,
      type: `${SystemSettingKeys.LOGINPAGE}`,
      key: `${SystemSettingKeys.LOGINPAGE}`,
      title: SystemSettingLabels[SystemSettingKeys.LOGINPAGE],
      path: '',
      disabled: false,
      childrenNodes: [],
      selectable: true,
    },
    {
      id: `${SystemSettingKeys.METADATA}`,
      type: `${SystemSettingKeys.METADATA}`,
      key: `${SystemSettingKeys.METADATA}`,
      title: SystemSettingLabels[SystemSettingKeys.METADATA],
      path: '',
      disabled: false,
      showLine: true,
      childrenNodes: [
        {
          id: `${SystemSettingKeys.FILE}`,
          type: `${SystemSettingKeys.FILE}`,
          key: `${SystemSettingKeys.FILE}`,
          title: SystemSettingLabels[SystemSettingKeys.FILE],
          path: SystemSettingsPath.File,
          disabled: false,
          childrenNodes: [
            {
              id: `${SystemSettingKeys.TABLELIST}`,
              type: `${SystemSettingKeys.TABLELIST}`,
              key: `${SystemSettingKeys.TABLELIST}`,
              title: SystemSettingLabels[SystemSettingKeys.TABLELIST],
              path: SystemSettingsPath.TableList,
              disabled: false,
              childrenNodes: [
                {
                  id: `${SystemSettingKeys.WORKINPROGRESS}`,
                  type: `${SystemSettingKeys.WORKINPROGRESS}`,
                  key: `${SystemSettingKeys.WORKINPROGRESS}`,
                  title: SystemSettingLabels[SystemSettingKeys.WORKINPROGRESS],
                  path: SystemSettingsPath.WorkInprogress,
                  disabled: false,
                  childrenNodes: [],
                  selectable: true,
                },
                {
                  id: `${SystemSettingKeys.SHARED}`,
                  type: `${SystemSettingKeys.SHARED}`,
                  key: `${SystemSettingKeys.SHARED}`,
                  title: SystemSettingLabels[SystemSettingKeys.SHARED],
                  path: SystemSettingsPath.Shared,
                  disabled: false,
                  childrenNodes: [],
                  selectable: true,
                },
                {
                  id: `${SystemSettingKeys.PUBLISHED}`,
                  type: `${SystemSettingKeys.PUBLISHED}`,
                  key: `${SystemSettingKeys.PUBLISHED}`,
                  title: SystemSettingLabels[SystemSettingKeys.PUBLISHED],
                  path: SystemSettingsPath.Published,
                  disabled: false,
                  childrenNodes: [],
                  selectable: true,
                },
              ],
            },
            {
              id: `${SystemSettingKeys.BULKUPLOAD}`,
              type: `${SystemSettingKeys.BULKUPLOAD}`,
              key: `${SystemSettingKeys.BULKUPLOAD}`,
              title: SystemSettingLabels[SystemSettingKeys.BULKUPLOAD],
              path: SystemSettingsPath.BulkUpload,
              disabled: false,
              childrenNodes: [],
              selectable: true,
            },
          ],
        },
        {
          id: `${SystemSettingKeys.TASK}`,
          type: `${SystemSettingKeys.TASK}`,
          key: `${SystemSettingKeys.TASK}`,
          title: SystemSettingLabels[SystemSettingKeys.TASK],
          path: SystemSettingsPath.Task,
          disabled: false,
          childrenNodes: [],
          selectable: true,
        },
        {
          id: `${SystemSettingKeys.VISUALISATION}`,
          type: `${SystemSettingKeys.VISUALISATION}`,
          key: `${SystemSettingKeys.VISUALISATION}`,
          title: SystemSettingLabels[SystemSettingKeys.VISUALISATION],
          path: SystemSettingsPath.Visualisation,
          disabled: false,
          childrenNodes: [],
          selectable: true,
        },
        {
          id: `${SystemSettingKeys.METADATA_TRANSMITTALS}`,
          type: `${SystemSettingKeys.METADATA_TRANSMITTALS}`,
          key: `${SystemSettingKeys.METADATA_TRANSMITTALS}`,
          title: SystemSettingLabels[SystemSettingKeys.METADATA_TRANSMITTALS],
          path: SystemSettingsPath.MetadataTransmittals,
          disabled: false,
          childrenNodes: [],
          selectable: true,
        },
      ],
    },
    {
      id: `${SystemSettingKeys.MAILSERVER}`,
      type: `${SystemSettingKeys.MAILSERVER}`,
      key: `${SystemSettingKeys.MAILSERVER}`,
      title: SystemSettingLabels[SystemSettingKeys.MAILSERVER],
      path: '',
      disabled: false,
      childrenNodes: [],
      selectable: true,
    },
    {
      id: `${SystemSettingKeys.SUPPORT}`,
      type: `${SystemSettingKeys.SUPPORT}`,
      key: `${SystemSettingKeys.SUPPORT}`,
      title: SystemSettingLabels[SystemSettingKeys.SUPPORT],
      path: '',
      disabled: false,
      showLine: true,
      childrenNodes: [
        {
          id: `${SystemSettingKeys.FAQ}`,
          type: `${SystemSettingKeys.FAQ}`,
          key: `${SystemSettingKeys.FAQ}`,
          title: SystemSettingLabels[SystemSettingKeys.FAQ],
          path: SystemSettingsPath.Faq,
          disabled: false,
          childrenNodes: [],
          selectable: true,
        },
        {
          id: `${SystemSettingKeys.USERGUIDE}`,
          type: `${SystemSettingKeys.USERGUIDE}`,
          key: `${SystemSettingKeys.USERGUIDE}`,
          title: SystemSettingLabels[SystemSettingKeys.USERGUIDE],
          path: SystemSettingsPath.UserGuide,
          disabled: false,
          childrenNodes: [],
          selectable: true,
        },
      ],
    },
  ];
  const [nodeSelected] = useState<ITreeNodeData<string>>(treeData[0]);

  const renderContent = () => {
    switch (nodeSelected?.key) {
      case `${SystemSettingKeys.General}`:
        return <GeneralSettings />;
      default:
        return <>Coming soon!</>;
    }
  };

  return (
    <div className={Style.pageWrapper}>
      <div className={Style.right}>{renderContent()}</div>
      <Loading isLoading={SystemSettingsStore.isLoading} label={SystemSettingsStore.loadingLabel}></Loading>
    </div>
  );
};

export default observer(SystemSettings);
