import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import FileInformationStore from './FileInformationStore';
import { TabContent, TabMenu } from '@aurecon-creative-technologies/styleguide';
import CloseButton from '../../shared/CloseButton';
import Details from './Details';
import History from './History';
import Style from './styles/FileInformation.module.scss';
import AppStore from '../../../stores/AppStore';
import Files from './Files';
import { FromModuleFilesList } from '../../../common/enums/FromModuleFilesList';

export interface IFileInformationProps {
  showFiles?: boolean;
  fromModule?: FromModuleFilesList;
}
const FileInformation: FC<IFileInformationProps> = ({ showFiles = true, fromModule }) => {
  useEffect(() => {
    // close when component is destroyed
    return () => FileInformationStore.close();
  }, []);

  if (FileInformationStore.file === null) return null;

  if (AppStore.isProjectExternalUser) {
    return (
      <>
        <CloseButton onClick={FileInformationStore.close} />
        <h2 className={Style.heading}>{FileInformationStore.file.title}</h2>
        <TabMenu cssClass={Style.tab} type="line" tabs={[{ id: 1, label: `Details` }]}>
          <TabContent for={1}>
            <div className={Style.tabContainer}>
              <div className={Style.scrollableContainer}>
                <Details />
              </div>
            </div>
          </TabContent>
        </TabMenu>
      </>
    );
  }

  const tabs = () => {
    const tabs = [{ id: 1, label: `Details` }];
    if (showFiles) tabs.push({ id: 3, label: `Files` });
    tabs.push({ id: 2, label: `History` });
    return tabs;
  };

  return (
    <>
      <CloseButton onClick={FileInformationStore.close} />
      <h2 className={Style.heading}>{FileInformationStore.file.title}</h2>
      <TabMenu cssClass={Style.tab} type="line" tabs={tabs()}>
        <TabContent for={1}>
          <div className={Style.tabContainer}>
            <div className={Style.scrollableContainer}>
              <Details />
            </div>
          </div>
        </TabContent>

        <TabContent for={2}>
          <div className={Style.tabContainer}>
            <div className={Style.scrollableContainer}>
              <History />
            </div>
          </div>
        </TabContent>

        {showFiles ? (
          <TabContent for={3}>
            <div className={Style.tabContainer}>
              <div className={Style.scrollableContainer}>
                <Files fromModule={fromModule} />
              </div>
            </div>
          </TabContent>
        ) : (
          <></>
        )}
      </TabMenu>
    </>
  );
};

export default observer(FileInformation);
