import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import TeamChartStore from './TeamChartStore';
import Style from './styles/TeamChart.module.scss';
import { ITeamChartTaskTeamUser } from './interface/TeamChart.interface';
import classNames from 'classnames';
import TaskUserTooltip from './TaskUserTooltip';
import { useTeamChartContext } from './TeamChartStoreContext';

interface ITaskTeamUserItemProps {
  taskTeamId: number;
  deliveryTeamId: number;
  user: ITeamChartTaskTeamUser;
}

const TaskTeamUserItem: FC<ITaskTeamUserItemProps> = (props) => {
  const context = useTeamChartContext();
  const { user, deliveryTeamId, taskTeamId } = props;
  return (
    <ul className={Style.taskTeamWrapper}>
      <li
        key={user.id}
        className={classNames(Style.taskTeamItem, {
          [Style.active]: user.check,
          [Style.disabled]: user.disabled,
        })}
        onClick={() =>
          !context.disableTaskTeam && TeamChartStore.addOrRemoveTaskTeamUserById(deliveryTeamId, taskTeamId, user)
        }>
        <TaskUserTooltip user={user} />
      </li>
    </ul>
  );
};
export default observer(TaskTeamUserItem);
