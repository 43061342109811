import React, { FC, useEffect, useState } from 'react';
import { getProjectApps, ProjectApp } from '../../api/authenticated/config/getProjectApps';
import AppStore from '../../stores/AppStore';
import { classNames, validateUrlToNavigate } from '../../utils/miscUtils';
import LayoutStore from '../layout/LayoutStore';
import Icon from '../shared/Icon';
import styles from './styles/Drawer.module.scss';
import NoImageIcon from '../displayApps/images/no-image-icon.png';
import { Pages } from '../../common/constants/Pages';
import { NavLink } from 'react-router-dom';

interface IMenuProps {
  open: boolean;
  onClose: () => void;
}
const AppDrawer: FC<IMenuProps> = (props) => {
  const { open, onClose } = props;
  const [apps, setApps] = useState<ProjectApp[]>([]);

  const handleImgLoadingError = (e) => {
    e.target.src = NoImageIcon;
  };
  const projectNumber = AppStore.projectNumber;
  useEffect(() => {
    const loadApps = async () => {
      if (projectNumber) {
        const result = await getProjectApps(projectNumber);
        setApps(result);
      }
    };

    loadApps();
  }, [projectNumber]);
  const appBarScroll = (direction: string) => {
    const element = document.getElementById('appContainer')!;
    let scrollAmount = 0;
    const step = 10;
    const distance = 100;
    const speed = 60;
    const slideTimer = setInterval(function () {
      if (direction == 'slideup') {
        element.scrollTop -= step;
      } else {
        element.scrollTop += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };
  return (
    <div>
      <div onClick={onClose} role="presentation" data-open={JSON.stringify(open)} className={styles.overlay} />
      <nav
        data-open={JSON.stringify(open)}
        className={classNames(styles.appDrawer, [!LayoutStore.showSidebar, styles.sidebarClose])}>
        <div>
          <div>
            <button type="button" className={styles.arrowUp} onClick={() => appBarScroll('slideup')}>
              <Icon name="keyboard_arrow_up" className={styles.arrowIcon} />
            </button>
          </div>
          <div className={styles.appContainer} id="appContainer">
            <>
              {AppStore.clientEnableSplashPage && (
                <div className={styles.appLink}>
                  <NavLink className={styles.linkLabel} to={Pages.Apps.Route} onClick={onClose}>
                    <Icon name="home" className={styles.appIcon} />
                    <div>Home</div>
                  </NavLink>
                  <br />
                </div>
              )}
              {apps.map((m) => (
                <div className={styles.appLink} key={m.id}>
                  <a
                    className={styles.linkLabel}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      window.open(validateUrlToNavigate(m.url) ? m.url : `//${m.url}`, '_blank');
                      onClose();
                    }}>
                    <img src={m.image} alt="" onError={(e) => handleImgLoadingError(e)} className={styles.appIcon} />
                    <div>{m.title}</div>
                  </a>
                  <br />
                </div>
              ))}
            </>
          </div>
          <div>
            <button type="button" className={styles.arrowDown} onClick={() => appBarScroll('slidedown')}>
              <Icon name="keyboard_arrow_down" className={styles.arrowIcon} />
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default AppDrawer;
