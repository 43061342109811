import { orderBy } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import { addDeliveryTeam } from '../../../api/authenticated/um/addDeliveryTeam';
import { addTaskTeam } from '../../../api/authenticated/um/addTaskTeam';
import { addUsersToDeliveryTeamRole } from '../../../api/authenticated/um/addUsersToDeliveryTeamRole';
import { addUsersToTaskTeam } from '../../../api/authenticated/um/addUsersToTaskTeam';
import { deactiveDeliveryTeam } from '../../../api/authenticated/um/deactiveDeliveryTeam';
import { deactiveTaskTeam } from '../../../api/authenticated/um/deactiveTaskTeam';
import { editDeliveryTeam } from '../../../api/authenticated/um/editDeliveryTeam';
import {
  IClientAppointingParty,
  IClientTeam,
  IClientTeamManagement,
  getClientTeams,
  IClientDistributionList,
} from '../../../api/authenticated/um/getClientTeams';
import { getDeliveryTeamUsers } from '../../../api/authenticated/um/getDeliveryTeamUsers';
import { IDeliveryTeamUser } from '../../../api/authenticated/um/getDeliveryTeamsTaskTeamsUsers';
import { getTaskTeamUsers } from '../../../api/authenticated/um/getTaskTeamUsers';
import { getUsers } from '../../../api/authenticated/um/getUsers';
import { ITaskTeamUser } from '../../../api/authenticated/um/interfaces/user.interface';
import { reactiveDeliveryTeam } from '../../../api/authenticated/um/reactiveDeliveryTeam';
import { reactiveTaskTeam } from '../../../api/authenticated/um/reactiveTaskTeam';
import { removeUserFromDeliveryTeamRole } from '../../../api/authenticated/um/removeUserFromDeliveryTeamRole';
import { removeUserFromTaskTeam } from '../../../api/authenticated/um/removeUserFromTaskTeam';
import { updateTaskTeam } from '../../../api/authenticated/um/updateTaskTeam';
import { updateUserTaskTeamRole } from '../../../api/authenticated/um/updateUserTaskTeamRole';
import { TeamManagementAction } from '../../../common/constants/TeamManagementAction';
import { DeliveryTeamRole } from '../../../common/enums/DeliveryTeamRole';
import { SortTypes } from '../../../common/enums/SortType';
import { TaskTeamRole } from '../../../common/enums/TaskTeamRole';
import { IBaseUser } from '../../../common/interfaces/baseUser';
import { sort } from '../../../utils/sortHelper';
import LayoutStore from '../../../components/layout/LayoutStore';
import { ITreeNodeData } from '../../../components/shared/TreeView';
import { IUser } from '../../../components/shared/UserSelector';
import { NodeType, NodeTypes } from '../../../components/settings/teamManagement/TeamManagement';
import { ITeamModel } from '../../../components/settings/teamManagement/models/ITeamManagementModel';
import { IHeader, IOption } from '@aurecon-creative-technologies/styleguide';
import {
  IDeliveryTeamAccessed,
  defaultSharePermissions,
} from '../../../components/settings/teamManagement/models/IDeliveryTeamPermissionModel';
import { HeaderTeams, TableHeaderAlignTypes } from '../../../components/settings/teamManagement/models/Types';
import { grantingAccessToOtherTeam } from '../../../api/authenticated/um/grantingAccessToOtherTeam';
import { IAccessibleDeliveryTeam } from '../../../api/authenticated/um/interfaces/deliveryTeamPermission.interface';
import { getAccessibleDeliveryTeams } from '../../../api/authenticated/um/getAccessibleDeliveryTeams';
import { DeliveryTeamAccessPermissionLabels } from '../../../common/enums/DeliveryTeamSharePermission';
import {
  addDeliveryTeamAcceptorUsers,
  getDeliveryTeamAcceptorUsers,
} from '../../../api/authenticated/um/deliveryTeamAcceptorUser';
import { addDistributionList } from '../../../api/authenticated/um/addDistributionList';
import { addUserToDistributionList } from '../../../api/authenticated/um/addUserToDisributionList';
import { removeUserFromDistributionList } from '../../../api/authenticated/um/removeUserFromDistributionList';
import { getDistributionListUsers } from '../../../api/authenticated/um/getDistributionListUsers';
import { getAllUsersForProject } from '../../../api/authenticated/um/getAllUsersForProject';

export class TeamManagementStore {
  constructor() {
    makeAutoObservable(this);
  }

  public treeData: ITreeNodeData<NodeTypes>[] = [];
  public clientTeams: IClientTeamManagement | null = null;
  public selectedUsers: IUser[] = [];
  public systemUsers: IUser[] = [];
  public deliveryTeamUsers: IDeliveryTeamUser[] = [];
  public taskTeamUsers: ITaskTeamUser[] = [];
  public allUsers: IBaseUser[] = [];
  public distributionOptionUsers: IBaseUser[] = [];

  public deliveryTeamAccesses: IDeliveryTeamAccessed[] = [];
  public teamOptions: IOption[] = [];
  public deliveryTeamAcceptorUsers: IUser[] = [];

  public distributionListUsers: IUser[] = [];

  private async reloadTeams(): Promise<void> {
    const teams = await getClientTeams();
    runInAction(() => {
      this.clientTeams = teams;
    });
  }

  public async init(): Promise<void> {
    await this.reloadTeams();
  }

  public async loadDeliveryTeamUsers(projectNumber: string, deliveryTeamId: number) {
    const deliveryTeamUsers = await getDeliveryTeamUsers(projectNumber, deliveryTeamId);
    const orderedData = orderBy(deliveryTeamUsers, (item) => item.name, SortTypes.ASC);
    runInAction(() => (this.deliveryTeamUsers = orderedData));
  }

  public async loadTaskTeamUsers(projectNumber: string, taskTeamId: number) {
    const taskTeamUsers = await getTaskTeamUsers(projectNumber, taskTeamId);
    const orderedData = orderBy(taskTeamUsers, (item) => item.name, SortTypes.ASC);
    runInAction(() => (this.taskTeamUsers = orderedData));
  }

  public searchDeliveryTeamUsers(searchText: string): IUser[] {
    const lowercaseText = searchText?.toLowerCase() ?? '';
    return this.deliveryTeamUsers.filter(
      (u) =>
        !u.deliveryTeamRoleIds.length &&
        (u.name.toLowerCase().includes(lowercaseText) || u.email.toLowerCase().includes(lowercaseText))
    );
  }

  public searchTaskTeamUsers(searchText: string): IUser[] {
    const lowercaseText = searchText?.toLowerCase() ?? '';
    return this.taskTeamUsers.filter(
      (u) =>
        !u.taskTeamRoleIds.length &&
        (u.name.toLowerCase().includes(lowercaseText) || u.email.toLowerCase().includes(lowercaseText))
    );
  }

  public setSelectedUsers(users: IUser[]) {
    runInAction(() => (this.selectedUsers = users));
  }

  public async saveNewTeam(
    projectNumber: string,
    title: string,
    abbreviation: string,
    teamType: string,
    deliveryTeamId?: number
  ): Promise<boolean> {
    let toastMessage = '';

    try {
      const payload = {
        projectNumber,
        title,
        transmittalCode: abbreviation,
      };
      let result;
      switch (teamType) {
        case TeamManagementAction.ADD_DELIVERY_TEAM:
          toastMessage = 'Delivery';
          result = await addDeliveryTeam(payload);
          break;
        case TeamManagementAction.ADD_TASK_TEAM:
          toastMessage = 'Task';
          result = await addTaskTeam({ ...payload, deliveryTeamId: deliveryTeamId ?? 0 });
          break;

        default:
          break;
      }

      if (result) {
        await this.reloadTeams();
        this.getClientData();
        LayoutStore.displayToast('success', `${toastMessage} team has been added successfully.`);
        return true;
      }

      return false;
    } catch {
      LayoutStore.displayToast('error', `${toastMessage} team cannot be added. Please try again later.`);
      return false;
    }
  }

  public async editDeliveryTeam(deliveryTeamId: number, projectNumber: string, title: string): Promise<boolean> {
    try {
      await editDeliveryTeam({
        deliveryTeamId,
        projectNumber,
        title,
      });

      await this.reloadTeams();
      this.getClientData();
      LayoutStore.displayToast('success', 'Delivery team has been edited successfully.');
      return true;
    } catch {
      LayoutStore.displayToast('error', 'Delivery team cannot be edited. Please try again later.');
      return false;
    }
  }

  public async editTaskTeam(taskTeamId: number, projectNumber: string, title: string): Promise<boolean> {
    try {
      await updateTaskTeam({
        taskTeamId,
        projectNumber,
        title,
      });

      await this.reloadTeams();
      this.getClientData();
      LayoutStore.displayToast('success', 'Task team has been edited successfully.');
      return true;
    } catch {
      LayoutStore.displayToast('error', 'Task team cannot be edited. Please try again later.');
      return false;
    }
  }
  public async reactiveTaskTeam(taskTeamId: number, projectNumber: string): Promise<boolean> {
    try {
      await reactiveTaskTeam({
        taskTeamId,
        projectNumber,
      });

      await this.reloadTeams();
      this.getClientData();
      LayoutStore.displayToast('success', 'Task team has been successfully reactivated.');
      return true;
    } catch {
      LayoutStore.displayToast('error', 'Task team was unsuccessfully reactivated.');
      return false;
    }
  }
  public async deactiveTaskTeam(taskTeamId: number, projectNumber: string): Promise<boolean> {
    try {
      await deactiveTaskTeam({
        taskTeamId,
        projectNumber,
      });

      await this.reloadTeams();
      this.getClientData();
      LayoutStore.displayToast('success', 'Task team has been successfully deactivated.');
      return true;
    } catch {
      LayoutStore.displayToast('error', 'Task team was unsuccessfully deactivated.');
      return false;
    }
  }

  public async reactiveDeliveryTeam(deliveryTeamId: number, projectNumber: string): Promise<boolean> {
    try {
      await reactiveDeliveryTeam({
        projectNumber,
        deliveryTeamId,
      });

      await this.reloadTeams();
      this.getClientData();
      LayoutStore.displayToast('success', 'Delivery team has been successfully reactivated.');
      return true;
    } catch {
      LayoutStore.displayToast('error', 'Delivery team was unsuccessfully reactivated.');
      return false;
    }
  }
  public async deactiveDeliveryTeam(deliveryTeamId: number, projectNumber: string): Promise<boolean> {
    try {
      await deactiveDeliveryTeam({
        projectNumber,
        deliveryTeamId,
      });

      await this.reloadTeams();
      this.getClientData();
      LayoutStore.displayToast('success', 'Delivery team has been successfully deactivated.');
      return true;
    } catch {
      LayoutStore.displayToast('error', 'Delivery team was unsuccessfully deactivated.');
      return false;
    }
  }

  public async addUserToDeliveryTeam(projectNumber: string, deliveryTeamId: number, users: IUser[]) {
    try {
      await addUsersToDeliveryTeamRole({
        deliveryTeamId,
        projectNumber,
        users: users.map((u) => ({
          id: u.id,
          email: u.email,
          name: u.name,
        })),
        deliveryTeamRoleId: DeliveryTeamRole.Authoriser,
      });
      LayoutStore.displayToast('success', 'User role has been changed successfully.');
    } catch {
      LayoutStore.displayToast('error', 'User role cannot be changed. Please try again later.');
    }

    await this.loadDeliveryTeamUsers(projectNumber, deliveryTeamId);
  }

  public async addUserToTaskTeam(
    projectNumber: string,
    taskTeamId: number,
    users: IUser[],
    taskTeamRoleId?: TaskTeamRole
  ) {
    try {
      await addUsersToTaskTeam({
        taskTeamId,
        projectNumber,
        users: users.map((u) => ({
          id: u.id,
          email: u.email,
          name: u.name,
        })),
        taskTeamRoleId,
      });

      LayoutStore.displayToast('success', 'User has been added successfully.');
    } catch (error) {
      LayoutStore.displayToast('error', 'User cannot be added. Please try again later.');
    }

    await this.loadTaskTeamUsers(projectNumber, taskTeamId);
  }

  public async removeUserFromDeliveryTeam(projectNumber: string, deliveryTeamId: number, userId: number) {
    try {
      await removeUserFromDeliveryTeamRole({
        deliveryTeamRoleId: DeliveryTeamRole.Authoriser,
        projectNumber,
        userId,
        deliveryTeamId,
      });
      LayoutStore.displayToast('success', 'User has been removed successfully.');
    } catch {
      LayoutStore.displayToast('error', 'User cannot be removed. Please try again later.');
    }

    await this.loadDeliveryTeamUsers(projectNumber, deliveryTeamId);
  }

  public async removeUserFromTaskTeam(projectNumber: string, taskTeamId: number, userId: number) {
    try {
      await removeUserFromTaskTeam({
        projectNumber,
        userId,
        taskTeamId,
      });
      LayoutStore.displayToast('success', 'User has been removed successfully.');
    } catch {
      LayoutStore.displayToast('error', 'User cannot be removed. Please try again later.');
    }
    await this.loadTaskTeamUsers(projectNumber, taskTeamId);
  }

  public async changeTaskTeamUserRole(
    selectedTaskTeam: ITeamModel,
    selectedUser: ITaskTeamUser,
    newRoles: (string | number)[],
    projectNumber: string
  ) {
    if (!projectNumber || !selectedUser) return;

    try {
      let isAddNew = true;
      let taskTeamRoleId = Number(newRoles[0]);

      //remove role
      const removedRole = selectedUser.taskTeamRoleIds.find((r) => !newRoles.includes(r));
      if (removedRole) {
        isAddNew = false;
        taskTeamRoleId = removedRole;
      }

      await updateUserTaskTeamRole({
        taskTeamRoleId: taskTeamRoleId,
        projectNumber: projectNumber,
        userId: selectedUser.id,
        taskTeamId: selectedTaskTeam.id,
        isAddNew: isAddNew,
      });

      await this.loadTaskTeamUsers(projectNumber, selectedTaskTeam.id);
      LayoutStore.displayToast('success', 'User role has been changed successfully');
    } catch {
      LayoutStore.displayToast('error', 'User role cannot changed. Please try again later.');
    }
  }

  public get filteredDeliveryTeamUsers(): IDeliveryTeamUser[] {
    const filteredData = sort(
      (this.deliveryTeamUsers || []).filter((u) => u.deliveryTeamRoleIds.length),
      'name',
      SortTypes.ASC
    );
    return filteredData;
  }

  public get filteredTaskTeamUsers(): ITaskTeamUser[] {
    return this.taskTeamUsers;
  }

  public getClientData() {
    const treeData: ITreeNodeData<NodeTypes>[] =
      this.clientTeams?.programmes.map((p) => {
        return {
          id: p.id,
          key: `${p.id}`,
          title: p.title,
          type: NodeType.Programme,
          path: `${p.id}`,
          disabled: false,
          showLine: true,
          childrenNodes: p.projects.map((pr) => {
            return {
              id: pr.projectNumber,
              key: `${p.id}/${pr.projectNumber}`,
              title: pr.title,
              type: NodeType.Project,
              path: `${p.id}/${pr.projectNumber}`,
              childrenNodes: this.buildTeamData(pr.deliveryTeams, p.id, pr.projectNumber)
                .concat(this.buildAppointingParties(pr.appointingParties, p.id, pr.projectNumber))
                .concat(this.buildExternalUserNode(p.id, pr.projectNumber))
                .concat(this.buildDistributionList(pr.distributionList, p.id, pr.projectNumber)),
            };
          }),
        };
      }) || [];

    runInAction(() => {
      this.treeData = sort(treeData, 'title', SortTypes.ASC);
    });
  }

  public async getOptionUsers(projectNumber: string) {
    if (!projectNumber) return;

    try {
      const users = sort((await getUsers(projectNumber, true)) || [], 'name', SortTypes.ASC);
      runInAction(() => {
        this.allUsers = users;
      });
    } catch {
      runInAction(() => {
        this.allUsers = [];
      });
    }
  }

  public applySort(columnName: string, direction: SortTypes) {
    if (direction != SortTypes.NONE) {
      if (columnName === 'role') {
        this.taskTeamUsers = orderBy(this.taskTeamUsers, (item) => item.taskTeamRoleIds, direction);
      }

      this.taskTeamUsers = sort(this.taskTeamUsers, columnName, direction);
    }
  }

  private buildExternalUserNode(programmeId: number, projectNumber: string): ITreeNodeData<NodeTypes>[] {
    const nodeId = `Ex-${projectNumber}`;
    return [
      {
        id: `Ex-${projectNumber}`,
        key: `${programmeId}/${projectNumber}/${nodeId}`,
        path: `${programmeId}/${projectNumber}/${nodeId}`,
        type: NodeType.ExternalUsers,
        title: `External Users`,
        childrenNodes: [],
        selectable: true,
        data: {
          projectNumber,
        },
      },
    ];
  }

  private buildAppointingParties(
    appointingParties: IClientAppointingParty[],
    programmeId: number,
    projectNumber: string
  ): ITreeNodeData<NodeTypes>[] {
    return appointingParties.map((ap) => {
      return {
        id: ap.id,
        key: `${programmeId}/${projectNumber}/${ap.id}`,
        path: `${programmeId}/${projectNumber}/${ap.id}`,
        type: NodeType.AppointingParty,
        title: `${ap.title} (${ap.code})`,
        childrenNodes: [],
        selectable: true,
        data: {
          projectNumber,
        },
      };
    });
  }
  private buildDistributionList(
    distributionList: IClientDistributionList[],
    programmeId: number,
    projectNumber: string
  ): ITreeNodeData<NodeTypes>[] {
    const groups: ITreeNodeData<NodeTypes>[] = distributionList.map((list) => ({
      id: list.id,
      key: `${programmeId}/${projectNumber}/${list.id}`,
      path: `${programmeId}/${projectNumber}/${list.id}`,
      type: NodeType.DistributionList,
      title: `${list.title}`,
      data: {
        projectNumber,
        programmeId,
      },
      childrenNodes: [],
      selectable: true,
      disabled: !list.active,
    }));
    const distributionListId = `${programmeId}-${projectNumber}-DistributionList`;
    return [
      {
        id: distributionListId,
        key: `${programmeId}/${projectNumber}/${distributionListId}`,
        path: `${programmeId}/${projectNumber}/${distributionListId}`,
        type: NodeType.ParentDistributionList,
        title: 'Distribution List',
        childrenNodes: groups,
        selectable: true,
        data: {
          projectNumber,
        },
        listActions: [
          {
            action: TeamManagementAction.ADD_DISTRIBUTION_LIST,
            actionTitle: 'Create New List',
            actionData: {
              projectNumber,
            },
          },
        ],
      },
    ];
  }
  private buildTeamData(teams: IClientTeam[], programmeId: number, projectNumber: string): ITreeNodeData<NodeTypes>[] {
    const groups: ITreeNodeData<NodeTypes>[] = teams.map((team) => ({
      id: team.id,
      key: `${programmeId}/${projectNumber}/${team.id}`,
      path: `${programmeId}/${projectNumber}/${team.id}`,
      type: NodeType.DeliveryTeam,
      title: `${team.title} (${team.code})`,
      data: {
        projectNumber,
        programmeId,
      },
      listActions: team.active
        ? [
            {
              action: TeamManagementAction.ADD_TASK_TEAM,
              actionTitle: 'Create New Task Team',
              actionData: {
                projectNumber: projectNumber,
                deliveryTeamId: team.id,
              },
            },
          ]
        : undefined,
      childrenNodes: team.taskTeams.map((t) => {
        return {
          id: t.id,
          key: `${programmeId}/${projectNumber}/${team.id}/${t.id}`,
          path: `${programmeId}/${projectNumber}/${team.id}/${t.id}`,
          type: NodeType.TaskTeam,
          title: `${t.title} (${t.code})`,
          data: {
            projectNumber,
          },
          childrenNodes: [],
          selectable: true,
          disabled: !t.active,
        };
      }),
      selectable: true,
      disabled: !team.active,
    }));
    const projectAppointedPartyId = `${programmeId}-${projectNumber}-AppointedParty`;
    return [
      {
        id: projectAppointedPartyId,
        key: `${programmeId}/${projectNumber}/${projectAppointedPartyId}`,
        path: `${programmeId}/${projectNumber}/${projectAppointedPartyId}`,
        type: NodeType.AppointedParty,
        title: 'Appointed Parties',
        childrenNodes: groups,
        selectable: false,
        listActions: [
          {
            action: TeamManagementAction.ADD_DELIVERY_TEAM,
            actionTitle: 'Create New Delivery Team',
            actionData: {
              projectNumber,
            },
          },
        ],
      },
    ];
  }

  public getDeliveryTeams(programmeId: number | null, projectNumber: string, deliveryTeamId: number) {
    if (!programmeId) return [];
    const deliveryTeams =
      this.clientTeams?.programmes
        ?.find((p) => p.id === programmeId)
        ?.projects.find((p) => p.projectNumber === projectNumber)
        ?.deliveryTeams.filter((d) => d.id !== deliveryTeamId && d.active) ?? [];

    const options = deliveryTeams.map((d: IClientTeam) => {
      return {
        id: d.id,
        value: `${d.title} (${d.code})`,
        disabled: this.deliveryTeamAccesses.some((x) => x.accessibleDeliveryTeamId == d.id),
      };
    });

    runInAction(() => {
      this.teamOptions = sort(options, 'value', SortTypes.ASC);
    });
  }

  public disableSelectedOption(option: IOption | null) {
    if (!option) return;
    runInAction(() => {
      this.teamOptions.forEach((x) => {
        if (x.id === option.id) {
          x.disabled = true;
        }
      });
    });
  }

  public addDTToAccessList(team: IDeliveryTeamAccessed) {
    runInAction(() => {
      this.deliveryTeamAccesses.unshift(team);
    });
  }

  public resetDTToAccessList() {
    runInAction(() => {
      this.deliveryTeamAccesses = [];
    });
  }

  public deleteDeliveryTeamAccesses(id: number) {
    runInAction(() => {
      this.deliveryTeamAccesses = this.deliveryTeamAccesses.filter((d) => d.accessibleDeliveryTeamId !== id);
      this.teamOptions.forEach((x) => {
        if (x.id === id) {
          x.disabled = false;
        }
      });
    });
  }

  public get sharePermissionTableColumns() {
    let tableHeaders = [
      {
        label: HeaderTeams.DELIVERY_TEAM,
        align: TableHeaderAlignTypes.LEFT,
      },
    ];
    tableHeaders = tableHeaders.concat([
      ...defaultSharePermissions.map((x) => {
        return {
          label: x.title,
          align: TableHeaderAlignTypes.LEFT,
        };
      }),
    ]);
    tableHeaders.push({
      label: HeaderTeams.REMOVE,
      align: TableHeaderAlignTypes.CENTER,
    });
    return tableHeaders as IHeader[];
  }

  public async getDeliveryTeamAccessible(projectNumber: string, deliveryTeamId: number) {
    const res = await getAccessibleDeliveryTeams(projectNumber, deliveryTeamId);
    const accessibleDeliveryTeams = res.map((x) => {
      return {
        accessibleDeliveryTeamId: x.accessibleDeliveryTeamId,
        accessibleDeliveryTeamTitle: x.accessibleDeliveryTeamTitle,
        permissions: sort(
          x.permissions.map((p) => {
            return {
              permissionId: p.permissionId,
              title: DeliveryTeamAccessPermissionLabels[p.permissionId],
              canAccess: p.canAccess,
            };
          }),
          'permissionId',
          SortTypes.ASC
        ),
      };
    });
    runInAction(() => {
      this.deliveryTeamAccesses = sort(accessibleDeliveryTeams, 'accessibleDeliveryTeamTitle', SortTypes.ASC);
    });
  }

  public async grantSharePermission(projectNumber: string, deliveryTeamId: number) {
    const teamAccessibleList: IAccessibleDeliveryTeam[] = [];
    this.deliveryTeamAccesses.forEach((x) => {
      x.permissions
        .filter((y) => y.canAccess)
        ?.forEach((p) => {
          teamAccessibleList.push({
            accessibleDeliveryTeamId: x.accessibleDeliveryTeamId,
            accessibleDeliveryTeamTitle: x.accessibleDeliveryTeamTitle,
            permissionId: p.permissionId,
            canAccess: p.canAccess,
          });
        });
    });

    try {
      await grantingAccessToOtherTeam({
        projectNumber: projectNumber,
        deliveryTeamId: deliveryTeamId,
        accessibleDeliveryTeams: teamAccessibleList,
      });
      LayoutStore.displayToast('success', 'Granting access to other teams successfully.');
    } catch (e) {
      LayoutStore.displayToast(
        'error',
        'Granting access to other teams that have not succeeded. Please try again later.'
      );
    }
  }

  public async addDeliveryTeamAcceptorUsers(projectNumber: string, deliveryTeamId: number) {
    try {
      await addDeliveryTeamAcceptorUsers({
        projectNumber: projectNumber,
        deliveryTeamId: deliveryTeamId,
        userIds: this.deliveryTeamAcceptorUsers.map((x) => x.id),
      });
      await this.fetchDeliveryTeamAcceptorUsers(projectNumber, deliveryTeamId);
      LayoutStore.displayToast('success', 'Add delivery team acceptor users successfully.');
    } catch (e) {
      LayoutStore.displayToast(
        'error',
        'Add delivery team acceptor users that have not succeeded. Please try again later.'
      );
    }
  }

  public async fetchDeliveryTeamAcceptorUsers(projectNumber: string, deliveryTeamId: number) {
    const res = await getDeliveryTeamAcceptorUsers(projectNumber, deliveryTeamId);
    const users = res.map((x) => {
      return {
        id: x.userId,
        email: x.email,
        name: x.name,
      };
    });
    runInAction(() => {
      this.deliveryTeamAcceptorUsers = [...users];
    });
  }

  public setDeliveryTeamAcceptorUsers(users: IUser[]) {
    runInAction(() => {
      this.deliveryTeamAcceptorUsers = [...users];
    });
  }
  public async saveNewDistributionList(projectNumber: string, title: string): Promise<boolean> {
    try {
      const params = {
        projectNumber,
        title,
      };
      const result = await addDistributionList(params);
      if (result) {
        await this.reloadTeams();
        this.getClientData();
        LayoutStore.displayToast('success', 'Distribution List has been added successfully.');
        return true;
      }

      return false;
    } catch {
      LayoutStore.displayToast('error', 'Distribution List cannot be added. Please try again later.');
      return false;
    }
  }

  public async addUserToDistributionList(projectNumber: string, distributionListId: number, userId: number) {
    try {
      await addUserToDistributionList({
        projectNumber,
        distributionListId,
        userId,
      });
      LayoutStore.displayToast('success', 'User has been added successfully.');
      await this.loadDistributionListUsers(projectNumber, distributionListId);
    } catch {
      LayoutStore.displayToast('error', 'User cannot add. Please try again later.');
    }
  }
  public async removeUserFromDistributionList(projectNumber: string, distributionListId: number, userId: number) {
    try {
      await removeUserFromDistributionList({
        projectNumber,
        distributionListId,
        userId,
      });
      LayoutStore.displayToast('success', 'User has been removed successfully.');
      await this.loadDistributionListUsers(projectNumber, distributionListId);
    } catch {
      LayoutStore.displayToast('error', 'User cannot be removed. Please try again later.');
    }
  }

  public async getAllUsersList(projectNumber: string) {
    if (!projectNumber) return;

    try {
      const users = sort((await getAllUsersForProject(projectNumber)) || [], 'name', SortTypes.ASC);
      runInAction(() => {
        this.distributionOptionUsers = users;
      });
    } catch {
      runInAction(() => {
        this.distributionOptionUsers = [];
      });
    }
  }

  public async loadDistributionListUsers(projectNumber: string, distributionListId: number) {
    if (!projectNumber) return;

    try {
      const users = await getDistributionListUsers(projectNumber, distributionListId);

      runInAction(() => {
        this.distributionListUsers = users.map((x) => {
          return {
            id: x.id,
            name: x.name,
            email: x.email,
          };
        }) as IUser[];
      });
    } catch {
      runInAction(() => {
        this.distributionListUsers = [];
      });
    }
  }

  public applySortDistributionList(columnName: string, direction: SortTypes) {
    if (direction != SortTypes.NONE) {
      this.distributionListUsers = sort(this.distributionListUsers, columnName, direction);
    }
  }
}

export default new TeamManagementStore();
