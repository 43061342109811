import { AxiosError } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import { IFileContainer } from '../../../api/authenticated/cms/FileContainerModel';
import {
  addTransmittalMessage,
  IAddTransmittalMessage,
} from '../../../api/authenticated/transmittals/addTransmittalMessage';
import { IUserDetail } from '../../../api/authenticated/transmittals/createTransmittal';
import { getTransmittal, IAttachmentFile, ITransmittal } from '../../../api/authenticated/transmittals/getTransmittal';
import { getTransmittalMessageAttachmentFiles } from '../../../api/authenticated/transmittals/getTransmittalMessageAttachmentFiles';
import {
  getProjectMembersAndTeams,
  IProjectMembersAndTeams,
  IProjectNotifyList,
  IUser,
} from '../../../api/authenticated/um/getProjectMembersAndTeams';
import { ITeamChartResult } from '../../TeamChart/interface/TeamChart.interface';
import { TaskTeamItem } from '../../transmittals/navBarSelector/ItemType';
import NavBarSelectorStore from '../../transmittals/navBarSelector/NavBarSelectorStore';
import { TransmittalFileType, TransmittalFileTypeText, TransmittalTeamChartType } from '../../transmittals/Types';
import { isHTML } from '../../../utils/miscUtils';

export class AddTransmittalMessageStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public isLoadingTransmittal = false;
  public hasUnsavedChanges = false;
  public transmittal: ITransmittal | null = null;
  public transmittalResponse: IAddTransmittalMessage | null = null;
  public projectMembersAndTeams: IProjectMembersAndTeams | null = null;
  public subjectErrorMessage: string | undefined;
  public showResponse = false;

  public errorCode: number | null = null;
  public errorMessage: string | null = null;

  public isProcessing = false;
  public supportingFiles: IAttachmentFile[] = [];
  public contentFiles: IFileContainer[] = [];
  public selectedContentFiles: IFileContainer[] = [];
  public showUploadSupportFileModal = false;
  public showContentUploadFileModal = false;
  public isOpenFiles = false;
  public openPanelIds = new Set<string>();
  public showUploadSupportFileErrorModal = false;
  public uploadSupportFileErrorMessage?: string;
  public selectedVisibleTos?: IProjectMembersAndTeams;

  public async init(transmittalTitle?: string) {
    runInAction(() => {
      this.transmittal = null;
      this.contentFiles = [];
      this.supportingFiles = [];
      this.isProcessing = false;
      this.isLoadingTransmittal = transmittalTitle ? true : false;
      this.showUploadSupportFileErrorModal = false;
    });

    if (!transmittalTitle) return;
    this.clearError();
    try {
      const result = await getTransmittal(
        transmittalTitle,
        NavBarSelectorStore.selectedItem?.project?.projectNumber ?? null
      );
      runInAction(() => {
        this.transmittal = result?.transmittal || null;
        if (!this.transmittal) return;
        const selectedItem = NavBarSelectorStore.selectedItem as TaskTeamItem;
        const transmittalMessages = [...this.transmittal.transmittalMessages];
        transmittalMessages.sort((a, b) => (a.createdDate < b.createdDate ? 1 : -1));
        const lastMessage = transmittalMessages[0];

        this.transmittalResponse = {
          projectNumber: NavBarSelectorStore.selectedItem?.project.projectNumber,
          transmittalId: this.transmittal.id,
          transmittalTitle: this.transmittal.title,
          subject: lastMessage.subject,
          message: '',
          taskTeamId: selectedItem.taskTeam?.id ?? null,
          dueDate: this.transmittal.dueDate,
          selectedNotifyUsers: [],
          selectedNotifyAppointingPartyUsers: [],
          notifyToDistributionList: [],
          selectedVisibleUsers: [],
          selectedVisibleAppointingPartyUsers: [],
          selectedVisibleDeliveryTeamIds: [],
          selectedVisibleTaskTeamIds: [],
          selectedVisibleAppointingPartyIds: [],
          draft: false,
          isClosed: this.transmittal.closed,
          visibleToTaskTeamUsers: [],
          visibleToTaskTeams: [],
          visibleToDeliveryTeams: [],
          visibleToAppointingParties: [],
          canRespond: this.transmittal.canRespond,
          visibleToDistributionList: [],
        };
        this.subjectErrorMessage = undefined;
        if (this.transmittalResponse.subject.length > 1000)
          this.subjectErrorMessage = 'Subject must not exceed 1000 characters';
      });
    } catch (err) {
      this.setError(err as AxiosError<string>);
    } finally {
      runInAction(() => {
        this.isLoadingTransmittal = false;
      });
    }
  }

  onSubjectChange = (value: string) => {
    runInAction(() => {
      if (!this.transmittalResponse || !this.transmittal) return;
      if (value !== this.transmittal.transmittalMessages[0].subject) this.hasUnsavedChanges = true;
      if (value.length > 1000) this.subjectErrorMessage = 'Subject must not exceed 1000 characters';
      else if (isHTML(value)) this.subjectErrorMessage = 'Cannot contain special characters / tags';
      else this.subjectErrorMessage = undefined;
      this.transmittalResponse.subject = value;
    });
  };

  onMessageChange = (value: string) => {
    runInAction(() => {
      if (!this.transmittalResponse || !this.transmittal) return;
      if (value !== this.transmittal.transmittalMessages[0].message) this.hasUnsavedChanges = true;
      //check if the value is empty
      if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
        this.transmittalResponse.message = '';
      } else {
        this.transmittalResponse.message = value;
      }
    });
  };

  public getTransmittalMessages() {
    return this.transmittal?.transmittalMessages
      .slice()
      .sort((a, b) => b.createdDate.getTime() - a.createdDate.getTime());
  }

  getNotifyUserCounts = () => {
    if (!this.transmittal) return 0;
    return (
      this.transmittal.allNotifiedUsers.length +
      (this.transmittal.allNotifiedUsers.some((t) => t.id === this.transmittal?.createdByUserId) ? 0 : 1)
    );
  };

  getVisibleUserCounts = () => {
    if (!this.transmittal || this.transmittal === null) return 0;
    const allUniqueByKey = [
      ...new Map(this.transmittal.transmittalVisibilityAllUsers.map((item) => [item['userId'], item])).values(),
    ];
    const externalUsersUniqueByKey = [
      ...new Map(this.transmittal.transmittalVisibilityExternalUsers.map((item) => [item['id'], item])).values(),
    ];
    const teamUsersUniqueByKey = [
      ...new Map(this.transmittal.transmittalVisibilityTaskTeamUsers.map((item) => [item['id'], item])).values(),
    ];
    const appointingPartyUsersUniqueByKey = [
      ...new Map(this.transmittal.transmittalVisibilityAppointingPartyUsers.map((item) => [item['id'], item])).values(),
    ];

    const allUsers = allUniqueByKey
      .map((u) => {
        return u.userName.toLowerCase();
      })
      .concat(
        externalUsersUniqueByKey.map((u) => {
          return u.name.toLowerCase();
        })
      )
      .concat(
        teamUsersUniqueByKey.map((u) => {
          return u.name.toLowerCase();
        })
      )
      .concat(
        appointingPartyUsersUniqueByKey.map((u) => {
          return u.name.toLowerCase();
        })
      );
    return allUsers.length;
  };

  public getMatchedNotifiedUsers(searchText: string): IProjectNotifyList {
    if (!this.projectMembersAndTeams)
      return {
        users: [],
        distributionList: [],
        appointingPartyUsers: [],
      } as IProjectNotifyList;
    return {
      users: this.projectMembersAndTeams.users.filter(
        (r) =>
          (this.compareText(r.userName, searchText) || this.compareText(r.userEmail, searchText)) &&
          !this.transmittalResponse?.selectedNotifyUsers.some((u) => u.id === r.userId)
      ),
      distributionList: this.projectMembersAndTeams.distributionList.filter(
        (r) =>
          this.compareText(r.distributionListName, searchText) &&
          !this.transmittalResponse?.notifyToDistributionList.some((u) => u.distributionListId === r.distributionListId)
      ),
      appointingPartyUsers: this.projectMembersAndTeams.appointingPartyUsers.filter(
        (r) =>
          (this.compareText(r.userName, searchText) || this.compareText(r.userEmail, searchText)) &&
          !this.transmittalResponse?.selectedNotifyAppointingPartyUsers.some((u) => u.id === r.userId)
      ),
    };
  }

  public onSelectedNotifiedUsersUpdated(selectedItems: IProjectNotifyList) {
    runInAction(() => {
      if (!this.transmittalResponse) return;
      this.hasUnsavedChanges = true;
      this.transmittalResponse.selectedNotifyUsers = [];
      this.transmittalResponse.selectedNotifyAppointingPartyUsers = [];
      this.transmittalResponse.notifyToDistributionList = [];

      selectedItems.users.forEach((u) => {
        const user = this.projectMembersAndTeams?.users.find(
          (r) => r.userId === u.userId && r.taskTeamId === u.taskTeamId && r.deliveryTeamId === u.deliveryTeamId
        );

        if (!user) return;
        this.transmittalResponse?.selectedNotifyUsers.push({
          id: user.userId,
          email: user.userEmail,
          name: user.userName,
          taskTeamId: user.taskTeamId,
          deliveryTeamId: user.deliveryTeamId,
          deliveryTeamTitle: user.deliveryTeamTitle,
          taskTeamTitle: user.taskTeamTitle,
          taskTeamCode: user.taskTeamCode,
          isExternal: user.isExternal,
        });
      });

      selectedItems.appointingPartyUsers.forEach((u) => {
        const user = this.projectMembersAndTeams?.appointingPartyUsers.find(
          (r) => r.userId === u.userId && r.appointingPartyId === u.appointingPartyId
        );

        if (!user) return;
        this.transmittalResponse?.selectedNotifyAppointingPartyUsers.push({
          id: user.userId,
          email: user.userEmail,
          name: user.userName,
          appointingPartyId: user.appointingPartyId,
        });
      });

      this.transmittalResponse.notifyToDistributionList = selectedItems.distributionList ?? [];
    });
  }

  public getMatchedVisibilityItems(searchText: string): IProjectMembersAndTeams {
    if (!this.projectMembersAndTeams)
      return {
        users: [],
        deliveryTeams: [],
        taskTeams: [],
        appointingParties: [],
        distributionList: [],
        appointingPartyUsers: [],
      } as IProjectMembersAndTeams;
    return {
      users: this.projectMembersAndTeams.users
        .filter(
          (r) =>
            (this.compareText(r.userName, searchText) || this.compareText(r.userEmail, searchText)) &&
            !this.transmittalResponse?.selectedVisibleUsers.some((u) => u.id === r.userId) &&
            !this.transmittal?.transmittalVisibilityTaskTeamUsers.some((u) => u.id === r.userId)
        )
        .slice(0, 4),
      deliveryTeams: this.projectMembersAndTeams.deliveryTeams
        .filter(
          (r) =>
            this.compareText(r.deliveryTeamTitle, searchText) &&
            !this.transmittalResponse?.selectedVisibleDeliveryTeamIds.some((d) => d === r.deliveryTeamId) &&
            !this.transmittal?.transmittalVisibilityDeliveryTeams.some((d) => d.id === r.deliveryTeamId)
        )
        .slice(0, 4),
      taskTeams: this.projectMembersAndTeams.taskTeams
        .filter(
          (r) =>
            this.compareText(r.taskTeamTitle, searchText) &&
            !this.transmittalResponse?.selectedVisibleTaskTeamIds.some((t) => t === r.taskTeamId) &&
            !this.transmittal?.transmittalVisibilityTaskTeams.some((t) => t.id === r.taskTeamId)
        )
        .slice(0, 4),
      appointingParties: this.projectMembersAndTeams.appointingParties
        .filter(
          (r) =>
            this.compareText(r.appointingPartyTitle, searchText) &&
            !this.transmittalResponse?.selectedVisibleAppointingPartyIds.some((a) => a === r.appointingPartyId) &&
            !this.transmittal?.transmittalVisibilityAppointingParties.some((a) => a.id === r.appointingPartyId)
        )
        .slice(0, 4),
      distributionList: this.projectMembersAndTeams.distributionList
        .filter(
          (r) =>
            this.compareText(r.distributionListName, searchText) &&
            !this.transmittalResponse?.visibleToDistributionList.some((u) => u.id === r.distributionListId)
        )
        .slice(0, 4),
      appointingPartyUsers: this.projectMembersAndTeams.appointingPartyUsers
        .filter(
          (r) =>
            (this.compareText(r.userName, searchText) || this.compareText(r.userEmail, searchText)) &&
            !this.transmittalResponse?.selectedNotifyAppointingPartyUsers.some((u) => u.id === r.userId) &&
            !this.transmittal?.transmittalVisibilityAppointingPartyUsers.some((u) => u.id === r.userId)
        )
        .slice(0, 4),
    };
  }

  public onSelectedVisibilityItemsUpdated(selectedProjectMembersAndTeams: IProjectMembersAndTeams) {
    runInAction(() => {
      if (!this.transmittalResponse) return;
      this.transmittalResponse.selectedVisibleUsers = [];
      this.transmittalResponse.selectedVisibleAppointingPartyUsers = [];
      this.hasUnsavedChanges = true;

      selectedProjectMembersAndTeams.users.forEach((user) => {
        this.transmittalResponse?.selectedVisibleUsers.push({
          id: user.userId,
          email: user.userEmail,
          name: user.userName,
          taskTeamId: user.taskTeamId,
          deliveryTeamId: user.deliveryTeamId,
          deliveryTeamTitle: user.deliveryTeamTitle,
          taskTeamCode: user.taskTeamCode,
          taskTeamTitle: user.taskTeamTitle,
          isExternal: user.isExternal,
        });
      });

      selectedProjectMembersAndTeams.appointingPartyUsers.forEach((user) => {
        this.transmittalResponse?.selectedVisibleAppointingPartyUsers.push({
          id: user.userId,
          email: user.userEmail,
          name: user.userName,
          appointingPartyId: user.appointingPartyId,
        });
      });

      this.transmittalResponse.visibleToDeliveryTeams = selectedProjectMembersAndTeams.deliveryTeams ?? [];
      this.transmittalResponse.visibleToTaskTeams = selectedProjectMembersAndTeams.taskTeams ?? [];
      this.transmittalResponse.visibleToAppointingParties = selectedProjectMembersAndTeams.appointingParties ?? [];
      this.transmittalResponse.visibleToDistributionList = selectedProjectMembersAndTeams.distributionList ?? [];

      this.transmittalResponse.selectedVisibleDeliveryTeamIds = selectedProjectMembersAndTeams.deliveryTeams.map(
        (t) => t.deliveryTeamId
      );

      this.transmittalResponse.selectedVisibleTaskTeamIds = this.transmittalResponse.visibleToTaskTeams.map(
        (t) => t.taskTeamId
      );

      this.transmittalResponse.selectedVisibleAppointingPartyIds =
        this.transmittalResponse.visibleToAppointingParties.map((t) => t.appointingPartyId);
    });
  }

  public async onSubmitTransmittalResponse() {
    if (!this.transmittalResponse) return;
    runInAction(() => {
      this.errorMessage = null;
      this.isProcessing = true;
    });
    try {
      this.transmittalResponse.contentFileIds = this.contentFiles
        .filter((f) => !!f.releasedFileId)
        .flatMap((f) =>
          f.containerFiles!.map((x) => ({
            releasedFileContainerId: f.releasedFileId,
            containerFileId: x.containerFileId!,
          }))
        );
      this.transmittalResponse.notifyToDistributionList = this.transmittalResponse.notifyToDistributionList.map(
        (t) => t.distributionListId
      );
      this.transmittalResponse.visibleToDistributionList = this.transmittalResponse.visibleToDistributionList.map(
        (t) => t.distributionListId
      );
      await addTransmittalMessage(this.transmittalResponse);
    } catch (err) {
      this.setError(err as AxiosError<string>);
      runInAction(() => {
        this.isProcessing = false;
      });
    }
  }

  public setDraftInfo(transmittalMessageId: number) {
    runInAction(() => {
      if (this.transmittalResponse) {
        this.transmittalResponse.transmittalMessageId = transmittalMessageId;
      }
    });
  }

  public async toggleUploadFileModal(open: boolean, uploadSucceeded?: boolean, errorMessage?: string) {
    runInAction(() => {
      this.showUploadSupportFileModal = open;
    });

    if (!uploadSucceeded && errorMessage) {
      runInAction(() => {
        this.showUploadSupportFileErrorModal = true;
        this.uploadSupportFileErrorMessage = errorMessage;
      });
    }

    // load draft supporting files
    if (this.transmittalResponse && this.transmittalResponse.transmittalMessageId && this.transmittal) {
      const supportingFiles = await getTransmittalMessageAttachmentFiles(
        this.transmittal?.projectNumber,
        this.transmittal.id,
        this.transmittalResponse.transmittalMessageId
      );
      this.supportingFiles = [...supportingFiles];
    }
  }

  public get getUploadSupportingFiles() {
    return this.supportingFiles;
  }

  public panelToggle(id: string) {
    runInAction(() => {
      this.openPanelIds.has(id) ? this.openPanelIds.delete(id) : this.openPanelIds.add(id);
    });
  }

  public setContentFiles(files: IFileContainer[]) {
    const newFiles = files.filter((file) => !this.contentFiles.some((contentFile) => contentFile.id === file.id));
    runInAction(() => {
      this.contentFiles = [...this.contentFiles, ...newFiles];
    });
  }

  public setSelectedContentFiles(files: IFileContainer[]) {
    const newFiles = files.filter(
      (file) => !this.selectedContentFiles.some((contentFile) => contentFile.id === file.id)
    );

    runInAction(() => {
      this.selectedContentFiles = [...this.selectedContentFiles, ...newFiles];
    });
  }

  public removeContentFile(fileId: number, filetype: TransmittalFileType) {
    if (fileId <= 0) return;
    if (filetype === TransmittalFileTypeText.FILE_CONTAINER) {
      const idx = this.contentFiles.findIndex((f) => f.id === fileId);
      if (idx < 0) return;

      this.contentFiles.splice(idx, 1);
      return;
    }
    if (filetype === TransmittalFileTypeText.CONTAINER_FILE) {
      const selectedContainerFile = this.contentFiles
        .flatMap((x) => x.containerFiles!)
        .filter((file) => file.containerFileId === fileId);
      if (selectedContainerFile.length === 1) {
        const idx = this.contentFiles.findIndex((f) =>
          selectedContainerFile.map((m) => m.fileContainerId).includes(f.id)
        );
        if (idx < 0) return;
        this.contentFiles.splice(idx, 1);
        return;
      }
      const updatedContentFiles = this.contentFiles.map((m) => {
        return {
          ...m,
          containerFiles: m.containerFiles!.filter(
            (f) => !selectedContainerFile.map((m) => m.containerFileId).includes(f.containerFileId)
          ),
        };
      });
      this.contentFiles = [...updatedContentFiles];
    }
  }

  public setIsOpenFiles(open: boolean) {
    runInAction(() => {
      this.isOpenFiles = open;
    });
  }

  private compareText(firstText: string, secondText: string) {
    return firstText.toLowerCase().trim().indexOf(secondText.toLowerCase().trim()) > -1;
  }

  public async getOptionUsers() {
    if (!NavBarSelectorStore.selectedItem?.project) return;

    try {
      const projectMembersAndTeams = await getProjectMembersAndTeams(
        NavBarSelectorStore.selectedItem.project.projectNumber
      );
      projectMembersAndTeams.users.sort((a, b) => (a.userName > b.userName ? 1 : -1));
      projectMembersAndTeams.taskTeams.sort((a, b) => (a.taskTeamTitle > b.taskTeamTitle ? 1 : -1));

      runInAction(() => {
        this.projectMembersAndTeams = projectMembersAndTeams;
      });
    } catch (err) {
      this.setError(err as AxiosError<string>);
      runInAction(() => {
        this.projectMembersAndTeams = null;
      });
    }
  }

  public setShowResponse() {
    this.showResponse = !this.showResponse;
  }

  public setShowUploadSupportFileErrorModal(open: boolean) {
    runInAction(() => {
      this.showUploadSupportFileErrorModal = open;
    });
  }

  public get disableTransmitButton(): boolean {
    if (!this.transmittalResponse) return true;

    return (
      this.transmittalResponse.isClosed ||
      !this.transmittalResponse.subject ||
      (this.transmittalResponse.subject && this.transmittalResponse.subject.length > 1000) ||
      !this.transmittalResponse.message ||
      this.isProcessing
    );
  }
  public get projectNumber() {
    return NavBarSelectorStore.selectedItem?.project.projectNumber;
  }

  public selectedUsersForTeamChart(dataType?: TransmittalTeamChartType) {
    if (!dataType) return [];
    if (dataType === 'Notify')
      return this.transmittalResponse?.selectedNotifyUsers.map((x) => {
        return {
          ...x,
          userId: x.id,
        } as IUserDetail;
      });
    if (dataType === 'Visible')
      return this.transmittalResponse?.selectedVisibleUsers?.map((x) => {
        return {
          ...x,
          userId: x.id,
        } as IUserDetail;
      });
  }
  public setNotifierUsersFromTeamChart(teamChartData: ITeamChartResult) {
    if (!this.transmittalResponse) return;

    const originIds = this.transmittalResponse.selectedNotifyUsers.map((x) => {
      return x.id;
    });
    const newIds = teamChartData.taskTeamUsers.map((n) => {
      return n.id;
    });
    const removeIds = originIds?.filter((u) => !newIds.includes(u));
    const addIds = newIds.filter((u) => !originIds?.includes(u));

    this.removeSelectedNotifyUserByIds(removeIds);
    this.setAddTeamChartNotifyDeliveryTeam(teamChartData);
    this.setAddTeamChartNotifyTaskTeam(teamChartData, addIds);
    this.setAddTeamChartNotifyExternalTeam(teamChartData);
  }

  private removeSelectedNotifyUserByIds(removeIds: number[]) {
    if (!removeIds.length || !this.transmittalResponse?.selectedNotifyUsers.length) return;

    this.transmittalResponse.selectedNotifyUsers = this.transmittalResponse?.selectedNotifyUsers.filter(
      (x) => !removeIds.includes(x.id)
    );
  }

  private setAddTeamChartNotifyDeliveryTeam(teamChartData: ITeamChartResult) {
    if (!teamChartData.deliveryUsers.length) return;

    teamChartData.deliveryUsers.forEach((u) => {
      const user = this.projectMembersAndTeams?.users.find(
        (r) => r.userId === u.id && r.deliveryTeamId === u.deliveryTeamId
      );

      if (!user) return;
      const selectedUser = this.transmittalResponse?.selectedNotifyUsers.find(
        (u) => u.id === user.userId && u.deliveryTeamId === user.deliveryTeamId
      );
      if (selectedUser) return;
      this.addSelectedNotifyUser(user);
    });
  }

  private setAddTeamChartNotifyExternalTeam(teamChartData: ITeamChartResult) {
    if (!this.transmittalResponse || !teamChartData.externalUsers.length) return;

    teamChartData.externalUsers.forEach((u) => {
      const user = this.getTeamMemberById(u.id);
      if (!user) return;
      const selectedUser = this.transmittalResponse?.selectedNotifyUsers.find(
        (u) => u.id === user.userId && u.isExternal === user.isExternal
      );
      if (selectedUser) return;
      this.addSelectedNotifyUser(user);
    });
  }

  private setAddTeamChartNotifyTaskTeam(teamChartData: ITeamChartResult, addIds: number[]) {
    if (!addIds.length) return;

    teamChartData.taskTeamUsers
      .filter((x) => addIds.includes(x.id))
      .forEach((u) => {
        const user = this.projectMembersAndTeams?.users.find(
          (r) => r.userId === u.id && r.deliveryTeamId === u.deliveryTeamId && r.taskTeamId === u.taskTeamId
        );
        if (!user) return;
        const selectedUser = this.transmittalResponse?.selectedNotifyUsers.find(
          (u) => u.id === user.userId && u.deliveryTeamId === user.deliveryTeamId && u.taskTeamId === user.taskTeamId
        );
        if (selectedUser) return;
        this.addSelectedNotifyUser(user);
      });
  }

  private addSelectedNotifyUser(user: IUser) {
    this.transmittalResponse?.selectedNotifyUsers.push({
      ...user,
      id: user.userId,
      name: user.userName,
      email: user.userEmail,
    });
  }

  public setVisibilityUsersFromTeamChart(teamChartData: ITeamChartResult) {
    runInAction(() => {
      if (!this.transmittalResponse) return;

      const originIds = this.transmittalResponse?.selectedVisibleUsers.map((x) => {
        return x.id;
      });
      const newIds = teamChartData.taskTeamUsers.map((n) => {
        return n.id;
      });
      const removeIds = originIds?.filter((u) => !newIds.includes(u));
      const addIds = newIds.filter((u) => !originIds?.includes(u));

      this.removeSelectedVisibleUserByIds(removeIds);
      this.setAddTeamChartVisibleDeliveryTeam(teamChartData);
      this.setAddTeamChartVisibleTaskTeam(teamChartData, addIds);
      this.setAddTeamChartVisibleExternalTeam(teamChartData);
    });
  }

  private removeSelectedVisibleUserByIds(removeIds: number[]) {
    if (!removeIds.length || !this.transmittalResponse?.selectedVisibleUsers.length) return;

    this.transmittalResponse.selectedVisibleUsers = this.transmittalResponse?.selectedVisibleUsers.filter(
      (x) => !removeIds.includes(x.id)
    );
  }

  private setAddTeamChartVisibleDeliveryTeam(teamChartData: ITeamChartResult) {
    if (!teamChartData.deliveryUsers.length) return;

    teamChartData.deliveryUsers.forEach((u) => {
      const user = this.projectMembersAndTeams?.users.find(
        (r) => r.userId === u.id && r.deliveryTeamId === u.deliveryTeamId
      );

      if (!user) return;
      const selectedUser = this.transmittalResponse?.selectedVisibleUsers.find(
        (u) => u.id === user.userId && u.deliveryTeamId === user.deliveryTeamId
      );
      if (selectedUser) return;
      this.addSelectedVisibleUser(user);
    });
  }

  private setAddTeamChartVisibleTaskTeam(teamChartData: ITeamChartResult, addIds: number[]) {
    if (!addIds.length) return;

    teamChartData.taskTeamUsers
      .filter((x) => addIds.includes(x.id))
      .forEach((u) => {
        const user = this.getTeamMemberById(u.id);
        if (!user) return;
        const selectedUser = this.transmittalResponse?.selectedVisibleUsers.find((u) => u.id === user.userId);
        if (selectedUser) return;
        this.addSelectedVisibleUser(user);
      });
  }

  private setAddTeamChartVisibleExternalTeam(teamChartData: ITeamChartResult) {
    if (!teamChartData.externalUsers.length) return;

    teamChartData.externalUsers.forEach((u) => {
      const user = this.getTeamMemberById(u.id);
      if (!user) return;
      const selectedUser = this.transmittalResponse?.selectedVisibleUsers.find(
        (u) => u.id === user.userId && u.isExternal === user.isExternal
      );
      if (selectedUser) return;
      this.addSelectedVisibleUser(user);
    });
  }

  private addSelectedVisibleUser(user: IUser) {
    this.transmittalResponse?.selectedVisibleUsers.push({
      ...user,
      id: user.userId,
      name: user.userName,
      email: user.userEmail,
    });
  }

  private getTeamMemberById(id: number) {
    return this.projectMembersAndTeams?.users.find((r) => r.userId === id);
  }

  public setError(error: AxiosError<string>) {
    runInAction(() => {
      this.errorCode = error?.response?.status ?? null;
      this.errorMessage = error?.response?.data ?? null;
    });
  }

  public clearError() {
    runInAction(() => {
      this.errorCode = null;
      this.errorMessage = null;
    });
  }

  public async toggleContentUploadFileModal(open: boolean) {
    runInAction(() => {
      this.showContentUploadFileModal = open;
    });
  }
  public clearTransmittalContentFile() {
    this.selectedContentFiles = [];
  }
  public handleUploadContentFiles(fileId: number[]) {
    const mapSelectedContentFiles = this.selectedContentFiles
      .flatMap((x) => x.containerFiles!)
      .filter((file) => fileId.includes(file.containerFileId!))
      .map((m) => ({ fileContainerId: m.fileContainerId, containerFileIds: m.containerFileId }));
    const selectedContentFiles = this.selectedContentFiles
      .filter(
        (file) =>
          mapSelectedContentFiles.map((m) => m.fileContainerId).includes(file.id) &&
          !this.contentFiles.some((contentFile) => contentFile.id === file.id)
      )
      .map((m) => {
        return {
          ...m,
          containerFiles: m.containerFiles!.filter(
            (f) =>
              mapSelectedContentFiles.map((m) => m.containerFileIds).includes(f.containerFileId) &&
              !this.contentFiles.some((contentFile) =>
                contentFile.containerFiles?.map((x) => x.containerFileId).includes(f.containerFileId)
              )
          ),
        };
      });
    this.contentFiles = [...this.contentFiles, ...selectedContentFiles];
    this.clearTransmittalContentFile();
  }
}

export default new AddTransmittalMessageStore();
