import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, IHeader } from '@aurecon-creative-technologies/styleguide';
import Style from '../../../../../styles/components/settings/systemAdminSettings/systemUsers/SystemUserTable.module.scss';
import SystemUserTableRow from './SystemUserTableRow';
import { ITableColumn } from './TableColumn';
import { ISystemUser } from '../../../../../api/authenticated/um/interfaces/user.interface';
import { SortTypes } from '../../../../../common/enums/SortType';
import { SystemUsersTableHeaderKeyValueFields } from '../../../../../common/enums/SystemUserTableHeader';

export interface ISystemUserTableProps {
  tableColumns: ITableColumn[];
  tableSystemUsers: ISystemUser[];
  onDeactiveOrActiveUser: (user: ISystemUser) => void;
  onRemovingUser: (user: ISystemUser) => void;
  onSortColumnChange?: (column: string, direction: SortTypes) => void;
}

const SystemUserTable: FC<ISystemUserTableProps> = (props) => {
  const { tableColumns, tableSystemUsers, onDeactiveOrActiveUser, onRemovingUser, onSortColumnChange } = props;
  const [columns, setColumns] = useState(tableColumns);

  useEffect(() => {
    setColumns(tableColumns);
  }, [tableColumns]);

  const sortColumnClickHandler = (tableColumn: ITableColumn, direction: SortTypes) => {
    onSortColumnChange && onSortColumnChange(tableColumn.valueField, direction);
  };

  const headers: IHeader[] = [
    ...columns.map((tableColumn) => {
      if (
        tableColumn.label === SystemUsersTableHeaderKeyValueFields.Action.label ||
        tableColumn.label === SystemUsersTableHeaderKeyValueFields.Reactivate.label
      ) {
        return {
          label: tableColumn.label,
          align: 'center',
        } as IHeader;
      }
      return {
        label: tableColumn.label,
        onSort: (sort) => sortColumnClickHandler(tableColumn, sort as SortTypes),
        sort: tableColumn.sort ? tableColumn.sort : 'none',
      } as IHeader;
    }),
  ];

  return (
    <>
      {
        <Table cssClass={Style.systemUserTable} headers={headers} breakpoint={10} hoverable>
          {tableSystemUsers.map((row, index) => (
            <SystemUserTableRow
              key={row.id}
              headers={headers}
              user={row}
              tableColumns={columns}
              onDeactiveOrActiveUser={() => onDeactiveOrActiveUser(row)}
              onRemovingUser={() => onRemovingUser(row)}
              totalRows={tableSystemUsers.length}
              rowIndex={index}
            />
          ))}
        </Table>
      }
    </>
  );
};

export default observer(SystemUserTable);
