import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import VisualisationStore from '../VisualisationStore';
import Style from './styles/SearchWidget.module.scss';
import { SearchBox, ISuggestion } from '@aurecon-creative-technologies/styleguide';
import PrimaryIconButton from '../../shared/PrimaryIconButton';

const ANIMATE_DURATION = 1000;
interface ISearchChainageWidgetProps {
  isLoading: boolean;
}
const SearchChainageWidget: FC<ISearchChainageWidgetProps> = (props) => {
  const { isLoading } = props;
  const [filteredChainage, setFilteredChainage] = useState<ISuggestion[]>([]);
  const [searchText, setSearchText] = useState('');

  const handleSearchBoxChange = async (searchValue: string) => {
    if (!searchValue) {
      setFilteredChainage([]);
      setSearchText('');
    } else {
      setSearchText(searchValue);
      const chainageValues = VisualisationStore.chainageLayerData
        .filter((c) => c.label.toLowerCase().includes(searchValue.toLowerCase()))
        .map((c) => {
          return {
            label: c.label,
            id: c.id,
          };
        });
      if (chainageValues.length) {
        setFilteredChainage(
          chainageValues.map((x) => {
            return {
              id: x.id,
              value: x.label,
              display: x.label,
            };
          })
        );
      } else {
        const noResultsLabel = `No results found for "${searchValue}"`;
        setFilteredChainage([
          {
            id: -1,
            value: noResultsLabel,
            display: noResultsLabel,
          },
        ]);
      }
    }
  };

  const onSelectedChainage = (selectedChainageValue: string | number) => {
    const chainageValue = VisualisationStore.chainageLayerData.find((c) => c.id === selectedChainageValue);
    if (!chainageValue) return;
    VisualisationStore.mapView.goTo(chainageValue.graphicData.geometry, {
      animate: true,
      duration: ANIMATE_DURATION,
      easing: 'linear',
    });

    VisualisationStore.mapView.popup.open({
      features: [chainageValue.graphicData],
      location: chainageValue.graphicData.geometry,
    });
    setFilteredChainage([]);
  };
  return (
    <>
      <div className={Style.searchContentWrapper}>
        <div className={Style.searchContainer}>
          <label className={Style.label}>Zoom to line km</label>
          <SearchBox
            disabled={isLoading}
            cssClass={Style.searchBox}
            hideSearchButton
            disableDefaultMatching
            suggestions={filteredChainage}
            onChange={handleSearchBoxChange}
            onSearch={handleSearchBoxChange}
            onSelect={(s) => onSelectedChainage(s.id)}
            placeholder="Search for Railway line,kilometer value..."
            defaultValue={searchText}
          />
          <PrimaryIconButton icon="search" loading={isLoading} onClick={() => handleSearchBoxChange(searchText)} />
        </div>
      </div>
    </>
  );
};

export default observer(SearchChainageWidget);
