import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import SecondaryButton from '../../shared/SecondaryButton';
import FilesStore from '../FilesStore';
import ReviewModal from '../reviewModal/ReviewModal';
import { TaskType } from '../../../common/enums/TaskType';
import { useSaveTask } from '../customHooks/useSaveTask';
import { useGetAddTaskData } from '../customHooks/GetAddTaskDataHook';
import { appInsightsTrackEvent } from '../../../utils/appInsights';
import { AppInsightEvent } from '../../../common/constants/AppInsightEvent';

interface ShareButtonProps {
  taskTeamId: number;
}

const ShareButton: FC<ShareButtonProps> = ({ taskTeamId }) => {
  const [isShowing, setIsShowing] = useState(false);
  const saveTask = useSaveTask(taskTeamId, TaskType.ShareApproval);
  const getAddTaskData = useGetAddTaskData(TaskType.ShareApproval, taskTeamId);

  const showModal = () => {
    appInsightsTrackEvent(AppInsightEvent.FILE_SHARE);
    setIsShowing(true);
  };

  return (
    <>
      <SecondaryButton disabled={!FilesStore.canShareReviewOnSelectedFiles || taskTeamId === 0} onClick={showModal}>
        Share
      </SecondaryButton>
      {isShowing && (
        <ReviewModal
          title="Share"
          description="If the 'Share' workflow is approved, a copy of the files will be assigned the 'Shared' status and available to the entire delivery team."
          closeModal={() => setIsShowing(false)}
          approversLabel="Approvers"
          approversSearchLabel="Add Approvers"
          allApproversLabel="Require all approvers?"
          isSingleApproverRequired={true}
          showSuitability
          getData={getAddTaskData}
          saveReview={saveTask}
          state="Shared"
        />
      )}
    </>
  );
};

export default observer(ShareButton);
