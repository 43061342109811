import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  ReactElement,
} from 'react';
import Styles from './styles/Tree.module.scss';
import { Button, Checkbox, Icon, SearchBox, Tooltip } from '@aurecon-creative-technologies/styleguide';
import classNames from 'classnames';
import { uniq } from 'lodash';

type TNodeType = string | number;

interface ITreeContextData {
  expand: boolean;
  collapsed: boolean;
  selectedNodeKey: string;
}

interface TreeContextType {
  contextValue: ITreeContextData;
  setTreeViewContext: (expand: boolean, collapsed: boolean) => void;
  setSelected: (key: string) => void;
}

export interface NodeAction {
  action: string;
  actionTitle: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionData?: any;
}

export interface NodeData<TNodeType> {
  id: number | string;
  type: TNodeType;
  title: string;
  key: string;
  path: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export interface ITreeNodeData<TNodeType> {
  /**
   * node id
   */
  id: number | string;
  /**
   * tree path
   */
  path: string;
  /**
   * node title will display
   */
  title: string | React.ReactNode;
  /**
   * Node Type
   */
  type: TNodeType;
  /**
   * React key
   */
  key: string;
  /**
   * Disables the treeNode
   */
  disabled?: boolean;
  /**
   * Set whether the treeNode can be selected,
   * default = true
   * */
  selectable?: boolean;
  /**
   * Shows the icon before a TreeNode's title
   * */
  showIcon?: boolean;
  /**
   * node actions
   */
  listActions?: NodeAction[];
  /**
   * Shows a connecting line
   * default = false
   */
  showLine?: boolean;
  /**
   * Child nodes
   */
  childrenNodes: ITreeNodeData<TNodeType>[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;

  titleStr?: string;
  className?: string;
  showCheckbox?: boolean;
  onSelectCheckbox?: (id: number | string, checked: boolean, selectedCheckboxs: (number | string)[]) => void;
  customIcon?: ReactElement;
}

export interface ITreeViewProps<TNodeType> {
  /**
   * Tree view title will display on the top bar.
   */
  treeName?: string;
  /**
   * Tree view data
   */
  treeData: ITreeNodeData<TNodeType>[];
  /**
   * node actions
   */
  rootAction?: NodeAction;
  /**
   * ClassName on the root element
   */
  rootClassName?: string;
  /**
   * Callback function for when the user clicks a treeNode
   * @param value
   * @returns value: NodeData<TNodeType>
   */
  onSelect: (value: NodeData<TNodeType>) => void;
  /**
   * Callback function when action button
   * @param value
   * @returns value: NodeData<TNodeType>
   */
  onAction?: (action: NodeAction, value?: NodeData<TNodeType>) => void;

  selectedNodeKey?: string;

  showSearchFilter?: boolean;

  className?: string;
}

export interface ITreeProps<TNodeType> {
  nodes: ITreeNodeData<TNodeType>[];
  /**
   * ClassName on the root element
   */
  rootClassName?: string;
  level?: number;
  /**
   * Callback function for when the user clicks a treeNode
   * @param value
   * @returns value: NodeData<TNodeType>
   */
  onSelect: (value: NodeData<TNodeType>) => void;
  /**
   * Callback function for when the user clicks on action button
   * @param value
   * @returns value: NodeData<TNodeType>
   */
  onAction?: (action: NodeAction, value: NodeData<TNodeType>) => void;
  expandedKeys: string[];
  showSearchBox: boolean;
  clearSearchBox?: () => void;
  className?: string;
  handleSelectedCheckboxs?: (node: ITreeNodeData<TNodeType>) => void;
  selectedCheckboxs: (number | string)[];
}

export interface ITreeNodeProps<TNodeType> {
  data: ITreeNodeData<TNodeType>;
  /**
   * Callback function for when the user clicks a node title
   * @param value
   * @returns value: NodeData<TNodeType>
   */
  onClick: (data: NodeData<TNodeType>) => void;
  /**
   * Callback function when action button
   * @param value
   * @returns value: NodeData<TNodeType>
   */
  onAction?: (action: NodeAction, value: NodeData<TNodeType>) => void;
  expandedKeys: string[];
  showSearchBox: boolean;
  clearSearchBox?: () => void;
  className?: string;
  selectedCheckboxs: (number | string)[];
  handleSelectedCheckboxs?: (node: ITreeNodeData<TNodeType>) => void;
}

const TreeNode: FC<ITreeNodeProps<TNodeType>> = (props) => {
  const {
    data,
    onClick,
    onAction,
    expandedKeys,
    showSearchBox,
    clearSearchBox,
    className,
    selectedCheckboxs,
    handleSelectedCheckboxs,
  } = props;
  const [showChildren, setShowChildren] = useState(false);
  const nodeLevel = data.path.split('/').length;
  const { contextValue, setTreeViewContext, setSelected } = useContext(TreeViewContext) as TreeContextType;

  useEffect(() => {
    if (showSearchBox)
      setTimeout(() => {
        const expand = expandedKeys?.includes(data.key) ?? false;
        setShowChildren(expand);
      });
  }, [expandedKeys, showSearchBox, data.key]);

  useEffect(() => {
    if (showSearchBox) return;

    if (contextValue.collapsed) {
      setShowChildren(false);
    } else if (contextValue.expand) {
      setShowChildren(true);
    }
  }, [showSearchBox, contextValue]);

  const handleExpandClick = (e) => {
    e.stopPropagation();
    if (data.disabled) return;
    setShowChildren(!showChildren);
    setTreeViewContext(false, false);
  };

  const handleSelectedNode = (node) => {
    if (!node.selectable || node.key === contextValue.selectedNodeKey) return;
    setSelected(node.key);
    onClick({ ...node, title: node?.titleStr ?? node?.title ?? '' });
  };

  const handleActionBtnClick = (action, value) => {
    setSelected('');
    onAction && onAction(action, value);
  };

  const renderNodeAction = (value: NodeData<TNodeType>, actions: NodeAction[]) => {
    return (
      <div className={Styles.nodeActions}>
        {actions.map((atc: NodeAction) => (
          <Button
            key={`${value.key}-${atc.action}`}
            size="small"
            label={atc.actionTitle}
            onClick={(e) => {
              e.preventDefault();
              handleActionBtnClick(atc, value);
            }}
            type="text"
            icon="add_circle"
            cssClass={Styles.buttonLink}
          />
        ))}
      </div>
    );
  };

  const refs = useRef<HTMLSpanElement[]>([]);
  const [showTooltips, setShowTooltips] = useState<string[]>([]);

  const handleShowTooltip = (label: string) => {
    const items: string[] = [];

    const element = refs.current[label];
    if (element && element.scrollWidth > element.clientWidth) items.push(label);

    setShowTooltips(items);
  };

  const hasTooltip = (label: string) => {
    return showTooltips.includes(label);
  };

  const checkBoxTitle = data.title?.toString() ?? '';
  return (
    <li
      className={classNames(
        Styles.nodeWrapper,
        className,
        { [Styles.root]: nodeLevel === 1 },
        { [Styles.expand]: showChildren },
        { [Styles.showLine]: data.showLine },
        { [Styles.disabled]: data.disabled }
      )}>
      <div
        onMouseOver={() => data.showCheckbox && handleShowTooltip(checkBoxTitle)}
        onMouseOut={() => data.showCheckbox && handleShowTooltip(checkBoxTitle)}
        className={classNames(
          Styles.nodeItem,
          { [Styles.root]: nodeLevel === 1 },
          { [Styles.disabled]: data.disabled },
          { [Styles.selected]: data.key === contextValue.selectedNodeKey }
        )}
        onClick={() => handleSelectedNode(data)}>
        {data.showCheckbox ? (
          <div className={Styles.wrapCheckbox}>
            <Checkbox
              indeterminate={!!data.childrenNodes.length && isAnyChildNodeChecked(data, selectedCheckboxs)}
              checked={isCheckboxSelected(data.id, selectedCheckboxs)}
              cssClass={Styles.checkBox}
              onChange={() => {
                handleSelectedCheckboxs && handleSelectedCheckboxs(data);
              }}
            />
            {hasTooltip(checkBoxTitle) ? (
              <Tooltip
                show={checkBoxTitle}
                cssClass={Styles.checkBoxLable}
                defaultUp={true}
                padding={15}
                offset={{ left: -(200 + nodeLevel * 40), top: -90 }}>
                <span ref={(el) => (refs.current[checkBoxTitle] = el)} className={Styles.checkBoxLable}>
                  {checkBoxTitle}
                </span>
              </Tooltip>
            ) : (
              <div className={Styles.checkBoxLable}>
                <span ref={(el) => (refs.current[checkBoxTitle] = el)} className={Styles.checkBoxLable}>
                  {checkBoxTitle}
                </span>
              </div>
            )}
          </div>
        ) : (
          <span className={Styles.nodeTitle}>{data.title}</span>
        )}
        {data.customIcon ? (
          <div>{data.customIcon}</div>
        ) : (!!data.childrenNodes.length || data.listActions?.length) && !data.disabled ? (
          <div onClick={(e) => handleExpandClick(e)}>
            <Icon cssClass={Styles.expandIcon} type={showChildren ? 'expand_less' : 'expand_more'} />
          </div>
        ) : (
          <></>
        )}
      </div>
      {(!!data.childrenNodes.length || data.listActions?.length) && showChildren && (
        <>
          {data.listActions && (
            <div
              className={classNames(Styles.actionWrapper, { [Styles.showLine]: data.showLine })}
              style={{ paddingLeft: `${(nodeLevel ?? 0) * 8}px` }}>
              {renderNodeAction(
                {
                  id: data.id,
                  key: data.key,
                  path: data.path,
                  title: data.titleStr ?? data.title?.toString() ?? '',
                  type: data.type,
                },
                data.listActions
              )}
            </div>
          )}
          <Tree
            nodes={data.childrenNodes}
            level={nodeLevel + 1}
            onSelect={(node) => handleSelectedNode(node)}
            onAction={onAction}
            expandedKeys={expandedKeys}
            showSearchBox={showSearchBox}
            clearSearchBox={clearSearchBox}
            className={className}
            selectedCheckboxs={selectedCheckboxs}
            handleSelectedCheckboxs={handleSelectedCheckboxs}
          />
        </>
      )}
    </li>
  );
};

const Tree: FC<ITreeProps<TNodeType>> = (props) => {
  const {
    nodes,
    onSelect,
    onAction,
    expandedKeys,
    showSearchBox,
    clearSearchBox,
    handleSelectedCheckboxs,
    selectedCheckboxs,
  } = props;
  const [expand] = useState(false);

  return (
    <ul
      className={classNames(Styles.treeView, { [Styles.ready]: expand })}
      style={{ paddingLeft: `${(props.level ?? 0) * 8}px` }}>
      {nodes.map((node: ITreeNodeData<TNodeType>) => (
        <TreeNode
          data={node}
          key={node.key}
          onClick={onSelect}
          onAction={onAction}
          expandedKeys={expandedKeys}
          showSearchBox={showSearchBox}
          clearSearchBox={clearSearchBox}
          className={node.className}
          handleSelectedCheckboxs={handleSelectedCheckboxs}
          selectedCheckboxs={selectedCheckboxs}
        />
      ))}
    </ul>
  );
};

const TreeViewContext = createContext<TreeContextType | null>(null);

const getParentKey = (key: string, tree: ITreeNodeData<TNodeType>[]): string => {
  let parentKey: string;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.childrenNodes) {
      if (node.childrenNodes.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.childrenNodes)) {
        parentKey = getParentKey(key, node.childrenNodes);
      }
    }
  }
  return parentKey!;
};

const getAllChildNodeIds = (node: ITreeNodeData<TNodeType>, ignoreItemHasShowCheckboxIsFalse = true) => {
  const currentId = ignoreItemHasShowCheckboxIsFalse && !node.showCheckbox ? null : node.id;
  if (!node.childrenNodes.length) return [currentId];

  const result = [...node.childrenNodes.map((t) => getAllChildNodeIds(t)), currentId].flatMap((t) => t);
  return result.filter((t) => !!t);
};

const getAllParentNodes = (treeData, id: string | number, ignoreItemHasShowCheckboxIsFalse = true) => {
  if (treeData.id === id) return [];
  if (!treeData.childrenNodes?.length && !Array.isArray(treeData)) return;

  const childrenNodes = Array.isArray(treeData) ? treeData : treeData.childrenNodes;
  for (const child of childrenNodes) {
    const result = getAllParentNodes(child, id);
    if (!result) continue;
    if (treeData.id) result.unshift(treeData);
    return ignoreItemHasShowCheckboxIsFalse ? result.filter((t) => t.showCheckbox) : result;
  }
};

const isCheckboxSelected = (id: string | number, selectedCheckboxs: (number | string)[]) => {
  return !!selectedCheckboxs.find((l) => l === id);
};

const isAnyChildNodeChecked = (node: ITreeNodeData<TNodeType>, selectedCheckboxs: (number | string)[]) => {
  const allChildNodeIds = getAllChildNodeIds(node);
  if (allChildNodeIds.every((t) => selectedCheckboxs.includes(t))) return false;

  const someChecked = allChildNodeIds.some((t) => selectedCheckboxs.includes(t));
  return someChecked;
};

const TreeView: FC<ITreeViewProps<TNodeType>> = (props) => {
  const { treeName, rootClassName, rootAction, treeData, selectedNodeKey, onSelect, onAction, showSearchFilter } =
    props;
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [dataList] = useState<{ key: string; title: string }[]>([]);
  const [selectedCheckboxs, setSelectedCheckboxs] = useState<(string | number)[]>([]);

  const setCheckboxs = (node: ITreeNodeData<TNodeType>, checked: boolean, selectedIds: (string | number)[]) => {
    setSelectedCheckboxs(selectedIds);

    if (node.onSelectCheckbox) node.onSelectCheckbox(node.id, checked, selectedIds);
  };

  const handleSelectedCheckboxs = (node: ITreeNodeData<TNodeType>) => {
    const allChildNodeIds = getAllChildNodeIds(node);
    let checked = !isCheckboxSelected(node.id, selectedCheckboxs);
    if (node.childrenNodes.length) {
      if (allChildNodeIds.every((t) => selectedCheckboxs.includes(t))) checked = false;
      else if (allChildNodeIds.some((t) => selectedCheckboxs.includes(t))) checked = true;
    }
    const parentItems = getAllParentNodes(treeData, node.id);

    if (checked) {
      let selected = uniq([...selectedCheckboxs, ...allChildNodeIds]);
      if (parentItems.length) {
        parentItems.reverse().forEach((parentItem) => {
          const childNodeIds = getAllChildNodeIds(parentItem).filter((id) => id !== parentItem.id);
          const someChildSelected = childNodeIds.some((t) => selected.includes(t));
          if (!someChildSelected) {
            selected = selected.filter((t) => t !== parentItem.id);
            return;
          }

          if (!selected.includes(parentItem.id)) selected.push(parentItem.id);
        });
      }
      setCheckboxs(node, checked, selected);

      return;
    }

    const removedIds = [node.id, ...allChildNodeIds];
    let temp = selectedCheckboxs.filter((t) => !removedIds.includes(t));

    parentItems.reverse().forEach((parentItem) => {
      const childNodeIds = getAllChildNodeIds(parentItem).filter((id) => id !== parentItem.id);
      const someChildSelected = childNodeIds.some((t) => temp.includes(t));
      if (someChildSelected) return;

      temp = temp.filter((t) => t !== parentItem.id);
    });

    setCheckboxs(node, checked, temp);
  };

  const generateList = useCallback(
    (data: ITreeNodeData<TNodeType>[]) => {
      for (const node of data) {
        const { key, title } = node;
        if (!dataList.some((t) => t.key === key)) dataList.push({ key, title: title?.toString() ?? '' });
        if (node.childrenNodes) {
          generateList(node.childrenNodes);
        }
      }
    },
    [dataList]
  );

  useEffect(() => {
    if (showSearchFilter) generateList(treeData);
  }, [showSearchFilter, treeData, generateList]);

  const [treeContext, setTreeContext] = useState<ITreeContextData>({
    collapsed: true,
    expand: false,
    selectedNodeKey: '',
  });

  useEffect(() => {
    if (!selectedNodeKey || treeContext.selectedNodeKey === selectedNodeKey) return;
    const newState = { ...treeContext, selectedNodeKey: selectedNodeKey };
    setTreeContext(newState);
  }, [selectedNodeKey, treeContext]);

  const setTreeViewContext = (c: boolean, e: boolean) => {
    const newState = { collapsed: c, expand: e, selectedNodeKey: treeContext.selectedNodeKey };
    setTreeContext(newState);
  };

  const setSelected = (selectedKey: string) => {
    const newState = { ...treeContext, selectedNodeKey: selectedKey };
    setTreeContext(newState);
  };

  const handleSelectedNode = (node) => {
    node.title = node?.titleStr ?? node?.title ?? '';
    onSelect(node);
  };

  const searchBoxRef = useCallback(
    (node) => {
      if (node && showSearchBox) node.focus();
    },
    [showSearchBox]
  );

  const renderTopControl = () => {
    const handleExpandClick = () => {
      setTreeViewContext(!treeContext.collapsed, treeContext.collapsed);
    };

    const onChange = (value: string) => {
      if (!value) {
        clearSearchBox();
        return;
      }
      const valueLowerCase = `${value}`.toLowerCase();
      const matchItemKeys = dataList
        .filter((item) => item.title?.toLowerCase()?.indexOf(valueLowerCase) > -1)
        .map((item) => item.key);
      let newExpandedKeys = uniq(matchItemKeys.map((key) => getParentKey(key, treeData)).filter((t) => t));

      const getParentOfParentKeys = (parentKeys: string[]) => {
        const newParentOfParentKeys = uniq(
          parentKeys.map((key) => getParentKey(key, treeData)).filter((t) => t && !newExpandedKeys.includes(t))
        );

        newExpandedKeys = [...newParentOfParentKeys, ...newExpandedKeys];
        if (newParentOfParentKeys.length) getParentOfParentKeys(newParentOfParentKeys);
      };
      getParentOfParentKeys(newExpandedKeys);

      setExpandedKeys([...newExpandedKeys, ...matchItemKeys]);
      setSearchText(value);
    };

    return (
      <div className={Styles.topBar}>
        {showSearchBox ? (
          <SearchBox
            ref={searchBoxRef}
            defaultValue={searchText}
            hideSearchButton
            onSearch={(value) => onChange(value)}
            onClear={() => onChange('')}
            placeholder={'Quick search...'}
          />
        ) : (
          <span className={Styles.topBarTitle}>{treeName}</span>
        )}
        <div className={Styles.topBarIcon}>
          {!showSearchBox && (
            <Icon type={treeContext.collapsed ? 'expand_more' : 'expand_less'} onClick={() => handleExpandClick()} />
          )}
          {showSearchFilter && (
            <Icon
              type={showSearchBox ? 'close' : 'search'}
              onClick={() => {
                setShowSearchBox(!showSearchBox);
                clearSearchBox();
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const clearSearchBox = () => {
    setSearchText('');
    setExpandedKeys([]);
  };

  const renderRootAction = () => {
    if (!rootAction) return;
    return (
      <div className={classNames(Styles.nodeActions, Styles.rootAction)}>
        <Button
          size="small"
          label={rootAction?.actionTitle}
          onClick={(e) => {
            e.preventDefault();
            onAction && onAction(rootAction);
          }}
          type="text"
          icon="add_circle"
          cssClass={Styles.buttonLink}
        />
      </div>
    );
  };

  const treeDataApplySearch = useMemo(() => {
    if (!searchText) return treeData;

    const searchTextLowerCase = `${searchText}`.toLowerCase();
    const loopDeep = (data: ITreeNodeData<TNodeType>[]): ITreeNodeData<TNodeType>[] =>
      data
        .filter((t) => expandedKeys.includes(t.key))
        .map((item) => {
          let title = item.title;
          const strTitleLowerCase = `${title}`.toLowerCase();
          const index = strTitleLowerCase.indexOf(searchTextLowerCase);

          if (index > -1 && title) {
            const strTitle = title.toString() ?? '';
            const searchTextLength = index + searchText.length;
            const beforeStr = strTitle.substring(0, index);
            const matchStr = strTitle.substring(index, searchTextLength);
            const afterStr = strTitle.slice(searchTextLength);
            title = (
              <span>
                {beforeStr}
                <span className={Styles.treeSearchValue}>{matchStr}</span>
                {afterStr}
              </span>
            );
          }

          if (item.childrenNodes) {
            return { ...item, title, titleStr: item.title?.toString(), childrenNodes: loopDeep(item.childrenNodes) };
          }

          return { ...item, title, titleStr: item.title?.toString() };
        });

    return loopDeep(treeData);
  }, [searchText, treeData, expandedKeys]);

  return (
    <TreeViewContext.Provider value={{ contextValue: treeContext, setTreeViewContext, setSelected }}>
      <div className={classNames(Styles.wrapper, rootClassName)}>
        {treeName && renderTopControl()}
        {renderRootAction()}
        <Tree
          nodes={treeDataApplySearch}
          onSelect={(node) => handleSelectedNode(node)}
          onAction={onAction}
          expandedKeys={expandedKeys}
          showSearchBox={showSearchBox}
          clearSearchBox={clearSearchBox}
          handleSelectedCheckboxs={handleSelectedCheckboxs}
          selectedCheckboxs={selectedCheckboxs}
        />
      </div>
    </TreeViewContext.Provider>
  );
};

export default TreeView;
