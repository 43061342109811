import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import DropDownMenuForTableCell from '../../shared/DropDownMenuForTableCell';
import DropDownMenuButton from '../../shared/DropDownMenuButton';
import { getFileSizeDetails } from '../../../utils/miscUtils';
import DownloadModal from '../../shared/DownloadModal';
import ForgeViewerStore from '../../visualisation/forgeViewer/ForgeViewerStore';
import ErrorModal from '../../shared/ErrorModal';
import FilesStore from '../../files/FilesStore';
import NavBarSelectorStore from '../../transmittals/navBarSelector/NavBarSelectorStore';
import { IContainerFile, IFileContainer } from '../../../api/authenticated/cms/FileContainerModel';
import CreateTransmittalStore from '../CreateTransmittalStore';
import AddTransmittalMessageStore from '../../transmittalDetails/AddTransmittalMessage/AddTransmittalMessageStore';
import getFileExtension from '../../../utils/fileUtils';
import { FileExt } from '../../../common/constants/FileExt';
import TransmittalDetailsFilesStore from '../../transmittalDetails/TransmittalDetailsFilesStore';

export interface ITransmittalContentFileActionProps {
  file: IFileContainer | null;
  containerFile: IContainerFile | null;
  showInfo: boolean;
  fileRevisionId?: number | null;
  releasedFileId?: number | null;
  transmittalId?: number;
  transmittalMessageId?: number;
}

const TransmittalContentFileAction: FC<ITransmittalContentFileActionProps> = ({
  file,
  containerFile,
  showInfo,
  fileRevisionId,
  releasedFileId,
  transmittalId,
  transmittalMessageId,
}) => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const { totalFileSize, isOverLimit } = getFileSizeDetails(file?.uploadedSize || 0);
  const [showErrorModal, setShowErrorModal] = useState(false);

  if (!file && !containerFile) return null;
  const projectNumber = NavBarSelectorStore.selectedItem?.project?.projectNumber ?? null;

  const handleContentFileDownload = async (file: IFileContainer | null, contentFile: IContainerFile | null) => {
    if (isOverLimit) {
      setShowDownloadModal(true);
    } else {
      if (file && !contentFile) {
        FilesStore.downloadFile({
          fileContainers: [file],
          projectNumber: projectNumber!,
          sharePointReleasedFileId: undefined,
          transmittalId: transmittalId,
          transmittalMessageId: transmittalMessageId,
        });
      }
      if (contentFile) {
        const selectedContentFiles = CreateTransmittalStore.selectedContentFiles
          .concat(AddTransmittalMessageStore.selectedContentFiles)
          .filter((x) => x.id === contentFile.fileContainerId)
          .map((m) => {
            return {
              ...m,
              containerFileId: contentFile.containerFileId,
            };
          }) as IFileContainer[];

        FilesStore.downloadFile({
          fileContainers: [selectedContentFiles[0]],
          projectNumber: projectNumber!,
          sharePointReleasedFileId: undefined,
          transmittalId: transmittalId,
          transmittalMessageId: transmittalMessageId,
        });
      }
    }
  };

  const handleOpenFile = async (
    contentFile: IContainerFile | null,
    fileContainerId?: number,
    fileContainerRevisionId?: number,
    releasedFileContainerId?: number | null
  ) => {
    if (contentFile === null) return;

    const fileContainer = CreateTransmittalStore.selectedContentFiles
      .concat(AddTransmittalMessageStore.selectedContentFiles)
      .find((x) => x.id === contentFile.fileContainerId);

    const contentFileDownload = {
      ...fileContainer,
      ...contentFile,
      ...fileContainer?.containerFiles?.find((x) => x.containerFileId === contentFile.containerFileId),
      id: fileContainer?.id ?? fileContainerId,
      fileRevisionId: fileContainer?.fileRevisionId ?? fileContainerRevisionId,
      releasedFileId: fileContainer?.releasedFileId ?? releasedFileContainerId,
    } as IFileContainer;

    if (contentFileDownload.hasDocumentViewer) {
      CreateTransmittalStore.handleOpenFile(contentFileDownload, transmittalId, transmittalMessageId);
      return;
    }

    if (contentFileDownload.isForgeFile) {
      if (!contentFileDownload.containerFileId) return;

      await ForgeViewerStore.getForgeViewer(
        contentFileDownload.containerFileId,
        NavBarSelectorStore.selectedItem!.project.projectNumber
      );
      if (ForgeViewerStore.errorMessage) setShowErrorModal(true);
    }
  };

  let pdfUrl = `${window.origin}/#/pdf-viewer?projectNumber=${
    NavBarSelectorStore.selectedItem!.project!.projectNumber
  }&fileRevisionId=${fileRevisionId}&releasedFileId=${releasedFileId}`;

  pdfUrl =
    transmittalId && transmittalMessageId
      ? `${pdfUrl}&transmittalId=${transmittalId}&transmittalMessageId=${transmittalMessageId}`
      : pdfUrl;
  return (
    <>
      {file && (
        <DropDownMenuForTableCell>
          {showInfo && (
            <DropDownMenuButton
              onClick={(event) => {
                event?.stopPropagation();
                TransmittalDetailsFilesStore.showFileInformation(file);
              }}>
              Info
            </DropDownMenuButton>
          )}
          <DropDownMenuButton
            onClick={(event) => {
              event?.stopPropagation();
              handleContentFileDownload(file, null);
            }}
            disabled={FilesStore.fileBusy[file.id]}>
            Download
          </DropDownMenuButton>
          {(file.hasDocumentViewer || file.isForgeFile) && (
            <DropDownMenuButton
              onClick={(event) => {
                event?.stopPropagation();
                handleOpenFile(
                  {
                    fileContainerId: file.id,
                    containerFileId: file.containerFileId,
                    fileExtension: file.extension,
                    originalFilename: file.originalFilename,
                    uploadedSize: file.uploadedSize,
                    hasDocumentViewer: file.hasDocumentViewer,
                    isForgeFile: file.isForgeFile,
                    native: null,
                    sharePointReleasedFileId: null,
                  },
                  file.id,
                  file.fileRevisionId,
                  file.releasedFileId
                );
              }}
              disabled={FilesStore.fileBusy[file.id]}>
              Open File
            </DropDownMenuButton>
          )}
          {getFileExtension(file.originalFilename)?.toLocaleLowerCase() === FileExt.PDF && fileRevisionId && (
            <DropDownMenuButton
              onClick={(event) => {
                event?.stopPropagation();
                window.open(`${pdfUrl}&id=${file.id}&containerFileId=${file.containerFileId}`, '_blank');
              }}>
              Open PDF File
            </DropDownMenuButton>
          )}
        </DropDownMenuForTableCell>
      )}
      <DownloadModal
        fileSize={totalFileSize}
        showModal={showDownloadModal}
        closeModal={() => setShowDownloadModal(false)}
        downloadAction={() => {
          {
            file && handleContentFileDownload(file, null);
          }
          {
            containerFile && handleContentFileDownload(null, containerFile);
          }
          setShowDownloadModal(false);
        }}
      />
      {showErrorModal && (
        <ErrorModal
          closeModal={() => setShowErrorModal(false)}
          errorCode={ForgeViewerStore.errorCode}
          errorMessage={ForgeViewerStore.errorMessage ?? null}
        />
      )}
    </>
  );
};

export default observer(TransmittalContentFileAction);
