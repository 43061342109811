import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ContentSelection } from './ContentSelection';
import { classNames } from '../../utils/miscUtils';
import FilesStore, { ActionColumns } from './FilesStore';
import ShareButton from './wipTab/ShareButton';
import FileTable from './fileTable/FileTable';
import TaskTeamReviewButton from './wipTab/TaskTeamReviewButton';
import ActionBar from '../shared/ActionBar';
import ActionBarSection from '../shared/ActionBarSection';
import DownloadButton from './DownloadButton';
import SelectedFilesLabel from './SelectedFilesLabel';
import DeliveryTeamReview from './sharedTab/DeliveryTeamReview';
import PublishButton from './sharedTab/PublishButton';
import CollaborateButton from './CollaborateButton';
import FileInformation from './fileInformation/FileInformation';
import Style from './styles/TabContainer.module.scss';
import FileOverflowButton from './FileOverflowButton';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import { NavigationItemTypes } from '../../common/models/ItemType';
import PrimaryButton from '../shared/PrimaryButton';
import SecondaryButton from '../shared/SecondaryButton';
import { IFileContainer } from '../../api/authenticated/cms/FileContainerModel';
import { FromModuleEnum } from '../../enums/FromModuleEnum';
import CreateTransmittalStore from '../transmittals/CreateTransmittalStore';
import AddTransmittalMessageStore from '../transmittalDetails/AddTransmittalMessage/AddTransmittalMessageStore';
import ClearFilterLink from '../shared/ClearFilterLink';
import EditFileMetadata from './editFileMetadata/EditFileMetadata';
import EditFileMetadataActionBar from './editFileMetadata/EditFileMetadataActionBar';
import FileUpload from './upload/FileUpload';
import NewFileFromTemplate from './upload/NewFileFromTemplate';
import AddContent from './upload/AddContent';
import SupersedeActionBar from './upload/SupersedeActionBar';
import FileInformationStore from './fileInformation/FileInformationStore';
import DropDownAdjustableColumns from '../shared/DropDownAdjustableColumns';

export interface ITabContainerProps {
  isOpenFiles?: boolean;
  onAddFilesToTransmittal?: (files: IFileContainer[]) => void;
  onCancelAddFilesToTransmittal?: () => void;
  fromModule: FromModuleEnum;
}

const TabContainer: FC<ITabContainerProps> = (props) => {
  const [editFileMetadata, setEditFileMetadata] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(0);

  useEffect(() => {
    FilesStore.setFromModule(props.fromModule);
  }, [props.fromModule]);

  const showFileTable =
    FilesStore.selectedSection == ContentSelection.WIP ||
    FilesStore.selectedSection === ContentSelection.Shared ||
    FilesStore.selectedSection === ContentSelection.Published;

  const showSelectedState = FilesStore.hasSelectedFile();

  const selectedTeamItemId =
    NavBarSelectorStore.selectedItem && NavBarSelectorStore.selectedItem.type === NavigationItemTypes.TaskTeam
      ? NavBarSelectorStore.selectedItem.taskTeam.id
      : undefined;

  const handleAddToTransmittalClick = () => {
    FileInformationStore.close();
    props.onAddFilesToTransmittal && props.onAddFilesToTransmittal(FilesStore.selectedFiles);
  };

  const handleCancelAddToTransmittalClick = () => {
    props.onCancelAddFilesToTransmittal && props.onCancelAddFilesToTransmittal();
  };

  const mapToFileMetadataModel = () =>
    (selectedFileId ? FilesStore.files.filter((f) => f.id === selectedFileId) : FilesStore.selectedFiles).map((f) => ({
      id: f.id,
      fileName: f.title,
      originalFileName: f.originalFilename,
      fileSize: f.uploadedSize,
      inWorkflow: f.inWorkflow,
      pattern1: f.pattern1,
      pattern2: f.pattern2,
      pattern3: f.pattern3,
      metadata: [
        {
          fieldIndex: 1,
          fieldValue: f.fieldValue1,
        },
        {
          fieldIndex: 2,
          fieldValue: f.fieldValue2,
        },
        {
          fieldIndex: 3,
          fieldValue: f.fieldValue3,
        },
        {
          fieldIndex: 4,
          fieldValue: f.fieldValue4,
        },
        {
          fieldIndex: 5,
          fieldValue: f.fieldValue5,
        },
        {
          fieldIndex: 6,
          fieldValue: f.fieldValue6,
        },
        {
          fieldIndex: 7,
          fieldValue: f.fieldValue7,
        },
        {
          fieldIndex: 8,
          fieldValue: f.fieldValue8,
        },
        {
          fieldIndex: 9,
          fieldValue: f.fieldValue9,
        },
        {
          fieldIndex: 10,
          fieldValue: f.fieldValue10,
        },
        {
          fieldIndex: 11,
          fieldValue: f.fieldValue11,
        },
        {
          fieldIndex: 12,
          fieldValue: f.fieldValue12,
        },
        {
          fieldIndex: 13,
          fieldValue: f.fieldValue13,
        },
        {
          fieldIndex: 14,
          fieldValue: f.fieldValue14,
        },
        {
          fieldIndex: 15,
          fieldValue: f.fieldValue15,
        },
        {
          fieldIndex: 16,
          fieldValue: f.fieldValue16,
        },
        {
          fieldIndex: 17,
          fieldValue: f.fieldValue17,
        },
        {
          fieldIndex: 18,
          fieldValue: f.fieldValue18,
        },
        {
          fieldIndex: 19,
          fieldValue: f.fieldValue19,
        },
        {
          fieldIndex: 20,
          fieldValue: f.fieldValue20,
        },
      ],
    }));

  const handleShowHideTableColumns = (items: string[]) => {
    const columns = FilesStore.tableColumns.map((m) => {
      return {
        id: m.valueField,
        label: m.label,
        width: m.width!,
        visible: ActionColumns.includes(m.valueField) || items.includes(m.valueField),
      };
    });

    FilesStore.setAdjustableTableColumns(columns, true);
  };

  return (
    <div
      className={classNames(Style.container, [
        showFileTable && FilesStore.isShowingFileInformation,
        Style.showFileInformation,
      ])}>
      {!FilesStore.openSupersedeFile && (
        <ActionBar className={Style.actionBar} selectedState={!editFileMetadata && showSelectedState}>
          {showSelectedState && !editFileMetadata && !props.isOpenFiles ? (
            <ActionBarSection>
              {FilesStore.selectedSection === ContentSelection.WIP && (
                <>
                  <TaskTeamReviewButton taskTeamId={selectedTeamItemId || 0} />
                  <ShareButton taskTeamId={selectedTeamItemId || 0} />
                  <CollaborateButton taskTeamId={selectedTeamItemId || 0} />
                </>
              )}
              {FilesStore.selectedSection === ContentSelection.Shared && (
                <>
                  <DeliveryTeamReview taskTeamId={selectedTeamItemId || 0} />
                  <PublishButton taskTeamId={selectedTeamItemId || 0} />
                  <CollaborateButton taskTeamId={selectedTeamItemId || 0} />
                </>
              )}
              {FilesStore.selectedSection === ContentSelection.Published && (
                <CollaborateButton taskTeamId={selectedTeamItemId || 0} />
              )}
              <DownloadButton />
              <FileOverflowButton
                onEdit={() => {
                  setEditFileMetadata(true);
                  FilesStore.setSelectedSection(ContentSelection.EditMetadata, undefined, false);
                }}
              />
              <SelectedFilesLabel />
            </ActionBarSection>
          ) : (
            <ActionBarSection>
              {!props.isOpenFiles && !editFileMetadata && !FilesStore.openSupersedeFile && <AddContent />}
              {!props.isOpenFiles && editFileMetadata && !FilesStore.openSupersedeFile && (
                <EditFileMetadataActionBar
                  onCancel={(isSaving: boolean) => {
                    setEditFileMetadata(false);
                    setSelectedFileId(0);

                    isSaving && FilesStore.setCurrentPage(1);
                    FilesStore.setSelectedSection(FilesStore.getContentSelection(FilesStore.fileContainerStateId));
                    FilesStore.resetSelectedFile();
                  }}
                />
              )}
              {props.isOpenFiles && (
                <>
                  <PrimaryButton
                    disabled={
                      !FilesStore.selectedFiles.length ||
                      (FilesStore.isAnySelectedFileLocked &&
                        !(CreateTransmittalStore.isOpenFiles || AddTransmittalMessageStore.isOpenFiles))
                    }
                    onClick={handleAddToTransmittalClick}>
                    Add to Transmittal
                  </PrimaryButton>
                  <SecondaryButton onClick={handleCancelAddToTransmittalClick}>Cancel</SecondaryButton>
                  <SelectedFilesLabel />
                </>
              )}
            </ActionBarSection>
          )}
          {!editFileMetadata && (
            <div className={Style.clearFilterWrapper}>
              <ClearFilterLink onClick={() => FilesStore.ClearFilter()} disabled={FilesStore.isLoading} />
              <DropDownAdjustableColumns
                items={FilesStore.getTableColumnsForShowHide}
                selectedItems={FilesStore.getAdjustableTableColumns()
                  .filter((col) => col.visible)
                  .map((m) => m.id)}
                onSelected={handleShowHideTableColumns}
                disabled={FilesStore.isLoading}
              />
            </div>
          )}
        </ActionBar>
      )}
      {FilesStore.openSupersedeFile && <SupersedeActionBar />}
      <div className={Style.content}>
        {!editFileMetadata && showFileTable && (
          <FileTable
            tableColumns={FilesStore.getTableColumns}
            tableFiles={FilesStore.filteredFiles}
            onFilter={(column, filter) => {
              FilesStore.applyFilter(column, filter);
            }}
            onSortColumnChange={(column, direction) => {
              FilesStore.applySort(column, direction);
            }}
            onEdit={(fileId) => {
              setSelectedFileId(fileId);
              setEditFileMetadata(true);
              FilesStore.setSelectedSection(ContentSelection.EditMetadata, undefined, false);
            }}
          />
        )}
        {!editFileMetadata && FilesStore.selectedSection == ContentSelection.UploadFile && <FileUpload />}
        {!editFileMetadata && FilesStore.selectedSection == ContentSelection.AddBlankDocument && (
          <NewFileFromTemplate />
        )}
        {!editFileMetadata && FilesStore.selectedSection == ContentSelection.AddTemplateDocument && (
          <NewFileFromTemplate />
        )}
        {editFileMetadata && <EditFileMetadata files={mapToFileMetadataModel()} />}
      </div>
      {showFileTable && FilesStore.isShowingFileInformation && (
        <div className={Style.fileInformation}>
          <FileInformation />
        </div>
      )}
    </div>
  );
};

export default observer(TabContainer);
