import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/ReviewSupersedeFileModal.module.scss';
import Icon from '../../shared/Icon';
import { dateTime12HFormat } from '../../../utils/dateUtils';
import {
  Checkbox,
  IColumnMoving,
  IHeader,
  Table,
  TableCell,
  TableRow,
  Tooltip,
} from '@aurecon-creative-technologies/styleguide';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import CloseButton from '../../shared/CloseButton';
import ModalActions from '../../shared/ModalActions';
import SecondaryButton from '../../shared/SecondaryButton';
import PrimaryButton from '../../shared/PrimaryButton';
import Modal from '../../shared/Modal';
import UploadStore from './UploadStore';
import { MetadataFieldTitle, MetadataFieldType } from '../../../common/enums/MetadataFieldType';
import { IMetadataField } from '../../../api/authenticated/cms/getProjectMetadata';
import SupersedeFileStore, {
  ISelectedDuplicateFileContainer,
  ISupersedeMetadataSelectedValueModel,
  IUploadContainerFile,
} from './SupersedeFileStore';
import FilesStore from '../FilesStore';
import { IFileContainer } from '../../../api/authenticated/cms/FileContainerModel';
import DropDownAdjustableColumns from '../../shared/DropDownAdjustableColumns';
import { classNames } from '../../../utils/miscUtils';

interface IReviewSupersedeColumn {
  label: string;
  resizable?: boolean;
  draggable?: boolean;
  onCheckbox?: (checked) => void;
  checked?: boolean;
  width?: number;
  minWidth?: number;
  valueField: string;
  visible?: boolean;
}

const ReviewSupersedeFileModal: FC = () => {
  const [containersToSupersedeTableColumns, setContainersToSupersedeTableColumns] = useState<IReviewSupersedeColumn[]>(
    []
  );
  const [containersToSupersedeTableHeaders, setContainersToSupersedeTableHeaders] = useState<IHeader[]>([]);
  const [areAllFilesSelected, setAreAllFilesSelected] = useState<boolean>(SupersedeFileStore.allFilesSelectedToCopy());
  const [filesToCopyTableColumns, setFilesToCopyTableColumns] = useState<IReviewSupersedeColumn[]>([]);
  const [filesToCopyTableHeaders, setFilesToCopyTableHeaders] = useState<IHeader[]>([]);

  useEffect(() => {
    setContainersToSupersedeTableColumns([
      {
        label: 'Original Filename',
        resizable: true,
        draggable: true,
        width: 200,
        minWidth: 200,
        valueField: 'fileContainerName',
        visible: true,
      },
      ...UploadStore.fileMetadata
        .filter((f) => !MetadataFieldTitle.includes(f.title))
        .map((field) => {
          return {
            label: field.title,
            resizable: true,
            draggable: true,
            width: 100,
            minWidth: 100,
            valueField: `${field.fieldValueIndex}`,
            visible: true,
          };
        }),
    ]);
    setFilesToCopyTableColumns([
      {
        label: '',
        valueField: 'checkbox',
        onCheckbox: (checked) => {
          SupersedeFileStore.setSelectedForAllFilesToCopy(checked);
          setAreAllFilesSelected(SupersedeFileStore.allFilesSelectedToCopy());
        },
        checked: areAllFilesSelected,
        minWidth: 30,
        width: 30,
        visible: true,
        resizable: false,
        draggable: false,
      },
      {
        label: 'Original Filename',
        valueField: 'originalFileName',
        resizable: true,
        draggable: true,
        width: 200,
        minWidth: 200,
        visible: true,
      },
      {
        label: 'Uploaded',
        valueField: 'uploadedDate',
        resizable: true,
        draggable: true,
        width: 200,
        minWidth: 200,
        visible: true,
      },
      {
        label: 'Action',
        valueField: 'Action',
        minWidth: 50,
        width: 50,
        visible: true,
        resizable: false,
        draggable: false,
      },
    ]);
  }, [setContainersToSupersedeTableColumns, areAllFilesSelected]);

  useEffect(() => {
    setContainersToSupersedeTableHeaders([
      ...containersToSupersedeTableColumns
        .filter((x) => x.visible)
        .map((col) => {
          return {
            label: col.label,
            resizable: col.resizable,
            draggable: col.draggable,
            width: col.width,
            minWidth: col.minWidth,
          };
        }),
    ]);
  }, [containersToSupersedeTableColumns]);

  useEffect(() => {
    setFilesToCopyTableHeaders([
      ...filesToCopyTableColumns
        .filter((x) => x.visible)
        .map((col) => {
          return {
            label: col.label,
            resizable: col.resizable,
            draggable: col.draggable,
            width: col.width,
            minWidth: col.minWidth,
            checked: col.valueField == 'checkbox' ? col.checked : undefined,
            onCheckbox: col.valueField == 'checkbox' ? col.onCheckbox : undefined,
          };
        }),
    ]);
  }, [filesToCopyTableColumns]);

  const doesFilenameExistInSupersedeList = (fileName: string) => {
    const existingNames = SupersedeFileStore.selectedDuplicateFileContainers
      .map((a) => a.newFiles.map((b) => b.fileName))
      .flat();

    return existingNames.includes(fileName);
  };

  const getMetadataValue = (metaData: ISupersedeMetadataSelectedValueModel[], field: IMetadataField) => {
    if (!metaData || !field.dataType.fieldType) return null;
    const metaDataValue = metaData.find((mt) => mt.fieldValueIndex === field.fieldValueIndex);
    if (metaDataValue && field.dataType?.fieldType === MetadataFieldType.List) return metaDataValue?.title;
    if (metaDataValue && field.dataType?.fieldType === MetadataFieldType.UserText) return metaDataValue?.value;
  };

  const setVisibleSelectedFileColumns = (columns: string[]) => {
    const visibleColumns = containersToSupersedeTableColumns.map((item) => {
      item.visible = columns.includes(item.valueField);
      return item;
    });
    setContainersToSupersedeTableColumns([...visibleColumns]);
  };

  const renderFilesToSupersede = (files: IUploadContainerFile[]) => {
    return files.map((m) => (
      <TableRow key={m.fileName}>
        {containersToSupersedeTableHeaders.map((h, i) => {
          const key = `${m.fileName}-${i}`;
          if (h.label === 'Original Filename') {
            return (
              <TableCell key={key}>
                <div className={Style.fileWrapper}>
                  <Icon name="shortcut" className={Style.fileIcon}></Icon>
                  <span>{m.fileName}</span>
                </div>
              </TableCell>
            );
          }
          return <TableCell key={key}></TableCell>;
        })}
      </TableRow>
    ));
  };

  const cellValue = (h: IHeader, file: ISelectedDuplicateFileContainer) => {
    const col = containersToSupersedeTableColumns.find((c) => c.label === h.label);
    if (!col) {
      return '--';
    }
    const fileMetaData = UploadStore.fileMetadata.find((f) => f.title === col.label);
    if (!fileMetaData) {
      return '--';
    }

    return getMetadataValue(file.metadata, fileMetaData);
  };

  const reorderSupersedeSelectionTable = (data: IColumnMoving) => {
    const moveColumn = containersToSupersedeTableColumns[data.fromIndex];
    if (moveColumn) {
      containersToSupersedeTableColumns.splice(data.fromIndex, 1);
      containersToSupersedeTableColumns.splice(data.toIndex, 0, moveColumn);
      setContainersToSupersedeTableColumns([...containersToSupersedeTableColumns]);
    }
  };

  const reorderDuplicatedFilesToCopyTable = (data: IColumnMoving) => {
    const moveColumn = filesToCopyTableColumns[data.fromIndex];
    if (moveColumn) {
      filesToCopyTableColumns.splice(data.fromIndex, 1);
      filesToCopyTableColumns.splice(data.toIndex, 0, moveColumn);
      setFilesToCopyTableColumns([...filesToCopyTableColumns]);
    }
  };

  const renderSupersedeSelectionTable = () => {
    return (
      <div className={Style.filesTableWrapper}>
        <div className={Style.labelWrapper}>
          <p className={Style.label}>
            <strong>Files to Supersede</strong>
          </p>
          <DropDownAdjustableColumns
            className={Style.dropDownAdjustableColumns}
            items={containersToSupersedeTableColumns.map((x) => {
              return {
                id: x.valueField,
                label: x.label,
              };
            })}
            selectedItems={containersToSupersedeTableColumns.filter((col) => col.visible).map((x) => x.valueField)}
            onSelected={setVisibleSelectedFileColumns}
          />
        </div>
        <Table
          cssClass={Style.fileContainersToSupersedeTable}
          headers={containersToSupersedeTableHeaders}
          hoverable
          onReorderColumn={reorderSupersedeSelectionTable}>
          {SupersedeFileStore.selectedDuplicateFileContainers
            .filter((f) => f.fileContainerId === SupersedeFileStore.selectedDuplicateFileContainerId)
            .map((f) => (
              <>
                <TableRow key={f.fileContainerId}>
                  {containersToSupersedeTableHeaders.map((h, i) => {
                    const key = `${f.fileContainerId}-${i}`;
                    if (h.label === 'Original Filename') {
                      return (
                        <TableCell key={key}>
                          <span>{f.fileContainerName}</span>
                        </TableCell>
                      );
                    }
                    return <TableCell key={key}>{cellValue(h, f)}</TableCell>;
                  })}
                </TableRow>
                {renderFilesToSupersede(f.newFiles)}
              </>
            ))}
        </Table>
        <p className={Style.label}>
          <strong>Copy files from current revision to new revision</strong>
        </p>
        <Table
          cssClass={Style.filesTable}
          headers={filesToCopyTableHeaders}
          hoverable
          onReorderColumn={reorderDuplicatedFilesToCopyTable}>
          {SupersedeFileStore.selectedDuplicateFileContainers
            .filter((f) => f.fileContainerId === SupersedeFileStore.selectedDuplicateFileContainerId)
            .flatMap(
              ({ fileContainerId, fileContainerRevisionId, releasedFileContainerId, selectedDuplicatedFilesToCopy }) =>
                selectedDuplicatedFilesToCopy
                  .map((m) => {
                    return {
                      ...m,
                      fileContainerId,
                      fileContainerRevisionId,
                      releasedFileContainerId,
                    };
                  })
                  .map((f) => {
                    const fileNameExists = doesFilenameExistInSupersedeList(f.originalFileName);

                    return (
                      <TableRow key={f.fileId} rowClass={classNames([fileNameExists, Style.rowDisabled])}>
                        {filesToCopyTableHeaders.map((h, i) => {
                          const key = `${f.originalFileName}-${i}`;
                          if (h.label === '') {
                            return (
                              <TableCell key={key}>
                                {fileNameExists ? (
                                  <Tooltip
                                    show={
                                      <div>
                                        This file has an identical filename to an uploaded file in this container
                                      </div>
                                    }>
                                    <Checkbox checked={f.isSelected} disabled={true} />
                                  </Tooltip>
                                ) : (
                                  <Checkbox
                                    checked={f.isSelected}
                                    onChange={(checked) => {
                                      SupersedeFileStore.setSelectedFileToCopy(f.fileId, checked);
                                      setAreAllFilesSelected(SupersedeFileStore.allFilesSelectedToCopy());
                                    }}
                                  />
                                )}
                              </TableCell>
                            );
                          }
                          if (h.label === 'Action') {
                            return (
                              <TableCell key={key}>
                                <div
                                  onClick={() => {
                                    FilesStore.downloadFile({
                                      fileContainers: [
                                        {
                                          id: f.fileContainerId,
                                          containerFileId: f.fileId,
                                          fileRevisionId: f.fileContainerRevisionId,
                                          releasedFileId: f.releasedFileContainerId,
                                        } as IFileContainer,
                                      ],
                                    });
                                  }}>
                                  <Icon className={Style.previewIcon} name="open_in_new" />
                                </div>
                              </TableCell>
                            );
                          }
                          if (h.label === 'Uploaded') {
                            return (
                              <TableCell key={key}>
                                <span>{dateTime12HFormat(f.uploadedDate)}</span>
                              </TableCell>
                            );
                          }
                          if (h.label === 'Original Filename') {
                            return (
                              <TableCell key={key}>
                                <span className={Style.textDisabled}>{f.originalFileName}</span>
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })
            )}
        </Table>
      </div>
    );
  };

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <CloseButton onClick={() => SupersedeFileStore.setOpenReviewSupersede(false)} disabled={false} />
        <div className={Style.container}>
          <div className={Style.header}>
            <h1>Review Supersede Files</h1>
          </div>
          {renderSupersedeSelectionTable()}
        </div>
        <ModalActions>
          <SecondaryButton onClick={() => SupersedeFileStore.setOpenReviewSupersede(false)}>Cancel</SecondaryButton>
          <PrimaryButton onClick={() => SupersedeFileStore.confirm()}>Confirm</PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(ReviewSupersedeFileModal);
