import React, { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { SearchBox, Pill, Icon, ISuggestion } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/AddTransmittalMessageVisibilitySelector.module.scss';
import {
  IProjectMembersAndTeams,
  IUser,
  ITaskTeam,
  IDeliveryTeam,
  IAppointingParty,
  IDistributionList,
} from '../../../api/authenticated/um/getProjectMembersAndTeams';
import TaskTeamTag from '../../shared/TaskTeamTag';
import DeliveryTeamTag from '../../shared/DeliveryTeamTag';
import AppointingPartyTag from '../../shared/AppointingPartyTag';
import DistributionListTag from '../../shared/DistributionListTag';
import { listSeparator, SelectorType } from '../../../enums/TransmittalsSelectorType';
import { IOpenPillProps, IPillSelectedItem } from '../../transmittals/Types';
import { IAppointingPartyUser } from '../../../api/authenticated/um/getProjectMembersAndTeams';

export interface IItemSelectorProps {
  label?: string;
  required?: boolean;
  searchPlaceholder: string;
  disabled?: boolean;
  isMultiUser?: boolean;
  selectedVisibleTos?: IProjectMembersAndTeams;
  getItems(text: string): IProjectMembersAndTeams;
  openSelectPillModal(selectPillItem: IOpenPillProps | undefined): void;
  onSelectedItemUpdated(setSelectedItems: IProjectMembersAndTeams): void;
}

const AddTransmittalMessageVisibilitySelector: FC<IItemSelectorProps> = ({
  label,
  required,
  searchPlaceholder,
  disabled,
  isMultiUser,
  selectedVisibleTos,
  getItems,
  openSelectPillModal,
  onSelectedItemUpdated,
}) => {
  const [disableSearchBox, setDisableSearchBox] = useState(false);
  const [triggerCleanup, setTriggerCleanup] = useState<number>(1);
  const [users, setUsers] = useState<IUser[]>([]);
  const [deliveryTeams, setDeliveryTeams] = useState<IDeliveryTeam[]>([]);
  const [taskTeams, setTaskTeams] = useState<ITaskTeam[]>([]);
  const [appointingParties, setAppointingParties] = useState<IAppointingParty[]>([]);
  const [selectedItems, setSelectedItems] = useState<IPillSelectedItem[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [selectedDeliveryTeams, setSelectedDeliveryTeams] = useState<IDeliveryTeam[]>([]);
  const [selectedTaskTeams, setSelectedTaskTeams] = useState<ITaskTeam[]>([]);
  const [selectedAppointingParties, setSelectedAppointingParties] = useState<IAppointingParty[]>([]);
  const [distributionListSet, setDistributionList] = useState<IDistributionList[]>([]);
  const [selectedDistributionList, setSelectedDistributionList] = useState<IDistributionList[]>([]);
  const [appointingPartyUsers, setAppointingPartyUsers] = useState<IAppointingPartyUser[]>([]);
  const [selectedAppointingPartyUsers, setSelectedAppointingPartyUsers] = useState<IAppointingPartyUser[]>([]);

  useEffect(() => {
    if (!selectedVisibleTos?.users) return;
    setSelectedUsers(selectedVisibleTos?.users);
  }, [selectedVisibleTos?.users]);

  const userPill = (user: IUser): IPillSelectedItem => ({
    id: `${SelectorType.User}/${user.userId}/${user.taskTeamId}/${user.deliveryTeamId}`,
    value: (
      <span>
        <Icon type="person" /> {user.userName} <b>{user.isExternal ? 'Ext.' : user.taskTeamCode}</b>
      </span>
    ),
    selectedType: SelectorType.User,
  });

  const taskTeamPill = (taskTeam: ITaskTeam): IPillSelectedItem => ({
    id: `${SelectorType.TaskTeam}/${taskTeam.taskTeamId}`,
    value: (
      <TaskTeamTag
        deliveryTeamTitle={taskTeam.deliveryTeamTitle}
        taskTeamTitle={taskTeam.taskTeamTitle}
        taskTeamCode={taskTeam.taskTeamCode}
        userCount={taskTeam.userCount}
      />
    ),
    selectedType: SelectorType.TaskTeam,
  });

  const deliveryTeamPill = (deliveryTeam: IDeliveryTeam): IPillSelectedItem => ({
    id: `${SelectorType.DeliveryTeam}/${deliveryTeam.deliveryTeamId}`,
    value: (
      <DeliveryTeamTag
        deliveryTeamTitle={deliveryTeam.deliveryTeamTitle}
        deliveryTeamCode={deliveryTeam.deliveryTeamCode}
      />
    ),
    selectedType: SelectorType.DeliveryTeam,
  });

  const distributionListPill = (distributionList: IDistributionList): IPillSelectedItem => ({
    id: `${SelectorType.DistributionList}/${distributionList.distributionListId}`,
    value: (
      <DistributionListTag
        distributionListName={distributionList.distributionListName}
        userCount={distributionList.userCount}
      />
    ),
    selectedType: SelectorType.DistributionList,
  });

  const appointingPartyPill = (appointingParty: IAppointingParty): IPillSelectedItem => ({
    id: `${SelectorType.AppointingParty}/${appointingParty.appointingPartyId}`,
    value: (
      <AppointingPartyTag
        appointingPartyCode={appointingParty.appointingPartyCode}
        appointingPartyTitle={appointingParty.appointingPartyTitle}
      />
    ),
    selectedType: SelectorType.AppointingParty,
  });

  const appointingPartyUserPill = (user: IAppointingPartyUser): IPillSelectedItem => ({
    id: `${SelectorType.AppointingPartyUser}/${user.userId}/${user.appointingPartyId}`,
    value: (
      <span>
        <Icon type="person" /> {user.userName} <b>APP</b>
      </span>
    ),
    selectedType: SelectorType.AppointingPartyUser,
  });

  const selectedVisibleToItems = selectedVisibleTos
    ? [
        ...(selectedVisibleTos.users?.map(userPill) || []),
        ...(selectedVisibleTos.appointingPartyUsers?.map(appointingPartyUserPill) || []),
        ...(selectedVisibleTos.deliveryTeams?.map(deliveryTeamPill) || []),
        ...(selectedVisibleTos.taskTeams?.map(taskTeamPill) || []),
        ...(selectedVisibleTos.appointingParties?.map(appointingPartyPill) || []),
        ...(selectedVisibleTos.distributionList?.map(distributionListPill) || []),
      ]
    : [];

  const removeSelectedItem = (item: IPillSelectedItem) => {
    if (disabled) return;

    setSelectedItems(selectedItems.filter((r) => r.id !== item.id));
    const selectedType = Number(item.id.split('/')[0]);

    if (selectedType === SelectorType.User) {
      const userId = Number(item.id.split('/')[1]);
      const updatedSelectedUsers = selectedUsers.filter((u) => u.userId !== userId);
      setSelectedUsers(updatedSelectedUsers);
      onSelectedItemUpdated({
        users: updatedSelectedUsers,
        deliveryTeams: selectedDeliveryTeams,
        taskTeams: selectedTaskTeams,
        appointingParties: selectedAppointingParties,
        distributionList: selectedDistributionList,
        appointingPartyUsers: selectedAppointingPartyUsers,
      });
    } else if (selectedType === SelectorType.DeliveryTeam) {
      const deliveryTeamId = Number(item.id.split('/')[1]);
      const updatedSelectedDeliveryTeams = selectedDeliveryTeams.filter((u) => u.deliveryTeamId !== deliveryTeamId);
      setSelectedDeliveryTeams(updatedSelectedDeliveryTeams);
      onSelectedItemUpdated({
        users: selectedUsers,
        deliveryTeams: updatedSelectedDeliveryTeams,
        taskTeams: selectedTaskTeams,
        appointingParties: selectedAppointingParties,
        distributionList: selectedDistributionList,
        appointingPartyUsers: selectedAppointingPartyUsers,
      });
    } else if (selectedType === SelectorType.TaskTeam) {
      const taskTeamId = Number(item.id.split('/')[1]);
      const updatedSelectedTaskTeams = selectedTaskTeams.filter((u) => u.taskTeamId !== taskTeamId);
      setSelectedTaskTeams(updatedSelectedTaskTeams);
      onSelectedItemUpdated({
        users: selectedUsers,
        deliveryTeams: selectedDeliveryTeams,
        taskTeams: updatedSelectedTaskTeams,
        appointingParties: selectedAppointingParties,
        distributionList: selectedDistributionList,
        appointingPartyUsers: selectedAppointingPartyUsers,
      });
    } else if (selectedType === SelectorType.AppointingParty) {
      const appointingPartyId = Number(item.id.split('/')[1]);
      const updatedSelectedAppointingParties = selectedAppointingParties.filter(
        (u) => u.appointingPartyId !== appointingPartyId
      );
      setSelectedAppointingParties(updatedSelectedAppointingParties);
      onSelectedItemUpdated({
        users: selectedUsers,
        deliveryTeams: selectedDeliveryTeams,
        taskTeams: selectedTaskTeams,
        appointingParties: updatedSelectedAppointingParties,
        distributionList: selectedDistributionList,
        appointingPartyUsers: selectedAppointingPartyUsers,
      });
    } else if (selectedType === SelectorType.DistributionList) {
      const listId = Number(item.id.split('/')[1]);
      const updatedSelectedDistributionList = selectedDistributionList.filter((u) => u.distributionListId !== listId);
      setSelectedDistributionList(updatedSelectedDistributionList);
      onSelectedItemUpdated({
        users: selectedUsers,
        deliveryTeams: selectedDeliveryTeams,
        taskTeams: selectedTaskTeams,
        appointingParties: selectedAppointingParties,
        distributionList: updatedSelectedDistributionList,
        appointingPartyUsers: selectedAppointingPartyUsers,
      });
    } else if (selectedType === SelectorType.AppointingPartyUser) {
      const listIds = item.id.split('/');
      const userId = Number(listIds[1]);
      const appointingPartyId = Number(listIds[2]);
      const updatedSelectedAppointingPartyUsers = selectedAppointingPartyUsers.filter(
        (u) => u.userId !== userId && u.appointingPartyId !== appointingPartyId
      );
      setSelectedAppointingPartyUsers(updatedSelectedAppointingPartyUsers);
      onSelectedItemUpdated({
        users: selectedUsers,
        deliveryTeams: selectedDeliveryTeams,
        taskTeams: selectedTaskTeams,
        appointingParties: selectedAppointingParties,
        distributionList: selectedDistributionList,
        appointingPartyUsers: updatedSelectedAppointingPartyUsers,
      });
    }
  };

  const onSelectPillItem = (item: IPillSelectedItem) => {
    if (!item) return;
    const id = Number(item.id.split('/')[1]);
    openSelectPillModal({ id: id, selectedType: item.selectedType });
  };

  const addSelectedItem = (id: string) => {
    if (disabled || listSeparator.some((x) => x.value === id)) {
      setUsers([]);
      setDeliveryTeams([]);
      setTaskTeams([]);
      setAppointingParties([]);
      setDistributionList([]);
      setAppointingPartyUsers([]);
      setTriggerCleanup(triggerCleanup + 1);
      return;
    }

    const type = Number(id.split('/')[0]);

    if (type === SelectorType.User) {
      const userId = Number(id.split('/')[1]);
      const taskTeamId = Number(id.split('/')[2]);
      const deliveryTeamId = Number(id.split('/')[3]);
      const user = users.find(
        (u) => u.userId === userId && u.taskTeamId === taskTeamId && u.deliveryTeamId === deliveryTeamId
      );
      if (!user || selectedUsers.some((u) => u.userId === userId)) return;
      const updatedSelectedUsers = [...selectedUsers, user];
      setSelectedUsers(updatedSelectedUsers);

      setSelectedItems([...selectedItems, userPill(user)]);
      onSelectedItemUpdated({
        users: updatedSelectedUsers,
        deliveryTeams: selectedDeliveryTeams,
        taskTeams: selectedTaskTeams,
        appointingParties: selectedAppointingParties,
        distributionList: selectedDistributionList,
        appointingPartyUsers: selectedAppointingPartyUsers,
      });
    } else if (type === SelectorType.DeliveryTeam) {
      const deliveryTeamId = Number(id.split('/')[1]);
      const deliveryTeam = deliveryTeams.find((t) => t.deliveryTeamId === deliveryTeamId);
      if (!deliveryTeam || selectedDeliveryTeams.some((t) => t.deliveryTeamId === deliveryTeamId)) return;
      const updatedSelectedDeliveryTeams = [...selectedDeliveryTeams, deliveryTeam];
      setSelectedDeliveryTeams(updatedSelectedDeliveryTeams);

      setSelectedItems([...selectedItems, deliveryTeamPill(deliveryTeam)]);
      onSelectedItemUpdated({
        users: selectedUsers,
        deliveryTeams: updatedSelectedDeliveryTeams,
        taskTeams: selectedTaskTeams,
        appointingParties: selectedAppointingParties,
        distributionList: selectedDistributionList,
        appointingPartyUsers: selectedAppointingPartyUsers,
      });
    } else if (type === SelectorType.TaskTeam) {
      const taskTeamId = Number(id.split('/')[1]);
      const taskTeam = taskTeams.find((t) => t.taskTeamId === taskTeamId);
      if (!taskTeam || selectedTaskTeams.some((t) => t.taskTeamId === taskTeamId)) return;
      const updatedSelectedTaskTeams = [...selectedTaskTeams, taskTeam];
      setSelectedTaskTeams(updatedSelectedTaskTeams);

      setSelectedItems([...selectedItems, taskTeamPill(taskTeam)]);
      onSelectedItemUpdated({
        users: selectedUsers,
        deliveryTeams: selectedDeliveryTeams,
        taskTeams: updatedSelectedTaskTeams,
        appointingParties: selectedAppointingParties,
        distributionList: selectedDistributionList,
        appointingPartyUsers: selectedAppointingPartyUsers,
      });
    } else if (type === SelectorType.AppointingParty) {
      const appointingPartyId = Number(id.split('/')[1]);
      const appointingParty = appointingParties.find((t) => t.appointingPartyId === appointingPartyId);
      if (!appointingParty || selectedAppointingParties.some((t) => t.appointingPartyId === appointingPartyId)) return;
      const updatedSelectedAppointingParties = [...selectedAppointingParties, appointingParty];
      setSelectedAppointingParties(updatedSelectedAppointingParties);

      setSelectedItems([...selectedItems, appointingPartyPill(appointingParty)]);
      onSelectedItemUpdated({
        users: selectedUsers,
        deliveryTeams: selectedDeliveryTeams,
        taskTeams: selectedTaskTeams,
        appointingParties: updatedSelectedAppointingParties,
        distributionList: selectedDistributionList,
        appointingPartyUsers: selectedAppointingPartyUsers,
      });
    } else if (type === SelectorType.DistributionList) {
      const listId = Number(id.split('/')[1]);
      const distributionList = distributionListSet.find((t) => t.distributionListId === listId);
      if (!distributionList || selectedDistributionList.some((t) => t.distributionListId === listId)) return;
      const updatedSelectedDistributionList = [...selectedDistributionList, distributionList];
      setSelectedDistributionList(updatedSelectedDistributionList);

      setSelectedItems([...selectedItems, distributionListPill(distributionList)]);
      onSelectedItemUpdated({
        users: selectedUsers,
        deliveryTeams: selectedDeliveryTeams,
        taskTeams: selectedTaskTeams,
        appointingParties: selectedAppointingParties,
        distributionList: updatedSelectedDistributionList,
        appointingPartyUsers: selectedAppointingPartyUsers,
      });
    } else if (type === SelectorType.AppointingPartyUser) {
      const listIds = id.split('/');
      const userId = Number(listIds[1]);
      const appointingPartyId = Number(listIds[2]);
      const user = appointingPartyUsers.find((u) => u.userId === userId && u.appointingPartyId === appointingPartyId);
      if (
        !user ||
        selectedAppointingPartyUsers.some((u) => u.userId === userId && u.appointingPartyId === appointingPartyId)
      )
        return;
      const updatedSelectedAppointingPartyUsers = [...selectedAppointingPartyUsers, user];
      setSelectedAppointingPartyUsers(updatedSelectedAppointingPartyUsers);

      setSelectedItems([...selectedItems, appointingPartyUserPill(user)]);
      onSelectedItemUpdated({
        users: selectedUsers,
        deliveryTeams: selectedDeliveryTeams,
        taskTeams: selectedTaskTeams,
        appointingParties: selectedAppointingParties,
        distributionList: selectedDistributionList,
        appointingPartyUsers: updatedSelectedAppointingPartyUsers,
      });
    }
    setUsers([]);
    setDeliveryTeams([]);
    setTaskTeams([]);
    setAppointingParties([]);
    setDistributionList([]);
    setAppointingPartyUsers([]);
    setTriggerCleanup(triggerCleanup + 1);

    if (!isMultiUser) setDisableSearchBox(true);
  };

  const loadItems = (text: string) => {
    if (disabled) return;
    if (!text) {
      setUsers([]);
      setDeliveryTeams([]);
      setTaskTeams([]);
      setAppointingParties([]);
      setDistributionList([]);
      setAppointingPartyUsers([]);
    } else {
      const items = getItems(text);
      setUsers(items.users);
      setDeliveryTeams(items.deliveryTeams);
      setTaskTeams(items.taskTeams);
      setAppointingParties(items.appointingParties);
      setDistributionList(items.distributionList);
      setAppointingPartyUsers(items.appointingPartyUsers);
    }
  };

  const filteredItems = useMemo(() => {
    let dropdownList = [] as ISuggestion[];
    const userList = users
      .filter(
        (user) =>
          !selectedUsers.find(({ userId, taskTeamId, deliveryTeamId }) => {
            return user.userId === userId && user.taskTeamId === taskTeamId && user.deliveryTeamId === deliveryTeamId;
          })
      )
      .map((r) => ({
        id: `${SelectorType.User}/${r.userId}/${r.taskTeamId}/${r.deliveryTeamId}`,
        value: `${r.userName} (${r.userEmail})`,
        display: (
          <>
            <span>
              {r.userName} ({r.userEmail})
            </span>
            <span className={Style.teamDetails}>
              <Icon type="people" /> {r.isExternal && 'External User'}
              {!r.isExternal && (
                <>
                  {r.deliveryTeamTitle} | <b>{r.taskTeamTitle}</b>
                </>
              )}
            </span>
          </>
        ),
      }));
    const userSeparator = listSeparator.find((x) => x.key === SelectorType.User)?.value;
    if (userList.length && userSeparator)
      dropdownList = [
        ...dropdownList,
        { id: userSeparator, value: 'Users', display: <span className={Style.disable}>Users</span> },
        ...userList,
      ];

    const deliveryTeamList = deliveryTeams
      .filter(
        (deliveryTeam) =>
          !selectedDeliveryTeams.find(({ deliveryTeamId }) => {
            return deliveryTeam.deliveryTeamId === deliveryTeamId;
          })
      )
      .map((r) => ({
        id: `${SelectorType.DeliveryTeam}/${r.deliveryTeamId}`,
        value: `${r.deliveryTeamTitle}`,
        display: (
          <span>
            <Icon type="people" /> {r.deliveryTeamTitle}
          </span>
        ),
      }));

    const taskTeamList = taskTeams
      .filter(
        (taskTeam) =>
          !selectedTaskTeams.find(({ taskTeamId }) => {
            return taskTeam.taskTeamId === taskTeamId;
          })
      )
      .map((r) => ({
        id: `${SelectorType.TaskTeam}/${r.taskTeamId}`,
        value: `${r.taskTeamTitle}`,
        display: (
          <span>
            <Icon type="people" /> {r.deliveryTeamTitle} | <b>{r.taskTeamTitle}</b>
            <span className={Style.userCount}>
              <Pill background="light" size="mini" colour={2}>
                {r.userCount}
              </Pill>
            </span>
          </span>
        ),
      }));

    const appointingPartyList = appointingParties
      .filter(
        (appointingParty) =>
          !selectedAppointingParties.find(({ appointingPartyId }) => {
            return appointingParty.appointingPartyId === appointingPartyId;
          })
      )
      .map((r) => ({
        id: `${SelectorType.AppointingParty}/${r.appointingPartyId}`,
        value: `${r.appointingPartyTitle}`,
        display: (
          <span>
            <Icon type="folder" /> {r.appointingPartyTitle} (Appointing Party)
            <span className={Style.userCount}>
              <Pill background="light" size="mini" colour={2}>
                {r.userCount}
              </Pill>
            </span>
          </span>
        ),
      }));

    const distributionList = distributionListSet
      .filter(
        (list) =>
          !selectedDistributionList.some(({ distributionListId }) => {
            return list.distributionListId === distributionListId;
          })
      )
      .map((r) => ({
        id: `${SelectorType.DistributionList}/${r.distributionListId}`,
        value: `${r.distributionListName}`,
        display: (
          <span>
            <Icon type="people" /> {r.distributionListName}
            <span className={Style.userCount}>
              <Pill background="light" size="mini" colour={2}>
                {r.userCount}
              </Pill>
            </span>
          </span>
        ),
      }));

    const appointingPartyUserList = appointingPartyUsers
      .filter(
        (user) =>
          !selectedAppointingPartyUsers.find(({ userId, appointingPartyId }) => {
            return user.userId === userId && user.appointingPartyId === appointingPartyId;
          })
      )
      .map((r) => ({
        id: `${SelectorType.AppointingPartyUser}/${r.userId}/${r.appointingPartyId}`,
        value: `${r.userName} (${r.userEmail})`,
        display: (
          <span>
            {r.userName} ({r.userEmail})
          </span>
        ),
      }));

    if (
      deliveryTeamList.length ||
      taskTeamList.length ||
      appointingPartyList.length ||
      distributionList.length ||
      appointingPartyUserList.length
    ) {
      const teamSeparator = listSeparator.find((x) => x.key === SelectorType.TaskTeam)?.value;
      const distributionListSeparator = listSeparator.find((x) => x.key === SelectorType.DistributionList)?.value;
      const appointingPartyUserListSeparator = listSeparator.find(
        (x) => x.key === SelectorType.AppointingPartyUser
      )?.value;
      dropdownList = [
        ...dropdownList,
        { id: teamSeparator ?? '', value: 'Teams', display: <span className={Style.listSeparator}>Teams</span> },
        ...deliveryTeamList,
        ...taskTeamList,
        ...appointingPartyList,
        {
          id: distributionListSeparator ?? '',
          value: 'DistributionList',
          display: <span className={Style.listSeparator}>Distribution List</span>,
        },
        ...distributionList,
        {
          id: appointingPartyUserListSeparator ?? '',
          value: 'AppointingPartyUserList',
          display: <span className={Style.listSeparator}>Appointing Party</span>,
        },
        ...appointingPartyUserList,
      ];
    }

    return dropdownList;
  }, [
    appointingParties,
    deliveryTeams,
    selectedAppointingParties,
    selectedDeliveryTeams,
    selectedTaskTeams,
    selectedUsers,
    taskTeams,
    users,
    distributionListSet,
    selectedDistributionList,
    appointingPartyUsers,
    selectedAppointingPartyUsers,
  ]);

  return (
    <>
      {label && (
        <label className={Style.label}>
          {label}
          {required && <span className={Style.required}>*</span>}
        </label>
      )}
      <div className={Style.searchBoxContainer}>
        {(selectedVisibleToItems.length ? selectedVisibleToItems : selectedItems).map((i) => {
          if (!i) return null;
          return (
            <Pill
              key={i.id}
              colour={1}
              onClick={() => onSelectPillItem(i)}
              onClose={() => removeSelectedItem(i)}
              cssClass={Style.userPill}>
              {i.value}
            </Pill>
          );
        })}
        <SearchBox
          placeholder={searchPlaceholder}
          hideSearchButton
          disableDefaultMatching
          suggestions={filteredItems}
          onChange={loadItems}
          onSearch={loadItems}
          onSelect={(s) => addSelectedItem(String(s.id))}
          triggerCleanup={triggerCleanup}
          disabled={disabled || disableSearchBox}
          suggestionLength={filteredItems?.length ?? 0}
          cssClass={Style.searchBox}
        />
      </div>
    </>
  );
};

export default observer(AddTransmittalMessageVisibilitySelector);
