import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TaskInformationAssignedTo.module.scss';
import UserCircle from '../shared/UserCircle';
import TaskInformationStore from './TaskInformationStore';
import TaskUserStatusIndicator from '../shared/TaskUserStatusIndicator';
import UserCircleCollection from '../shared/UserCircleCollection';
import classNames from 'classnames';
import SeeMoreLink from '../shared/SeeMoreLink';
import TaskAssigneeModal from './TaskAssigneeModal';

const takeTopAssignees = 2;
const TaskInformationAssignedTo: FC = () => {
  const [showAssigneeModal, setShowAssigneeModal] = useState(false);
  if (TaskInformationStore.task === null) return null;

  const firstThreeAssignees = TaskInformationStore.task.taskUsers.slice(0, takeTopAssignees);
  return (
    <div className={Style.assignUser}>
      {!TaskInformationStore.task.isSingleApproverRequired && (
        <div className={Style.requiredAllApprovedText}>Required all users to approve</div>
      )}
      <ul className={classNames(Style.users, Style.userCircle)}>
        {TaskInformationStore.task.taskUsers.map((user) => (
          <li key={user.userId} className={Style.user}>
            <div className={Style.namePanel}>
              <UserCircle initials={user.initials} email={user.email} />
              <div className={Style.name}>{user.name}</div>
            </div>
            <TaskUserStatusIndicator taskUserStatusId={user.taskUserStatusId} />
          </li>
        ))}
      </ul>
      <div className={Style.userCircleCollection}>
        <UserCircleCollection
          users={firstThreeAssignees.map((user) => ({
            id: user.userId,
            initials: user.initials,
            name: user.name,
            email: user.email,
          }))}
        />
        {TaskInformationStore.task.taskUsers.length > takeTopAssignees && (
          <SeeMoreLink onClick={() => setShowAssigneeModal(true)} />
        )}
        <TaskAssigneeModal showing={showAssigneeModal} onClose={() => setShowAssigneeModal(false)} />
      </div>
    </div>
  );
};

export default observer(TaskInformationAssignedTo);
