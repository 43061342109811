import { Icon, Loader } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { classNames } from '../../utils/miscUtils';
import Style from './styles/PrimaryIconButton.module.scss';

export interface IPrimaryIconButtonProps {
  className?: string;
  icon: string;
  disabled?: boolean;
  active?: boolean;
  loading?: boolean;
  size?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const PrimaryIconButton: FC<IPrimaryIconButtonProps> = ({
  className,
  icon,
  disabled,
  active,
  loading,
  size,
  onClick,
}) => {
  return (
    <button
      className={classNames(Style.iconButton, [!!active && !loading, Style.active], className)}
      disabled={disabled || loading}
      onClick={(e) => {
        onClick && onClick(e);
        return false;
      }}>
      {loading ? <Loader size="extra small" /> : <Icon type={icon} size={size} />}
    </button>
  );
};

export default observer(PrimaryIconButton);
