import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/SupportFilesUploadModal.module.scss';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import Modal from '../../shared/Modal';
import CloseButton from '../../shared/CloseButton';
import ModalActions from '../../shared/ModalActions';
import SecondaryButton from '../../shared/SecondaryButton';
import PrimaryButton from '../../shared/PrimaryButton';
import FileDropZone from '../../shared/FileDropZone';
import { Text } from '@aurecon-creative-technologies/styleguide';
import SupportingFilesUploadStore from './SupportingFilesUploadStore';
import { ICreateTransmittal } from '../../../api/authenticated/transmittals/createTransmittal';
import CreateTransmittalErrorModal from '../CreateTransmittalErrorModal';
import ConfirmationModal from '../../shared/ConfirmationModal';
import LayoutStore from '../../layout/LayoutStore';
import { SupportFileMode } from '../Types';
import { IAddTransmittalMessage } from '../../../api/authenticated/transmittals/addTransmittalMessage';
import ProgressBar from '../../shared/ProgressBar';
import SelectSupportFileList from '../../shared/SelectSupportFileList';

interface ISupportingFilesUploadModalProps {
  payload?: ICreateTransmittal | IAddTransmittalMessage | null;
  projectNumber?: string;
  transmittalId?: number;
  transmittalMessageId?: number;
  closeModal: (uploadSucceeded: boolean, errorMessage?: string) => void;
  mode: SupportFileMode;
}

const SupportingFilesUploadModal: FC<ISupportingFilesUploadModalProps> = (props) => {
  const { mode, payload, projectNumber, transmittalId, transmittalMessageId, closeModal } = props;
  const [showErrorModal, setShowErrorModal] = useState(false);

  const closeTabEvent = (ev) => {
    if (!SupportingFilesUploadStore.selectedFiles.length) return;

    ev.preventDefault();
    ev.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
    return ev.returnValue;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', closeTabEvent);
    return () => {
      window.removeEventListener('beforeunload', closeTabEvent);
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      await SupportingFilesUploadStore.initialUpload(mode, payload, projectNumber, transmittalId);
    };

    init();
  }, [mode, payload, projectNumber, transmittalId, transmittalMessageId]);

  const handleCancelButtonClick = () => {
    closeModal && closeModal(SupportingFilesUploadStore.showUploadSuccess, SupportingFilesUploadStore.errorMessage);
    SupportingFilesUploadStore.clear();
  };

  const onUploadButtonClick = async () => {
    await SupportingFilesUploadStore.handleUploadSupportingFiles();
    if (SupportingFilesUploadStore.errorMessage) setShowErrorModal(true);

    if (SupportingFilesUploadStore.filesFailedToUpload.length)
      SupportingFilesUploadStore.setErrorMessage(
        `${SupportingFilesUploadStore.filesFailedToUpload.length} file(s) cannot be uploaded at the moment. Please try again later.`
      );
    else {
      LayoutStore.displayToast(
        'success',
        `${SupportingFilesUploadStore.selectedFiles.length} has been uploaded successfully`
      );
    }
    handleCancelButtonClick();
  };

  const handleOnDropzoneChange = (acceptedFiles) => {
    SupportingFilesUploadStore.addFiles(acceptedFiles);
  };

  const errorMessage = () => {
    if (SupportingFilesUploadStore.isDuplicatedFiles)
      return `The selected file(s) already exist. Please choose another file and try again. ${SupportingFilesUploadStore.duplicatedFiles
        .map((f) => f.name)
        .join(', ')}`;
    if (SupportingFilesUploadStore.missingFileExtension)
      return `The selected file(s) missing File Extension. Please choose another files and try again. ${SupportingFilesUploadStore.missingExtensionFiles
        .map((f) => f.name)
        .join(', ')}`;

    if (SupportingFilesUploadStore.filesSizeExceededTheLimit)
      return 'Total file size exceed maximum limit. Maximum total file size is 1GB.';
    return '';
  };

  return (
    <>
      <CentreOnPage>
        <Overlay />
        <Modal className={Style.container}>
          <CloseButton onClick={handleCancelButtonClick} disabled={false} />
          <div>
            <Text type="h3">Add Supporting File(s)</Text>
            <div className={Style.uploadForm}>
              <FileDropZone
                label={
                  <div>
                    <p>
                      <b>Upload Supporting file(s)</b>
                    </p>
                    <p>Drag & drop or click to browse for files</p>
                    <p>(Max Upload: 1GB)</p>
                  </div>
                }
                multiple={true}
                loading={SupportingFilesUploadStore.isProcessing}
                onDropzoneChange={(acceptedFiles) => handleOnDropzoneChange(acceptedFiles)}
              />
              <SelectSupportFileList
                files={SupportingFilesUploadStore.uploadFiles}
                onFileListChange={(files) => SupportingFilesUploadStore.handleFileListChange(files)}
                onRemoveFile={SupportingFilesUploadStore.removeFile}
              />
            </div>
          </div>
          <ModalActions>
            <SecondaryButton onClick={handleCancelButtonClick}>Cancel</SecondaryButton>
            <PrimaryButton
              disabled={
                !SupportingFilesUploadStore.selectedFiles.length || SupportingFilesUploadStore.isCheckingDuplicated
              }
              onClick={() => onUploadButtonClick()}>
              Upload
            </PrimaryButton>
          </ModalActions>
        </Modal>
      </CentreOnPage>
      {showErrorModal && (
        <CreateTransmittalErrorModal
          errorMessage={SupportingFilesUploadStore.errorMessage}
          closeModal={() => setShowErrorModal(false)}
        />
      )}
      {SupportingFilesUploadStore.showProgressBar && (
        <ProgressBar
          completed={SupportingFilesUploadStore.percentageUploaded}
          uploadSuccess={SupportingFilesUploadStore.showUploadSuccess}
          uploadFailed={SupportingFilesUploadStore.showUploadFailed}
          listOfFailedFiles={SupportingFilesUploadStore.filesFailedToUpload || []}
          onClose={handleCancelButtonClick}
        />
      )}
      <ConfirmationModal
        showModal={SupportingFilesUploadStore.openUploadError}
        heading="Upload Error"
        message={errorMessage()}
        confirmText="Ok"
        onCancel={() => SupportingFilesUploadStore.setOpenUploadError(false)}
        onConfirm={() => SupportingFilesUploadStore.setOpenUploadError(false)}
      />
    </>
  );
};

export default observer(SupportingFilesUploadModal);
