import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/teamManagement/ExternalUserDetails.module.scss';
import {
  FormInput,
  IHeader,
  ISuggestion,
  Loader,
  SearchBox,
  Table,
  TableCell,
  TableRow,
} from '@aurecon-creative-technologies/styleguide';
import { IUser } from '../../shared/UserSelector';
import ConfirmationModal from '../../shared/ConfirmationModal';
import SearchBar from '../../shared/SearchBar';
import ExternalUserStore from '../../../stores/settings/teamManagement/ExternalUserStore';
import { SortTypes } from '../../../common/enums/SortType';
import PrimaryButton from '../../shared/PrimaryButton';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import AppStore from '../../../stores/AppStore';

interface IExternalUserDetailsProps {
  projectNumber: string;
}

const ExternalUserDetails: FC<IExternalUserDetailsProps> = (props) => {
  const { projectNumber } = props;
  const [loading, setLoading] = useState(false);
  const [addingOrDeleteUser, setAddingOrDeleteUser] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [sortDirection, setSortDirection] = useState<SortTypes>();
  const [sortedField, setSortedField] = useState('');
  const [searchText, setSearchText] = useState('');
  const [triggerCleanup, setTriggerCleanup] = useState<number>(1);
  const [disableDisplayName, setDisableDisplayName] = useState<boolean>(false);

  useEffect(() => {
    const effectAsync = async () => {
      setLoading(true);
      await ExternalUserStore.init(projectNumber);
      setLoading(false);
    };
    effectAsync();
  }, [projectNumber]);

  const removeUserFromList = async () => {
    setAddingOrDeleteUser(true);
    selectedUser && (await ExternalUserStore.removeExternalUserFromList());
    setShowConfirmation(false);
    setAddingOrDeleteUser(false);
    setSelectedUser(undefined);
  };
  const addSelectedUser = () => {
    ExternalUserStore.addMembersToExternalUser();
    setTriggerCleanup(triggerCleanup + 1);
    if (canInviteOrRemoveAzure) setDisableDisplayName(false);
  };
  const onSortSelected = (columnName: string, sort: SortTypes) => {
    setSortedField(columnName);
    setSortDirection(sort);
    ExternalUserStore.applySort(columnName, sort);
  };

  const headers: IHeader[] = [
    {
      label: 'User Name',
      sort: sortedField === 'name' ? sortDirection : 'none',
      onSort: (sort) => onSortSelected('name', sort as SortTypes),
    },
    {
      label: 'User Email',
      sort: sortedField === 'email' ? sortDirection : 'none',
      onSort: (sort) => onSortSelected('email', sort as SortTypes),
    },
    {
      label: 'Remove',
    },
  ];

  const onUsersSelected = (selected: ISuggestion) => {
    if (canInviteOrRemoveAzure) setDisableDisplayName(true);
    ExternalUserStore.onSelectedUsersUpdated(selected);
  };
  const onUsersSearch = (keyword: string) => {
    if (canInviteOrRemoveAzure) setDisableDisplayName(false);
    ExternalUserStore.getMatchedUsers(keyword);
  };
  const onUserClear = () => {
    if (canInviteOrRemoveAzure) setDisableDisplayName(false);
    ExternalUserStore.getMatchedUsers('');
  };
  const onDisplayNameChanged = (text: string) => {
    ExternalUserStore.setDisplayName(text);
  };

  const canInviteOrRemoveAzure = AppStore.client?.canInviteOrRemoveAzure;

  if (loading) return <Loader />;

  return (
    <div className={Style.Container}>
      <div className={Style.addUserBox}>
        <div className={Style.heading}>Add Users</div>
        <div className={Style.addForm}>
          <div className={Style.userEmailInput}>
            <div className={Style.label}>
              User Email <span className={Style.requiredSymbol}>*</span>
            </div>
            <SearchBox
              hideSearchButton
              disableDefaultMatching
              suggestions={ExternalUserStore.filteredUsers}
              onChange={onUsersSearch}
              onSearch={onUsersSearch}
              onSelect={onUsersSelected}
              onClear={onUserClear}
              triggerCleanup={triggerCleanup}
            />
          </div>

          <div className={Style.displayNameInput}>
            <div className={Style.label}>
              Display Name <span className={Style.requiredSymbol}>*</span>
            </div>
            <FormInput
              disabled={(canInviteOrRemoveAzure && disableDisplayName) || !canInviteOrRemoveAzure}
              value={ExternalUserStore.displayName ?? ''}
              onChange={onDisplayNameChanged}
            />
          </div>

          <div>
            <div className={Style.labelBtn}>Add</div>
            <PrimaryButton
              className={Style.btnAdd}
              disabled={!ExternalUserStore.isEnabledAddMemberBtn}
              onClick={addSelectedUser}>
              Add
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className={Style.UserTable}>
        <SearchBar
          searchValue={searchText}
          cssClass={Style.searchUserBox}
          onSearch={(keyword) => {
            setSearchText(keyword);
          }}
          placeHolderText="Quick search..."
        />
        <Table headers={headers} breakpoint={10} hoverable>
          {ExternalUserStore.getExternalUsersList()
            .filter(
              (u) =>
                u.name.toLowerCase().includes((searchText ? searchText : u.name).toLowerCase()) ||
                u.email.includes((searchText ? searchText : u.email).toLowerCase())
            )
            .map((u) => (
              <TableRow key={u.id}>
                <TableCell>{u.name}</TableCell>
                <TableCell>{u.email}</TableCell>
                <TableCell>
                  <PrimaryIconButton
                    icon="delete"
                    onClick={() => {
                      setShowConfirmation(true);
                      setSelectedUser(u);
                      ExternalUserStore.setRemoveUser(u);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </Table>
      </div>

      <ConfirmationModal
        showModal={showConfirmation}
        heading="Deleting User?"
        message={`This action will revoke User’s access.Are you sure you want to delete ${selectedUser?.name}?`}
        confirmText="Yes"
        cancelText="No"
        loading={addingOrDeleteUser}
        onConfirm={removeUserFromList}
        onCancel={() => setShowConfirmation(false)}
      />
    </div>
  );
};

export default observer(ExternalUserDetails);
