import React, { FC, useLayoutEffect, useState } from 'react';
import { Modal, Grid } from '@aurecon-creative-technologies/styleguide';
import { add } from 'date-fns';
import Cookies from 'universal-cookie';
import Style from './styles/TermsCookiesModal.module.scss';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import { Link, useLocation } from 'react-router-dom';
import { Pages } from '../../common/constants/Pages';
import { getTermConditionAgreement } from '../../api/authenticated/um/getTermConditionAgreement';
import { addTermConditionAgreement } from '../../api/authenticated/um/addTermConditionAgreement';
import { useAuth } from '../../authentication/hooks/useAuth';

const cookies = new Cookies();

const TermsCookiesModal: FC = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(true);
  const [acceptedTerms, setAcceptedTerms] = useState(true);
  const { pathname } = useLocation();
  const { logout } = useAuth();

  useLayoutEffect(() => {
    const action = async () => {
      const c = cookies.get('tucana.cookies');
      const result = await getTermConditionAgreement();
      setAcceptedCookies(c && c.accepted);
      setAcceptedTerms(!!result.termConditionAgreements.length);
    };

    action();
  }, []);

  const handleAcceptTerms = async () => {
    await addTermConditionAgreement();
    setAcceptedTerms(true);
  };

  const handleAcceptCookies = () => {
    cookies.set('tucana.cookies', { accepted: true }, { path: '/', expires: add(new Date(), { years: 1 }) });
    setAcceptedCookies(true);
  };

  const handleDeclineTerms = () => {
    logout();
  };

  const hideModal =
    pathname.includes(Pages.TermsConditions.Route) ||
    pathname.includes('cookie') ||
    pathname.includes(Pages.PrivacyPolicy.Route);

  return (
    <>
      <Modal
        isShowing={!hideModal && (!acceptedTerms || !acceptedCookies)}
        onClose={() => {
          return;
        }}
        isCloseButton={false}
        size="medium"
        cssClass={Style.modalWrapper}>
        {!acceptedTerms && (
          <Grid row gap={12}>
            <Grid item cell xs={8}>
              <p>
                Please consent to the <Link to={Pages.TermsConditions.Route}>Terms & Conditions</Link> and{' '}
                <Link to={Pages.PrivacyPolicy.Route}>Privacy Policy</Link>.
              </p>
            </Grid>
            <Grid item cell>
              <SecondaryButton onClick={handleDeclineTerms}>Decline</SecondaryButton>
            </Grid>
            <Grid item cell>
              <PrimaryButton onClick={handleAcceptTerms}>Accept</PrimaryButton>
            </Grid>
          </Grid>
        )}
        {!acceptedCookies && !acceptedTerms && <div className={Style.modalDivider} />}
        {!acceptedCookies && (
          <Grid row gap={12}>
            <Grid item cell xs={10}>
              <p>
                We use cookies to ensure that we give you the best experience on our application.{' '}
                <a href="/#/cookies" target="_blank" rel="noreferrer">
                  Find out more
                </a>
              </p>
            </Grid>
            <Grid item cell>
              <PrimaryButton onClick={handleAcceptCookies}>Accept</PrimaryButton>
            </Grid>
          </Grid>
        )}
      </Modal>
    </>
  );
};

export default TermsCookiesModal;
