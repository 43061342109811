import React, { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/EditFileMetadata.module.scss';
import Loading from '../../shared/Loading';
import {
  IFieldValueModel,
  IFileDataModel,
  IFileMedataValueModel,
  IFileMetadataDataModel,
} from './models/IFileDataModel';
import EditableFilesTable from './EditableFilesTable';
import EditFileMetadataStore from './EditFileMetadataStore';
import ErrorModal from '../../shared/ErrorModal';
import UploadStore from '../upload/UploadStore';
import FileMetadataForm from '../upload/FileMetadataForm';
import { MetadataFieldType } from '../../../common/enums/MetadataFieldType';
import { CSVLink } from 'react-csv';
import { Icon } from '@aurecon-creative-technologies/styleguide';

interface IEditFileMetadataProps {
  files: IFileDataModel[];
}
const EditFileMetadata: FC<IEditFileMetadataProps> = (props) => {
  const { files } = props;

  const [loadingMetadata, setLoadingMetadata] = useState(true);
  const [allowEditNamingField, setAllowEditNamingField] = useState(false);
  const [selectedFileIds, setSelectedFileIds] = useState<number[]>([]);
  const [fileMetadataValues, setMetadataValues] = useState<IFileMedataValueModel[]>([]);

  const fileData = useMemo<IFileDataModel[]>(() => {
    return files.map((f) => {
      const fileMetadata = fileMetadataValues.find((x) => x.fileContainerId === f.id);
      const fileData: IFileDataModel = {
        ...f,
        pattern1: fileMetadata?.pattern1 ?? f.pattern1,
        pattern2: fileMetadata?.pattern2 ?? f.pattern2,
        pattern3: fileMetadata?.pattern3 ?? f.pattern3,
        metadata: f.metadata.map((m) => {
          const metadataValue = fileMetadataValues
            .find((md) => md.fileContainerId === f.id)
            ?.metadata.find((md) => md.fieldIndex === m.fieldIndex);

          if (metadataValue) {
            m.fieldValue = metadataValue.fieldTitle;
          }
          return m;
        }),
      };

      return fileData;
    });
  }, [files, fileMetadataValues]);

  useEffect(() => {
    const effectAsync = async () => {
      setLoadingMetadata(true);
      await UploadStore.getFileData();
      setLoadingMetadata(false);
    };
    effectAsync();

    return () => UploadStore.clear();
  }, []);

  useEffect(() => {
    EditFileMetadataStore.setFileMetadataValues(fileMetadataValues);
  }, [fileMetadataValues]);

  const onFileSelected = (fileIds: number[]) => {
    const selectedFiles = files.filter((f) => fileIds.includes(f.id));
    setSelectedFileIds(fileIds);
    setAllowEditNamingField(!!selectedFiles.length && selectedFiles.every((f) => !f.inWorkflow));
  };

  const mapToMetadata = (metadataFields: IFileMetadataDataModel[]): IFieldValueModel[] => {
    return metadataFields.map((m) => {
      const fieldValue = UploadStore.fileDataValues.find((fv) => fv.fieldValueIndex === m.fieldIndex);
      const metadata = UploadStore.fileMetadata.find((fmd) => fmd.fieldValueIndex === fieldValue?.fieldValueIndex);
      let metadataValue;
      if (metadata?.dataType.fieldType === MetadataFieldType.List) {
        metadataValue = metadata?.dataType.fieldValues?.find((fv) => fv.code === fieldValue?.value);
      }
      if (metadata?.dataType.fieldType === MetadataFieldType.UserText) {
        metadataValue = fieldValue?.value;
      }
      const metadataField = UploadStore.fileMetadata.find((fmd) => fmd.fieldValueIndex === m.fieldIndex);

      const parentFieldValue = UploadStore.fileDataValues.find(
        (fmd) => fmd.fieldValueIndex === metadataField?.dataType.parentFieldValueIndex
      );
      const fileMetadata = metadataFields.find((md) => md.fieldIndex === metadataField?.dataType.parentFieldValueIndex);
      return {
        ...m,
        id:
          parentFieldValue && parentFieldValue.title !== fileMetadata?.fieldValue && !metadata
            ? metadataField?.id
            : metadata?.id,
        fieldValue:
          parentFieldValue && parentFieldValue.title !== fileMetadata?.fieldValue && !metadataValue
            ? null
            : metadataValue?.code
            ? metadataValue?.code
            : metadataValue || m.fieldValue,
        fieldTitle:
          parentFieldValue && parentFieldValue.title !== fileMetadata?.fieldValue && !metadataValue
            ? null
            : metadataValue?.title
            ? metadataValue?.title
            : metadataValue || m.fieldValue,
      } as IFieldValueModel;
    });
  };

  const onApplyFileMetadata = () => {
    const apimsMetadata = UploadStore.getApimsMetadata();
    const newFileMetadataValues = selectedFileIds.map((fileId) => {
      const fileData = files.find((f) => f.id === fileId);
      const selected = selectedFileIds.some((x) => x === fileId);
      return {
        fileContainerId: fileId,
        fileName: fileData?.fileName,
        pattern1: selected && apimsMetadata?.pattern1 ? apimsMetadata?.pattern1 : fileData?.pattern1,
        pattern2: selected && apimsMetadata?.pattern2 ? apimsMetadata?.pattern2 : fileData?.pattern2,
        pattern3: selected && apimsMetadata?.pattern3 ? apimsMetadata?.pattern3 : fileData?.pattern3,
        metadata: mapToMetadata(fileData?.metadata || []),
      } as IFileMedataValueModel;
    });
    setMetadataValues(newFileMetadataValues);
  };

  const isEditApimsMetadata = () => {
    const apimsMetadata = UploadStore.getApimsMetadata();
    if (!apimsMetadata) return false;
    return apimsMetadata.pattern1 || apimsMetadata.pattern2 || apimsMetadata.pattern3;
  };

  if (loadingMetadata || EditFileMetadataStore.saving)
    return <Loading isLoading={loadingMetadata || EditFileMetadataStore.saving} />;

  const exportData = EditFileMetadataStore.getResultsCSVData();

  const buildErrorMessage = () => {
    if (EditFileMetadataStore.updateFileMetadataErrors.length) {
      return (
        <div>
          <div className={Style.message}>
            <Icon cssClass={Style.warning} type="warning" />
            <p>
              {EditFileMetadataStore.updateFileMetadataErrors.length} file cannot be updated due to an error. Click on
              Export as CSV for edit metadata result details.
            </p>
          </div>
          <CSVLink
            className={Style.exportButton}
            data={exportData.data}
            headers={exportData.headers}
            filename={exportData.filename}
            target="_blank">
            Export as CSV
          </CSVLink>
        </div>
      );
    }

    return EditFileMetadataStore.errorMessage;
  };

  return (
    <>
      <div className={Style.container}>
        <div className={Style.medataColumn}>
          <div className={Style.metadataWrapper}>
            <FileMetadataForm
              heading="Edit Metadata"
              selectedFiles={
                (UploadStore.fileDataValues.length || isEditApimsMetadata()) && selectedFileIds ? selectedFileIds : []
              }
              allowEditNamingField={allowEditNamingField}
              onApplyFileData={onApplyFileMetadata}
            />
          </div>
          <div className={Style.fileTableWrapper}>
            <div className={Style.rightContainer}>
              <EditableFilesTable files={fileData} onFileSelected={onFileSelected} />
            </div>
          </div>
        </div>
      </div>
      {EditFileMetadataStore.showError && (
        <ErrorModal
          closeModal={() => EditFileMetadataStore.setShowError(false)}
          errorCode={null}
          header="Edit Metadata Results"
          errorMessage={buildErrorMessage()}
        />
      )}
    </>
  );
};

export default observer(EditFileMetadata);
