import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useReviewStore } from './ReviewStoreContext';
import Style from './styles/SelectSupportFileList.module.scss';
import FileDropZone from '../../shared/FileDropZone';
import { DateInput, Text } from '@aurecon-creative-technologies/styleguide';
import ConfirmationModal from '../../shared/ConfirmationModal';
import SelectSupportFileList from '../../shared/SelectSupportFileList';

const AddSupportFiles: FC = () => {
  const store = useReviewStore();

  const handleOnDropzoneChange = (uploadFiles) => {
    store.addSupportingFiles(uploadFiles);
  };
  const errorMessage = () => {
    if (store.isDuplicatedFiles)
      return `The selected file(s) already exist. Please choose another file and try again. ${store.duplicatedFiles
        .map((f) => f.name)
        .join(', ')}`;
    if (store.missingFileExtension)
      return `The selected file(s) missing File Extension. Please choose another files and try again. ${store.missingExtensionFiles
        .map((f) => f.name)
        .join(', ')}`;

    if (store.filesSizeExceededTheLimit) return 'Total file size exceed maximum limit. Maximum total file size is 1GB.';
    return '';
  };

  return (
    <>
      <div className={Style.advanceContainer}>
        <DateInput
          label="Due Date"
          required={false}
          error={store.taskDuedateErrorMessage}
          dates={{
            startDate: store.taskDuedate,
            endDate: null,
          }}
          onDateChange={store.setTaskDuedate}
        />
        <Text type="h3">Supporting File(s)</Text>
        <div className={Style.uploadForm}>
          <FileDropZone
            label={
              <div>
                <p>
                  <b>Choose Supporting files</b>
                </p>
                <p>Drag & drop or click to browse for files</p>
                <p>(Max Upload: 1GB)</p>
              </div>
            }
            multiple={true}
            disabled={store.isSaving}
            onDropzoneChange={(uploadFiles) => handleOnDropzoneChange(uploadFiles)}
          />
        </div>
        <SelectSupportFileList
          files={store.uploadSupportFiles}
          onFileListChange={(files) => store.handleFileListChange(files)}
          onRemoveFile={store.removeSupportFile}
        />
      </div>
      <ConfirmationModal
        showModal={store.openUploadError}
        heading="Upload Error"
        message={errorMessage()}
        confirmText="Ok"
        onCancel={() => store.setOpenUploadError(false)}
        onConfirm={() => store.setOpenUploadError(false)}
      />
    </>
  );
};

export default observer(AddSupportFiles);
