import React, { FC, useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Accordion,
  AccordionPanel,
  Button,
  FormInput,
  Grid,
  Table,
  Tooltip,
} from '@aurecon-creative-technologies/styleguide';
import Style from '../../../styles/components/settings/projectManagement/ProjectTransmittalDefaults.module.scss';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import SecondaryButton from '../../shared/SecondaryButton';
import PrimaryButton from '../../shared/PrimaryButton';
import { v4 as uuidv4 } from 'uuid';
import { classNames, validateAlphaNumeric, validateMailTypes } from '../../../utils/miscUtils';
import LayoutStore from '../../layout/LayoutStore';
import { getTransmittalTypes, IType } from '../../../api/authenticated/transmittals/getTransmittalTypes';
import {
  getTransmittalTypesBeingUsed,
  ITransmittalTypesBeingUsed,
} from '../../../api/authenticated/transmittals/getTransmittalTypesBeingUsed';
import { getTransmittalReasons, IReason } from '../../../api/authenticated/transmittals/getTransmittalReasons';
import { updateTransmittalDefaultsTypes } from '../../../api/authenticated/config/updateTransmittalDefaultsTypes';
import { SortTypes } from '../../../common/enums/SortType';
import { sort } from '../../../utils/sortHelper';
import Icon from '../../shared/Icon';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import {
  IClonedMailTypes,
  IMailReasonModel,
  TransmittalFiledLabels,
  TransmittalMailTypeFiledNames,
  TransmittalReasonFiledNames,
} from './Models/transmittalMailTypeSettingModels';
import {
  deleteTransmittalMailReason,
  deleteTransmittalType,
} from '../../../api/authenticated/transmittals/deleteTransmittalType';
import {
  archiveTransmittalReason,
  archiveTransmittalType,
} from '../../../api/authenticated/transmittals/archiveTransmittalType';
import ConfirmationModal from '../../shared/ConfirmationModal';

const maxNameLength = 200;
const maxAbbreLength = 3;
const tableHeaderKeyValueFields = {
  mail: {
    label: 'Mail Type / Mail Reason',
  },
  abbreviation: {
    label: 'Abbreviation',
  },
  action: {
    label: 'Action',
  },
};

enum TransmittalLookupTypes {
  MAIL_TYPE = 'Mail type',
  MAIL_REASON = 'Mail reason',
}

interface IProjectTransmittalDefaults {
  projectNumber: string;
}

const ProjectTransmittalDefaults: FC<IProjectTransmittalDefaults> = ({ projectNumber }) => {
  const [editMode, setEditMode] = useState(false);
  const [types, setMailTypes] = useState<IType[]>([]);
  const [typesBeingUsed, setTypesBeingUsed] = useState<ITransmittalTypesBeingUsed[]>([]);
  const [allReasons, setAllMailReasons] = useState<IReason[]>([]);
  const [clonedMailTypes, setClonedMailTypes] = useState<IClonedMailTypes[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [activePanels, setActivePanels] = useState<string[]>([]);
  const [upsertMailReasons, setUpsertMailReasons] = useState<IMailReasonModel[]>([]);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<{
    showModal: boolean;
    entityId?: number;
    entityName?: string;
    dataType?: TransmittalLookupTypes;
  }>();

  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState<{
    showModal: boolean;
    entityId?: number;
    entityName?: string;
    isArchive?: boolean;
    dataType?: TransmittalLookupTypes;
  }>();

  const loadMails = useCallback(async () => {
    if (!projectNumber) return;
    setActivePanels([]);
    const mailTypes = await getTransmittalTypes(projectNumber);
    const mailReasons = await getTransmittalReasons(projectNumber);
    const typesBeingUsed = await getTransmittalTypesBeingUsed(projectNumber);

    setMailTypes(mailTypes);
    setTypesBeingUsed(typesBeingUsed);
    setAllMailReasons(mailReasons);

    setClonedMailTypes([
      ...mailTypes.map((m) => {
        return {
          id: m.id,
          tempId: uuidv4(),
          mailType: m.title,
          abbreviation: m.namingCode,
          order: m.order,
          type: 'EDIT' as const,
          archived: m.archived,
          errors: { mailType: '', abbreviation: '' },
        };
      }),
    ]);

    setUpsertMailReasons([
      ...mailReasons.map((x) => {
        return {
          ...x,
          tempId: uuidv4(),
          type: 'EDIT',
          errors: { title: '', namingCode: '' },
        } as IMailReasonModel;
      }),
    ]);
  }, [projectNumber]);
  useEffect(() => {
    loadMails();
  }, [loadMails]);

  const getHeaders = () => {
    return [
      {
        label: tableHeaderKeyValueFields.mail.label,
        style: { width: editMode ? '70%' : '80%' },
      },
      {
        label: tableHeaderKeyValueFields.abbreviation.label,
        style: { width: editMode ? '20%' : '20%' },
      },

      ...(editMode
        ? [
            {
              label: tableHeaderKeyValueFields.action.label,
              style: { width: '10%' },
            },
          ]
        : []),
    ];
  };

  const getColumnWidth = (column: string) => {
    return getHeaders().find((f) => f.label === column)?.style.width;
  };

  const handleCancel = () => {
    setClonedMailTypes([
      ...types.map((m) => {
        return {
          id: m.id,
          tempId: uuidv4(),
          mailType: m.title,
          abbreviation: m.namingCode,
          order: m.order,
          type: 'EDIT' as const,
          archived: m.archived,
          errors: { mailType: '', abbreviation: '' },
        };
      }),
    ]);

    setUpsertMailReasons([
      ...allReasons.map((x) => {
        return {
          ...x,
          tempId: uuidv4(),
          type: 'EDIT',
          errors: { title: '', namingCode: '' },
        } as IMailReasonModel;
      }),
    ]);

    setEditMode(false);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await updateTransmittalDefaultsTypes({
        projectNumber: projectNumber,
        projectTransmittalTypes: [
          ...clonedMailTypes.map((m) => {
            return {
              id: m.type === 'ADD' ? 0 : m.id,
              title: m.mailType,
              namingCode: m.abbreviation,
              order: m.order,
              tempId: m.tempId,
            };
          }),
        ],
        projectTransmittalReasons: [
          ...upsertMailReasons.map((m) => {
            return {
              id: m.type === 'ADD' ? 0 : m.id,
              title: m.title,
              namingCode: m.namingCode,
              transmittalTypeId: m.transmittalTypeId,
              order: m.order,
              tempId: m.tempId,
              tempTransmittalTypeId: m.tempTransmittalTypeId,
            };
          }),
        ],
      });
      await loadMails();
      LayoutStore.displayToast('success', 'Transmittal Defaults have been successfully saved.');
    } catch {
      handleCancel();
      LayoutStore.displayToast('error', 'Transmittal Defaults can not be saved.');
    }

    setIsSaving(false);
    setEditMode(false);
  };

  const handleAddNewType = () => {
    const sorted = sort([...clonedMailTypes], 'order', SortTypes.DESC);
    const maxOrder = sorted.length ? sorted[0].order : 0;
    const newType: IClonedMailTypes = {
      id: 0,
      tempId: uuidv4(),
      mailType: '',
      abbreviation: '',
      order: maxOrder + 1,
      type: 'ADD' as const,
      archived: false,
      errors: {
        mailType: '* is a required field',
        abbreviation: '* is a required field',
      },
    };
    clonedMailTypes.push(newType);
    setClonedMailTypes([...clonedMailTypes]);
  };

  const handleAddNewReason = (tempTypeId: string) => {
    const parentType = clonedMailTypes.find((r) => r.tempId === tempTypeId);
    if (!parentType) return;

    const sorted = sort(
      [...upsertMailReasons.filter((x) => x.transmittalTypeId === parentType.id)],
      'order',
      SortTypes.DESC
    );

    const maxOrder = sorted.length ? sorted[0].order : 0;

    const newReason: IMailReasonModel = {
      id: 0,
      tempId: uuidv4(),
      namingCode: '',
      title: '',
      transmittalTypeId: parentType.id,
      tempTransmittalTypeId: parentType.tempId,
      order: maxOrder + 1,
      type: 'ADD',
      archived: false,
      used: false,
      errors: {
        title: '* is a required field',
        namingCode: '* is a required field',
      },
    };
    upsertMailReasons.push(newReason);
    setAllMailReasons([...upsertMailReasons]);
  };

  const handleSetMailType = (
    value: string,
    tempId: string,
    fieldName: string,
    validationCallback: (value, id) => string
  ) => {
    const current = clonedMailTypes.find((f) => f.tempId === tempId);
    if (!current) return;

    current[fieldName] = value;
    current.errors[fieldName] = validationCallback(value, current.id);
    setClonedMailTypes([...clonedMailTypes]);
  };

  const hasErrors = () => {
    return (
      clonedMailTypes.some((s) => s.errors.mailType || s.errors.abbreviation) ||
      upsertMailReasons.some((s) => s.errors.title || s.errors.namingCode)
    );
  };

  const textCommonRulesValidate = (value, label: string, maxLength: number) => {
    value = value?.trim() ?? '';
    if (!value) return `* is a required field`;
    if (
      (!validateMailTypes(value) && label !== TransmittalFiledLabels.ABBREVIATION) ||
      (!validateAlphaNumeric(value) && label === TransmittalFiledLabels.ABBREVIATION)
    )
      return `Invalid ${label}`;

    if (value.length > maxLength) return `${label} can not exceed ${maxLength} characters.`;
    return '';
  };

  const validateMailTypeName = useCallback(
    (value, id) => {
      const error = textCommonRulesValidate(value, TransmittalFiledLabels.MAIL_TYPE_TITLE, maxNameLength);
      if (error.length) return error;
      if (
        value &&
        clonedMailTypes.filter((t) => t.id !== id).some((t) => t.mailType.toLowerCase() === value.toLowerCase())
      )
        return `${TransmittalFiledLabels.MAIL_TYPE_TITLE} already exists`;

      return '';
    },
    [clonedMailTypes]
  );

  const validateAbbreviationName = useCallback(
    (value, id) => {
      const error = textCommonRulesValidate(value, TransmittalFiledLabels.ABBREVIATION, maxAbbreLength);
      if (error.length) return error;
      if (
        value &&
        clonedMailTypes.filter((t) => t.id !== id).some((t) => t.abbreviation.toLowerCase() === value.toLowerCase())
      )
        return `Abbrv. already exists`;

      return '';
    },
    [clonedMailTypes]
  );

  const validateMailReasonAbbreviationName = useCallback(
    (value, tempId, transmittalTypeId) => {
      const error = textCommonRulesValidate(value, TransmittalFiledLabels.ABBREVIATION, maxAbbreLength);
      if (error.length) return error;
      if (
        value &&
        upsertMailReasons
          .filter((t) => t.tempId !== tempId && t.transmittalTypeId == transmittalTypeId)
          .some((t) => t.namingCode.toLowerCase() === value.toLowerCase())
      )
        return `Abbrv. already exists`;

      return '';
    },
    [upsertMailReasons]
  );

  const validateMailReasonName = useCallback(
    (value, tempId, transmittalTypeId) => {
      const error = textCommonRulesValidate(value, TransmittalFiledLabels.MAIL_REASON_TITLE, maxNameLength);
      if (error.length) return error;

      if (
        value &&
        upsertMailReasons
          .filter((t) => t.tempId !== tempId && t.transmittalTypeId === transmittalTypeId)
          .some((t) => t.title.toLowerCase() === value.toLowerCase())
      )
        return `${TransmittalFiledLabels.MAIL_REASON_TITLE} already exists`;

      return '';
    },
    [upsertMailReasons]
  );

  const handleSetMailReason = (
    value: string,
    tempId: string,
    fieldName: string,
    validationCallback: (value, id, transmittalTypeId) => string
  ) => {
    const current = upsertMailReasons.find((f) => f.tempId === tempId);
    if (!current) return;

    current[fieldName] = value;
    current.errors[fieldName] = validationCallback(value, current.tempId, current.transmittalTypeId);
    setUpsertMailReasons([...upsertMailReasons]);
  };

  const renderActions = () => {
    return (
      <>
        {editMode ? (
          <div className={Style.actionButtonsWrapper}>
            <SecondaryButton disabled={isSaving} onClick={handleCancel}>
              Cancel
            </SecondaryButton>
            <PrimaryButton disabled={hasErrors()} loading={isSaving} onClick={() => handleSave()}>
              Save
            </PrimaryButton>
          </div>
        ) : (
          <PrimaryIconButton
            icon="edit"
            size="medium"
            className={Style.actionButton}
            onClick={() => setEditMode(true)}></PrimaryIconButton>
        )}
      </>
    );
  };

  const renderMailReasonActionButtons = (m: IMailReasonModel) => {
    return m.used ? (
      <Tooltip show={<div>{!m.archived ? 'Archive' : 'Unarchive'}</div>}>
        <PrimaryIconButton
          icon="inventory_2"
          onClick={() => {
            setShowArchiveConfirmation({
              showModal: true,
              entityId: m.id,
              entityName: m.title,
              isArchive: !m.archived,
              dataType: TransmittalLookupTypes.MAIL_REASON,
            });
          }}
          disabled={!editMode}
        />
      </Tooltip>
    ) : (
      <PrimaryIconButton
        icon="delete"
        onClick={() => {
          if (!m.id) {
            setUpsertMailReasons([...upsertMailReasons.filter((f) => f.tempId !== m.tempId)]);
            return;
          }
          setShowDeleteConfirmation({
            showModal: true,
            entityId: m.id,
            entityName: m.title,
            dataType: TransmittalLookupTypes.MAIL_REASON,
          });
        }}
        disabled={!editMode}
      />
    );
  };

  const renderReasons = (s: IMailReasonModel) => {
    return (
      <>
        <div key={s.id} className={Style.rowWrapperReason}>
          <div
            style={{ width: getColumnWidth(tableHeaderKeyValueFields.mail.label) }}
            className={classNames(Style.displayReason, Style.textOverflow)}>
            {editMode && <Icon name="drag_indicator" className={Style.iconIndicator} />}
            {s.id > 0 ? (
              s.title
            ) : (
              <>
                <div className={Style.inputWrapper}>
                  <FormInput
                    value={s.title}
                    placeholder="Enter reason"
                    error={s.errors.title}
                    onChange={(value) => {
                      handleSetMailReason(value, s.tempId, TransmittalReasonFiledNames.TITLE, validateMailReasonName);
                    }}
                  />
                  {!s.errors.title && (
                    <span className={Style.charactersLeft}>{maxNameLength - s.title?.length ?? 0} characters left</span>
                  )}
                </div>
              </>
            )}
          </div>
          <div
            style={{ width: getColumnWidth(tableHeaderKeyValueFields.abbreviation.label) }}
            className={classNames(Style.row, Style.textOverflow)}>
            {s.id > 0 ? (
              s.namingCode
            ) : (
              <>
                <div className={Style.inputWrapper}>
                  <FormInput
                    value={s.namingCode}
                    placeholder="Enter abbreviation"
                    error={s.errors.namingCode}
                    onChange={(value) => {
                      handleSetMailReason(
                        value.toUpperCase(),
                        s.tempId,
                        TransmittalReasonFiledNames.NAMING_CODE,
                        validateMailReasonAbbreviationName
                      );
                    }}
                  />
                  {!s.errors.namingCode && (
                    <span className={Style.charactersLeft}>
                      {maxAbbreLength - s.namingCode?.length ?? 0} characters left
                    </span>
                  )}
                </div>
              </>
            )}
          </div>
          {editMode && (
            <div style={{ width: getColumnWidth(tableHeaderKeyValueFields.action.label) }} className={Style.row}>
              {renderMailReasonActionButtons(s)}
            </div>
          )}
        </div>
      </>
    );
  };

  const rearrangeList = (arr, sourceIndex, destIndex) => {
    const newItemsList = Array.from(arr);

    const [reOrdered] = newItemsList.splice(sourceIndex, 1);
    newItemsList.splice(destIndex, 0, reOrdered);

    return newItemsList;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const { destination, source } = result;

    if (result.type === 'droppableItem') {
      const newItemsList = rearrangeList(
        Array.from(clonedMailTypes),
        source.index,
        destination.index
      ) as IClonedMailTypes[];
      newItemsList.map((m, i) => {
        m.order = i + 1;
        return m;
      });

      const sorted = sort([...newItemsList], 'order', SortTypes.ASC);
      setClonedMailTypes([...sorted]);
    } else if (result.type === 'droppableSubItem') {
      if (destination.droppableId !== source.droppableId) {
        return;
      }
      const selectedReasonList = upsertMailReasons.filter((x) => x.transmittalTypeId == Number(source.droppableId));
      const newItemsList = rearrangeList(Array.from(selectedReasonList), source.index, destination.index) as IReason[];
      newItemsList.map((m, i) => {
        m.order = i + 1;
        return m;
      });
      const updateReasons = [
        ...upsertMailReasons.map((item) => {
          const newItem = newItemsList.find((i) => i.id === item.id);
          return newItem ? { ...item, ...newItem } : item;
        }),
      ];
      const sorted = sort([...updateReasons], 'order', SortTypes.ASC);
      setUpsertMailReasons([...sorted]);
    }
  };

  const renderActionButtons = (m: IClonedMailTypes) => {
    return typesBeingUsed.some((s) => s.transmittalTypeId === m.id) ? (
      <Tooltip show={<div>{!m.archived ? 'Archive' : 'Unarchive'}</div>}>
        <PrimaryIconButton
          icon="inventory_2"
          onClick={() => {
            setShowArchiveConfirmation({
              showModal: true,
              entityId: m.id,
              entityName: m.mailType,
              isArchive: !m.archived,
              dataType: TransmittalLookupTypes.MAIL_TYPE,
            });
          }}
          disabled={!editMode}
        />
      </Tooltip>
    ) : (
      <PrimaryIconButton
        icon="delete"
        onClick={() => {
          if (!m.id) {
            setClonedMailTypes([...clonedMailTypes.filter((f) => f.tempId !== m.tempId)]);
            return;
          }
          setShowDeleteConfirmation({
            showModal: true,
            entityId: m.id,
            entityName: m.mailType,
            dataType: TransmittalLookupTypes.MAIL_TYPE,
          });
        }}
        disabled={!editMode}
      />
    );
  };

  const renderMailTypes = () => {
    return (
      <>
        <Accordion
          verticalPanelGap="24px"
          headingBgColour="#F2F2F2"
          headingFontColour="#000000"
          panelBgColour="#FAFAFA"
          headingIconColour="#f37021"
          activePanelIds={activePanels}
          onPanelToggle={(m) => handleAccordionToggle(m)}
          cssClass={Style.transmittalDefaultAccordion}>
          {clonedMailTypes.map((m, i) => (
            <Draggable draggableId={m.tempId} index={i} key={m.tempId} isDragDisabled={!editMode || m.archived}>
              {(provided) => (
                <div>
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <AccordionPanel
                      key={m.tempId}
                      label={
                        <>
                          <div className={Style.rowWrapperType}>
                            <div
                              style={{ width: getColumnWidth(tableHeaderKeyValueFields.mail.label) }}
                              className={classNames(Style.row, [!editMode, Style.textOverflow])}>
                              {editMode && <Icon name="drag_indicator" className={Style.iconIndicator} />}
                              {m.id > 0 ? (
                                <span className={classNames([m.archived, Style.disableRow])}>{m.mailType}</span>
                              ) : (
                                <>
                                  <div className={Style.inputWrapper}>
                                    <FormInput
                                      value={m.mailType}
                                      placeholder="Enter mail type"
                                      error={m.errors.mailType}
                                      onChange={(value) => {
                                        handleSetMailType(
                                          value,
                                          m.tempId,
                                          TransmittalMailTypeFiledNames.MAIL_TYPE,
                                          validateMailTypeName
                                        );
                                      }}
                                    />
                                    {!m.errors.mailType && (
                                      <span className={Style.charactersLeft}>
                                        {maxNameLength - m.mailType?.length ?? 0} characters left
                                      </span>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                            <div
                              style={{ width: getColumnWidth(tableHeaderKeyValueFields.abbreviation.label) }}
                              className={classNames(Style.row, [!editMode, Style.textOverflow])}>
                              {m.id > 0 ? (
                                <span className={classNames([m.archived, Style.disableRow])}>{m.abbreviation}</span>
                              ) : (
                                <>
                                  <div className={Style.inputWrapper}>
                                    <FormInput
                                      value={m.abbreviation}
                                      placeholder="Enter abbreviation"
                                      error={m.errors.abbreviation}
                                      onChange={(value) => {
                                        handleSetMailType(
                                          value.toUpperCase(),
                                          m.tempId,
                                          TransmittalMailTypeFiledNames.ABBREVIATION,
                                          validateAbbreviationName
                                        );
                                      }}
                                    />
                                    {!m.errors.abbreviation && (
                                      <span className={Style.charactersLeft}>
                                        {maxAbbreLength - m.abbreviation?.length ?? 0} characters left
                                      </span>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                            {editMode && (
                              <div
                                style={{ width: getColumnWidth(tableHeaderKeyValueFields.action.label) }}
                                className={Style.row}>
                                {renderActionButtons(m)}
                              </div>
                            )}
                          </div>
                        </>
                      }
                      panelId={m.id.toString()}>
                      <Droppable droppableId={m.id.toString()} type={`droppableSubItem`}>
                        {(provided) => (
                          <div ref={provided.innerRef} className={Style.contentWrapper}>
                            {upsertMailReasons
                              .filter((r) => r.transmittalTypeId === m.id)
                              .map((s, i) => (
                                <Draggable
                                  draggableId={s.id.toString()}
                                  key={s.id}
                                  index={i}
                                  isDragDisabled={!editMode}>
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}>
                                      {renderReasons(s)}
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                      {editMode && (
                        <div className={Style.rowWrapperReason}>
                          <Button
                            type="text"
                            cssClass={Style.addNewReasonButton}
                            icon="add"
                            label="Add New Mail Reason"
                            disabled={m.archived}
                            onClick={() => handleAddNewReason(m.tempId)}
                          />
                        </div>
                      )}
                    </AccordionPanel>
                  </div>
                </div>
              )}
            </Draggable>
          ))}
        </Accordion>
      </>
    );
  };

  const handleAccordionToggle = (id: string) => {
    const newIds = new Set<string>(activePanels);
    newIds.has(id) ? newIds.delete(id) : newIds.add(id);
    setActivePanels(Array.from(newIds));
  };

  const handleDeleteTransmittalLookupEntity = async () => {
    if (!showDeleteConfirmation || !showDeleteConfirmation.entityId || !showDeleteConfirmation.dataType) return;
    setIsSaving(true);
    try {
      showDeleteConfirmation.dataType === TransmittalLookupTypes.MAIL_TYPE
        ? await deleteTransmittalType(projectNumber, showDeleteConfirmation.entityId)
        : await deleteTransmittalMailReason(projectNumber, showDeleteConfirmation.entityId);
      await loadMails();
      LayoutStore.displayToast('success', `${showDeleteConfirmation.dataType} has been successfully deleted.`);
    } catch {
      LayoutStore.displayToast('error', `${showDeleteConfirmation.dataType} can not be saved.`);
    }

    setIsSaving(false);
    setShowDeleteConfirmation({
      showModal: false,
      dataType: undefined,
    });
  };

  const handleArchiveTransmittalLookupEntity = async () => {
    if (!showArchiveConfirmation || !showArchiveConfirmation.entityId || !showArchiveConfirmation.dataType) return;

    setIsSaving(true);
    try {
      const isArchive = showArchiveConfirmation?.isArchive ? true : false;
      showArchiveConfirmation.dataType === TransmittalLookupTypes.MAIL_TYPE
        ? await archiveTransmittalType(projectNumber, showArchiveConfirmation.entityId, isArchive)
        : await archiveTransmittalReason(projectNumber, showArchiveConfirmation.entityId, isArchive);
      await loadMails();
      LayoutStore.displayToast(
        'success',
        `${showArchiveConfirmation.dataType} has been successfully ${
          showArchiveConfirmation.isArchive ? 'archived' : 'unarchived'
        }.`
      );
    } catch {
      LayoutStore.displayToast(
        'error',
        `${showArchiveConfirmation.dataType} can not be ${
          showArchiveConfirmation.isArchive ? 'archived' : 'unarchived'
        }.`
      );
    }

    setIsSaving(false);
    setShowArchiveConfirmation({
      showModal: false,
    });
  };

  return (
    <>
      <Grid row md={12} cssClass={Style.gridGroupWrapper}>
        <div className={classNames(Style.header, Style.settingGroup)}>
          <span>Transmittal Defaults</span>
          {renderActions()}
        </div>
        <p>Create mail type and mail reason to help organise and manage transmittals.</p>
      </Grid>
      <Grid item md={12} cssClass={Style.gridTableWrapper}>
        <Table headers={getHeaders()} />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" type="droppableItem" isDropDisabled={!editMode}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className={Style.contentWrapper}>
                {renderMailTypes()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {editMode && (
          <div>
            <Button
              type="text"
              cssClass={Style.addNewButton}
              icon="add"
              label="Add New Mail Type"
              onClick={handleAddNewType}
            />
          </div>
        )}
      </Grid>
      {showDeleteConfirmation && showDeleteConfirmation.dataType && (
        <ConfirmationModal
          showModal={showDeleteConfirmation.showModal ?? false}
          heading={`Delete ${showDeleteConfirmation.dataType.toLocaleLowerCase()}`}
          message={`This action will delete this ${showDeleteConfirmation.dataType.toLocaleLowerCase()} and can not be undone. Are you sure you want to delete ${
            showDeleteConfirmation?.entityName
          }?`}
          onCancel={() => {
            setShowDeleteConfirmation({
              showModal: false,
            });
          }}
          onConfirm={() => {
            handleDeleteTransmittalLookupEntity();
          }}
          confirmText="Delete"></ConfirmationModal>
      )}
      {showArchiveConfirmation && showArchiveConfirmation.dataType && (
        <ConfirmationModal
          showModal={showArchiveConfirmation.showModal ?? false}
          heading={`${
            showArchiveConfirmation.isArchive ? 'Archive' : 'Unarchive'
          } ${showArchiveConfirmation.dataType.toLocaleLowerCase()}`}
          message={`This action will ${
            showArchiveConfirmation.isArchive ? 'archive' : 'unarchive'
          } this ${showArchiveConfirmation.dataType.toLocaleLowerCase()}. Are you
        sure you want to ${showArchiveConfirmation.isArchive ? 'archive' : 'unarchive'}
        ${showArchiveConfirmation.entityName}?`}
          onCancel={() => {
            setShowArchiveConfirmation({
              showModal: false,
            });
          }}
          onConfirm={handleArchiveTransmittalLookupEntity}
          confirmText="Yes"
          cancelText="No"></ConfirmationModal>
      )}
    </>
  );
};

export default observer(ProjectTransmittalDefaults);
