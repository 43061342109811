import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/projectManagement/AddProject.module.scss';
import { Checkbox, Grid, Tooltip } from '@aurecon-creative-technologies/styleguide';
import ProjectManagementStore from '../../../stores/settings/projectManagement/ProjectManagementStore';
import Loading from '../../shared/Loading';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import { ProjectStatus, ProjectStatusDisplayText } from '../../../common/enums/ProjectStatus';
import ConfirmationModal from '../../shared/ConfirmationModal';
import ErrorModal from '../../shared/ErrorModal';
import Dot from '../../shared/Dot';
import AppStore from '../../../stores/AppStore';
import { classNames } from '../../../utils/miscUtils';
import ProjectApps from './ProjectApps';
import ProjectTransmittalDefaults from './ProjectTransmittalDefaults';

interface IProjectDetailsProps {
  projectNumber: string;
  onUpdate: () => void;
  onDelete: (programmeName: string) => void;
}

const ProjectName = 'ProjectName';
const modalTexts = {
  [ProjectStatus.Active]: {
    heading: 'Archive Project?',
    message: `This action will archive this project. Are you sure you want to archive ${ProjectName}?`,
  },
  [ProjectStatus.Inactive]: {
    heading: 'Unarchive Project?',
    message: `This action will unarchive the project. Are you sure you want to unarchive ${ProjectName}?`,
  },
};

const ProjectDetails: FC<IProjectDetailsProps> = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  useEffect(() => {
    const fetchProjectAsync = async () => {
      await ProjectManagementStore.getProjectDetails(props.projectNumber);
    };
    fetchProjectAsync();
  }, [props.projectNumber]);

  const onConfirm = async () => {
    const archive = ProjectManagementStore.projectDetails?.projectStatusId !== ProjectStatus.Inactive;
    ProjectManagementStore.projectDetails &&
      (await ProjectManagementStore.archiveProject(ProjectManagementStore.projectDetails.projectNumber, archive));

    setOpenModal(false);
  };

  const onConfirmDelete = async () => {
    if (ProjectManagementStore.projectDetails) {
      const programmeTitle = ProjectManagementStore.projectDetails.programmeTitle;
      await ProjectManagementStore.deleteProject(ProjectManagementStore.projectDetails.projectNumber);
      setOpenDeleteModal(false);
      if (!ProjectManagementStore.showError) {
        props.onDelete(programmeTitle);
      }
    }
  };

  const onCancelDelete = () => {
    setOpenDeleteModal(false);
  };

  const onCancel = () => {
    setOpenModal(false);
  };

  if (ProjectManagementStore.loading) return <Loading isLoading={ProjectManagementStore.loading} />;

  const statusDisplayArchiveButtons = [ProjectStatus.Active, ProjectStatus.Inactive];
  const archive = ProjectManagementStore.projectDetails?.projectStatusId !== ProjectStatus.Inactive;
  return (
    <>
      {ProjectManagementStore.projectDetails && (
        <div className={Style.fromWrapper}>
          <Grid row={true} md={12}>
            <Grid item md={12}>
              <div className={Style.header}>
                <span>Project Details</span>
                <div className={Style.actions}>
                  {ProjectManagementStore.projectDetails.projectStatusId !== ProjectStatus.Inactive && (
                    <PrimaryIconButton
                      icon="edit"
                      size="medium"
                      className={Style.actionButton}
                      disabled={ProjectManagementStore.projectDetails.projectStatusId === ProjectStatus.Inactive}
                      onClick={() => props.onUpdate()}></PrimaryIconButton>
                  )}
                  {!ProjectManagementStore.projectDetails.hasOpenTasks &&
                    statusDisplayArchiveButtons.includes(ProjectManagementStore.projectDetails.projectStatusId) && (
                      <Tooltip
                        show={<div className={Style.tooltipText}>{archive ? 'Archive' : 'Unarchive'}</div>}
                        defaultUp={true}>
                        <PrimaryIconButton
                          icon="inventory_2"
                          size="medium"
                          disabled={ProjectManagementStore.projectDetails.hasOpenTasks}
                          className={Style.actionButton}
                          onClick={() => setOpenModal(true)}></PrimaryIconButton>
                      </Tooltip>
                    )}
                  <Tooltip
                    show={
                      ProjectManagementStore.projectDetails.canDelete ? (
                        <div className={Style.tooltipText}>Delete</div>
                      ) : (
                        <div className={Style.tooltipText}>Cannot delete project, the project is not empty</div>
                      )
                    }
                    defaultUp={true}>
                    <PrimaryIconButton
                      icon="delete"
                      size="medium"
                      disabled={!ProjectManagementStore.projectDetails.canDelete}
                      className={classNames(Style.actionButton, [
                        !ProjectManagementStore.projectDetails.canDelete,
                        Style.nonePointerEvents,
                      ])}
                      onClick={() => setOpenDeleteModal(true)}></PrimaryIconButton>
                  </Tooltip>
                </div>
              </div>
            </Grid>

            <Grid item gap={16}>
              <Grid item md={6}>
                <div className={Style.formDisplayField}>
                  <div className={Style.formControlLabel}>Programme</div>
                  <div>{ProjectManagementStore.projectDetails.programmeTitle}</div>
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={Style.formDisplayField}>
                  <div className={Style.formControlLabel}>Project Name</div>
                  <div className={Style.value}>{ProjectManagementStore.projectDetails.title}</div>
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={Style.formDisplayField}>
                  <div className={Style.formControlLabel}>Project Number</div>
                  <div>{ProjectManagementStore.projectDetails.projectNumber}</div>
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={Style.formDisplayField}>
                  <div className={Style.formControlLabel}>Project Status</div>
                  <div className={Style.projectStatusWrapper}>
                    <Dot
                      className={classNames(
                        Style.projectStatusDot,
                        [
                          ProjectManagementStore.projectDetails.projectStatusId === ProjectStatus.Draft ||
                            ProjectManagementStore.projectDetails.projectStatusId === ProjectStatus.InProgress,
                          Style.inProgress,
                        ],
                        [ProjectManagementStore.projectDetails.projectStatusId === ProjectStatus.Active, Style.active],
                        [
                          ProjectManagementStore.projectDetails.projectStatusId === ProjectStatus.ProvisioningFailed,
                          Style.failed,
                        ],
                        [
                          ProjectManagementStore.projectDetails.projectStatusId === ProjectStatus.Inactive,
                          Style.inactive,
                        ]
                      )}
                    />
                    <small className={Style.projectStatusText}>
                      {ProjectStatusDisplayText[ProjectManagementStore.projectDetails.projectStatusId]}
                    </small>
                    {ProjectManagementStore.projectDetails.projectStatusId === ProjectStatus.ProvisioningFailed && (
                      <p className={Style.statusFailedMessage}>
                        Please contact your admin or{' '}
                        <a href={'mailto:' + AppStore.client!.supportUrl} target="_self">
                          Contact Support
                        </a>{' '}
                        if access is required.
                      </p>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={Style.formDisplayField}>
                  <div className={Style.formControlLabel}>Project Admins</div>
                  <div>{ProjectManagementStore.projectDetails.projectAdminUsers.map((x) => x.name).join(', ')}</div>
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={Style.formDisplayField}>
                  <div className={Style.formControlLabel}>Project Owner</div>
                  <div>{ProjectManagementStore.projectDetails.projectOwnerName}</div>
                </div>
              </Grid>
              <Grid item md={12}>
                <div className={Style.formDisplayField}>
                  <div className={Style.formControlLabel}>Description</div>
                  <div>{ProjectManagementStore.projectDetails.description}</div>
                </div>
              </Grid>
              <Grid item md={12}>
                <div className={Style.formDisplayField}>
                  <div className={Style.formControlLabel}>Temporary Access</div>
                  <Grid item md={12}>
                    <Checkbox
                      label="Allow collaboration with other task teams"
                      checked={ProjectManagementStore.projectDetails.allowCollaborationOtherTaskTeams || false}
                      cssClass={Style.checkbox}
                      disabled={true}
                    />
                  </Grid>
                </div>
              </Grid>
            </Grid>

            <Grid row md={12} cssClass={classNames(Style.breakLine)}></Grid>

            <Grid row md={12} cssClass={classNames(Style.gridGroup)}>
              <div className={classNames(Style.header, Style.settingGroup)}>
                <span>Project Defaults</span>
              </div>
              <Grid gap={16} item md={12}>
                <p className={Style.settingNoticed}>
                  By default, left menu bar displays 4 main features and allows user to switch features from anywhere.
                  Admin can hide feature and it will not available for users.
                </p>
              </Grid>
              <Grid row md={12} gap={8}>
                <Grid item md={12}>
                  <Checkbox
                    label="Display Apps"
                    checked={ProjectManagementStore.projectDetails.displayApps}
                    cssClass={Style.checkbox}
                    disabled={true}
                  />
                </Grid>
                <Grid item md={12}>
                  <Checkbox
                    label="Display Files"
                    checked={ProjectManagementStore.projectDetails.displayFiles}
                    cssClass={Style.checkbox}
                    disabled={true}
                  />
                </Grid>
                <Grid item md={12}>
                  <Checkbox
                    label="Display Task"
                    cssClass={Style.checkbox}
                    checked={ProjectManagementStore.projectDetails.displayTasks}
                    disabled={true}
                  />
                </Grid>
                <Grid item md={12}>
                  <Checkbox
                    disabled={true}
                    label="Display Visualisation"
                    cssClass={Style.checkbox}
                    checked={ProjectManagementStore.projectDetails.displayVisualisation}
                  />
                </Grid>
                <Grid item md={12}>
                  <Checkbox
                    disabled={true}
                    label="Display Transmittals"
                    cssClass={Style.checkbox}
                    checked={ProjectManagementStore.projectDetails.displayTransmittals}
                  />
                </Grid>
              </Grid>
            </Grid>

            {ProjectManagementStore.projectDetails.displayApps && (
              <>
                <Grid row md={12} cssClass={classNames(Style.breakLine)}></Grid>
                <ProjectApps projectNumber={props.projectNumber} />
              </>
            )}
            <>
              <Grid row md={12} cssClass={classNames(Style.breakLine)}></Grid>
              <ProjectTransmittalDefaults projectNumber={props.projectNumber} />
            </>
          </Grid>

          {modalTexts[ProjectManagementStore.projectDetails.projectStatusId] && (
            <ConfirmationModal
              showModal={openModal}
              heading={modalTexts[ProjectManagementStore.projectDetails.projectStatusId].heading.replace(
                ProjectName,
                ProjectManagementStore.projectDetails.title
              )}
              message={modalTexts[ProjectManagementStore.projectDetails.projectStatusId].message.replace(
                ProjectName,
                ProjectManagementStore.projectDetails.title
              )}
              confirmText="Yes"
              cancelText="No"
              onConfirm={onConfirm}
              onCancel={onCancel}></ConfirmationModal>
          )}

          <ConfirmationModal
            showModal={openDeleteModal}
            heading="Delete Project?"
            message={`This action will delete the project and can not be undone. Are you sure you want to delete ${ProjectManagementStore.projectDetails.title}?`}
            confirmText="Yes"
            cancelText="No"
            loading={ProjectManagementStore.loading}
            onConfirm={onConfirmDelete}
            onCancel={onCancelDelete}></ConfirmationModal>
          {ProjectManagementStore.showError && (
            <ErrorModal
              closeModal={() => ProjectManagementStore.setShowErrorModal(false)}
              errorCode={ProjectManagementStore.errorCode}
              errorMessage={ProjectManagementStore.errorMessage}
            />
          )}
        </div>
      )}
    </>
  );
};

export default observer(ProjectDetails);
