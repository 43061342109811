import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/projectManagement/AddProject.module.scss';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import { Checkbox, ComboBox, FormInput, Grid } from '@aurecon-creative-technologies/styleguide';
import classNames from 'classnames';
import UserSelectorExpandable from '../../shared/UserSelectorExpandable';
import { IUser } from '../../shared/UserSelector';
import ProjectManagementStore from '../../../stores/settings/projectManagement/ProjectManagementStore';
import Loading from '../../shared/Loading';
import { ProjectFields, UpdateProjectValidator } from './Models/addNewProjectValidator';
import ErrorModal from '../../shared/ErrorModal';
import LimitedCharactersLeft from '../../shared/LimitedCharactersLeft';
import { IUpdateProjectModel } from '../../../api/authenticated/config/models/projectModel';
import FormControlError from '../../shared/FormControlError';
import ProjectApps from './ProjectApps';
import ConfirmationModal from '../../shared/ConfirmationModal';

interface IUpdateProjectProps {
  projectNumber: string;
  onSave: (newProjectNumber: string) => void;
  onCancel: () => void;
}

const UpdateProject: FC<IUpdateProjectProps> = (props) => {
  const [formDirty, setFormDirty] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>(
    ProjectManagementStore.projectDetails?.projectAdminUsers.map((x) => {
      return {
        id: x.id,
        name: x.name,
        email: x.email,
      };
    }) ?? []
  );
  const [saving, setSaving] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const [updateProjectModel, setUpdateProject] = useState<IUpdateProjectModel>({
    projectNumber: ProjectManagementStore.projectDetails?.projectNumber || '',
    projectPhase: '',
    projectTitle: ProjectManagementStore.projectDetails?.title || '',
    projectOwnerUserId: ProjectManagementStore.projectDetails?.projectOwnerUserId || null,
    projectAdminUserIds: ProjectManagementStore.projectDetails?.projectAdminUsers.map((x) => x.id) || [],
    description: ProjectManagementStore.projectDetails?.description || '',
    displayApps: ProjectManagementStore.projectDetails?.displayApps ?? true,
    displayFiles: ProjectManagementStore.projectDetails?.displayFiles ?? true,
    displayTasks: ProjectManagementStore.projectDetails?.displayTasks ?? true,
    displayTransmittals: ProjectManagementStore.projectDetails?.displayTransmittals ?? true,
    displayVisualisation: ProjectManagementStore.projectDetails?.displayVisualisation ?? true,
    allowCollaborationOtherTaskTeams: ProjectManagementStore.projectDetails?.allowCollaborationOtherTaskTeams || null,
  });

  useEffect(() => {
    const fetchSystemUsersAsync = async () => {
      await ProjectManagementStore.getAllSystemUsers();
    };
    ProjectManagementStore.cleanUp();
    fetchSystemUsersAsync();
  }, []);

  const handleUpdateBtnClick = async () => {
    setSaving(true);
    await ProjectManagementStore.updateProject(updateProjectModel);
    if (!ProjectManagementStore.errorMessage) props.onSave(updateProjectModel.projectNumber);
    setSaving(false);
  };

  const disabledSave =
    !formDirty ||
    (errors &&
      (errors[ProjectFields.programmeId] ||
        errors[ProjectFields.projectNumber] ||
        errors[ProjectFields.projectTitle] ||
        errors[ProjectFields.projectAdminUserIds] ||
        errors[ProjectFields.projectOwnerUserId]));

  const onFormInputChanged = (fieldName: string, value) => {
    if (fieldName === ProjectFields.description) {
      if (value.length > 200) {
        value = value.substring(0, 200);
      }
    }
    if (fieldName === ProjectFields.projectNumber) {
      if (value.length > 50) {
        value = value.substring(0, 50);
      }
    }
    const newUpdateProject = { ...updateProjectModel, [fieldName]: value };
    let errorMessage = validateForm(newUpdateProject);
    const projectTitleError = ProjectManagementStore.validateDuplicateProjectTitle(
      newUpdateProject.projectTitle,
      newUpdateProject.projectNumber
    );
    if (projectTitleError) {
      errorMessage = { ...errorMessage, projectTitle: projectTitleError };
    }
    setErrors({ ...errorMessage });
    setUpdateProject(newUpdateProject);
    setFormDirty(true);
  };

  const validateForm = (updateProject: IUpdateProjectModel) => {
    const errorMessage = { ...errors };
    Object.keys(UpdateProjectValidator).forEach(
      (key) => (errorMessage[key] = UpdateProjectValidator[key](updateProject[key]))
    );
    return errorMessage;
  };

  const onProjectAdminUsersSelected = (users: IUser[]) => {
    setSelectedUsers(users);
    onFormInputChanged(
      ProjectFields.projectAdminUserIds,
      users.map((x) => x.id)
    );
  };

  if (ProjectManagementStore.loading) return <Loading isLoading={ProjectManagementStore.loading} />;

  return (
    <>
      {ProjectManagementStore.projectDetails !== null && (
        <div className={Style.fromWrapper}>
          <Grid row={true} md={12}>
            <Grid item md={12}>
              <div className={Style.header}>
                <span>Project Details</span>
                <div className={Style.updateProjectActions}>
                  <SecondaryButton onClick={() => props.onCancel()}>Cancel</SecondaryButton>
                  <PrimaryButton
                    disabled={disabledSave}
                    loading={saving}
                    onClick={() => {
                      void (async () => {
                        await handleUpdateBtnClick();
                      })();
                    }}>
                    Save
                  </PrimaryButton>
                </div>
              </div>
            </Grid>

            <Grid item gap={8}>
              <Grid item md={6}>
                <FormInput
                  label="Programme"
                  value={ProjectManagementStore.projectDetails.programmeTitle}
                  placeholder="Programme Name"
                  required
                  readonly={true}
                />
              </Grid>
              <Grid item md={6}>
                <FormInput
                  label="Project Name"
                  placeholder="Enter a Project Name..."
                  required
                  disabled={saving}
                  value={updateProjectModel.projectTitle || ''}
                  onChange={(value) => onFormInputChanged(ProjectFields.projectTitle, value)}
                />
                <LimitedCharactersLeft maxLength={100} value={updateProjectModel.projectTitle} />
                <FormControlError message={errors[ProjectFields.projectTitle]} formDirty={formDirty} showIcon={true} />
              </Grid>
              <Grid item md={6}>
                <FormInput
                  label="Project Number"
                  placeholder="Enter Project Number..."
                  required
                  disabled
                  value={updateProjectModel.projectNumber || ''}
                  onChange={(value) => onFormInputChanged(ProjectFields.projectNumber, value)}
                />
              </Grid>
              <Grid item md={6}>
                <FormInput
                  label="Project Phase"
                  placeholder="Select Project Phase"
                  required
                  disabled={true}
                  showIcon={false}
                  type="dropdown"
                  onChange={(value) => onFormInputChanged(ProjectFields.projectPhase, value)}
                />
              </Grid>
              <Grid item md={6}>
                <div className={Style.formItemWrapper}>
                  <div className={Style.formControlLabel}>
                    Project Admins <span className={Style.required}>*</span>
                  </div>
                  <UserSelectorExpandable
                    required
                    searchPlaceholder="Enter Name..."
                    isMultiUser
                    disabled={saving}
                    className={Style.searchBox}
                    getUsers={(searchText) => ProjectManagementStore.searchUsers(searchText)}
                    onSelectedUsersUpdated={onProjectAdminUsersSelected}
                    defaultSelectedUsers={selectedUsers}
                  />
                  <FormControlError
                    message={errors[ProjectFields.projectAdminUserIds]}
                    formDirty={formDirty}
                    showIcon={true}
                  />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={Style.formItemWrapper}>
                  <ComboBox
                    label="Project Owner"
                    cssClass={Style.comboBox}
                    placeholder="Enter Project Owner..."
                    showIcon={false}
                    disabled={saving}
                    options={ProjectManagementStore.systemUsers.map((v) => ({
                      id: v.id.toString(),
                      value: v.name || '',
                    }))}
                    selected={updateProjectModel.projectOwnerUserId?.toString()}
                    onSelect={(value) => onFormInputChanged(ProjectFields.projectOwnerUserId, value?.id.toString())}
                    onClear={() => onFormInputChanged(ProjectFields.projectOwnerUserId, null)}
                  />
                  <FormControlError
                    message={errors[ProjectFields.projectOwnerUserId]}
                    formDirty={formDirty}
                    showIcon={true}
                  />
                </div>
              </Grid>
              <Grid item md={12}>
                <FormInput
                  label="Description"
                  value={updateProjectModel.description}
                  onChange={(value) => onFormInputChanged('description', value)}
                  placeholder="Enter description..."
                  multiline={true}
                  disabled={saving}
                />
                <LimitedCharactersLeft maxLength={200} value={updateProjectModel.description} />
              </Grid>
              <Grid item md={12}>
                <Grid item md={12}>
                  <div className={Style.formDisplayField}>
                    <div className={Style.formControlLabel}>Temporary Access</div>
                    <Grid item md={12}>
                      <Checkbox
                        label="Allow collaboration with other task teams"
                        checked={updateProjectModel.allowCollaborationOtherTaskTeams || false}
                        cssClass={Style.checkbox}
                        disabled={saving}
                        onChange={(value) => {
                          if (!value) {
                            setOpenWarningModal(true);
                            return;
                          }
                          onFormInputChanged(
                            ProjectFields.allowCollaborationOtherTaskTeams,
                            !updateProjectModel.allowCollaborationOtherTaskTeams
                          );
                        }}
                      />
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid row md={12} cssClass={classNames(Style.breakLine)}></Grid>

            <Grid row md={12} cssClass={classNames(Style.gridGroup)}>
              <div className={classNames(Style.header, Style.settingGroup)}>
                <span>Project Defaults</span>
              </div>
              <Grid gap={16} item md={12}>
                <p className={Style.settingNoticed}>
                  By default, left menu bar displays 4 main features and allows user to switch features from anywhere.
                  Admin can hide feature and it will not available for users.
                </p>
              </Grid>
              <Grid row md={12} gap={8}>
                <Grid item md={12}>
                  <Checkbox
                    label="Display Apps"
                    checked={updateProjectModel.displayApps}
                    cssClass={Style.checkbox}
                    disabled={saving}
                    onChange={() => onFormInputChanged(ProjectFields.displayApps, !updateProjectModel.displayApps)}
                  />
                </Grid>
                <Grid item md={12}>
                  <Checkbox
                    label="Display Files"
                    checked={updateProjectModel.displayFiles}
                    cssClass={Style.checkbox}
                    disabled={saving}
                    onChange={() => onFormInputChanged(ProjectFields.displayFiles, !updateProjectModel.displayFiles)}
                  />
                </Grid>
                <Grid item md={12}>
                  <Checkbox
                    label="Display Task"
                    cssClass={Style.checkbox}
                    checked={updateProjectModel.displayTasks}
                    disabled={saving}
                    onChange={() => onFormInputChanged(ProjectFields.displayTasks, !updateProjectModel.displayTasks)}
                  />
                </Grid>
                <Grid item md={12}>
                  <Checkbox
                    label="Display Visualisation"
                    cssClass={Style.checkbox}
                    disabled={saving}
                    checked={updateProjectModel.displayVisualisation}
                    onChange={() =>
                      onFormInputChanged(ProjectFields.displayVisualisation, !updateProjectModel.displayVisualisation)
                    }
                  />
                </Grid>
                <Grid item md={12}>
                  <Checkbox
                    label="Display Transmittals"
                    cssClass={Style.checkbox}
                    disabled={saving}
                    checked={updateProjectModel.displayTransmittals}
                    onChange={() =>
                      onFormInputChanged(ProjectFields.displayTransmittals, !updateProjectModel.displayTransmittals)
                    }
                  />
                </Grid>
              </Grid>

              {updateProjectModel.displayApps && (
                <>
                  <Grid row md={12} cssClass={classNames(Style.breakLine)}></Grid>
                  <ProjectApps projectNumber={props.projectNumber} />
                </>
              )}
            </Grid>
          </Grid>
          {ProjectManagementStore.showError && (
            <ErrorModal
              closeModal={() => ProjectManagementStore.setShowErrorModal(false)}
              errorCode={ProjectManagementStore.errorCode}
              errorMessage={ProjectManagementStore.errorMessage}
            />
          )}
        </div>
      )}

      <ConfirmationModal
        showModal={openWarningModal}
        heading="Revoke Temporary Access"
        message="This action will revoke all temporary access within the project and cannot be undone. Are you sure you want to perform this action?"
        confirmText="Yes"
        cancelText="No"
        onCancel={() => setOpenWarningModal(false)}
        onConfirm={() => {
          setOpenWarningModal(false);
          onFormInputChanged(
            ProjectFields.allowCollaborationOtherTaskTeams,
            !updateProjectModel.allowCollaborationOtherTaskTeams
          );
        }}
      />
    </>
  );
};

export default observer(UpdateProject);
