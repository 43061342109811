import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ActionBar from '../../../shared/ActionBar';
import ActionBarSection from '../../../shared/ActionBarSection';
import Style from '../../../../styles/components/settings/systemAdminSettings/systemUsers/SystemUserList.module.scss';
import { ITab, TabContent } from '@aurecon-creative-technologies/styleguide';
import SystemUsersStore from '../../../../stores/settings/systemAdminSettings/systemUser/SystemUsersStore';
import TabContainer from './TabContainer';
import PageTab from '../../../shared/PageTab';
import { SystemUsersTabIds, SystemUsersTabLabels } from '../../../../common/enums/SystemUserTabs';
import { CSVLink } from 'react-csv';
import { ISystemUserCSV } from '../../../../api/authenticated/um/interfaces/user.interface';
import { ICSVData } from '../../../../common/interfaces/csvData';
import SecondaryButton from '../../../shared/SecondaryButton';
import SearchBar from '../../../shared/SearchBar';

const SystemUserList: FC = () => {
  const [csvData, setCsvData] = useState<ICSVData<ISystemUserCSV> | null>(null);
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const SystemUsersTabs: ITab[] = [
    { id: SystemUsersTabIds.ACTIVE, label: SystemUsersTabLabels.ACTIVE },
    { id: SystemUsersTabIds.DEACTIVE, label: SystemUsersTabLabels.DEACTIVE },
  ];

  const onSelectTab = (id: number) => {
    SystemUsersStore.setSelectedTabId(id);
  };

  const fetchDataAsync = async () => {
    setClicked(false);
    if (csvData && !!SystemUsersStore.allSystemUsers.length) return;

    setLoading(true);
    await SystemUsersStore.getAllSystemUsers();
    const mappingItems = SystemUsersStore.allSystemUsers;
    const data = SystemUsersStore.mappingCSVData(mappingItems);
    setCsvData(data);
    setLoading(false);
  };

  useEffect(() => {
    if (csvData && !clicked) {
      csvLinkRef?.current?.link.click();
      setClicked(true);
    }
  }, [clicked, csvData]);

  return (
    <>
      <ActionBar className={Style.pageHeading}>
        <p className={Style.pageHeadingLabel}>User List</p>

        <ActionBarSection>
          <SearchBar
            searchValue={SystemUsersStore.searchText}
            cssClass={Style.searchUserBox}
            onSearch={(keyword) => {
              SystemUsersStore.setSearchText(keyword);
            }}
            placeHolderText="Quick search..."
          />
          {SystemUsersStore.selectedTabId === SystemUsersTabIds.ACTIVE && (
            <SecondaryButton className={Style.btnMenuUploadBtn} disabled={loading} onClick={fetchDataAsync}>
              Export to CSV
              {csvData && (
                <CSVLink
                  headers={csvData?.headers ?? []}
                  filename={csvData?.filename}
                  data={csvData?.data ?? []}
                  ref={csvLinkRef}
                />
              )}
            </SecondaryButton>
          )}
        </ActionBarSection>
      </ActionBar>

      <PageTab defaultActiveTab={SystemUsersStore.selectedTabId} tabs={SystemUsersTabs} onSelectTab={onSelectTab}>
        {SystemUsersTabs.map((tab) => (
          <TabContent key={tab.id} for={tab.id}>
            <TabContainer tabId={tab.id} />
          </TabContent>
        ))}
      </PageTab>
    </>
  );
};

export default observer(SystemUserList);
