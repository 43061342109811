import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import TaskInformationStore from './TaskInformationStore';
import { ITaskFileContainer } from '../../api/authenticated/tasks/getTask';
import DropDownMenuForTableCell from '../shared/DropDownMenuForTableCell';
import DropDownMenuButton from '../shared/DropDownMenuButton';
import { getFileSizeDetails } from '../../utils/miscUtils';
import DownloadModal from '../shared/DownloadModal';
import ForgeViewerStore from '../visualisation/forgeViewer/ForgeViewerStore';
import ErrorModal from '../shared/ErrorModal';
import NavBarSelectorStore from '../tasks/navBarSelector/NavBarSelectorStore';
import { IContainerFile } from '../../api/authenticated/cms/FileContainerModel';
import { FileExt } from '../../common/constants/FileExt';
import getFileExtension from '../../utils/fileUtils';
import { openViewer } from '../../utils/pdfViewerUtils';

export interface ITaskInformationFileActionProps {
  taskFile: ITaskFileContainer;
  containerFile?: IContainerFile;
}

const TaskInformationFileAction: FC<ITaskInformationFileActionProps> = ({ taskFile, containerFile }) => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const { totalFileSize, isOverLimit } = getFileSizeDetails(taskFile.fileSize);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const handleForgeViewer = async (containerFileId: number) => {
    await ForgeViewerStore.getForgeViewer(containerFileId, NavBarSelectorStore.selectedItem!.project!.projectNumber);
    if (ForgeViewerStore.errorMessage !== null) setShowErrorModal(true);
  };

  const task = TaskInformationStore.task;

  if (!task) return null;

  const handleOpenTaskFile = () => {
    if (!containerFile) return;

    const fileContainerId = containerFile.fileContainerId as number;

    return (
      <>
        {containerFile.hasDocumentViewer && (
          <DropDownMenuButton
            onClick={(event) => {
              event?.stopPropagation();
              TaskInformationStore.openTaskFile(taskFile, containerFile.containerFileId);
            }}
            disabled={TaskInformationStore.taskFilesBusy[fileContainerId]}>
            Open File
          </DropDownMenuButton>
        )}

        {containerFile && containerFile.isForgeFile && (
          <DropDownMenuButton
            disabled={TaskInformationStore.taskFilesBusy[fileContainerId]}
            onClick={(event) => {
              event?.stopPropagation();
              handleForgeViewer(containerFile.containerFileId!);
            }}>
            Open File
          </DropDownMenuButton>
        )}

        {getFileExtension(containerFile.originalFilename)?.toLocaleLowerCase() === FileExt.PDF &&
          !!taskFile.fileContainerRevisionId && (
            <DropDownMenuButton
              onClick={(event) => {
                event?.stopPropagation();
                openViewer(
                  `projectNumber=${NavBarSelectorStore.selectedItem!.project!.projectNumber}&id=${
                    taskFile.fileContainerId
                  }&fileRevisionId=${taskFile?.fileContainerRevisionId}&containerFileId=${
                    containerFile.containerFileId
                  }&releasedFileId=${taskFile?.releasedFileContainerId}`
                );
              }}>
              Open PDF File
            </DropDownMenuButton>
          )}
      </>
    );
  };

  return (
    <>
      <DropDownMenuForTableCell>
        {!containerFile && (
          <DropDownMenuButton
            onClick={(event) => {
              event?.stopPropagation();
              TaskInformationStore.openFileInfo(taskFile);
            }}>
            Info
          </DropDownMenuButton>
        )}
        <DropDownMenuButton
          onClick={(event) => {
            event?.stopPropagation();
            if (isOverLimit) {
              setShowDownloadModal(true);
            } else {
              TaskInformationStore.downloadTaskFile(taskFile, containerFile?.containerFileId);
            }
          }}
          disabled={TaskInformationStore.taskFilesBusy[taskFile.fileContainerId]}>
          Download
        </DropDownMenuButton>

        {handleOpenTaskFile()}
      </DropDownMenuForTableCell>
      <DownloadModal
        closeModal={() => setShowDownloadModal(false)}
        showModal={showDownloadModal}
        downloadAction={() => {
          TaskInformationStore.downloadTaskFile(taskFile, containerFile?.containerFileId);
          setShowDownloadModal(false);
        }}
        fileSize={totalFileSize}
      />
      {showErrorModal && (
        <ErrorModal
          closeModal={() => setShowErrorModal(false)}
          errorCode={ForgeViewerStore.errorCode}
          errorMessage={ForgeViewerStore.errorMessage ?? null}
        />
      )}
    </>
  );
};

export default observer(TaskInformationFileAction);
