import { FileContainerState } from '../../../common/enums/FileContainerState';
import { TaskStatus } from '../../../common/enums/TaskStatus';
import { TaskType } from '../../../common/enums/TaskType';
import { TaskUserStatus } from '../../../common/enums/TaskUserStatus';
import { serverAxios } from '../api';
import { IContainerFile } from '../cms/FileContainerModel';

export interface ITask {
  id: number;
  message?: string;
  createdDate: Date;
  lastUpdatedDate: Date;
  completedDate?: Date;
  dueDate?: Date;
  taskStatusId: TaskStatus;
  displayTaskStatus: string;
  taskTypeId: TaskType;
  createdByUserId: number;
  createdByUserName: string;
  createdByUserEmail: string;
  createdByUserInitials: string;
  suitabilityId: number;
  suitabilityCode: string;
  suitabilityTitle: string;
  projectNumber: string;
  taskFileContainers: ITaskFileContainer[];
  taskUsers: ITaskUser[];
  attachmentFiles: ITaskAttachmentFile[];
  canApprove: boolean;
  canReject: boolean;
  canReassign: boolean;
  canUploadDocuments: boolean;
  isSingleApproverRequired: boolean;
}

export interface ITaskFileContainer {
  fileContainerId: number;
  fileContainerRevisionId: number;
  releasedFileContainerId: number | null;
  fileTitle: string;
  fileSize: number;
  fileContainerStateId: FileContainerState;
  revision: number;
  fieldValue1: string | null;
  fieldValue2: string | null;
  fieldValue3: string | null;
  fieldValue4: string | null;
  fieldValue5: string | null;
  fieldValue6: string | null;
  fieldValue7: string | null;
  fieldValue8: string | null;
  fieldValue9: string | null;
  fieldValue10: string | null;
  fieldValue11: string | null;
  fieldValue12: string | null;
  fieldValue13: string | null;
  fieldValue14: string | null;
  fieldValue15: string | null;
  fieldValue16: string | null;
  fieldValue17: string | null;
  fieldValue18: string | null;
  fieldValue19: string | null;
  fieldValue20: string | null;
  uploadedDate: Date;
  createdDate: Date;
  uploadedByUserName: string;
  suitabilityCode: string;
  isSuitabilityRefCode: boolean | null;
  suitabilityTitle: string;
  extension: string;
  formattedRevision: string;
  deliveryTeamTitle: string;
  taskTeamTitle: string;
  containerFiles: IContainerFile[];
}

export interface ITaskUser {
  userId: number;
  name: string;
  email: string;
  initials: string;
  taskUserStatusId: TaskUserStatus;
  message?: string;
  completedDate?: Date;
}

export interface ITaskAttachmentFile {
  id: number;
  originalFileName: string;
  uploadedByUserName: string;
  addedDate: Date;
  totalSize: number;
}

export async function getTask(taskId: number, abortSignal?: AbortSignal): Promise<ITask> {
  const result = await serverAxios.get<ITask>(`api/task/${taskId}`, { signal: abortSignal });
  return result.data;
}
