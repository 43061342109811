import React, { FC, useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { IHeader, Checkbox, Tooltip, TableRow, TableCell } from '@aurecon-creative-technologies/styleguide';
import { IFileContainer } from '../../../api/authenticated/cms/FileContainerModel';
import FilesStore from '../FilesStore';
import Icon from '../../shared/Icon';
import DropDownMenuButton from '../../shared/DropDownMenuButton';
import DropDownMenuForTableCell from '../../shared/DropDownMenuForTableCell';
import WorkflowPill from '../../shared/WorkflowPill';
import Style from './styles/FileTableRow.module.scss';
import { classNames, getFileSizeDetails, getFileSizeString } from '../../../utils/miscUtils';
import DownloadModal from '../../shared/DownloadModal';
import { ITableColumn } from '../../../common/interfaces/TableColumn';
import LayoutStore from '../../layout/LayoutStore';
import ErrorModal from '../../shared/ErrorModal';
import ForgeViewerStore from '../../visualisation/forgeViewer/ForgeViewerStore';
import { dateTime12HFormat } from '../../../utils/dateUtils';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';
import AppStore from '../../../stores/AppStore';
import getFileExtension from '../../../utils/fileUtils';
import { FileExt } from '../../../common/constants/FileExt';
import { FileContainerState } from '../../../common/enums/FileContainerState';
import { openViewer } from '../../../utils/pdfViewerUtils';
import FileInformationStore from '../fileInformation/FileInformationStore';
import { appInsightsTrackEvent } from '../../../utils/appInsights';
import { AppInsightEvent } from '../../../common/constants/AppInsightEvent';

export interface IFileTableRowProps {
  headers: IHeader[];
  file: IFileContainer;
  tableColumns: ITableColumn[];
  onEdit: (fileId: number) => void;
}

const ShiftKey = 'Shift';
const BodyTagName = 'body';
const FileTableRow: FC<IFileTableRowProps> = ({ headers, file, tableColumns, onEdit }) => {
  const { totalFileSize, isOverLimit } = getFileSizeDetails(file.uploadedSize);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [shiftOn, setShiftOn] = useState(false);
  const [showTooltips, setShowTooltips] = useState<string[]>([]);
  const refs = useRef<HTMLTableCellElement[]>([]);

  useEffect(() => {
    const handleKeyPressDown = (e) => {
      if (e.srcElement.tagName.toLowerCase() !== BodyTagName) return;
      if (e.key === ShiftKey) {
        window?.getSelection()?.removeAllRanges();
        setShiftOn(true);
      }
    };
    const handleKeyPressUp = (e) => {
      if (e.srcElement.tagName.toLowerCase() !== BodyTagName) return;
      if (e.key === ShiftKey) {
        window.getSelection();
        setShiftOn(false);
      }
    };
    window.addEventListener('keydown', handleKeyPressDown, false);
    window.addEventListener('keyup', handleKeyPressUp, false);
    return () => {
      window.removeEventListener('keyup', handleKeyPressUp);
      window.removeEventListener('keydown', handleKeyPressDown);
    };
  }, [shiftOn]);

  const handleShowTooltip = (label: string) => {
    const items: string[] = [];

    const element = refs.current[label];
    if (element && element.scrollWidth > element.clientWidth) items.push(label);

    setShowTooltips(items);
  };

  const cellValue = (h: IHeader) => {
    const col = tableColumns.find((c) => c.label === h.label);
    if (!col) {
      return '';
    }
    if (file[col.valueField] instanceof Date) return dateTime12HFormat(file[col.valueField] as Date);
    return file[col.valueField];
  };

  const handleResendToForge = async (fileContainerRevisionId: number | null, containerFileId: number) => {
    await FilesStore.resendToForge(fileContainerRevisionId, containerFileId);
    if (FilesStore.errorMessage !== null) setShowErrorModal(true);
    else LayoutStore.displayToast('success', 'The file was resent successfully.');
  };

  const handleForgeViewer = async (containerFileId: number | null) => {
    if (!containerFileId) return;
    await ForgeViewerStore.getForgeViewer(containerFileId, NavBarSelectorStore.selectedItem!.project.projectNumber);
    if (ForgeViewerStore.errorMessage !== null) {
      FilesStore.setError(ForgeViewerStore.error!);
      setShowErrorModal(true);
    }
  };

  const handleCopyLink = async (fileTitle) => {
    appInsightsTrackEvent(AppInsightEvent.FILE_COPY_LINK);
    const revisionCodeIndex = fileTitle.indexOf('-[');
    if (revisionCodeIndex > -1) fileTitle = fileTitle.substring(0, revisionCodeIndex);
    const url = `${window.location.origin}/#/files/${AppStore.projectNumber}/${AppStore.deliveryTeamCode}/${
      AppStore.taskTeamCode
    }/${FileContainerState[FilesStore.fileContainerStateId]}/${fileTitle}`;
    navigator.clipboard.writeText(url);
    LayoutStore.displayToast('success', 'Copied to clipboard.');
  };

  const hasTooltip = (label: string) => {
    return showTooltips.includes(label);
  };

  const tableCellContent = (headerLabel: string, file: IFileContainer, showLockIcon: boolean) => (
    <div className={Style.filenameCell}>
      <span ref={(el) => (refs.current[headerLabel] = el)} className={classNames(Style.overFlow)}>
        {file.title}
      </span>
      {showLockIcon && file.isLocked && <Icon className={Style.lockIcon} name="lock" />}
    </div>
  );

  const downloadOnClick = () => {
    appInsightsTrackEvent(AppInsightEvent.FILE_DOWNLOAD);

    if (isOverLimit) {
      setShowDownloadModal(true);
    } else {
      FilesStore.downloadFile({
        fileContainers: [
          {
            ...file,
            releasedFileId: FilesStore.fileContainerStateId === FileContainerState.Wip ? null : file.releasedFileId,
          },
        ],
        downloadAllContainerFile: true,
        fileContainerStateId: FilesStore.fileContainerStateId,
      });
    }
  };
  return (
    <>
      <TableRow
        key={FilesStore.getFileKey(file)}
        onClick={() => {
          if (FileInformationStore.file?.id === file.id) {
            FileInformationStore.close();
            return;
          }
          FilesStore.showFileInformation(file, null);
        }}>
        {headers.map((h, i) => {
          const def = headers[i];

          if (def.onCheckbox) {
            const checked = FilesStore.isFileSelected(file);
            return (
              <TableCell key={i} cellClass={Style.checkbox}>
                <div
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    FilesStore.handleSelectStart(file, !checked, shiftOn);
                  }}>
                  <Checkbox checked={checked} />
                </div>
              </TableCell>
            );
          }
          if (def.label === '') {
            return (
              <DropDownMenuForTableCell key={i}>
                <DropDownMenuButton
                  onClick={(event) => {
                    event?.stopPropagation();
                    FilesStore.showFileInformation(file, null);
                  }}>
                  Info
                </DropDownMenuButton>
                <DropDownMenuButton
                  onClick={(event) => {
                    event?.stopPropagation();
                    downloadOnClick();
                  }}
                  disabled={FilesStore.fileBusy[file.id]}>
                  Download
                </DropDownMenuButton>
                {file.hasDocumentViewer && (
                  <DropDownMenuButton
                    onClick={(event) => {
                      event?.stopPropagation();
                      FilesStore.openFile({
                        ...file,
                        releasedFileId:
                          FilesStore.fileContainerStateId === FileContainerState.Wip ? null : file.releasedFileId,
                      });
                    }}
                    disabled={FilesStore.fileBusy[file.id]}>
                    Open File
                  </DropDownMenuButton>
                )}
                {file.isForgeFile && file.fileRevisionId && (
                  <DropDownMenuButton
                    onClick={(event) => {
                      event?.stopPropagation();
                      handleForgeViewer(file.containerFileId);
                    }}>
                    Open File
                  </DropDownMenuButton>
                )}
                {getFileExtension(file.originalFilename)?.toLocaleLowerCase() === FileExt.PDF && file.fileRevisionId && (
                  <DropDownMenuButton
                    onClick={(event) => {
                      event?.stopPropagation();
                      openViewer(
                        `projectNumber=${NavBarSelectorStore.selectedItem!.project.projectNumber}&id=${
                          file.id
                        }&fileRevisionId=${file.fileRevisionId}&containerFileId=${
                          file.containerFileId
                        }&releasedFileId=${file.releasedFileId}`
                      );
                    }}>
                    Open PDF File
                  </DropDownMenuButton>
                )}
                <DropDownMenuButton
                  onClick={(event) => {
                    event?.stopPropagation();
                    handleCopyLink(file.title);
                  }}>
                  Copy Link
                </DropDownMenuButton>
                {file.canSubmitForge && (
                  <DropDownMenuButton
                    onClick={(event) => {
                      event?.stopPropagation();
                      handleResendToForge(file.fileRevisionId, file.containerFileId);
                    }}>
                    Resend to Forge
                  </DropDownMenuButton>
                )}
                {(AppStore.projectAdminister || FilesStore.fileContainerStateId === FileContainerState.Wip) &&
                  !FilesStore.isFileReachedLimitedTimeToEditMetadata(file) &&
                  file.canCollaborate && (
                    <DropDownMenuButton
                      disabled={file.isLocked}
                      onClick={(event) => {
                        event?.stopPropagation();
                        onEdit(file.id);
                      }}>
                      Edit
                    </DropDownMenuButton>
                  )}
              </DropDownMenuForTableCell>
            );
          }

          if (def.label === 'Filename') {
            return (
              <TableCell
                key={i}
                cellClass={Style.cellWidth}
                onHover={() => {
                  handleShowTooltip(def.label);
                }}>
                {hasTooltip(def.label) && (
                  <Tooltip show={<div className={Style.wordWrap}>{file.title}</div>}>
                    {tableCellContent(def.label, file, true)}
                  </Tooltip>
                )}
                {!hasTooltip(def.label) && tableCellContent(def.label, file, true)}
              </TableCell>
            );
          }
          if (def.label === 'Original Filename') {
            return (
              <TableCell
                key={i}
                onHover={() => {
                  handleShowTooltip(def.label);
                }}>
                {hasTooltip(def.label) && (
                  <Tooltip show={<div className={Style.wordWrap}>{file.originalFilename}</div>}>
                    <div ref={(el) => (refs.current[def.label] = el)} className={Style.overFlow}>
                      {file.originalFilename}
                    </div>
                  </Tooltip>
                )}
                {!hasTooltip(def.label) && (
                  <div
                    ref={(el) => (refs.current[def.label] = el)}
                    className={classNames(Style.cellWidth, Style.overFlow)}>
                    {file.originalFilename}
                  </div>
                )}
              </TableCell>
            );
          }
          if (def.label === 'Workflow') {
            return (
              <TableCell key={i}>
                <WorkflowPill taskTypeId={file.taskTypeId} />
              </TableCell>
            );
          }
          if (def.label === 'File Size') {
            return (
              <TableCell key={i}>
                <span>{getFileSizeString(file.uploadedSize)}</span>
              </TableCell>
            );
          }
          if (def.label === 'Number of files') {
            return (
              <TableCell key={i}>
                <span>{file.containerFiles?.length ?? 0}</span>
              </TableCell>
            );
          }
          if (def.label === 'Task Team Originator') {
            return (
              <TableCell
                key={i}
                onHover={() => {
                  handleShowTooltip(def.label);
                }}>
                {hasTooltip(def.label) && (
                  <Tooltip
                    show={
                      <div className={Style.wordWrap}>
                        {file.deliveryTeamTitle}
                        <strong> | {file.taskTeamTitle}</strong>
                      </div>
                    }>
                    <div className={Style.overFlow} ref={(el) => (refs.current[def.label] = el)}>
                      {file.deliveryTeamTitle}
                      <strong> | {file.taskTeamTitle}</strong>
                    </div>
                  </Tooltip>
                )}
                {!hasTooltip(def.label) && (
                  <div className={Style.overFlow} ref={(el) => (refs.current[def.label] = el)}>
                    {file.deliveryTeamTitle}
                    <strong> | {file.taskTeamTitle}</strong>
                  </div>
                )}
              </TableCell>
            );
          }

          return (
            <TableCell
              align={def.align}
              key={i}
              checkbox
              onHover={() => {
                handleShowTooltip(def.label);
              }}>
              <>
                {hasTooltip(def.label) && (
                  <Tooltip show={cellValue(h)}>
                    <div className={Style.overFlow} ref={(el) => (refs.current[def.label] = el)}>
                      {cellValue(h)}
                    </div>
                  </Tooltip>
                )}
                {!hasTooltip(def.label) && (
                  <div className={Style.overFlow} ref={(el) => (refs.current[def.label] = el)}>
                    {cellValue(h)}
                  </div>
                )}
              </>
            </TableCell>
          );
        })}
      </TableRow>

      <DownloadModal
        fileSize={totalFileSize}
        showModal={showDownloadModal}
        closeModal={() => setShowDownloadModal(false)}
        downloadAction={() => {
          FilesStore.downloadFile({ fileContainers: [file] });
          setShowDownloadModal(false);
        }}
      />
      {showErrorModal && (
        <ErrorModal
          closeModal={() => setShowErrorModal(false)}
          errorCode={FilesStore.errorCode}
          errorMessage={FilesStore.errorMessage}
        />
      )}
    </>
  );
};

export default observer(FileTableRow);
