import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import FilesStore from './FilesStore';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import { ContentSelection } from './ContentSelection';
import DeleteModal from './wipTab/DeleteModal';
import LayoutStore from '../layout/LayoutStore';
import Style from './styles/FileOverflowButton.module.scss';
import { NavigationItemTypes } from '../../common/models/ItemType';
import DropDownMenu from '../shared/DropDownMenu';
import DropDownMenuButton from '../shared/DropDownMenuButton';
import AppStore from '../../stores/AppStore';
import { FileContainerState } from '../../common/enums/FileContainerState';

interface IFileOverflowButtonProps {
  onEdit: () => void;
}

const FileOverflowButton: FC<IFileOverflowButtonProps> = (props) => {
  const { onEdit } = props;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState<{ deleting: number; deleted: number }>();

  const closeModal = () => {
    setShowDeleteModal(false);
    setDone(undefined);
  };

  const deleteAction = async () => {
    setLoading(true);
    const deleting = FilesStore.selectedFiles.length;
    const deleted = await FilesStore.deleteSelectedFiles();
    setLoading(false);

    if (deleted == deleting) {
      LayoutStore.displayToast('success', 'Files have been deleted successfully');
      FilesStore.resetSelectedFile();
      closeModal();
    } else {
      setDone({ deleted, deleting });
    }
  };

  const showDeleteButton =
    NavBarSelectorStore.selectedItem?.type === NavigationItemTypes.TaskTeam &&
    FilesStore.selectedSection === ContentSelection.WIP &&
    NavBarSelectorStore.selectedItem.taskTeam.canDelete;

  if (!showDeleteButton && !AppStore.projectAdminister) return null;

  return (
    <>
      {!FilesStore.isTemporaryTaskTeam && (
        <DropDownMenu
          disabled={!FilesStore.selectedFiles.length}
          icon="more_vert"
          iconClassName={Style.overflowButton}
          itemsContainerClassName={Style.contextMenu}>
          {showDeleteButton && (
            <DropDownMenuButton disabled={!FilesStore.canDeleteSelectedFiles} onClick={() => setShowDeleteModal(true)}>
              Delete
            </DropDownMenuButton>
          )}
          {(AppStore.projectAdminister || FilesStore.fileContainerStateId === FileContainerState.Wip) &&
            !FilesStore.isAnySelectedReachedLimitTimeToEditMetadata && (
              <DropDownMenuButton
                disabled={FilesStore.isAnySelectedFileLocked || !FilesStore.selectedFiles.length}
                onClick={onEdit}>
                Edit
              </DropDownMenuButton>
            )}
        </DropDownMenu>
      )}
      <DeleteModal
        showModal={showDeleteModal}
        closeModal={closeModal}
        deleteAction={deleteAction}
        errorCode={FilesStore.errorCode}
        errorMessage={FilesStore.errorMessage}
        loading={loading}
        done={done}
      />
    </>
  );
};

export default observer(FileOverflowButton);
