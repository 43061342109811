import { makeAutoObservable, runInAction } from 'mobx';
import { IPageMetaData, IPagedResponse } from '../../../common/models/IPagedResponse';
import { PagingArray } from '../../../utils/pagingArray';
import {
  IGetSupersedeFile,
  SupersedeFileFilter,
  getSupersedeFileContainers,
  ISupersedeFileContainerBase,
} from '../../../api/authenticated/cms/getSupersedeFiles';
import { IDateRangeFilter, ITableColumn } from '../../../common/interfaces/TableColumn';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';
import { NavigationItemTypes } from '../../../common/models/ItemType';
import UploadStore from './UploadStore';
import FilesStore from '../FilesStore';
import { FileContainerState } from '../../../common/enums/FileContainerState';
import { UploadFileStatusEnum } from '../../../common/enums/UploadFileStatus';
import { IVisibilityColumn } from '../../../common/interfaces/AdjustableTableColumn';

export interface ISupersedeMetadataSelectedValueModel {
  fieldValueIndex: number;
  value: string;
  title: string;
}

export interface IUploadContainerFile {
  file: File;
  fileName: string;
  size: number;
  templateId?: number;
  isUnbranded: boolean;
}

export interface ISupersedeFileContainerModel {
  tempFileContainerId: string;
  groupedContainerName: string | null;
  files: IUploadContainerFile[];
  statuses: UploadFileStatusEnum[];
}

export interface ISelectedDuplicateFileContainer {
  tempFileContainerId: string;
  selectedAtPage: number;
  newFiles: IUploadContainerFile[];
  selectedDuplicatedFilesToCopy: ISelectedDuplicateFileToCopyModel[];
  fileContainerId: number;
  fileContainerName: string;
  suitabilityId: number | null;
  suitabilityTitle: string | null;
  suitabilityCode: string | null;
  fileContainerRevisionId: number;
  releasedFileContainerId: number | null;
  releasedFileContainerTitle: string | null;
  fieldValue1: string | null;
  fieldValue2: string | null;
  fieldValue3: string | null;
  fieldValue4: string | null;
  fieldValue5: string | null;
  fieldValue6: string | null;
  fieldValue7: string | null;
  fieldValue8: string | null;
  fieldValue9: string | null;
  fieldValue10: string | null;
  fieldValue11: string | null;
  fieldValue12: string | null;
  fieldValue13: string | null;
  fieldValue14: string | null;
  fieldValue15: string | null;
  fieldValue16: string | null;
  fieldValue17: string | null;
  fieldValue18: string | null;
  fieldValue19: string | null;
  fieldValue20: string | null;
  metadata: ISupersedeMetadataSelectedValueModel[];
}

export interface ISelectedDuplicateFileToCopyModel {
  fileId: number;
  originalFileName: string;
  uploadedDate: Date;
  size: number;
  isSelected: boolean;
}

const CheckBoxColumn = 'checkBoxColumn';
const ActionColumn = 'actionColumn';
export const ActionColumns = [CheckBoxColumn, ActionColumn];

export class SupersedeFileStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public pagingMetaData: IPageMetaData = {
    pageNumber: 1,
    pageCount: 1,
  } as IPageMetaData;
  public isLoading = false;
  public supersedeFileContainers: ISupersedeFileContainerBase[] = [];
  public selectedFileContainers: ISupersedeFileContainerModel[] = [];
  public fileFilters: SupersedeFileFilter = new SupersedeFileFilter();
  public tableColumns: ITableColumn[] = [];
  public currentFileContainer: ISupersedeFileContainerModel | null = null;
  public currentPage = 1;
  public selectedDuplicateFileContainerId = 0;
  public selectedDuplicateFileContainers: ISelectedDuplicateFileContainer[] = [];
  public searchKeyword: string | null = null;
  public openReviewSupersede = false;

  private clearFilterOptions() {
    runInAction(() => {
      this.tableColumns.forEach((c) => {
        c.dateFilter = undefined;
        c.listFilter = undefined;
        c.textFilter = undefined;
        c.sort = undefined;
      });
      this.setDefaultFilter();
    });
    this.loadTableColumns();
  }

  public async init() {
    this.cleanup();
    this.setCurrentFile();
    this.loadTableColumns();
    await this.searchSupersedeFiles();
  }

  public async setCurrentPage(page) {
    if (page === this.pagingMetaData.pageNumber) return;
    runInAction(() => {
      this.pagingMetaData.pageNumber = page;
      this.supersedeFileContainers = [];
    });
    await this.searchSupersedeFiles();
  }

  public async nextFile() {
    runInAction(() => {
      this.currentPage += 1;
    });
    this.setCurrentFile();
    await this.searchSupersedeFiles();
  }

  public async confirmReview() {
    this.setOpenReviewSupersede(true);
  }

  public async confirm() {
    const fileContainer = this.selectedDuplicateFileContainers.find(
      (f) => f.fileContainerId === this.selectedDuplicateFileContainerId
    );

    if (!fileContainer) return;
    await FilesStore.lockMultipleSupersedeFileContainers([
      {
        fileContainerId: fileContainer.fileContainerId,
        fileContainerRevisionId: fileContainer.fileContainerRevisionId,
      },
    ]);

    if (this.currentPage !== this.selectedFileContainers.length) {
      this.setOpenReviewSupersede(false);
      this.next();
      return;
    }
    UploadStore.setSupersedeFileContainers([
      ...this.selectedDuplicateFileContainers.map((m) => {
        return {
          ...m,
          selectedDuplicatedFiles: m.selectedDuplicatedFilesToCopy.filter((f) => f.isSelected),
        };
      }),
    ]);
    FilesStore.setOpenSupersedeFile(false);
    this.setOpenReviewSupersede(false);
  }

  public skipAndNext = async () => {
    await this.next();
  };

  public async skipAndConfirm() {
    FilesStore.setOpenSupersedeFile(false);
  }

  private async next() {
    runInAction(() => {
      this.pagingMetaData = { pageNumber: 1, pageCount: 1 } as IPageMetaData;
    });
    this.setSelectedDuplicateFileContainerId(0);
    this.setSearchKeyword(null);
    await this.nextFile();
  }

  public setSelectedSupersedeFileContainer(fileContainer: ISupersedeFileContainerBase, checked: boolean) {
    if (!this.currentFileContainer) return;
    const currentFileContainer = this.currentFileContainer;
    const selected = this.selectedDuplicateFileContainers.filter(
      (f) => f.fileContainerId != fileContainer.fileContainerId
    );
    if (checked) {
      const keys = Object.keys(fileContainer).filter((key) => key.startsWith('fieldValue'));
      const fieldValues = keys.map((key, index) => ({
        fieldValueIndex: index + 1,
        value: fileContainer[key],
        title: fileContainer[key],
      }));

      selected.push({
        ...fileContainer,
        tempFileContainerId: currentFileContainer.tempFileContainerId,
        selectedAtPage: this.pagingMetaData.pageNumber,
        fileContainerRevisionId: fileContainer.fileContainerRevisionId,
        newFiles: currentFileContainer.files,
        selectedDuplicatedFilesToCopy: fileContainer.supersedeFiles.map((m) => ({
          fileId: m.fileId,
          originalFileName: m.originalFilename,
          uploadedDate: m.uploadedDate,
          size: m.size,
          isSelected: false,
        })),
        metadata: fieldValues,
      });
      this.setSelectedDuplicateFileContainerId(fileContainer.fileContainerId);
    } else {
      this.setSelectedDuplicateFileContainerId(0);
    }

    this.setSelectedDuplicateFileContainers(selected);
  }

  public async searchSupersedeFiles() {
    runInAction(() => {
      this.isLoading = true;
    });
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return;
    const taskTeamId = NavBarSelectorStore.selectedItem.taskTeam.id;

    this.setCurrentFile();
    if (!this.currentFileContainer) return;

    const condition = {
      taskTeamId: taskTeamId,
      uploadedFilenames: this.currentFileContainer.files.map((m) => m.fileName),
      fileContainerStateId: FilesStore.fileContainerStateId,
      searchKeyword: this.searchKeyword ?? undefined,
      filterByFileContainerName: this.fileFilters.title ?? null,
      filterByOriginalFilename: this.fileFilters.originalFilename ?? null,
      date1: this.fileFilters.date1 ?? null,
      date2: this.fileFilters.date2 ?? null,
      operator: this.fileFilters.operator ?? null,
      page: this.pagingMetaData.pageNumber,
    } as IGetSupersedeFile;

    const response = await getSupersedeFileContainers(condition);

    this.setPagingMetaData(response);
    const files = response.data;
    runInAction(() => {
      this.supersedeFileContainers = files.map((x) => {
        return {
          ...x,
          title:
            FilesStore.fileContainerStateId === FileContainerState.Shared && x.releasedFileContainerTitle
              ? x.releasedFileContainerTitle
              : x.fileContainerName,
        };
      });
    });

    setTimeout(() => {
      runInAction(() => {
        this.isLoading = false;
      });
    }, 500);
  }

  public async ClearFilter() {
    this.clearFilterOptions();
    await this.searchSupersedeFiles();
  }

  public setDefaultFilter() {
    this.fileFilters = new SupersedeFileFilter().setDefaultFilter();
  }

  public loadTableColumns() {
    const uploadedDate = this.getTableColumnByFieldId('Uploaded')?.dateFilter;
    const tableColumns: ITableColumn[] = [
      {
        label: '',
        valueField: CheckBoxColumn,
        width: 35,
        visible: true,
      },
      {
        label: 'Filename',
        valueField: 'title',
        textFilter: { filter: this.getTableColumn('Filename')?.textFilter?.filter },
        width: 200,
        minWidth: 100,
        visible: true,
      },
      {
        label: 'Original Filename',
        valueField: 'originalFilename',
        textFilter: { filter: this.getTableColumn('Original Filename')?.textFilter?.filter },
        width: 200,
        minWidth: 100,
        visible: true,
      },
      {
        label: 'Uploaded',
        valueField: 'uploaded',
        dateFilter: {
          startDate: uploadedDate?.startDate ?? uploadedDate?.startDate ?? null,
          endDate: uploadedDate?.endDate ?? uploadedDate?.endDate ?? null,
          operator: uploadedDate?.operator ?? uploadedDate?.operator ?? null,
        },
        width: 200,
        minWidth: 100,
        visible: true,
      },
      {
        label: 'Action',
        valueField: ActionColumn,
        width: 50,
        visible: true,
      },
    ];

    runInAction(() => {
      this.tableColumns = tableColumns;
    });
  }

  public get getTableColumnsForShowHide(): IVisibilityColumn[] {
    return this.tableColumns
      .filter((x) => !ActionColumns.includes(x.valueField))
      .map((x) => {
        return {
          id: x.valueField,
          label: x.label,
        };
      });
  }
  public setVisibleSelectedFileColumns(columns: string[]) {
    runInAction(() => {
      this.tableColumns.forEach((item) => {
        item.visible = ActionColumns.includes(item.valueField) || columns.includes(item.valueField);
      });
    });
  }

  public applyFilter(column: string, filter?: string | IDateRangeFilter) {
    runInAction(async () => {
      this.pagingMetaData.pageNumber = 1;
      const parameter = this.getFilterFromTableColumns(column, filter);
      this.fileFilters.setExtraConditions(parameter);
      await this.searchSupersedeFiles();
    });
  }

  private getFilterFromTableColumns(column: string, filter?: string | IDateRangeFilter) {
    const parameter = {};

    this.tableColumns.forEach((c) => {
      if (c.valueField === column) {
        if (c.dateFilter) c.dateFilter = filter as IDateRangeFilter;
        if (c.textFilter) c.textFilter.filter = filter as string;
      }
      if (c.dateFilter) parameter[c.valueField] = c.dateFilter;
      if (c.textFilter) parameter[c.valueField] = c.textFilter.filter;
    });
    return parameter;
  }

  private getTableColumnByFieldId(fieldId: string) {
    return this.tableColumns.find((tableColumn) => tableColumn.valueField === fieldId);
  }

  public async onSearch(keyword: string | null) {
    this.setSearchKeyword(keyword);
    runInAction(() => {
      this.pagingMetaData = { pageNumber: 1, pageCount: 1 } as IPageMetaData;
    });
    await this.searchSupersedeFiles();
  }

  private setSearchKeyword(keyword: string | null) {
    runInAction(() => {
      this.searchKeyword = keyword;
    });
  }

  public cleanup() {
    runInAction(() => {
      this.pagingMetaData = { pageNumber: 1, pageCount: 1 } as IPageMetaData;
      this.supersedeFileContainers = [];
      this.selectedDuplicateFileContainers = [];
      this.selectedDuplicateFileContainerId = 0;
      this.currentPage = 1;
      this.searchKeyword = null;
    });
    this.clearFilterOptions();
  }

  private getTableColumn(label: string) {
    return this.tableColumns.find((tableColumn) => tableColumn.label === label);
  }

  public setPagingMetaData(res: IPagedResponse<ISupersedeFileContainerBase>) {
    const paginatedListObject = new PagingArray(
      res.pageSize,
      res.pageNumber,
      res.totalCount,
      res.pageCount === 0 ? 1 : res.pageCount
    );
    runInAction(() => {
      this.pagingMetaData = paginatedListObject.getMetaData();
    });
  }

  public setSelectedFiles(selected: ISupersedeFileContainerModel[]) {
    runInAction(() => {
      this.selectedFileContainers = selected;
    });
  }

  public setSelectedDuplicateFileContainerId(selected: number) {
    runInAction(() => {
      this.selectedDuplicateFileContainerId = selected;
    });
  }

  public setCurrentFile() {
    runInAction(() => {
      this.currentFileContainer = this.selectedFileContainers[this.currentPage - 1];
    });
  }

  public setSelectedDuplicateFileContainers(files: ISelectedDuplicateFileContainer[]) {
    runInAction(() => {
      this.selectedDuplicateFileContainers = files;
    });
  }

  public setSelectedSupersedeFiles(selectedFileContainers: ISupersedeFileContainerModel[]) {
    runInAction(() => {
      this.selectedFileContainers = selectedFileContainers;
    });
  }

  public setOpenReviewSupersede(open: boolean) {
    runInAction(() => {
      this.openReviewSupersede = open;
    });
  }

  public setSelectedForAllFilesToCopy(selected: boolean) {
    runInAction(() => {
      this.selectedDuplicateFileContainers = this.selectedDuplicateFileContainers.map((m) => {
        return {
          ...m,
          selectedDuplicatedFilesToCopy: m.selectedDuplicatedFilesToCopy.map((s) => {
            return {
              ...s,
              isSelected: this.selectedDuplicateFileContainerId === m.fileContainerId ? selected : s.isSelected,
            };
          }),
        };
      });
    });
  }

  public allFilesSelectedToCopy(): boolean {
    return this.selectedDuplicateFileContainers
      .filter((f) => f.fileContainerId === this.selectedDuplicateFileContainerId)
      .flatMap((f) => f.selectedDuplicatedFilesToCopy)
      .every((e) => e.isSelected);
  }

  public setSelectedFileToCopy(fileId: number, selected: boolean) {
    runInAction(() => {
      this.selectedDuplicateFileContainers = this.selectedDuplicateFileContainers.map((m) => {
        return {
          ...m,
          selectedDuplicatedFilesToCopy: m.selectedDuplicatedFilesToCopy.map((s) => {
            return {
              ...s,
              isSelected:
                this.selectedDuplicateFileContainerId === m.fileContainerId && s.fileId === fileId
                  ? selected
                  : s.isSelected,
            };
          }),
        };
      });
    });
  }
}

export default new SupersedeFileStore();
