import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/FileTemplatesUploadModal.module.scss';
import CentreOnPage from '../../../../shared/CentreOnPage';
import Overlay from '../../../../shared/Overlay';
import Modal from '../../../../shared/Modal';
import CloseButton from '../../../../shared/CloseButton';
import ModalActions from '../../../../shared/ModalActions';
import SecondaryButton from '../../../../shared/SecondaryButton';
import PrimaryButton from '../../../../shared/PrimaryButton';
import FileDropZone from '../../../../shared/FileDropZone';
import FileTemplatesUploadStore from './FileTemplatesUploadStore';
import LayoutStore from '../../../../layout/LayoutStore';
import ProgressBar from '../../../../shared/ProgressBar';
import Icon from '../../../../shared/Icon';
import { FormInput } from '@aurecon-creative-technologies/styleguide';
import { maxTemplateFileNameLength } from '../FileTemplatesSettings';
import PrimaryIconButton from '../../../../shared/PrimaryIconButton';

interface IFileTemplatesUploadModalProps {
  closeModal: (uploadSucceeded: boolean, errorMessage?: string) => void;
  checkTemplateFileName: (templateFileName?: string) => string;
  loadTemplates: () => void;
}

const FileTemplatesUploadModal: FC<IFileTemplatesUploadModalProps> = (props) => {
  const { closeModal, checkTemplateFileName, loadTemplates } = props;
  const [templateFileName, setTemplateFileName] = useState('');
  const [inputIsOnBlur, setInputIsOnBlur] = useState<boolean>(false);

  const closeTabEvent = (ev) => {
    if (!FileTemplatesUploadStore.selectedFile) return;

    ev.preventDefault();
    ev.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
    return ev.returnValue;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', closeTabEvent);
    return () => {
      window.removeEventListener('beforeunload', closeTabEvent);
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      await FileTemplatesUploadStore.initialUpload(templateFileName?.trim() ?? '');
    };

    init();
  }, [templateFileName]);

  const handleCancelButtonClick = () => {
    closeModal && closeModal(FileTemplatesUploadStore.showUploadSuccess);
    FileTemplatesUploadStore.clear();
  };

  const onUploadButtonClick = async () => {
    const templateName = FileTemplatesUploadStore.templateFileName;
    if (!templateName) return;

    if (
      (await FileTemplatesUploadStore.checkTemplateFileNamesDuplicated([{ id: null, templateName }]))?.some(
        (t) => t.duplicated
      )
    ) {
      loadTemplates();
      return;
    }

    await FileTemplatesUploadStore.handleUploadFileTemplates();

    if (FileTemplatesUploadStore.filesFailedToUpload.length)
      LayoutStore.displayToast('error', `Template cannot be uploaded. Please try again later.`);
    else {
      LayoutStore.displayToast('success', `Template has been successfully uploaded.`);
    }
    handleCancelButtonClick();
  };

  const handleOnDropzoneChange = (acceptedFiles) => {
    FileTemplatesUploadStore.addFiles(acceptedFiles);
  };

  return (
    <>
      <CentreOnPage>
        <Overlay />
        <Modal className={Style.container}>
          <CloseButton onClick={handleCancelButtonClick} disabled={false} />
          <div>
            <p className={Style.header}>Upload Template</p>
            {FileTemplatesUploadStore.selectedFile ? (
              <div className={Style.wrapFileName}>
                <span>{FileTemplatesUploadStore.selectedFile.name}</span>
                <PrimaryIconButton
                  icon="close"
                  size="medium"
                  className={Style.closeIcon}
                  onClick={() => FileTemplatesUploadStore.removeFile()}
                />
              </div>
            ) : (
              <div className={Style.uploadForm}>
                <FileDropZone
                  className={Style.dropzone}
                  label={
                    <div>
                      <Icon className={Style.noteAddIcon} name={'note_add'} />

                      <p>
                        Drag & drop or click to <span className={Style.highLight}>browse</span> for files
                      </p>
                      <p>File supported: .docx, .xlsx, .pptx</p>
                      <p>Maximum size: 20MB</p>
                    </div>
                  }
                  multiple={false}
                  loading={FileTemplatesUploadStore.isProcessing}
                  onDropzoneChange={(acceptedFiles) => handleOnDropzoneChange(acceptedFiles[0])}
                />
                {(FileTemplatesUploadStore.filesSizeExceededTheLimit ||
                  FileTemplatesUploadStore.isSelectedInvalidExtension) && (
                  <p className={Style.errorSummary}>
                    {FileTemplatesUploadStore.isSelectedInvalidExtension
                      ? 'This files type is not supported, please upload a supported file type (.docx, .xlsx, .pptx)'
                      : 'File is larger than the maximum size supported'}
                  </p>
                )}
              </div>
            )}
            <div className={Style.wrapTemplateName}>
              <FormInput
                required
                label="Template File Name"
                value={templateFileName}
                placeholder="Enter template name..."
                error={
                  inputIsOnBlur || FileTemplatesUploadStore.templateFileName
                    ? checkTemplateFileName(FileTemplatesUploadStore.templateFileName)
                    : ''
                }
                onChange={(value) => {
                  if (value.length > maxTemplateFileNameLength) return;
                  setTemplateFileName(value);
                }}
                onBlur={() => setInputIsOnBlur(true)}
              />
              <span className={Style.charactersLeft}>
                {maxTemplateFileNameLength - templateFileName?.length ?? 0} characters left
              </span>
            </div>
          </div>
          <ModalActions>
            <SecondaryButton onClick={handleCancelButtonClick}>Cancel</SecondaryButton>
            <PrimaryButton
              disabled={
                !FileTemplatesUploadStore.selectedFile ||
                !FileTemplatesUploadStore.templateFileName ||
                !!checkTemplateFileName(FileTemplatesUploadStore.templateFileName)
              }
              onClick={() => onUploadButtonClick()}>
              Done
            </PrimaryButton>
          </ModalActions>
        </Modal>
      </CentreOnPage>

      {FileTemplatesUploadStore.showProgressBar && (
        <ProgressBar
          completed={FileTemplatesUploadStore.percentageUploaded}
          uploadSuccess={FileTemplatesUploadStore.showUploadSuccess}
          uploadFailed={FileTemplatesUploadStore.showUploadFailed}
          listOfFailedFiles={FileTemplatesUploadStore.filesFailedToUpload || []}
          onClose={handleCancelButtonClick}
        />
      )}
    </>
  );
};

export default observer(FileTemplatesUploadModal);
