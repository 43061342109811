import React, { FC, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import BaseMapToggleWidget from './widgets/BaseMapToggleWidget';
import HomeWidget from './widgets/HomeWidget';
import ZoomWidget from './widgets/ZoomWidget';
import CompassWidget from './widgets/CompassWidget';
import FullScreenWidget from './widgets/FullScreenWidget';
import LegendWidget from './widgets/LegendWidget';
import VisualisationStore from './VisualisationStore';
import esriConfig from '@arcgis/core/config';
import WebMap from '@arcgis/core/WebMap';
import MapView from '@arcgis/core/views/MapView';
import Style from './styles/Visualisation.module.scss';
import LayerTreeWidget from './widgets/LayerTreeWidget';
import { BaseMaps } from '../../common/constants/BaseMaps';

const WebmapViewer: FC = () => {
  const mapViewRef = useRef(null);

  useEffect(() => {
    if (!mapViewRef.current) return;

    esriConfig.apiKey = VisualisationStore.arcGisApiKey!;

    const map = new WebMap({
      portalItem: {
        id: VisualisationStore.projectArcGisSettings.webSceneId!,
      },
      basemap: VisualisationStore.projectArcGisSettings.baseMap ?? BaseMaps.DEFAULT,
    });

    const mapView = new MapView({
      container: mapViewRef.current,
      map: map,
      ui: {
        components: ['attribution'],
      },
    });

    VisualisationStore.setMapView(mapView);
  }, []);

  return (
    <div className={Style.mapViewContainer}>
      <div className={Style.mapView} ref={mapViewRef}>
        <>
          <div className={Style.rightTopWidgets}>
            <FullScreenWidget />
            <CompassWidget />
            <BaseMapToggleWidget />
            <HomeWidget />
            <ZoomWidget />
          </div>
          <LegendWidget />
          <div className={Style.leftTopWidgets}>
            <LayerTreeWidget />
          </div>
        </>
      </div>
    </div>
  );
};

export default observer(WebmapViewer);
