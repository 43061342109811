import { flatMap } from 'lodash';
import { computed, makeAutoObservable, runInAction } from 'mobx';
import { getClientInfo, IClient } from '../api/authenticated/config/getClientInfo';
import { getAppSettings, IAppConfiguration } from '../api/unauthenticated/config/getAppSettings';
import { ClientUserRole } from '../common/enums/ClientUserRole';
import { Pages } from '../common/constants/Pages';

export interface IProjectSetting {
  displayApps: boolean;
  displayFiles: boolean;
  displayTasks: boolean;
  displayVisualisation: boolean;
  displayTransmittals: boolean;
}
class AppStore {
  constructor() {
    makeAutoObservable(this, { projects: computed }, { autoBind: true });
  }

  public settings?: IAppConfiguration;
  public hasInit = false;
  public client?: IClient;
  public hasClientInfoError = false;
  public disableNavigation = false;
  public programmeId?: number;
  public projectNumber?: string;
  public deliveryTeamId?: number;
  public deliveryTeamCode?: string;
  public taskTeamId?: number;
  public taskTeamCode?: string;

  public projectSettings: IProjectSetting = {
    displayApps: false,
    displayFiles: false,
    displayTasks: false,
    displayVisualisation: false,
    displayTransmittals: false,
  };

  public loadSettings() {
    return getAppSettings().then((settings) => {
      runInAction(() => {
        this.settings = settings;
      });
      return settings;
    });
  }

  public init() {
    getClientInfo()
      .then((res) => {
        runInAction(() => {
          this.client = res;
          this.hasInit = true;
          this.setDefaultProjectSelected();
        });
      })
      .catch(() => {
        runInAction(() => {
          this.hasClientInfoError = true;
        });
      });
  }

  private setDefaultProjectSelected() {
    const firstProject = this.findSelectedDefault();

    if (!firstProject) return;

    this.projectNumber = firstProject.projectNumber;
    this.projectSettings.displayApps = firstProject.displayApps;
    this.projectSettings.displayFiles = firstProject.displayFiles;
    this.projectSettings.displayTasks = firstProject.displayTasks;
    this.projectSettings.displayVisualisation = firstProject.displayVisualisation;
    this.projectSettings.displayTransmittals = firstProject.displayTransmittals;
  }

  private findSelectedDefault() {
    if (this.projectNumber) {
      return this.projects.find((x) => x.projectNumber === this.projectNumber);
    }
    return this.projects.find(
      (x) => x.displayApps || x.displayFiles || x.displayTasks || x.displayVisualisation || x.displayTransmittals
    );
  }

  public async forceRefresh() {
    const res = await getClientInfo();
    runInAction(() => {
      this.client = res;
      this.setDefaultProjectSelected();
    });
  }

  public get projectAdminister() {
    return flatMap(this.client?.programmes, (programme) => programme.projects).some(
      (e) => e.projectNumber === this.projectNumber && e.canAdminister
    );
  }

  public get programmes() {
    return flatMap(this.client?.programmes);
  }

  public get projects() {
    return flatMap(this.client?.programmes, (programme) => programme.projects);
  }

  public get administratorProjects() {
    return flatMap(this.client?.programmes, (programme) => programme.projects).filter((p) => p.canAdminister);
  }

  public get isProjectExternalUser() {
    return flatMap(this.client?.programmes, (programme) => programme.projects).some(
      (e) => e.projectNumber === this.projectNumber && e.isProjectExternalUser
    );
  }

  public get isSystemAdmin() {
    return this.client?.user.clientUserRole === ClientUserRole.SystemAdmin;
  }

  public get isTemporaryAccess() {
    if (!this.client) return false;

    return this.client?.programmes
      .flatMap((f) => f.projects)
      .flatMap((f) => f.taskTeams)
      .some((f) => f.isTemporaryAccessible && f.id === this.taskTeamId);
  }

  public get applyApims() {
    return flatMap(this.client?.programmes, (programme) => programme.projects).some(
      (e) => e.projectNumber === this.projectNumber && e.applyApims
    );
  }

  public setDisableNavigation(disable: boolean) {
    runInAction(() => {
      this.disableNavigation = disable;
    });
  }

  public setProgrammeId(programmeId?: number) {
    runInAction(() => {
      this.programmeId = programmeId;
    });
  }

  public setProjectNumber(projectNumber?: string) {
    runInAction(() => {
      this.projectNumber = projectNumber;
    });
  }

  public setDeliveryTeamId(deliveryTeamId?: number) {
    runInAction(() => {
      this.deliveryTeamId = deliveryTeamId;
    });
  }

  public setDeliveryTeamCode(deliveryTeamCode?: string) {
    runInAction(() => {
      this.deliveryTeamCode = deliveryTeamCode;
    });
  }

  public setTaskTeamId(taskTeamId?: number) {
    runInAction(() => {
      this.taskTeamId = taskTeamId;
    });
  }

  public setTaskTeamCode(taskTeamCode?: string) {
    runInAction(() => {
      this.taskTeamCode = taskTeamCode;
    });
  }

  public navigateToNextFeature(route: string) {
    const nextRouteState = this.getNextRouteState();
    if (this.isProjectExternalUser) window.location.href = `#${nextRouteState}`;

    const disabledLocationPath = this.getLocationPathState(route);

    if (disabledLocationPath) {
      if (nextRouteState) {
        window.location.href = `#${nextRouteState}`;
      }
    }
  }

  public setProjectSettings(
    displayApps: boolean,
    displayFiles: boolean,
    displayTasks: boolean,
    displayVisualisation: boolean,
    displayTransmittals: boolean
  ) {
    runInAction(() => {
      this.projectSettings.displayApps = displayApps;
      this.projectSettings.displayFiles = displayFiles;
      this.projectSettings.displayTasks = displayTasks;
      this.projectSettings.displayVisualisation = displayVisualisation;
      this.projectSettings.displayTransmittals = displayTransmittals;
    });
  }

  public getNextRouteState() {
    if (this.isProjectExternalUser) return Pages.Transmittals.Route;
    const routePages: { page: string; setting: boolean }[] = [
      { page: Pages.Files.Route, setting: this.projectSettings.displayFiles },
      { page: Pages.Tasks.Route, setting: this.projectSettings.displayTasks },
      { page: Pages.Visualisation.Route, setting: this.projectSettings.displayVisualisation },
      { page: Pages.Transmittals.Route, setting: this.projectSettings.displayTransmittals },
    ];
    const selectPage = routePages.find((x) => x.setting)?.page;
    return selectPage;
  }
  public getLocationPathState(pathName: string) {
    let disable = false;
    switch (pathName) {
      case Pages.DisplayApps.Route:
        disable = !this.projectSettings.displayApps;
        break;
      case Pages.Files.Route:
        disable = !this.projectSettings.displayFiles;
        break;
      case Pages.Tasks.Route:
        disable = !this.projectSettings.displayTasks;
        break;
      case Pages.Visualisation.Route:
        disable = !this.projectSettings.displayVisualisation;
        break;
      case Pages.Transmittals.Route:
        disable = !this.projectSettings.displayTransmittals;
        break;
      default:
        break;
    }
    return disable;
  }
  public get selectedProjectNumber() {
    return this.projectNumber;
  }

  public get selectedProgrammeId() {
    return this.programmeId;
  }

  public get selectedDeliveryTeamId() {
    return this.deliveryTeamId;
  }

  public get selectedDeliveryTeamCode() {
    return this.deliveryTeamCode;
  }

  public get selectedTaskTeamId() {
    return this.taskTeamId;
  }

  public get selectedTaskTeamCode() {
    return this.taskTeamCode;
  }

  public get clientEnableSupportingFiles() {
    return this.client?.enableSupportingFiles ?? false;
  }

  public get clientAllowEnableDisableProjectSupportingFiles() {
    return this.client?.allowEnableDisableProjectSupportingFiles ?? false;
  }

  public get clientEnableSplashPage() {
    return this.client?.enableSplashPage ?? false;
  }

  public get clientCanSyncAADUser() {
    return this.client?.canSyncAADUser ?? false;
  }
}

export default new AppStore();
