import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import TreeView, { NodeAction, NodeData } from '../../shared/TreeView';
import Style from '../../../styles/components/settings/projectManagement/ProjectManagement.module.scss';
import { Icon } from '@aurecon-creative-technologies/styleguide';
import ProjectManagementStore from '../../../stores/settings/projectManagement/ProjectManagementStore';
import AddProgrammeModal from './AddProgrammeModal';
import { NodeType, NodeTypes } from '../teamManagement/TeamManagement';
import ProjectDetails from './ProjectDetails';
import AddProject from './AddProject';
import UpdateProject from './UpdateProject';
import { ClientUserRole } from '../../../common/enums/ClientUserRole';
import AppStore from '../../../stores/AppStore';
import ProgrammeDetails from './ProgrammeDetails';

export const NodeActions = {
  ADD_PROGRAMME: 'ADD_PROGRAMME',
  ADD_PROJECT: 'ADD_PROJECT',
};

const ProjectManagement: FC = () => {
  const [treeAction, setTreeAction] = useState<{ action: NodeAction; value: NodeData<NodeTypes> } | undefined>();
  const [showNoticed, setShowNoticed] = useState<boolean>(true);
  const [nodeSelectedKey, setNodeSelectedKey] = useState<string | undefined>();
  const [updateProject, setUpdateProject] = useState<boolean>(false);
  const [nodeSelected, setNodeSelected] = useState<NodeData<NodeTypes> | undefined>(undefined);

  useEffect(() => {
    const fetchDataAsync = async () => {
      await ProjectManagementStore.fetchProjectData();
    };
    fetchDataAsync();
  }, []);

  const handleTreeViewAction = (act, value) => {
    if (act.action !== NodeActions.ADD_PROGRAMME) {
      setNodeSelected(undefined);
    }
    setUpdateProject(false);
    setTreeAction({ action: act, value: value });
    if (value) setNodeSelectedKey(value.key);
  };

  const handleNodeSelected = (node) => {
    setTreeAction(undefined);
    setUpdateProject(false);
    setNodeSelectedKey(node.key);
    setNodeSelected(node);
  };

  const setSelectedProgramme = (programmeName) => {
    const node = ProjectManagementStore.buildNodeSelectedProgramme(programmeName);
    setNodeSelected(node);
    setNodeSelectedKey(node?.key);
    setTreeAction(undefined);
  };

  const renderNoticed = () => {
    if (!showNoticed || treeAction || nodeSelected) return;

    return (
      showNoticed &&
      !treeAction &&
      !nodeSelected && (
        <div className={Style.noticed}>
          <div className={Style.header}>
            Welcome
            <Icon className={Style.closedBtn} type="closed" onClick={() => setShowNoticed(false)} />
          </div>
          <p>To setup your first programme, click on the &#34;Create New Programme&#34; from the list panel.</p>
        </div>
      )
    );
  };

  return (
    <div className={Style.pageWrapper}>
      <div className={Style.left}>
        <TreeView
          treeName="Project List"
          treeData={ProjectManagementStore.treeData}
          onSelect={handleNodeSelected}
          onAction={(action, value) => handleTreeViewAction(action, value)}
          rootClassName={Style.teamManagementTree}
          selectedNodeKey={nodeSelectedKey}
          rootAction={
            AppStore.client?.user.clientUserRole === ClientUserRole.SystemAdmin
              ? {
                  action: NodeActions.ADD_PROGRAMME,
                  actionTitle: 'Create new programme',
                }
              : undefined
          }
          showSearchFilter
        />
      </div>
      <div className={Style.right}>
        {renderNoticed()}
        {treeAction && treeAction.action.action === NodeActions.ADD_PROJECT && (
          <AddProject
            programmeId={Number(treeAction.value.id)}
            programmeName={treeAction.value.title}
            onSave={(projectNumber) => {
              if (projectNumber) {
                const node = ProjectManagementStore.buildNodeSelected(projectNumber);
                setNodeSelectedKey(node?.key);
                setTreeAction(undefined);
                setNodeSelected(node);
              }
            }}
            onCancel={(programmeName) => setSelectedProgramme(programmeName)}
          />
        )}
        {nodeSelected && nodeSelected.type === NodeType.Project && !updateProject && (
          <ProjectDetails
            projectNumber={nodeSelected.id.toString()}
            onUpdate={() => setUpdateProject(true)}
            onDelete={(programmeName) => setSelectedProgramme(programmeName)}></ProjectDetails>
        )}
        {nodeSelected && nodeSelected.type === NodeType.Project && updateProject && (
          <UpdateProject
            projectNumber={nodeSelected.id.toString()}
            onSave={(projectNumber) => {
              if (projectNumber) {
                const node = ProjectManagementStore.buildNodeSelected(projectNumber);
                setNodeSelectedKey(node?.key);
                setTreeAction(undefined);
                setNodeSelected(node);
                setUpdateProject(false);
              }
            }}
            onCancel={() => setUpdateProject(false)}
          />
        )}
        {nodeSelected && nodeSelected.type === NodeType.Programme && (
          <ProgrammeDetails
            programmeId={nodeSelected.id}
            programmeName={nodeSelected.title.toString()}
            onUpdate={(programmeName) => {
              if (programmeName) {
                const node = ProjectManagementStore.buildNodeSelectedProgramme(programmeName);
                setNodeSelected(node);
                setNodeSelectedKey(node?.key);
              }
            }}
            onDelete={() => {
              setTreeAction(undefined);
              setNodeSelectedKey(undefined);
              setNodeSelected(undefined);
            }}
          />
        )}
      </div>
      {treeAction && treeAction.action.action === NodeActions.ADD_PROGRAMME && (
        <AddProgrammeModal
          onClose={(programmeName) => {
            if (programmeName) {
              const node = ProjectManagementStore.buildNodeSelectedProgramme(programmeName);
              setNodeSelected(node);
              setNodeSelectedKey(node?.key);
            }
            setTreeAction(undefined);
          }}
        />
      )}
    </div>
  );
};

export default observer(ProjectManagement);
