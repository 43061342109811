import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Text } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/FileUpload.module.scss';
import FileDropZone from '../../shared/FileDropZone';
import UploadStore from './UploadStore';
import FileOverLimitErrorModal from './FileOverLimitErrorModal';
import FilesStore from '../FilesStore';
import { FileContainerState } from '../../../common/enums/FileContainerState';
import UploadVisibleToTeams from './UploadVisibleToTeams';
interface IUploadFilesFormProps {
  multiple: boolean;
  disabled?: boolean;
  acceptedExtension?: string;
}
const UploadFilesForm: FC<IUploadFilesFormProps> = (props) => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const handleOnDropzoneChange = async (acceptedFiles) => {
    UploadStore.setShowMetadataWarning(props.disabled ?? false);
    if (!props.disabled) {
      await UploadStore.addFiles(acceptedFiles, props.multiple, props.acceptedExtension);
    }
    if (UploadStore.isOverLimit) setShowErrorModal(true);
  };

  return (
    <>
      <div className={Style.fromWrapper}>
        <div className={Style.cardHeader}>
          <Text type="h3">File Upload</Text>
          {FilesStore.fileContainerStateId === FileContainerState.Wip && <UploadVisibleToTeams />}
        </div>
        <div className={Style.uploadForm}>
          <FileDropZone
            loading={UploadStore.isProcessing}
            multiple={props.multiple}
            disabled={props.disabled}
            className={Style.wrapperFileDropZone}
            onDropzoneChange={(acceptedFiles) => handleOnDropzoneChange(acceptedFiles)}
          />
          {UploadStore.isSelectedInvalidExtension === true && (
            <p className={Style.validationError}>
              Please choose files that have the same extension as the original [
              <strong>*.{props.acceptedExtension}</strong>].
              {UploadStore.isSelectedInvalidExtension}
            </p>
          )}
        </div>
      </div>
      {showErrorModal && <FileOverLimitErrorModal closeModal={() => setShowErrorModal(false)} />}
    </>
  );
};

export default observer(UploadFilesForm);
