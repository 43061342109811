import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { TaskStatus } from '../../common/enums/TaskStatus';
import { classNames } from '../../utils/miscUtils';
import Dot from './Dot';
import Style from './styles/TaskStatusIndicator.module.scss';
import { TaskStatusText } from '../../common/constants/TaskStatusText';

export interface ITaskStatusIndicatorProps {
  taskStatusId: TaskStatus;
  displayTaskStatus: string;
  className?: string;
}

const TaskStatusIndicator: FC<ITaskStatusIndicatorProps> = ({ taskStatusId, displayTaskStatus, className }) => {
  return (
    <span className={classNames(className)}>
      <Dot
        className={classNames(
          [taskStatusId === TaskStatus.NotStarted, Style.notStarted],
          [taskStatusId === TaskStatus.AwaitingReview, Style.awaitingReview],
          [taskStatusId === TaskStatus.InProgress, Style.inProgress],
          [taskStatusId === TaskStatus.Approved, Style.approved],
          [taskStatusId === TaskStatus.Rejected, Style.rejected],
          [displayTaskStatus === TaskStatusText[TaskStatus.Overdue], Style.overdue]
        )}
      />
      <span>{displayTaskStatus ?? TaskStatusText[taskStatusId]}</span>
    </span>
  );
};

export default observer(TaskStatusIndicator);
