import { AxiosError } from 'axios';
import { computed, makeAutoObservable, runInAction } from 'mobx';
import { IFileContainer } from '../../../api/authenticated/cms/FileContainerModel';
import { IAcceptor, IAddTaskData, IReviewer, ISuitability } from '../../../api/authenticated/tasks/getAddData';
import FilesStore from '../FilesStore';
import LayoutStore from '../../layout/LayoutStore';
import { IUser } from '../../../api/authenticated/um/interfaces/user.interface';
import { ReviewState } from '../../tasks/Types';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';
import AppStore from '../../../stores/AppStore';
import { SortTypes } from '../../../common/enums/SortType';
import { sort } from '../../../utils/sortHelper';
import { DeliveryTeamAccessPermissions } from '../../../common/enums/DeliveryTeamSharePermission';
import { getSharedAndPublishedDeliveryTeamAccess } from '../../../api/authenticated/um/getSharedAndPublishedDeliveryTeamAccess';
import { addDays, isAfter, isToday } from 'date-fns';
import { IDateInputDates } from '@aurecon-creative-technologies/styleguide';
import { ContentSelection } from '../ContentSelection';
import { uniqBy } from 'lodash';
import { calculateChunks, fileSizeUnits } from '../../../utils/miscUtils';
import getFileExtension from '../../../utils/fileUtils';
import { IAddFileRequest, IAddFileResponse } from '../../../api/authenticated/tasks/addTaskAttachmentFiles';
import { IAddTaskResponse } from '../../../api/authenticated/tasks/addTask';
import { FileItem, IUploadFile } from '../../../common/interfaces/fileUpload';
import { uploadTaskAttachmentFile } from '../../../api/authenticated/tasks/uploadTaskAttachmentFile';

export interface ISaveReviewData {
  fileContainers: IFileContainer[];
  reviewers: IReviewer[];
  suitability: ISuitability | null;
  selectedMessage: string;
  isSingleApproverRequired: boolean;
  acceptors: IAcceptor[];
  isRequiredSingleAcceptorUser: boolean;
  temporaryAccessExpiredOn: Date | null;
  dueDate: Date | null;
  attachmentFiles: IAddFileRequest[];
}

export interface IReviewStoreParams {
  closeModal: () => void;
  title: string;
  description: string;
  approversLabel: string;
  approversSearchLabel: string;
  allApproversLabel?: string;
  showSuitability?: boolean;
  showAcceptorUsers?: boolean;
  isSingleApproverRequired?: boolean;
  showAccessibleDeliveryTeams?: boolean;
  allowSelectAnotherProjectTeam?: boolean;
  temporaryAccessExpiredOn?: Date | null;
  state: ReviewState;
  getData: () => Promise<IAddTaskData>;
  saveReview: (data: ISaveReviewData) => Promise<IAddTaskResponse | null | undefined>;
}
export interface SupportFileItem extends File {
  duplicated: boolean;
}
export class ReviewStore {
  constructor(params: IReviewStoreParams) {
    makeAutoObservable(this, { canSave: computed }, { autoBind: true });

    this.closeModal = params.closeModal;
    this.title = params.title;
    this.description = params.description;
    this.approversLabel = params.approversLabel;
    this.approversSearchLabel = params.approversSearchLabel;
    this.allApproversLabel = params.allApproversLabel;
    this.showSuitability = params.showSuitability ?? false;
    this.isSingleApproverRequired =
      params.isSingleApproverRequired === undefined ? false : params.isSingleApproverRequired;
    this.getData = params.getData;
    this.saveReview = params.saveReview;
    this.selectedFiles = Object.values(FilesStore.selectedFiles);
    this.state = params.state;
    this.showAcceptorUsers = params.showAcceptorUsers ?? false;
    this.showAccessibleDeliveryTeams = params.title === 'Share' || params.title === 'Publish';
    this.allowSelectAnotherProjectTeam = !!params.allowSelectAnotherProjectTeam;

    this.loadData();
  }

  public closeModal: () => void;
  public title: string;
  public description: string;
  public approversLabel: string;
  public approversSearchLabel: string;
  public allApproversLabel?: string;
  public showSuitability: boolean;
  public showAcceptorUsers: boolean;
  public getData: () => Promise<IAddTaskData>;
  public saveReview: (data: ISaveReviewData) => Promise<IAddTaskResponse | null | undefined>;
  public isLoadingData = false;
  public isSaving = false;
  public reviewers: IReviewer[] = [];
  public suitabilities: ISuitability[] = [];
  public selectedFiles: IFileContainer[] = [];
  public selectedReviewers: IReviewer[] = [];
  public selectedSuitability: ISuitability | null = null;
  public selectedMessage = '';
  public isSingleApproverRequired = false;
  public errorCode: number | null = null;
  public errorMessage: string | null = null;
  public state: ReviewState;
  public acceptors: IAcceptor[] = [];
  public selectedAcceptorUsers: IAcceptor[] = [];
  public isRequiredAllAcceptor = false;
  public isRequireSingleAcceptorConfig = true;
  public showAccessibleDeliveryTeams: boolean;
  public allowSelectAnotherProjectTeam = false;
  public temporaryAccessExpiredOn: Date | null = null;
  public temporaryAccessExpiredOnErrorMessage = '';
  public taskDuedate: Date | null = null;
  public taskDuedateErrorMessage = '';
  public defaultTemporaryAccessExpiredOnNextDays = AppStore?.client?.defaultTemporaryAccessExpiredOnNextDays ?? 90;
  public accessibleDeliveryTeams: {
    accessibleDeliveryTeamId: number | null;
    accessibleDeliveryTeamTitle: string | null;
    accessibleDeliveryTeamCode: string | null;
    IsCurrentDeliveryTeam: boolean;
  }[] = [];
  public isProcessing = false;
  public uploadSupportFiles: SupportFileItem[] = [];
  private fileChunks: { [filename: string]: IUploadFile } = {};
  // Limit 1GB
  public limitBytes = fileSizeUnits.GB;
  public openUploadError = false;
  public duplicatedFiles: File[] = [];
  public missingExtensionFiles: File[] = [];
  public missingFileExtension = false;
  public filesSizeExceededTheLimit = false;
  public isDuplicatedFiles = false;
  public showProgressBar = false;
  public showUploadSuccess = false;
  public showUploadFailed = false;
  public totalChunks = 0;
  public totalUploadedChunks = 0;
  public percentageUploaded = 0;
  private chunkSize = 3000000;

  private async loadData() {
    runInAction(() => {
      this.isLoadingData = true;
      this.reviewers = [];
      this.suitabilities = [];
      this.accessibleDeliveryTeams = [];
    });
    try {
      const { reviewers, suitabilities, acceptors, isRequireSingleAcceptorConfig } = await this.getData();
      runInAction(() => {
        this.reviewers = reviewers;
        this.suitabilities = suitabilities;
        this.acceptors = acceptors;
        this.isRequireSingleAcceptorConfig = isRequireSingleAcceptorConfig;
        this.allowSelectAnotherProjectTeam = false;
        this.sortData(this.suitabilities);
        this.getAccessibleDeliveryTeams();
      });
    } finally {
      runInAction(() => {
        this.isLoadingData = false;
      });
    }
  }
  public sortData(suitabilities: ISuitability[]) {
    suitabilities.sort((a, b) => (a.code.toLowerCase() > b.code.toLowerCase() ? 1 : -1));
  }

  public getMatchedUsers(searchText: string) {
    let filterUsers = [...this.reviewers];

    if (!this.allowSelectAnotherProjectTeam) {
      filterUsers = [...filterUsers.filter((x) => x.accessible === undefined || x.accessible)];
    }
    return filterUsers
      .filter(
        (r) =>
          r.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
          r.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
      .map((r) => ({
        id: r.userId,
        name: r.name,
        email: r.email,
      }));
  }

  public toggleShowUserFromAnotherTeam(value: boolean) {
    runInAction(() => {
      this.allowSelectAnotherProjectTeam = value;
      if (value && !this.temporaryAccessExpiredOn) {
        this.setTemporaryAccessExpiredOn({
          startDate: addDays(new Date(), this.defaultTemporaryAccessExpiredOnNextDays),
          endDate: null,
        });
      }
    });
  }

  public setTemporaryAccessExpiredOn(value: IDateInputDates) {
    runInAction(() => {
      this.temporaryAccessExpiredOn = value.startDate;

      this.temporaryAccessExpiredOnErrorMessage =
        this.allowSelectAnotherProjectTeam && !value.startDate
          ? 'Access expired is a required field'
          : value.startDate && !this.isValidTemporaryAccessExpiration()
          ? 'Date must be greater than today.'
          : '';
    });
  }

  public get isSelectUserFromAnotherTeam() {
    const otherTeamUserIds = this.reviewers.filter((x) => !x.accessible);
    return this.selectedReviewers.some((x) => otherTeamUserIds.some((y) => y.userId === x.userId));
  }

  public getMatchedAcceptorUsers(searchText: string, showAllAppointingPartyAcceptors: boolean) {
    return this.acceptors
      ?.filter(
        (r) =>
          (showAllAppointingPartyAcceptors || (!showAllAppointingPartyAcceptors && r.isDeliveryTeamAcceptor)) &&
          (r.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
            r.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
      )
      .map((r) => ({
        id: r.userId,
        name: r.name,
        email: r.email,
      }));
  }

  public onSelectedAcceptorUsersUpdated(users: IUser[]) {
    if (this.isSaving) return;
    const acceptorUsers = this.acceptors.filter((at) => users.some((u) => u.id === at.userId));
    runInAction(() => {
      this.selectedAcceptorUsers = [...acceptorUsers];
    });
  }

  public onSelectedUsersUpdated(users: IUser[]) {
    if (this.isSaving) return;
    const reviewers: IReviewer[] = [];
    users.forEach((u) => {
      const reviewer = this.reviewers.find((r) => r.userId === u.id);
      if (reviewer) reviewers.push(reviewer);
    });
    runInAction(() => {
      this.selectedReviewers = reviewers;
    });
  }

  public removeSelectedFile(fileId: number) {
    if (this.isSaving) return;
    runInAction(() => {
      this.selectedFiles = this.selectedFiles.filter((f) => f.id !== fileId);
    });
    if (this.selectedFiles.length === 0) this.closeModal();
  }

  public setSelectedSuitability(id?: number) {
    if (this.isSaving) return;
    const suitability = this.suitabilities.find((s) => s.id === id) ?? null;
    runInAction(() => {
      this.selectedSuitability = suitability;
    });
  }

  public setSelectedMessage(message: string) {
    if (this.isSaving) return;
    runInAction(() => {
      this.selectedMessage = message;
    });
  }

  public setIsSingleApproverRequired(value: boolean) {
    if (this.isSaving) return;
    runInAction(() => {
      this.isSingleApproverRequired = value;
    });
  }

  public setIsRequiredAllAcceptorUser(value: boolean) {
    if (this.isSaving) return;
    runInAction(() => {
      this.isRequiredAllAcceptor = value;
    });
  }

  public async getAccessibleDeliveryTeams() {
    const isCollaborate = this.state === 'Collaborate';
    let selectedSection = this.title;
    if (!this.showAccessibleDeliveryTeams && isCollaborate) {
      this.showAccessibleDeliveryTeams =
        (FilesStore.selectedSection === ContentSelection.Shared ||
          FilesStore.selectedSection === ContentSelection.Published) &&
        isCollaborate;
      selectedSection =
        FilesStore.selectedSection === ContentSelection.Shared
          ? 'Share'
          : FilesStore.selectedSection === ContentSelection.Published
          ? 'Publish'
          : '';
    }

    if (!this.selectedFiles || !this.showAccessibleDeliveryTeams) return;

    const projectNumber = NavBarSelectorStore.selectedItem?.project.projectNumber ?? AppStore.projectNumber;
    const currentDeliveryTeamId = AppStore.selectedDeliveryTeamId
      ? AppStore.selectedDeliveryTeamId ?? AppStore.deliveryTeamId
      : null;

    if (!projectNumber || !currentDeliveryTeamId) return;

    const accessibleDeliveryTeamsList = await getSharedAndPublishedDeliveryTeamAccess(
      projectNumber,
      currentDeliveryTeamId
    );
    const deliveryTeamAccessPermission = this.getDeliveryTeamAccessPermissionType(selectedSection);
    this.accessibleDeliveryTeams = (
      deliveryTeamAccessPermission === DeliveryTeamAccessPermissions.CAN_ACCESS_SHARED_FILES
        ? accessibleDeliveryTeamsList.sharedDeliveryTeams
        : accessibleDeliveryTeamsList.publishedDeliveryTeams
    ).map((x) => {
      return {
        accessibleDeliveryTeamId: x.deliveryTeamId,
        accessibleDeliveryTeamTitle: x.deliveryTeamTitle,
        accessibleDeliveryTeamCode: x.deliveryTeamCode,
        IsCurrentDeliveryTeam: x.deliveryTeamId === currentDeliveryTeamId,
      };
    });
    runInAction(() => {
      this.accessibleDeliveryTeams = this.accessibleDeliveryTeams
        ? sort(this.accessibleDeliveryTeams, 'accessibleDeliveryTeamTitle', SortTypes.ASC)
        : [];
    });
  }

  public getDeliveryTeamAccessPermissionType(selectedSection: string) {
    switch (selectedSection) {
      case 'Share':
        return DeliveryTeamAccessPermissions.CAN_ACCESS_SHARED_FILES;
      case 'Publish':
        return DeliveryTeamAccessPermissions.CAN_ACCESS_PUBLISHED_FILE;
      default:
        return;
    }
  }

  public get canSave(): boolean {
    return (
      this.selectedFiles.length > 0 &&
      this.isReviewersAndSuitabilitySelected() &&
      (!this.showAcceptorUsers ||
        (this.acceptors.some((x) => x.isDeliveryTeamAcceptor) && this.selectedAcceptorUsers.length > 0) ||
        !this.acceptors.some((x) => x.isDeliveryTeamAcceptor)) &&
      this.isValidTemporaryAccessExpiration() &&
      this.isValidTaskDueDate()
    );
  }

  public isReviewersAndSuitabilitySelected() {
    return this.selectedReviewers.length > 0 && (!this.showSuitability || !!this.selectedSuitability);
  }

  private isValidTemporaryAccessExpiration() {
    if (!this.allowSelectAnotherProjectTeam) return true;
    if (!this.temporaryAccessExpiredOn) return false;

    return isToday(this.temporaryAccessExpiredOn) || isAfter(this.temporaryAccessExpiredOn, new Date());
  }

  private isValidTaskDueDate() {
    if (!this.taskDuedate) return true;

    return isToday(this.taskDuedate) || isAfter(this.taskDuedate, new Date());
  }

  public setError(error: AxiosError<string>) {
    runInAction(() => {
      this.errorCode = error?.response?.status ?? null;
      this.errorMessage = error?.response?.data ?? null;
    });
  }

  public clearError() {
    runInAction(() => {
      this.errorCode = null;
      this.errorMessage = null;
    });
  }

  public async save() {
    if (this.isSaving) return;
    runInAction(() => {
      this.isSaving = true;
    });
    this.clearError();
    try {
      if (this.uploadSupportFiles.length) {
        this.startUploadProcess();
        this.totalChunks = 0;
        this.uploadSupportFiles.forEach((file) => {
          this.setCalculateFileChunks(file);
          this.totalChunks += this.fileChunks[file.name].chunks.length;
        });
      }

      const saveReviewResult = await this.saveReview({
        fileContainers: this.selectedFiles,
        reviewers: this.selectedReviewers,
        suitability: this.selectedSuitability,
        selectedMessage: this.selectedMessage,
        isSingleApproverRequired: this.isSingleApproverRequired,
        acceptors: this.selectedAcceptorUsers,
        isRequiredSingleAcceptorUser: !this.isRequiredAllAcceptor,
        temporaryAccessExpiredOn: this.temporaryAccessExpiredOn,
        dueDate: this.taskDuedate,
        attachmentFiles: this.uploadSupportFiles.map((file) => {
          return {
            filename: file.name,
            totalFileSize: file.size,
            totalFileChunks: this.fileChunks[file.name].chunks.length,
          };
        }),
      });

      if (saveReviewResult && saveReviewResult.attachmentFiles.length) {
        await this.handleUploadSupportingFiles(
          saveReviewResult.taskId,
          saveReviewResult.projectNumber,
          saveReviewResult.attachmentFiles
        );
      }
      this.closeModal();
      LayoutStore.displayToast('success', 'The Workflow was successfully initiated.');
    } catch (err) {
      this.setError(err as AxiosError<string>);
    } finally {
      runInAction(() => {
        this.isSaving = false;
        FilesStore.loadFiles();
      });
    }
  }

  private startUploadProcess() {
    runInAction(() => {
      this.showProgressBar = true;
      this.fileChunks = {};
      this.showUploadSuccess = false;
      this.showUploadFailed = false;
    });
  }

  public async handleUploadSupportingFiles(taskId: number, projectNumber: string, attachmentFiles: IAddFileResponse[]) {
    await Promise.all(
      attachmentFiles.map(async (addedFile) => {
        return this.uploadFile(addedFile, taskId, projectNumber);
      })
    );

    runInAction(() => {
      this.showUploadSuccess = !this.filesFailedToUpload.length;
      this.showUploadFailed = !this.showUploadSuccess;
    });
  }

  public get filesFailedToUpload() {
    const failedFiles: string[] = [];
    Object.keys(this.fileChunks).forEach((filename) => {
      const uploadFile = this.fileChunks[filename];
      if (uploadFile.failed) failedFiles.push(filename);
    });
    return failedFiles;
  }

  private setCalculateFileChunks(file: FileItem) {
    runInAction(() => {
      this.fileChunks[file.name] = {
        numberOfChunksUploaded: 0,
        failed: false,
        chunks: calculateChunks(file.size, this.chunkSize),
      };
    });
  }

  private async uploadFile(addedFile: IAddFileResponse, taskId: number, projectNumber: string) {
    const file = this.uploadSupportFiles.find((f) => f.name === addedFile.filename);
    if (!file) return;
    try {
      await this.uploadChunks(projectNumber, file, this.fileChunks[file.name], addedFile.id, taskId);
    } catch (err) {
      runInAction(() => {
        this.fileChunks[file.name].failed = true;
      });
    }
  }

  private async uploadChunks(
    projectNumber: string,
    file: File,
    uploadFile: IUploadFile,
    attachmentId: number,
    taskId: number
  ) {
    for (const chunk of uploadFile.chunks) {
      const blob = file.slice(chunk.start, chunk.end);
      const isLast = chunk.index === uploadFile.chunks.length - 1;
      await uploadTaskAttachmentFile({
        blob: blob,
        projectNumber: projectNumber,
        taskId: taskId,
        attachmentFileId: attachmentId,
        index: chunk.index,
        offset: chunk.start,
        isLastChunk: isLast,
      });

      runInAction(() => {
        uploadFile.numberOfChunksUploaded++;
        this.totalUploadedChunks += 1;
        this.percentageUploaded = (this.totalUploadedChunks / this.totalChunks) * 100;
      });
    }
  }

  public setTaskDuedate(value: IDateInputDates) {
    runInAction(() => {
      this.taskDuedate = value.startDate;
      this.taskDuedateErrorMessage =
        value.startDate && !this.isValidTaskDueDate() ? 'Date must be greater than today.' : '';
    });
  }

  public addSupportingFiles(files: SupportFileItem[]) {
    runInAction(() => {
      this.isProcessing = true;
    });

    const exceedLimitation =
      this.sizeOfUploadFiles(this.uploadSupportFiles) + this.sizeOfUploadFiles(files) > this.limitBytes;

    const duplicatedFiles = files.filter((f) => this.uploadSupportFiles.some((nf) => nf.name === f.name));

    const missingExtensionFiles = files.filter((f) => !f.type && !getFileExtension(f.name));

    runInAction(() => {
      this.openUploadError = exceedLimitation || !!duplicatedFiles.length || !!missingExtensionFiles.length;
      this.duplicatedFiles = duplicatedFiles;
      this.missingExtensionFiles = missingExtensionFiles;
      this.missingFileExtension = !!missingExtensionFiles.length;
      this.filesSizeExceededTheLimit = exceedLimitation;
      this.isDuplicatedFiles = !!duplicatedFiles.length;

      this.uploadSupportFiles = uniqBy(
        [...this.uploadSupportFiles, ...files.filter((f) => !duplicatedFiles.some((df) => df.name === f.name))],
        'name'
      );

      this.uploadSupportFiles = this.uploadSupportFiles.filter(
        (f) => !missingExtensionFiles.some((df) => df.name === f.name)
      );
      this.isProcessing = false;
    });
  }
  public sizeOfUploadFiles(files: File[]) {
    return files.reduce((size, file) => size + file.size, 0);
  }

  public removeSupportFile(filename: string) {
    const index = this.uploadSupportFiles.findIndex((f) => f.name === filename);
    if (index > -1) {
      runInAction(() => {
        this.uploadSupportFiles.splice(index, 1);
      });
    }
  }

  public handleFileListChange(files: SupportFileItem[]) {
    runInAction(() => {
      this.uploadSupportFiles = [];
    });
    this.addSupportingFiles(files);
  }

  public setOpenUploadError(open: boolean) {
    this.openUploadError = open;
  }
}
