import { serverAxios } from '../api';
import { ITaskTeamUser } from './interfaces/user.interface';

export async function getTaskTeamUsers(
  projectNumber: string,
  taskTeamId: number,
  abortSignal?: AbortSignal
): Promise<ITaskTeamUser[]> {
  const result = await serverAxios.get<{ users: ITaskTeamUser[] }>('api/um/system/taskteamusers', {
    params: { projectNumber, taskTeamId },
    signal: abortSignal,
  });
  return result.data.users;
}
