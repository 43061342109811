import * as React from 'react';
import { observer } from 'mobx-react-lite';
import Icon from '../../shared/Icon';
import NavBarSelectorItemContainer from '../../shared/NavBarSelectorItemContainer';
import { ProjectItem } from './ItemType';
import Style from './styles/NavBarSelectorProject.module.scss';
import classNames from 'classnames';
import AppStore from '../../../stores/AppStore';

export interface INavBarSelectorProjectProps {
  item: ProjectItem;
}

const NavBarSelectorProject: React.FC<INavBarSelectorProjectProps> = ({ item }) => {
  return (
    <NavBarSelectorItemContainer
      className={classNames(Style.container, {
        [Style.disableProject]: !AppStore.isProjectExternalUser && !item.project.appointingPartyUser,
      })}>
      <Icon className={Style.icon} name="folder" />
      <span>{`${item.project.projectNumber} ${item.project.title}`}</span>
    </NavBarSelectorItemContainer>
  );
};

export default observer(NavBarSelectorProject);
