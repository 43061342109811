import React, { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import TreeView, { NodeAction, NodeData } from '../../shared/TreeView';
import Style from '../../../styles/components/settings/teamManagement/TeamManagement.module.scss';
import TeamManagementStore from '../../../stores/settings/teamManagement/TeamManagementStore';
import { FormInput, Grid, Icon } from '@aurecon-creative-technologies/styleguide';
import CreateNewTeamModal from './CreateNewTeamModal';
import { isAlphanumericUpper, isValidRegex } from '../../../utils/miscUtils';
import AppStore from '../../../stores/AppStore';
import DeliveryTeamDetails from './DeliveryTeamDetails';
import { IClientTeam } from '../../../api/authenticated/um/getClientTeams';
import { ITeamDetailsProps, TeamManagementAction } from '../../../common/constants/TeamManagementAction';
import AppointingPartyUserDetails from './AppointingPartyUserDetails';
import TaskTeamDetails from './TaskTeamDetails';
import ExternalUserDetails from './ExternalUserDetails';
import SecondaryButton from '../../shared/SecondaryButton';
import DisributionListDetails from './DisributionListDetails';

export type NodeTypes =
  | NodeType.Programme
  | NodeType.Project
  | NodeType.AppointedParty
  | NodeType.DeliveryTeam
  | NodeType.TaskTeam
  | NodeType.AppointingParty
  | NodeType.ExternalUsers
  | NodeType.DistributionList
  | NodeType.ParentDistributionList;

export enum NodeType {
  Programme = 'Programme',
  Project = 'Project',
  AppointedParty = 'AppointedParty',
  DeliveryTeam = 'DeliveryTeam',
  TaskTeam = 'TaskTeam',
  AppointingParty = 'AppointingParty',
  ExternalUsers = 'ExternalUsers',
  DistributionList = 'DistributionList',
  ParentDistributionList = 'ParentDistributionList',
}

const ModalTexts = {
  [TeamManagementAction.ADD_DELIVERY_TEAM]: {
    heading: 'Create New Delivery Team',
    message: 'You can create a new delivery team for this project.',
  },
  [TeamManagementAction.ADD_TASK_TEAM]: {
    heading: 'Create New Task Team',
    message: 'You can create a new task team for this project.',
  },
  [TeamManagementAction.ADD_DISTRIBUTION_LIST]: {
    heading: 'Create New List',
    message: 'Enter a name for the distribution list.',
  },
};

const TeamManagement: FC = () => {
  const [showNoticed, setShowNoticed] = useState<boolean>(true);
  const [showNoticedList, setShowNoticedList] = useState<boolean>(true);
  const [saving, setSaving] = useState(false);
  const [teamType, setTeamType] = useState<string>(TeamManagementAction.ADD_DELIVERY_TEAM);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDistributionModal, setShowDistributionModal] = useState<boolean>(false);
  const [nodeSelected, setNodeSelected] = useState<NodeData<NodeTypes> | undefined>(undefined);
  const [selectedTeam, setSelectedTeam] = useState<IClientTeam>();
  const [projectNumber, setProjectNumber] = useState('');
  const [deliveryTeamId, setDeliveryTeamId] = useState<number>();
  const [teamName, setTeamName] = useState('');
  const [abbreviation, setAbbreviation] = useState('');
  const [teamNameError, setTeamNameError] = useState<string>('');
  const [distributionListError, setDistributionListError] = useState<string>('');
  const [abbreviationError, setAbbreviationError] = useState<string>('');
  const [programmeId, setProgrammeId] = useState<number>();
  const [distributionListName, setDistributionListName] = useState('');
  const [listNameCharacterCount, setListNameCharacterCount] = useState<number>(200);
  const [nodeSelectedKey, setNodeSelectedKey] = useState<string | undefined>();

  const validateTeamName = useCallback(() => {
    let error = !isValidRegex(teamName, { allowSpace: true, allowMacron: true })
      ? 'Team name must be only alphanumerical'
      : TeamManagementStore.clientTeams?.programmes.some((p) =>
          p.projects.some(
            (pr) =>
              pr.projectNumber === projectNumber &&
              pr.deliveryTeams.some((dt) => dt.title.toLowerCase().trim() === teamName.toLowerCase().trim())
          )
        )
      ? 'Team name already exists'
      : teamName.length > 100
      ? 'Team name is longer than 100 characters'
      : '';

    if (
      teamType === TeamManagementAction.ADD_TASK_TEAM &&
      TeamManagementStore.clientTeams?.programmes.some((p) =>
        p.projects.some(
          (pr) =>
            pr.projectNumber === projectNumber &&
            pr.deliveryTeams.some((dt) =>
              dt.taskTeams.some((tt) => tt.title.toLowerCase().trim() === teamName.toLowerCase().trim())
            )
        )
      )
    ) {
      error = 'Team name already exists';
    }

    setTeamNameError(error);
  }, [teamName, projectNumber, teamType]);

  const validateAbbreviation = useCallback(() => {
    let error = !isAlphanumericUpper(abbreviation.trim())
      ? 'Abbreviation must be only alphanumerical'
      : TeamManagementStore.clientTeams?.programmes.some((p) =>
          p.projects.some(
            (pr) =>
              pr.projectNumber === projectNumber &&
              pr.deliveryTeams.some((dt) => dt.code.toUpperCase().trim() === abbreviation.toUpperCase().trim())
          )
        )
      ? 'Abbreviation already exists'
      : abbreviation.length > (AppStore.client?.namingCodeMaximumLength || 0)
      ? `Abbreviation is longer than ${AppStore.client?.namingCodeMaximumLength} characters`
      : '';

    if (
      teamType === TeamManagementAction.ADD_TASK_TEAM &&
      TeamManagementStore.clientTeams?.programmes.some((p) =>
        p.projects.some(
          (pr) =>
            pr.projectNumber === projectNumber &&
            pr.deliveryTeams.some((dt) =>
              dt.taskTeams.some((tt) => tt.code.toLowerCase().trim() === abbreviation.toLowerCase().trim())
            )
        )
      )
    ) {
      error = 'Abbreviation already exists';
    }

    setAbbreviationError(error);
  }, [abbreviation, projectNumber, teamType]);

  const validateDistributionListName = useCallback(() => {
    const maxCharacterCount = 200;
    const error = TeamManagementStore.clientTeams?.programmes.some((p) =>
      p.projects.some(
        (pr) =>
          pr.projectNumber === projectNumber &&
          pr.distributionList.some((dt) => dt.title.toLowerCase().trim() === distributionListName.toLowerCase().trim())
      )
    )
      ? 'List name already exists in this project'
      : distributionListName.length >= maxCharacterCount
      ? `List name is longer than ${maxCharacterCount} characters`
      : '';
    setDistributionListError(error);
    setListNameCharacterCount(maxCharacterCount - distributionListName.length);
  }, [distributionListName, projectNumber]);

  useEffect(() => {
    const effectAsync = async () => {
      await TeamManagementStore.init();
      TeamManagementStore.getClientData();
    };

    effectAsync();
  }, []);

  useEffect(() => {
    validateTeamName();
    validateAbbreviation();
  }, [validateTeamName, validateAbbreviation]);

  useEffect(() => {
    validateDistributionListName();
  }, [validateDistributionListName]);

  const handleTreeViewAction = async (act: NodeAction, value) => {
    setNodeSelected(undefined);
    setTeamType(act.action);
    if (value) setNodeSelectedKey(value.key);
    if (act.action === TeamManagementAction.ADD_DISTRIBUTION_LIST) {
      setShowDistributionModal(true);
    } else {
      setShowModal(true);
    }

    setProjectNumber(act.actionData?.projectNumber);
    setDeliveryTeamId(act.actionData?.deliveryTeamId);
  };

  const handleNodeSelected = async (node) => {
    setNodeSelected(node);
    setProjectNumber(node.data.projectNumber);
    setProgrammeId(node.data.programmeId);
    setNodeSelectedKey(node.key);
    let team;
    switch (node.type) {
      case NodeType.DeliveryTeam:
        team = TeamManagementStore.clientTeams?.programmes
          .flatMap((pr) => pr.projects)
          .flatMap((p) => p.deliveryTeams)
          .find((dt) => dt.id === node.id);
        break;
      case NodeType.TaskTeam:
        team = TeamManagementStore.clientTeams?.programmes
          .flatMap((pr) => pr.projects)
          .flatMap((pd) => pd.deliveryTeams)
          .flatMap((pt) => pt.taskTeams)
          .find((dt) => dt.id === node.id);
        break;
      case NodeType.DistributionList:
        team = TeamManagementStore.clientTeams?.programmes
          .flatMap((pr) => pr.projects)
          .flatMap((p) => p.distributionList)
          .find((dt) => dt.id === node.id);
        break;
      case NodeType.ParentDistributionList:
        setNodeSelected(undefined);
        setTeamType(TeamManagementAction.ADD_DISTRIBUTION_LIST);
        break;
      default:
        break;
    }
    if (team) {
      setSelectedTeam(team);
    }

    if (node.type === NodeType.TaskTeam) {
      const taskTeam = TeamManagementStore.clientTeams?.programmes
        .flatMap((pr) => pr.projects)
        .flatMap((p) => p.deliveryTeams)
        .flatMap((dt) => dt.taskTeams)
        .find((tt) => tt.id === node.id);
      setSelectedTeam(taskTeam);
    }
  };

  const renderNoticed = () => {
    return (
      <>
        {!nodeSelected && showNoticed && !isDistributionList && (
          <div className={Style.noticed}>
            <div className={Style.header}>
              Create new Delivery Team
              <Icon className={Style.closedBtn} type="closed" onClick={() => setShowNoticed(false)} />
            </div>
            <p>
              To create a team, simply click on the &#34;Create New Delivery Team&#34; button. You have to create a
              Delivery Team before you can create a Task Team. Once that&#39;s done, you can start adding users to your
              team and assign roles later.
            </p>
          </div>
        )}
        {!nodeSelected && isDistributionList && (
          <div>
            {showNoticedList && (
              <div className={Style.noticed}>
                <div className={Style.header}>
                  Create new Distribution List
                  <Icon className={Style.closedBtn} type="closed" onClick={() => setShowNoticedList(false)} />
                </div>
                <p>
                  Create a distribution list that contains recipients that you send transmittals to regularly. These
                  recipients must already be users of this project. To create a list, simply click on the &#34; Create
                  New List&#34; button.
                </p>
              </div>
            )}
            <SecondaryButton className={Style.distributionButton} onClick={openDistributionModal}>
              Create New List
            </SecondaryButton>
          </div>
        )}
      </>
    );
  };

  const onAbbreviationChanged = (value: string) => {
    if (AppStore.client?.namingCodeMaximumLength && value.length > AppStore.client?.namingCodeMaximumLength) return;
    setAbbreviation(value.toUpperCase());
  };
  const openDistributionModal = () => {
    setShowDistributionModal(true);
  };
  const onModalClosed = () => {
    setShowModal(false);
    setTeamName('');
    setAbbreviation('');
  };
  const onDistributionModalClosed = () => {
    setShowDistributionModal(false);
    setDistributionListName('');
  };
  const isDistributionList = teamType === TeamManagementAction.ADD_DISTRIBUTION_LIST;
  const onDistributionListSave = async () => {
    setSaving(true);
    const result = await TeamManagementStore.saveNewDistributionList(projectNumber, distributionListName);
    setSaving(false);

    if (result) onDistributionModalClosed();
  };
  const onTeamSaved = async () => {
    setSaving(true);
    const result = await TeamManagementStore.saveNewTeam(
      projectNumber,
      teamName,
      abbreviation,
      teamType,
      deliveryTeamId
    );
    setSaving(false);

    if (result) onModalClosed();
  };
  const renderTeamDetails = () => {
    if (!nodeSelected) return;

    const props: ITeamDetailsProps = {
      teamType: nodeSelected.type,
      projectNumber: projectNumber,
      team: {
        id: selectedTeam?.id ?? 0,
        title: selectedTeam?.title ?? '',
        code: selectedTeam?.code ?? '',
        active: selectedTeam?.active ?? false,
      },
      programmeId: programmeId ?? null,
    };
    const listparams = {
      distributionListId: selectedTeam?.id ?? 0,
      distributionListName: selectedTeam?.title ?? '',
      projectNumber: projectNumber,
      onDelete: () => {
        const distributionListKey = `${programmeId}/${projectNumber}/${programmeId}-${projectNumber}-DistributionList`;
        setNodeSelectedKey(distributionListKey);
        setNodeSelected(undefined);
        setShowNoticed(true);
        setTeamType(TeamManagementAction.ADD_DISTRIBUTION_LIST);
        renderNoticed();
      },
    };
    switch (nodeSelected.type) {
      case NodeType.DeliveryTeam:
        return <DeliveryTeamDetails {...props} />;
      case NodeType.TaskTeam:
        return <TaskTeamDetails {...props} />;
      case NodeType.AppointingParty:
        return <AppointingPartyUserDetails projectNumber={projectNumber} />;
      case NodeType.ExternalUsers:
        return <ExternalUserDetails projectNumber={projectNumber} />;
      case NodeType.DistributionList:
        return <DisributionListDetails {...listparams} />;
      default:
        return;
    }
  };

  const modalText = ModalTexts[teamType];
  return (
    <div className={Style.pageWrapper}>
      <div className={Style.left}>
        <TreeView
          treeName="Team List"
          treeData={TeamManagementStore.treeData}
          onSelect={handleNodeSelected}
          onAction={(action, value) => handleTreeViewAction(action, value)}
          selectedNodeKey={nodeSelectedKey}
          rootClassName={Style.teamManagementTree}
          showSearchFilter
        />
      </div>
      <div className={Style.right}>
        {renderNoticed()}
        {renderTeamDetails()}
      </div>
      <CreateNewTeamModal
        showModal={showModal}
        heading={modalText.heading}
        message={modalText.message}
        loading={saving}
        saving={saving}
        disabledSave={!!teamNameError || !!abbreviationError || !teamName || !abbreviation}
        onCancel={onModalClosed}
        onSave={onTeamSaved}
        teamType={teamType}>
        <Grid row>
          <Grid md={6}>
            <FormInput
              type="text"
              label="Team Name"
              placeholder="Enter team name..."
              required
              cssClass={Style.cellInputLeft}
              error={teamNameError}
              value={teamName}
              onChange={setTeamName}
            />
          </Grid>
          <Grid md={6}>
            <FormInput
              type="text"
              label="Abbreviation"
              placeholder="Enter abbreviation..."
              required
              cssClass={Style.cellInputRight}
              error={abbreviationError}
              value={abbreviation}
              onChange={onAbbreviationChanged}
            />
          </Grid>
        </Grid>
      </CreateNewTeamModal>
      <CreateNewTeamModal
        showModal={showDistributionModal}
        heading={modalText.heading}
        message={modalText.message}
        loading={saving}
        saving={saving}
        disabledSave={!!distributionListError || !distributionListName}
        onCancel={onDistributionModalClosed}
        onSave={onDistributionListSave}
        teamType={teamType}>
        <Grid row>
          <Grid md={12}>
            <FormInput
              type="text"
              label="List Name"
              placeholder="Enter list name..."
              required
              cssClass={Style.cellInputLeft}
              error={distributionListError}
              value={distributionListName}
              onChange={setDistributionListName}
            />
          </Grid>
        </Grid>
        {!distributionListError && <span className={Style.charNum}>{listNameCharacterCount} characters left</span>}
      </CreateNewTeamModal>
    </div>
  );
};

export default observer(TeamManagement);
