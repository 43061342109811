import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import VisualisationStore from './VisualisationStore';
import MapViewer from './MapViewer';
import { Loader } from '@aurecon-creative-technologies/styleguide';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import { getAppInsightsInstance } from '../../utils/appInsights';
import { AppInsightPageView } from '../../common/constants/AppInsightPageView';

const Visualisation: FC = () => {
  const project = NavBarSelectorStore.selectedProject;

  useEffect(() => {
    const appInsights = getAppInsightsInstance();
    if (appInsights) appInsights.trackPageView({ name: AppInsightPageView.VISUALISATION, isLoggedIn: true });
  }, []);

  useEffect(() => {
    if (!project) return;
    VisualisationStore.loadProjectArcGisSettings(project);
    VisualisationStore.setupArcGis();
  }, [project]);

  const missingConfig =
    !VisualisationStore.projectArcGisSettings.webSceneId && !VisualisationStore.isLoadingArcGisProjectSettings;

  if (VisualisationStore.isLoadingArcGisProjectSettings || VisualisationStore.arcGisIsLoggingIn)
    return <Loader label="Loading map settings..." />;

  if (!missingConfig && VisualisationStore.arcGisIsAuthenticated) return <MapViewer />;

  const message = `Something went wrong: ${
    missingConfig && VisualisationStore.arcGisErrorLoggingIn
      ? 'Configuration is missing and there is a login problem.'
      : VisualisationStore.arcGisErrorLoggingIn
      ? 'There was an authentication error.'
      : 'There is a configuration error.'
  }`;

  return <div>{message}</div>;
};

export default observer(Visualisation);
