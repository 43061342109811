import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/teamManagement/DeliveryTeamUsers.module.scss';
import classnames from 'classnames';
import UserSelectorExpandable from '../../shared/UserSelectorExpandable';
import TeamManagementStore from '../../../stores/settings/teamManagement/TeamManagementStore';
import { Loader, Pill, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide';
import { IUser } from '../../shared/UserSelector';
import ConfirmationModal from '../../shared/ConfirmationModal';
import SearchBar from '../../shared/SearchBar';
import PrimaryButton from '../../shared/PrimaryButton';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import AppStore from '../../../stores/AppStore';
import SingleUserSelector from '../../shared/SingleUserSelector';
import { ITeamModel } from './models/ITeamManagementModel';

export interface IDeliveryTeamUsersProps {
  projectNumber: string;
  team: ITeamModel;
}

const DeliveryTeamUsers: FC<IDeliveryTeamUsersProps> = (props) => {
  const { projectNumber, team } = props;
  const [userSelected, setUserSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addingOrDeleteUser, setAddingOrDeleteUser] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [searchText, setSearchText] = useState('');

  const userTableHeaders = [
    { label: 'User Name' },
    { label: 'User Email' },
    { label: 'User Role' },
    { label: 'Remove' },
  ];

  useEffect(() => {
    if (!projectNumber || !team.id) return;

    const effectAsync = async () => {
      setLoading(true);
      await TeamManagementStore.loadDeliveryTeamUsers(projectNumber, team.id);
      setLoading(false);
    };
    effectAsync();
  }, [team.id, projectNumber]);

  const onUsersSelected = (users: IUser[]) => {
    setUserSelected(true);
    setSelectedUsers(users);
    setUserSelected(false);
  };

  const onSingleUserSelected = (user: IUser) => {
    setUserSelected(true);
    setSelectedUsers([user]);

    setUserSelected(false);
  };

  const addUserToTeam = async () => {
    setAddingOrDeleteUser(true);
    await TeamManagementStore.addUserToDeliveryTeam(projectNumber, team.id, selectedUsers);
    setSelectedUsers([]);
    setAddingOrDeleteUser(false);
  };

  const removeUserFromTeam = async () => {
    setAddingOrDeleteUser(true);
    selectedUser &&
      (await TeamManagementStore.removeUserFromDeliveryTeam(projectNumber, team.id, selectedUser.id || 0));
    setShowConfirmation(false);
    setAddingOrDeleteUser(false);
    setSelectedUser(undefined);
  };

  const canInviteOrRemoveAzure = AppStore.client?.canInviteOrRemoveAzure;

  if (loading) return <Loader />;

  return (
    <div className={Style.pageWrapper}>
      <>
        <div className={Style.addUserBox}>
          <div className={Style.heading}>Add Users</div>
          <div className={Style.addNewSession}>
            {!canInviteOrRemoveAzure && (
              <div
                className={classnames(
                  canInviteOrRemoveAzure ? Style.halfRow : Style.row,
                  Style.label,
                  Style.searchBoxLabel
                )}>
                Users
              </div>
            )}
            <div
              className={classnames(Style.addUserContainer, !canInviteOrRemoveAzure ? Style.flex : '', {
                [Style.selectedUserContainer]: !!selectedUsers.length,
              })}>
              {canInviteOrRemoveAzure && (
                <div className={Style.addUserRow}>
                  <div
                    className={classnames(
                      canInviteOrRemoveAzure ? Style.halfRow : Style.row,
                      Style.label,
                      Style.searchBoxLabel
                    )}>
                    User Email <span className={Style.required}>*</span>
                  </div>
                  <div className={classnames(Style.halfRow, Style.label, Style.searchBoxLabel, Style.displayNameLabel)}>
                    Display Name <span className={Style.required}>*</span>
                  </div>
                  <div className={Style.labelBtn}>Add</div>
                </div>
              )}
              {!canInviteOrRemoveAzure && (
                <div className={Style.addUserRow}>
                  <UserSelectorExpandable
                    required
                    searchPlaceholder="Add user email..."
                    isMultiUser
                    className={Style.searchBox}
                    getUsers={(searchText) => TeamManagementStore.searchDeliveryTeamUsers(searchText)}
                    onSelectedUsersUpdated={onUsersSelected}
                    disabled={userSelected || addingOrDeleteUser}
                    defaultSelectedUsers={selectedUsers}
                  />
                  <PrimaryButton
                    className={Style.addUserButton}
                    onClick={addUserToTeam}
                    disabled={!selectedUsers.length || addingOrDeleteUser}>
                    Add
                  </PrimaryButton>
                </div>
              )}
              {canInviteOrRemoveAzure && (
                <div className={Style.addUserRow}>
                  <SingleUserSelector
                    className={Style.searchBox}
                    getUsers={(searchText) => TeamManagementStore.searchDeliveryTeamUsers(searchText)}
                    onSelectedUserUpdated={onSingleUserSelected}
                  />
                  <PrimaryButton
                    className={Style.addUserButton}
                    onClick={addUserToTeam}
                    disabled={!selectedUsers.length || addingOrDeleteUser}>
                    Add
                  </PrimaryButton>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={Style.teamUserTable}>
          <div className={Style.quickSearch}>
            <SearchBar
              searchValue={searchText}
              cssClass={Style.searchUserBox}
              onSearch={(keyword) => {
                setSearchText(keyword);
              }}
              placeHolderText="Quick search..."
            />
          </div>
          <Table headers={userTableHeaders}>
            {TeamManagementStore.filteredDeliveryTeamUsers
              .filter(
                (u) =>
                  u.name.toLowerCase().includes((searchText ? searchText : u.name).toLowerCase()) ||
                  u.email.includes((searchText ? searchText : u.email).toLowerCase())
              )
              .map((u) => (
                <TableRow key={u.id}>
                  <TableCell>{u.name}</TableCell>
                  <TableCell>{u.email}</TableCell>
                  <TableCell>
                    <Pill colour={1} cssClass={Style.teamRolePill}>
                      {u.deliveryTeamRoleTitle}
                    </Pill>
                  </TableCell>
                  <TableCell>
                    <PrimaryIconButton
                      icon="delete"
                      onClick={() => {
                        setShowConfirmation(true);
                        setSelectedUser(u);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </Table>
        </div>
      </>
      <ConfirmationModal
        showModal={showConfirmation}
        heading={`Deleting Delivery Team Authoriser?`}
        message={`This action will revoke User's access as a Delivery Team Authoriser from ${team.title}. This action does not remove User’s access as a Task Team user. Are you sure you want to delete ${selectedUser?.name}?`}
        confirmText="Yes"
        cancelText="No"
        loading={addingOrDeleteUser}
        onConfirm={removeUserFromTeam}
        onCancel={() => setShowConfirmation(false)}
      />
    </div>
  );
};

export default observer(DeliveryTeamUsers);
