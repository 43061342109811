import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/FileUpload.module.scss';
import UploadStore from './UploadStore';
import FileMetadataForm from './FileMetadataForm';
import UploadFilesForm from './UploadFilesForm';
import ProgressBar from './ProgressBar';
import SelectedFiles from './SelectedFiles';
import SupersedeFile from './SupersedeFile';
import FilesStore from '../FilesStore';
import { getAppInsightsInstance } from '../../../utils/appInsights';
import { AppInsightPageView } from '../../../common/constants/AppInsightPageView';

const FileUpload: FC = () => {
  useEffect(() => {
    const appInsights = getAppInsightsInstance();
    if (appInsights) appInsights.trackPageView({ name: AppInsightPageView.FILE_UPLOAD, isLoggedIn: true });
  }, []);

  useEffect(() => {
    const fetchNamingConventionAssistance = async () => {
      await UploadStore.getNamingConventionAssistance(FilesStore.fileContainerStateId);
    };

    fetchNamingConventionAssistance();
  }, []);

  const handleFileListChange = (files) => {
    UploadStore.validationUploadFiles(files);
  };
  return FilesStore.openSupersedeFile ? (
    <SupersedeFile />
  ) : (
    <>
      <div className={Style.header}>Upload</div>
      <div className={Style.container}>
        <div className={Style.uploadColumn}>
          <div className={Style.metadataWrapper}>
            <FileMetadataForm />
          </div>

          <div className={Style.uploadWrapper}>
            <div className={Style.rightContainer}>
              <UploadFilesForm multiple={true} />
              <SelectedFiles
                onFileListChange={(files) => handleFileListChange(files)}
                openSupersedeWindow={() => {
                  FilesStore.setOpenSupersedeFile(true);
                }}
              />
            </div>
          </div>
        </div>
        {UploadStore.showProgressBar && <ProgressBar />}
      </div>
    </>
  );
};

export default observer(FileUpload);
