import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Styles from './styles/PrivacyPolicy.module.scss';
import { getAppInsightsInstance } from '../../utils/appInsights';
import { AppInsightPageView } from '../../common/constants/AppInsightPageView';

const PrivacyPolicy: FC = () => {
  useEffect(() => {
    const appInsights = getAppInsightsInstance();
    if (appInsights)
      appInsights.trackPageView({
        name: AppInsightPageView.PRIVACY_POLICY,
        isLoggedIn: true,
      });
  }, []);

  return (
    <div className={Styles.container}>
      <h1>Privacy Policy</h1>
      <p>
        This privacy policy sets out how Aurecon collects, uses and manages personal data. Aurecon views personal
        privacy as an important issue and is committed to respecting the privacy and personal information provided to or
        collected by Aurecon. This policy also forms part of the terms of use of Aurecon&apos;s websites. This policy
        may be updated from time and can be viewed on Aurecon&apos;s website:{' '}
        <a href="https://www.aurecongroup.com">www.aurecongroup.com</a>.
      </p>
      <strong>Aurecon</strong>
      <p>
        Aurecon is an engineering and infrastructure advisory company, with its holding company registered in Melbourne,
        Victoria, Australia. Aurecon has subsidiary and related companies that conduct business in locations throughout
        Australia, New Zealand, Asia, Middle East and Africa. The locations or countries in which Aurecon operates can
        be viewed here.
      </p>
      <strong>Information Collected</strong>
      <p>
        In the conduct of its functions and activities, Aurecon may collect and hold personal information about an
        individual, including but not limited to name, date of birth, gender, business or home address, contact details,
        occupation, professional qualifications and experience, employer, financial information and internet webpages
        accessed. Aurecon collects, uses and manages personal information in accordance with this privacy policy and in
        compliance with the relevant data protection and privacy laws and regulations in areas where it operates.
      </p>
      <strong>The purpose of collecting personal information</strong>
      <p>
        Personal information will be collected and used or disclosed by Aurecon for the purposes of conducting its
        business activities, for pursuing its legitimate interest, for legal or regulatory compliance activities
        required by law or good business practice or for the purposes for which the information is provided. pursues a
        range of legitimate interests including but not limited to:
      </p>
      <ul>
        <li>recruitment and employment opportunities;</li>
        <li>promotion of Aurecon&apos;s services, including details of events and seminars;</li>
        <li>performing its professional services and duties;</li>
        <li>assessing prospective clients and projects, including risk and creditworthiness;</li>
        <li>handling or defending complaints, disputes or claims;</li>
        <li>undertaking research relating to professional services activities;</li>
        <li>
          passing information to Aurecon&apos;s related companies, credit agencies, government agencies and legal and
        </li>
        <li>professional service providers where appropriate; and</li>
        <li>
          compliance with the relevant statutory obligations, including government reporting and specific local law
          requirements.
        </li>
      </ul>
      <strong>Processing of personal information</strong>
      <p>
        Aurecon collects and processes a wide range of personal data as part of operating its business. The basis for
        processing personal data includes but is not limited to:
      </p>
      <ul>
        <li>the consent given by an individual to Aurecon to process the individual&apos;s personal information;</li>
        <li>
          performance of professional services and duties in terms of a contract to which an individual is a party;
        </li>
        <li>
          compliance with a legal obligation that Aurecon as the controller is required to meet in terms of relevant
          privacy and data protection laws and regulations;
        </li>
        <li>
          processing is necessary to protect an individual&apos;s vital interests or the vital interests of another and
          and processing is necessary for the purposes of the legitimate interests pursued by Aurecon or by a third
          party, except where such interests are overridden by: security interests, an individual&apos;s fundamental
          rights and freedoms and the protection of personal information from serious harm, all of which require of of
          personal data in particular where the data subject is a child.
        </li>
      </ul>
      <strong>Recipients of personal data</strong>
      <p>
        Aurecon as a global business holds information and data in numerous countries. It may therefore transfer
        personal information or data to Aurecon offices or locations and in data centres in any country, but only for
        the above mentioned lawful purposes and subject always to this privacy policy and the laws of the relevant
        country.
      </p>
      <strong>Period of retention of personal information</strong>
      <p>
        Aurecon does not store or retain an individual&apos;s personal information for any longer than is required in
        accordance with Aurecon&apos;s legitimate interest to do so or for the period necessary to comply with our
        obligations related to the storage of specific types of records and/or information.
      </p>
      <strong>Security and internet websites</strong>
      <p>
        Aurecon takes reasonable security measures to protect personal information, but unauthorised access a
        possibility. Aurecon will not take responsibility for anyone outside of Aurecon&apos;s control breaching its
        security measures. Aurecon may make non-Aurecon websites available through hyperlinks on its website which are
        not subject to Aurecon&apos;s privacy policy. Aurecon is not responsible for those sites and the privacy policy;
        conditions of use and security of those websites should be assessed by the user before use.
      </p>
      <strong>Rights as a data subject</strong>
      <p>
        At any stage while Aurecon is in possession of or, processing of an individual&apos;s personal data, the
        individual will with regard to the management of the information have the following rights:
      </p>
      <ul>
        <li>to request a copy of the personal data held by Aurecon (right of access);</li>
        <li>to have personal data corrected (right of rectification);</li>
        <li>in certain circumstances to have personal data erased from records (right to erasure);</li>
        <li>to restrict the processing of personal data (right to restriction of processing);</li>
        <li>to object how personal data is processed (right to object);</li>
        <li>
          to have personal data transferred to an individual or to another business in certain circumstances (the right
          of portability).
        </li>
      </ul>
      <p>
        If processing of personal information is based on consent, an individual can withdraw his/her consent for
        Aurecon to process his/her personal data at any time by contacting Aurecon at <a>data@aurecongroup.com</a> or at
        the relevant office address or telephone number listed at{' '}
        <a href="https://www.aurecongroup.com">www.aurecongroup.com</a>. Aurecon will review or process the request or
        matter and provide a response as soon as practicable.
      </p>
      <p>Consequences of not providing personal information</p>
      <p>
        An individual is not obligated to provide their personal information, however as this information is required
        for Aurecon to provide an individual with its services and deal with legitimate interests, may may not be able
        to offer the individual services without it.
      </p>
      <strong>Removal or change of person details and complaints</strong>
      <p>
        If a person wishes to view, correct, update or remove personal details held by Aurecon, or has any questions,
        concerns or complaints regarding privacy or Aurecon&apos;s management of personal information, Aurecon may be
        contacted at <a>data@aurecongroup.com</a> or at the relevant office address or telephone number listed at{' '}
        <a href="https://www.aurecongroup.com">www.aurecongroup.com</a>. Aurecon will review or process the request or
        matter and provide a response as soon as practicable. Aurecon respects the right of individuals to remain
        anonymous.
      </p>
      <p>
        If an individual remains dissatisfied then he/she also has the right to take any complaints about how Aurecon
        process personal data to privacy protection agencies in the areas where Aurecon operates.
      </p>
      <p>The contact details for a range of privacy protection agencies can be found via this link.</p>
      <p>
        Australia has nominated the Office of the Australian Information Commissioner (OAIC) as the primary Supervisory
        Authority; Website: <a href="https://www.oaic.gov.au/">https://www.oaic.gov.au/</a>.
      </p>
      <strong>Automated Decisions</strong>
      <p>
        Aurecon does not make decisions based solely on the automated processing of data, including profiling, produce
        legal effects that significantly affect the individuals involved.
      </p>
      <p>
        If automated decisions are made, affected persons will be given an opportunity to express their views on
        automated decision in question and object to it.
      </p>
      <p>Updated: 29 May 2018</p>
    </div>
  );
};

export default observer(PrivacyPolicy);
