import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/temporaryAccessLog/TemporaryAccessLog.module.scss';
import TemporaryAccessLogStore, {
  ActionColumns,
  tableHeaderKeyValueFields,
} from '../../../stores/settings/temporaryAccessLog/TemporaryAccessLogStore';
import {
  Dropdown,
  IHeader,
  Loader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  useToast,
} from '@aurecon-creative-technologies/styleguide';
import Icon from '../../shared/Icon';
import { SortType } from '../../../common/enums/SortType';
import { formatDate } from '../../../utils/dateUtils';
import { ITemporaryFileAccessLog } from '../../../api/authenticated/cms/interface/getTemporaryAccessLogRequest';
import Overlay from '../../shared/Overlay';
import PrimaryButton from '../../shared/PrimaryButton';
import classNames from 'classnames';
import Dot from '../../shared/Dot';
import ConfirmationModal from '../../shared/ConfirmationModal';
import ErrorModal from '../../shared/ErrorModal';
import UserTextFilter from '../../shared/UserTextFilter';
import { ITableColumn } from '../../../common/interfaces/TableColumn';

const unSortingColumns = [
  tableHeaderKeyValueFields.sharedToUser.label,
  tableHeaderKeyValueFields.taskTeamOriginator.label,
  tableHeaderKeyValueFields.fileContainerState.label,
  tableHeaderKeyValueFields.grantedByUser.label,
  tableHeaderKeyValueFields.expiryDate.label,
  tableHeaderKeyValueFields.status.label,
  tableHeaderKeyValueFields.actionColumn.label,
];

const TemporaryAccessLog: FC = () => {
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [revokeItem, setRevokeItem] = useState<ITemporaryFileAccessLog | null>(null);
  const { addToast } = useToast();
  useEffect(() => {
    TemporaryAccessLogStore.initData();
  }, []);

  const getFilterComponent = (tableColumn: ITableColumn) => {
    if (tableColumn.textFilter)
      return (
        <UserTextFilter
          textFilter={tableColumn.textFilter}
          key={tableColumn.valueField}
          onChange={(value) => TemporaryAccessLogStore.filterChange(tableColumn, value)}
        />
      );
  };

  const headers = (): IHeader[] => {
    return TemporaryAccessLogStore.tableColumns?.map((tableColumn) => {
      const isUnSortable = unSortingColumns.includes(tableColumn.label);
      const sortDirection = tableColumn.sort ? tableColumn.sort : 'none';
      const isActionColumn = ActionColumns.includes(tableColumn.valueField);
      return {
        label: tableColumn.label,
        sort: isUnSortable || isActionColumn ? undefined : sortDirection,
        onSort:
          isUnSortable || isActionColumn
            ? undefined
            : (sort) => TemporaryAccessLogStore.onSortColumnChange(tableColumn.valueField, sort as SortType),
        style: {
          ...tableColumn.style,
          display: 'table-cell',
        },
        filter: getFilterComponent(tableColumn),
        draggable: !isActionColumn,
        resizable: false,
        align: !isActionColumn ? 'left' : 'center',
        width: tableColumn.width,
        minWidth: tableColumn.minWidth,
      } as IHeader;
    });
  };

  const buildProjectDropdown = () => {
    return (
      <div className={Style.selectProjectDropdownWrapper}>
        <Dropdown
          cssClass={Style.selectProjectDropdown}
          placeholder="Select Project"
          items={TemporaryAccessLogStore.items.map((x) => {
            if (x.programme) {
              return {
                id: x.id,
                disable: true,
                label: (
                  <span className={Style.programmeOptionWrapper}>
                    <span>{x.programme.title}</span>
                  </span>
                ),
              };
            }

            return {
              id: x.id,
              disable: false,
              label: (
                <span className={Style.projectOptionWrapper}>
                  <Icon name="folder" className={Style.icon} />
                  <span>
                    {x.project?.projectNumber} {x.project?.title}
                  </span>
                </span>
              ),
            };
          })}
          selectedItem={TemporaryAccessLogStore.selectProjectItemId}
          onSelectItem={(value) => TemporaryAccessLogStore.changeProject(value)}
        />
      </div>
    );
  };

  const buildTableSection = () => {
    const cellValue = (h: IHeader, dataRow: ITemporaryFileAccessLog) => {
      const col = TemporaryAccessLogStore.tableColumns.find((c) => c.label === h.label);
      if (!col) {
        return '';
      }
      if (dataRow[col.valueField] instanceof Date) return formatDate(dataRow[col.valueField] as Date);
      else if (typeof dataRow[col.valueField] === 'boolean') {
        return (
          <div>
            {dataRow[col.valueField] && (
              <>
                <Dot className={Style.notExpiry} />
                <span>Yes</span>
              </>
            )}
            {!dataRow[col.valueField] && (
              <>
                <Dot className={Style.expired} />
                <span>No</span>
              </>
            )}
          </div>
        );
      } else if (col.valueField === tableHeaderKeyValueFields.taskTeamOriginator.value)
        return (
          <span>
            {dataRow.deliveryTeamTitle} | <strong>{dataRow.taskTeamOriginator}</strong>
            <span />
          </span>
        );
      return dataRow[col.valueField] ?? '';
    };

    const renderTableRow = () => {
      return (
        <>
          {TemporaryAccessLogStore.data.map((row) => (
            <TableRow key={row.id}>
              {headers().map((h, i) => {
                if (h.label === tableHeaderKeyValueFields.actionColumn.label) {
                  return (
                    <TableCell key={i} cellClass={Style.actionCell}>
                      <PrimaryButton
                        className={Style.actionButton}
                        disabled={!row.status}
                        onClick={() => {
                          setOpenWarningModal(true);
                          setRevokeItem(row);
                        }}>
                        <Icon name="close" className={Style.icon}></Icon>
                      </PrimaryButton>
                    </TableCell>
                  );
                }
                return <TableCell key={i}>{cellValue(h, row)}</TableCell>;
              })}
            </TableRow>
          ))}
        </>
      );
    };

    const showPaging =
      !!TemporaryAccessLogStore.data.length &&
      TemporaryAccessLogStore.pagingMetaData &&
      TemporaryAccessLogStore.pagingMetaData.totalCount > 0;

    const buildPagination = () => {
      return (
        <>
          {showPaging && (
            <div className={Style.paginationWrapper}>
              <div className={Style.paginationContainer}>
                <Pagination
                  page={TemporaryAccessLogStore.pagingMetaData.pageNumber}
                  pageCount={TemporaryAccessLogStore.pagingMetaData.pageCount}
                  onChange={(page: number) => {
                    TemporaryAccessLogStore.setCurrentPage(page);
                  }}
                />
                <div className={Style.paginationFlexGrow}>
                  {TemporaryAccessLogStore.pagingMetaData.firstItemOnPage + 1} -{' '}
                  {TemporaryAccessLogStore.pagingMetaData.lastItemOnPage + 1} of{' '}
                  {TemporaryAccessLogStore.pagingMetaData.totalCount} Files
                </div>
              </div>
            </div>
          )}
        </>
      );
    };

    return (
      <>
        <div className={classNames(Style.tableSection, [showPaging, Style.showPaging])}>
          <Table cssClass={Style.logsTable} headers={headers()} breakpoint={10} hoverable>
            {renderTableRow()}
          </Table>
        </div>
        {buildPagination()}
      </>
    );
  };

  const revokeTemporaryAccessFile = async () => {
    if (!revokeItem) return;
    await TemporaryAccessLogStore.revokeTemporaryAccess(revokeItem);
    if (!TemporaryAccessLogStore.showError) {
      addToast({
        type: 'success',
        message: `Revoke the user’s access to ${revokeItem?.fileContainerTitle} successfully.`,
        timeout: 5000,
      });
    }

    setRevokeItem(null);
  };

  return (
    <div className={Style.pageWrapper}>
      {TemporaryAccessLogStore.loading && (
        <div>
          <Overlay className={Style.modalOverlay}></Overlay>
          <Loader cssClass={Style.savingSpinner} size="small" label={TemporaryAccessLogStore.loadingLabel} />
        </div>
      )}
      <p className={classNames(Style.headingLabel, Style.header)}>Select Project</p>
      <div className={Style.projectFilterSection}>{buildProjectDropdown()}</div>
      {buildTableSection()}
      <ConfirmationModal
        showModal={openWarningModal}
        heading="Revoke Access?"
        message={`This action will revoke the user’s access to ${revokeItem?.fileContainerTitle}. Are you sure?`}
        confirmText="Yes"
        cancelText="No"
        loading={TemporaryAccessLogStore.loading}
        onCancel={() => {
          setOpenWarningModal(false);
          setRevokeItem(null);
        }}
        onConfirm={() => {
          setOpenWarningModal(false);
          revokeTemporaryAccessFile();
        }}
      />
      {TemporaryAccessLogStore.showError && (
        <ErrorModal
          closeModal={() => TemporaryAccessLogStore.cleanUp()}
          errorCode={TemporaryAccessLogStore.errorCode}
          errorMessage={TemporaryAccessLogStore.errorMessage}
        />
      )}
    </div>
  );
};

export default observer(TemporaryAccessLog);
