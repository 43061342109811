import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/teamManagement/DeliveryTeamDetails.module.scss';
import TeamManagementStore from '../../../stores/settings/teamManagement/TeamManagementStore';
import { Dropdown, IHeader, Loader, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide';
import ConfirmationModal from '../../shared/ConfirmationModal';
import SearchBar from '../../shared/SearchBar';
import TeamDetailsHeader from './TeamDetailsHeader';
import { ITeamDetailsProps } from '../../../common/constants/TeamManagementAction';
import AddUsers from './AddUsers';
import { TaskTeamRole } from '../../../common/enums/TaskTeamRole';
import { TaskTeamRoleText } from '../../../common/constants/TaskTeamRoleText';
import { DropdownStyle } from '../../../common/constants/Dropdown';
import { SortTypes } from '../../../common/enums/SortType';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import { ITaskTeamUser } from '../../../api/authenticated/um/interfaces/user.interface';

const RoleItems = [{ id: TaskTeamRole.Approver, label: TaskTeamRoleText[TaskTeamRole.Approver] }];

const TaskTeamDetails: FC<ITeamDetailsProps> = (props) => {
  const { projectNumber, team, teamType } = props;
  const [loading, setLoading] = useState(false);
  const [deletingUser, setDeletingUser] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedUser, setSelectedUser] = useState<ITaskTeamUser>();
  const [searchText, setSearchText] = useState('');
  const [sortedField, setSortedField] = useState('');
  const [sortDirection, setSortDirection] = useState<SortTypes>();
  const [activeTaskTeam, setTaskTeamStatus] = useState<boolean>(false);

  useEffect(() => {
    if (!projectNumber || !team.id) return;

    const effectAsync = async () => {
      setLoading(true);
      await TeamManagementStore.loadTaskTeamUsers(projectNumber, team.id);
      setLoading(false);
    };
    effectAsync();
  }, [team.id, projectNumber]);

  useEffect(() => {
    const taskActiveStatus = TeamManagementStore.clientTeams?.programmes
      .flatMap((pr) => pr.projects)
      .flatMap((p) => p.deliveryTeams)
      .flatMap((p) => p.taskTeams)
      .find((tt) => tt.id === team.id)?.active;
    setTaskTeamStatus(!!taskActiveStatus);
  }, [team]);

  const removeUserFromTeam = async () => {
    setDeletingUser(true);
    selectedUser && (await TeamManagementStore.removeUserFromTaskTeam(projectNumber, team.id, selectedUser.id || 0));
    setShowConfirmation(false);
    setDeletingUser(false);
    setSelectedUser(undefined);
  };

  const onSortSelected = (columnName: string, sortDirection: SortTypes) => {
    setSortedField(columnName);
    setSortDirection(sortDirection);
    TeamManagementStore.applySort(columnName, sortDirection);
  };
  const headers: IHeader[] = [
    {
      label: 'User Name',
      sort: sortedField === 'name' ? sortDirection : 'none',
      onSort: (sort) => onSortSelected('name', sort as SortTypes),
    },
    {
      label: 'User Email',
      sort: sortedField === 'email' ? sortDirection : 'none',
      onSort: (sort) => onSortSelected('email', sort as SortTypes),
    },
    {
      label: 'User Role',
      sort: sortedField === 'role' ? sortDirection : 'none',
      onSort: (sort) => onSortSelected('role', sort as SortTypes),
    },
    {
      label: 'Remove',
    },
  ];

  if (loading) return <Loader />;

  return (
    <div className={Style.deliveryTeamContainer}>
      <TeamDetailsHeader team={team} teamType={teamType} projectNumber={projectNumber} />
      {activeTaskTeam && (
        <>
          <AddUsers
            projectNumber={projectNumber}
            roleItems={RoleItems}
            addUserToTeam={(selectedUsers, roleId) =>
              TeamManagementStore.addUserToTaskTeam(projectNumber, team.id, selectedUsers, roleId)
            }
          />
          <div className={Style.teamUserTable}>
            <SearchBar
              searchValue={searchText}
              cssClass={Style.searchUserBox}
              onSearch={(keyword) => {
                setSearchText(keyword);
              }}
              placeHolderText="Quick search..."
            />
            <Table headers={headers}>
              {TeamManagementStore.filteredTaskTeamUsers
                .filter(
                  (u) =>
                    u.name.toLowerCase().includes((searchText ? searchText : u.name).toLowerCase()) ||
                    u.email.includes((searchText ? searchText : u.email).toLowerCase())
                )
                .map((u) => (
                  <TableRow key={u.id}>
                    <TableCell>{u.name}</TableCell>
                    <TableCell>{u.email}</TableCell>
                    <TableCell>
                      <Dropdown
                        placeholder="Select Additional Role"
                        items={RoleItems}
                        selectedMultipleItems={u.taskTeamRoleIds}
                        onSelectMultipleItems={(value) =>
                          TeamManagementStore.changeTaskTeamUserRole(team, u, value, projectNumber)
                        }
                        multiple={true}
                        multipleDisplayValues={true}
                        optionsHeight={DropdownStyle.defaultHeight}
                        disabled={!activeTaskTeam}
                      />
                    </TableCell>
                    <TableCell>
                      <PrimaryIconButton
                        icon="delete"
                        disabled={!activeTaskTeam}
                        onClick={() => {
                          setShowConfirmation(true);
                          setSelectedUser(u);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </Table>
          </div>
        </>
      )}

      <ConfirmationModal
        showModal={showConfirmation}
        heading={
          selectedUser?.taskTeamRoleIds.includes(TaskTeamRole.Approver)
            ? `Deleting Task Team Approver?`
            : `Deleting Task Team User?`
        }
        message={
          selectedUser?.taskTeamRoleIds.includes(TaskTeamRole.Approver) ? (
            <>
              This action will revoke user’s access as a task team approver from {team.title} and also as a task team
              user. Are you sure you want to delete {selectedUser?.name}?
            </>
          ) : (
            <>
              This action will revoke user’s access as a task team user from {team.title}.
              <br />
              <br />
              Please note that deleting a task team user will also revoke the user’s access as a delivery team
              authoriser. Are you sure you want to delete {selectedUser?.name} from both teams?
            </>
          )
        }
        confirmText="Yes"
        cancelText="No"
        loading={deletingUser}
        onConfirm={removeUserFromTeam}
        onCancel={() => setShowConfirmation(false)}
      />
    </div>
  );
};

export default observer(TaskTeamDetails);
