import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { FormInput, Grid, Tooltip, ComboBox, IOption } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/NewFileFromTemplate.module.scss';
import UploadStore from './UploadStore';
import { ApimsMetaDataType, MetadataFieldType } from '../../../common/enums/MetadataFieldType';
import FilesStore from '../FilesStore';
import { FileContainerState } from '../../../common/enums/FileContainerState';
import Icon from '../../shared/Icon';
import AppStore from '../../../stores/AppStore';

export const FileContainerFieldNames = {
  SuitabilityId: 'suitabilityId',
};

const NamingConventionFields: FC = () => {
  useEffect(() => {
    UploadStore.clearMetadataFileNameError();
  }, []);

  const isAPIMS = AppStore.applyApims;

  const renderSuitabilityComboBox = () => {
    return (
      <ComboBox
        label="Suitability"
        placeholder="- Select an option-"
        showIcon={false}
        required={true}
        selected={UploadStore.fileContainerMetadataFields.suitabilityId?.toString()}
        onSelect={(value) =>
          value &&
          UploadStore.setFileFieldValues<number>(FileContainerFieldNames.SuitabilityId, parseInt(value.id.toString()))
        }
        onClear={() => UploadStore.setFileFieldValues<number>(FileContainerFieldNames.SuitabilityId)}
        options={FilesStore.suitabilities.map<IOption>((s) => {
          return {
            id: s.id.toString(),
            value: `${s.code} ${s.title}`,
          };
        })}
        cssClass={Style.comboBox}
      />
    );
  };

  const renderSequenceNumberControl = () => {
    return (
      <div className={Style.formControl}>
        <label className={Style.label}>
          <span className={Style.labelText}>Sequence Number</span>
          <Tooltip
            show={
              <div className={Style.conventionTooltip}>
                Project no. - Discipline code - Document Type code - <strong>Sequence No.</strong> - [Revision Code{' '}
                Revision No] <br />
                e.g. 00000-AC-AM-<strong>00005</strong>-[P01]
              </div>
            }
            defaultUp
            cssClass={Style.metadataFieldTooltip}>
            <Icon className={Style.infoIcon} name="info" outlined />
          </Tooltip>
        </label>
        <FormInput
          cssClass={Style.input}
          value={UploadStore.sequenceNumber}
          error={UploadStore.sequenceNumberError}
          placeholder="e.g. 00005"
          onChange={UploadStore.setSequenceNumber}
        />
      </div>
    );
  };

  const renderNonApimsControls = () => {
    return (
      <>
        {renderSequenceNumberControl()}
        <div className={Style.formControl}>
          <label className={Style.label}>
            <span className={Style.labelText}>Revision Number</span>
            <Tooltip
              show={
                <div className={Style.conventionTooltip}>
                  Project no. - Discipline code - Document Type code - Sequence No. - {'['}
                  Revision Code <strong>Revision No.</strong>]
                  <br />
                  e.g. 00000-AC-AM-00005-[P<strong>01</strong>]
                </div>
              }
              defaultUp
              cssClass={Style.metadataFieldTooltip}>
              <Icon className={Style.infoIcon} name="info" outlined />
            </Tooltip>
          </label>
          <FormInput
            cssClass={Style.input}
            value={UploadStore.revisionNumber}
            placeholder="Enter text"
            error={UploadStore.revisionNumberError}
            onChange={(value) => UploadStore.setRevisionNumber(value)}
          />
        </div>
      </>
    );
  };

  const renderApimsControls = () => {
    return (
      <>
        <div className={Style.formControl}>
          <label className={Style.label}>
            <span className={Style.labelText}>Sequence Number</span>
            <Tooltip
              show={
                <div className={Style.conventionTooltip}>
                  Project no. - Discipline code - Document Type code - <strong>Sequence No.</strong> - [Revision Code
                  {FilesStore.fileContainerStateId === FileContainerState.Shared ? ' Revision No.' : ''}] <br />
                  e.g. 00000-AC-AM-<strong>00005</strong>-[P
                  {FilesStore.fileContainerStateId === FileContainerState.Shared ? '01' : ''}]
                </div>
              }
              defaultUp
              cssClass={Style.metadataFieldTooltip}>
              <Icon className={Style.infoIcon} name="info" outlined />
            </Tooltip>
          </label>
          <FormInput
            cssClass={Style.input}
            value={UploadStore.sequenceNumber}
            error={UploadStore.sequenceNumberError}
            placeholder="e.g. 00005"
            onChange={UploadStore.setSequenceNumber}
          />
        </div>
        {UploadStore.pattern1Metadata && (
          <div className={Style.formControl}>
            <label className={Style.label}>
              <span className={Style.labelText}>
                {FilesStore.apimsMetadataFields.find((x) => x.title === ApimsMetaDataType.Pattern1)?.description ??
                  ApimsMetaDataType.Pattern1}
              </span>
              <Tooltip
                show={
                  <div className={Style.conventionTooltip}>
                    Project no. - Discipline code - Document Type code - Sequence No. - {'['}
                    <strong>Revision Code</strong>{' '}
                    {FilesStore.fileContainerStateId === FileContainerState.Shared ? ' Revision No.' : ''}]
                    <br />
                    e.g. 00000-AC-AM-00005-[<strong>P</strong>
                    {FilesStore.fileContainerStateId === FileContainerState.Shared ? '01' : ''}]
                  </div>
                }
                defaultUp
                cssClass={Style.metadataFieldTooltip}>
                <Icon className={Style.infoIcon} name="info" outlined />
              </Tooltip>
            </label>
            {UploadStore.pattern1Metadata.dataType.fieldType === MetadataFieldType.Alphabet && (
              <ComboBox
                showIcon={false}
                selected={UploadStore.pattern1}
                onSelect={(value) => {
                  UploadStore.setPattern1(MetadataFieldType.Alphabet, value?.value);
                }}
                options={UploadStore.getDropdownValues(UploadStore.pattern1Metadata).map((x) => {
                  return {
                    id: x.id,
                    value: x.label,
                  };
                })}
                placeholder="- Select an option -"
                cssClass={Style.comboBox}
              />
            )}
            {UploadStore.pattern1Metadata.dataType.fieldType === MetadataFieldType.Numeric && (
              <FormInput
                cssClass={Style.input}
                value={UploadStore.pattern1}
                placeholder="Enter text"
                error={UploadStore.pattern1Error}
                onChange={(value) => UploadStore.setPattern1(MetadataFieldType.Numeric, value)}
              />
            )}
          </div>
        )}

        {FilesStore.fileContainerStateId === FileContainerState.Shared && UploadStore.pattern2Metadata && (
          <div className={Style.formControl}>
            <label className={Style.label}>
              <span className={Style.labelText}>
                {FilesStore.apimsMetadataFields.find((x) => x.title === ApimsMetaDataType.Pattern2)?.description ??
                  ApimsMetaDataType.Pattern2}
              </span>
              <Tooltip
                show={
                  <div className={Style.conventionTooltip}>
                    Project no. - Discipline code - Document Type code - Sequence No. - [Revision Code
                    <strong>
                      {FilesStore.fileContainerStateId === FileContainerState.Shared ? ' Revision No.' : ''}
                    </strong>
                    ] e.g. 00000-AC-AM-00005-[P
                    <strong>{FilesStore.fileContainerStateId === FileContainerState.Shared ? '01' : ''}</strong>]
                  </div>
                }
                defaultUp
                cssClass={Style.metadataFieldTooltip}>
                <Icon className={Style.infoIcon} name="info" outlined />
              </Tooltip>
            </label>
            {UploadStore.pattern2Metadata.dataType.fieldType === MetadataFieldType.Alphabet && (
              <ComboBox
                showIcon={false}
                selected={UploadStore.pattern2}
                onSelect={(value) => {
                  UploadStore.setPattern2(MetadataFieldType.Alphabet, value?.value);
                }}
                options={UploadStore.getDropdownValues(UploadStore.pattern2Metadata).map((x) => {
                  return {
                    id: x.id,
                    value: x.label,
                  };
                })}
                placeholder="- Select an option -"
                cssClass={Style.comboBox}
              />
            )}
            {UploadStore.pattern2Metadata.dataType.fieldType === MetadataFieldType.Numeric && (
              <FormInput
                cssClass={Style.input}
                value={UploadStore.pattern2}
                placeholder="Enter text"
                error={UploadStore.pattern2Error}
                onChange={(value) => UploadStore.setPattern2(MetadataFieldType.Numeric, value)}
              />
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {isAPIMS ? renderApimsControls() : renderNonApimsControls()}
      <Grid item md={12}>
        <div className={Style.formControl}>{renderSuitabilityComboBox()}</div>
      </Grid>
    </>
  );
};

export default observer(NamingConventionFields);
