import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/ProgressBar.module.scss';
import CentreOnPage from './CentreOnPage';
import Overlay from './Overlay';
import PrimaryButton from './PrimaryButton';

interface IProgressBarProps {
  completed: number;
  uploadSuccess?: boolean;
  uploadFailed?: boolean;
  listOfFailedFiles?: string[];
  onClose?: () => void;
}

const ProgressBar: FC<IProgressBarProps> = (props) => {
  const { completed, uploadSuccess, uploadFailed, listOfFailedFiles, onClose } = props;

  const closeWindowButtonClick = () => {
    onClose && onClose();
  };

  return (
    <CentreOnPage>
      <Overlay className={Style.modalOverlay} />
      <div className={Style.modal}>
        {completed <= 100 && !uploadFailed && !uploadSuccess && (
          <>
            <h1>
              Files currently uploading.
              <br />
              Don&apos;t close the browser.
            </h1>
            <p>{`${completed.toFixed(1)}%`}</p>
            <div className={Style.container}>
              <div className={Style.filler} style={{ width: `${completed}%` }}>
                <span className={Style.label}> </span>
              </div>
            </div>
            <p>To continue using Tucana, open a new browser window</p>
            <PrimaryButton onClick={() => window.open('/', '_blank')}>Open New Browser</PrimaryButton>
          </>
        )}
        {uploadFailed && (
          <>
            <h1>
              There was an error uploading
              <br />
              the files. Please try again.
            </h1>
            <p>The following file(s) failed to upload:</p>
            <div className={Style.fileNameBox}>
              {listOfFailedFiles?.map((item) => {
                return (
                  <p className={Style.fileNames} key={item}>
                    {item}
                  </p>
                );
              })}
            </div>
            <p>To continue using Tucana, you may now close this window</p>
            <PrimaryButton onClick={closeWindowButtonClick}>Close Window</PrimaryButton>
          </>
        )}
        {uploadSuccess && (
          <>
            <h1>The upload was successful.</h1>
            <p>To continue using Tucana, you may now close this window</p>
            <PrimaryButton onClick={closeWindowButtonClick}>Close Window</PrimaryButton>
          </>
        )}
      </div>
    </CentreOnPage>
  );
};

export default observer(ProgressBar);
