import { FileContainerState } from '../../../common/enums/FileContainerState';
import { serverAxios } from '../api';

export interface IFileTemporaryAccessDetails {
  name: string;
  effectiveTo: Date;
}

export async function getFileContainerTemporaryAccessDetails(
  projectNumber: string,
  fileContainerId: number,
  fileContainerStateId: FileContainerState,
  abortSignal?: AbortSignal
): Promise<IFileTemporaryAccessDetails[]> {
  const result = await serverAxios.get<{ temporaryAccessDetails: IFileTemporaryAccessDetails[] }>(
    'api/cms/fileContainerTemporaryAccessDetails',
    {
      params: {
        projectNumber,
        fileContainerId,
        fileContainerStateId,
      },
      signal: abortSignal,
    }
  );
  return result.data?.temporaryAccessDetails ?? [];
}
