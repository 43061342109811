import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/teamManagement/AppointingParty.module.scss';
import {
  Dropdown,
  FormInput,
  IHeader,
  ISuggestion,
  Loader,
  SearchBox,
  Table,
  TableCell,
  TableRow,
} from '@aurecon-creative-technologies/styleguide';
import { IUser } from '../../shared/UserSelector';
import ConfirmationModal from '../../shared/ConfirmationModal';
import SearchBar from '../../shared/SearchBar';
import AppointingPartyStore from '../../../stores/settings/teamManagement/AppointingPartyStore';
import { DropdownStyle } from '../../../common/constants/Dropdown';
import { SortTypes } from '../../../common/enums/SortType';
import PrimaryButton from '../../shared/PrimaryButton';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import AppStore from '../../../stores/AppStore';

interface IAppointingPartyUserDetailsProps {
  projectNumber: string;
}

const AppointingPartyUserDetails: FC<IAppointingPartyUserDetailsProps> = (props) => {
  const { projectNumber } = props;
  const [loading, setLoading] = useState(false);
  const [addingOrDeleteUser, setAddingOrDeleteUser] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [sortDirection, setSortDirection] = useState<SortTypes>();
  const [sortedField, setSortedField] = useState('');
  const [searchText, setSearchText] = useState('');
  const [triggerCleanup, setTriggerCleanup] = useState<number>(1);
  const [disableDisplayName, setDisableDisplayName] = useState<boolean>(false);
  const canInviteOrRemoveAzure = AppStore.client?.canInviteOrRemoveAzure;

  useEffect(() => {
    if (!projectNumber) return;

    const effectAsync = async () => {
      setLoading(true);
      await AppointingPartyStore.init(projectNumber);
      setLoading(false);
    };
    effectAsync();
  }, [projectNumber]);

  const removeUserFromList = async () => {
    setAddingOrDeleteUser(true);
    selectedUser && (await AppointingPartyStore.removeUserFromAppointingParty());
    setShowConfirmation(false);
    setAddingOrDeleteUser(false);
    setSelectedUser(undefined);
  };
  const addSelectedUser = () => {
    AppointingPartyStore.addMembersToAppointingParty();
    setTriggerCleanup(triggerCleanup + 1);
    if (canInviteOrRemoveAzure) setDisableDisplayName(false);
  };
  const onSortSelected = (columnName: string, sort: SortTypes) => {
    setSortedField(columnName);
    setSortDirection(sort);
    AppointingPartyStore.applySort(columnName, sort);
  };
  const onUsersSelected = (selected: ISuggestion) => {
    if (canInviteOrRemoveAzure) setDisableDisplayName(true);
    AppointingPartyStore.onSelectedUsersUpdated(selected);
  };
  const onUsersSearch = (keyword: string) => {
    if (canInviteOrRemoveAzure) setDisableDisplayName(false);
    AppointingPartyStore.getMatchedUsers(keyword);
  };
  const onUserClear = () => {
    if (canInviteOrRemoveAzure) setDisableDisplayName(false);
    AppointingPartyStore.getMatchedUsers('');
  };
  const onDisplayNameChanged = (text: string) => {
    AppointingPartyStore.setDisplayName(text);
  };
  const headers: IHeader[] = [
    {
      label: 'User Name',
      sort: sortedField === 'name' ? sortDirection : 'none',
      onSort: (sort) => onSortSelected('name', sort as SortTypes),
    },
    {
      label: 'User Email',
      sort: sortedField === 'email' ? sortDirection : 'none',
      onSort: (sort) => onSortSelected('email', sort as SortTypes),
    },
    {
      label: 'User Role',
      sort: sortedField === 'role' ? sortDirection : 'none',
      onSort: (sort) => onSortSelected('role', sort as SortTypes),
    },
    {
      label: 'Remove',
    },
  ];
  if (loading) return <Loader />;

  return (
    <div className={Style.appointingPartyContainer}>
      <div className={Style.header}></div>
      <div className={Style.addUserBox}>
        <div className={Style.heading}>Add Users</div>
        <div className={Style.addForm}>
          <div className={Style.userEmailInput}>
            <div className={Style.label}>
              User Email <span className={Style.requiredSymbol}>*</span>
            </div>
            <SearchBox
              hideSearchButton
              disableDefaultMatching
              suggestions={AppointingPartyStore.filteredUsers}
              onChange={onUsersSearch}
              onSearch={onUsersSearch}
              onSelect={onUsersSelected}
              onClear={onUserClear}
              triggerCleanup={triggerCleanup}
            />
          </div>

          <div className={Style.displayNameInput}>
            <div className={Style.label}>
              Display Name <span className={Style.requiredSymbol}>*</span>
            </div>
            <FormInput
              disabled={(canInviteOrRemoveAzure && disableDisplayName) || !canInviteOrRemoveAzure}
              value={AppointingPartyStore.displayName ?? ''}
              onChange={onDisplayNameChanged}
            />
          </div>

          <div className={Style.roleInput}>
            <div className={Style.label}>Role</div>
            <Dropdown
              placeholder="Select Additional Role"
              items={AppointingPartyStore.appointingPartyRoles}
              selectedMultipleItems={AppointingPartyStore.selectedRoleIds}
              onSelectMultipleItems={(value) => AppointingPartyStore.setSelectedRole(value)}
              multiple={true}
              multipleDisplayValues={true}
              optionsHeight={DropdownStyle.defaultHeight}
            />
          </div>

          <div>
            <div className={Style.labelBtn}>Add</div>
            <PrimaryButton
              className={Style.btnAdd}
              disabled={!AppointingPartyStore.isEnabledAddMemberBtn}
              onClick={addSelectedUser}>
              Add
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className={Style.UserTable}>
        <SearchBar
          searchValue={searchText}
          cssClass={Style.searchUserBox}
          onSearch={(keyword) => {
            setSearchText(keyword);
          }}
          placeHolderText="Quick search..."
        />
        <Table headers={headers} breakpoint={10} hoverable>
          {AppointingPartyStore.getAppointingPartyUsers()
            .filter(
              (u) =>
                u.name.toLowerCase().includes((searchText ? searchText : u.name).toLowerCase()) ||
                u.email.includes((searchText ? searchText : u.email).toLowerCase())
            )
            .map((u) => (
              <TableRow key={u.id}>
                <TableCell>{u.name}</TableCell>
                <TableCell>{u.email}</TableCell>
                <TableCell>
                  <Dropdown
                    placeholder="Select Additional Role"
                    items={AppointingPartyStore.appointingPartyRoles}
                    selectedMultipleItems={u.appointingPartyRoleIds}
                    onSelectMultipleItems={(value) => AppointingPartyStore.changeUserRole(u, value)}
                    multiple={true}
                    multipleDisplayValues={true}
                    optionsHeight={DropdownStyle.defaultHeight}
                  />
                </TableCell>
                <TableCell>
                  <PrimaryIconButton
                    icon="delete"
                    onClick={() => {
                      setShowConfirmation(true);
                      setSelectedUser(u);
                      AppointingPartyStore.setRemoveUser(u);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </Table>
      </div>

      <ConfirmationModal
        showModal={showConfirmation}
        heading="Deleting User?"
        message={`This action will revoke User’s access from appointing Party. Are you sure you want to delete ${selectedUser?.name}?`}
        confirmText="Yes"
        cancelText="No"
        loading={addingOrDeleteUser}
        onConfirm={removeUserFromList}
        onCancel={() => setShowConfirmation(false)}
      />
    </div>
  );
};

export default observer(AppointingPartyUserDetails);
