import { makeAutoObservable, runInAction } from 'mobx';
import AppStore from '../../../stores/AppStore';
import { flatMap } from 'lodash';
import { IProgramme, IProject } from '../../../api/authenticated/config/getClientInfo';
import { SortTypes } from '../../../common/enums/SortType';
import { sort } from '../../../utils/sortHelper';
import { Pages } from '../../../common/constants/Pages';

const localStorageSelectorKey = 'visualisation-selector-id';

export interface IItem {
  id: string;
  programme?: IProgramme;
  project?: IProject;
  key?: string;
}

export class NavBarSelectorStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public items: IItem[] = [];
  public selectedItem?: IItem;
  public selectedProject?: IProject;

  public initSelector() {
    runInAction(() => {
      this.items = flatMap(sort(AppStore.client?.programmes || [], 'title', SortTypes.ASC), (programme) => [
        {
          id: `programmeId:${programme.id}`,
          programme,
          key: `${programme.title}`,
        },
        ...sort(programme.projects || [], 'title', SortTypes.ASC).map((project) => ({
          id: `projectNumber:${project.projectNumber}`,
          project: { ...project, programmeId: programme.id },
          key: `${programme.title}-${project.title}`,
        })),
      ]);

      this.setDefaultItemSelected();
    });
  }

  private setDefaultItemSelected() {
    let id: string | null = null;
    if (AppStore.selectedProgrammeId && AppStore.selectedProjectNumber) {
      const globalItem = this.items.filter(
        (x) =>
          x.project?.programmeId === AppStore.selectedProgrammeId &&
          x.project?.projectNumber === AppStore.selectedProjectNumber
      );
      id = globalItem[0]?.id ?? null;
    }

    if (!id) {
      id = localStorage.getItem(localStorageSelectorKey) ?? this.items[0]?.id;
    }

    this.selectedItem = this.items.find((item) => item.id === id);

    if (!this.selectedItem && this.items.length > 0) {
      this.selectedItem = this.items.filter((item) => item.project)[0];
    }
    if (this.selectedItem) {
      this.updateGlobalNavItemSelected(this.selectedItem);
    }
    this.selectedProject = this.selectedItem?.project;
  }

  public setSelectedSelectorItem(id: string) {
    const selectedItem = this.items.find((item) => item.id === id);
    if (!selectedItem || !selectedItem.project) return;

    this.updateGlobalNavItemSelected(selectedItem);

    runInAction(() => {
      this.selectedItem = selectedItem;
      this.selectedProject = selectedItem.project;
    });
  }

  private updateGlobalNavItemSelected(item: IItem) {
    AppStore.setDeliveryTeamId();
    AppStore.setTaskTeamId();
    AppStore.setProgrammeId(item.project?.programmeId);
    AppStore.setProjectNumber(item.project?.projectNumber);
    localStorage.setItem(localStorageSelectorKey, item.id);
    this.setProjectSettings(item.project);
    AppStore.navigateToNextFeature(Pages.Visualisation.Route);
  }

  private setProjectSettings(project?: IProject) {
    AppStore.setProjectSettings(
      project?.displayApps ?? false,
      project?.displayFiles ?? false,
      project?.displayTasks ?? false,
      project?.displayVisualisation ?? false,
      project?.displayTransmittals ?? false
    );
  }
}

export default new NavBarSelectorStore();
