import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAuth0 } from '@auth0/auth0-react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest, logoutRequest } from '../configs/MsalConfig';
import AppStore from '../../stores/AppStore';
import { getAuthClientInstance } from '../instances/authClientInstance';

interface IAccountInfo {
  userName: string;
  userId: string;
}

export const useAuth = (): {
  isAuthenticated: boolean;
  account: IAccountInfo | null;
  isInProgress: boolean;
  logout: () => void;
  login: (redirectUrl: string) => void;
} => {
  const { instance, inProgress, accounts } = useMsal();
  const {
    isAuthenticated: isAuth0Authenticated,
    isLoading,
    user,
    error,
    logout: auth0Logout,
    loginWithRedirect,
  } = useAuth0();
  const [isInProgress, setIsInProgress] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [account, setAccount] = useState<IAccountInfo | null>(null);

  useEffect(() => {
    setIsInProgress(inProgress !== InteractionStatus.None);
    if (accounts.length) {
      setIsAuthenticated(true);
      setAccount({ userName: accounts[0].username, userId: accounts[0].localAccountId });
    }
  }, [accounts, inProgress, instance]);

  useEffect(() => {
    setIsInProgress(isLoading);
    if (user && !error) {
      setIsAuthenticated(isAuth0Authenticated);
      setAccount({ userName: user.name!, userId: user.sub! });
    }
  }, [error, isAuth0Authenticated, isLoading, user]);

  const logout = async () => {
    const type = await getAuthClientInstance().getType();
    if (type === 'msal') {
      instance.logoutRedirect(logoutRequest);
    } else {
      auth0Logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }
  };

  const login = async (redirectUrl: string) => {
    const type = await getAuthClientInstance().getType();
    if (type === 'msal') {
      instance.loginRedirect({
        ...loginRequest,
        scopes: [...loginRequest.scopes, ...[AppStore.settings?.appApiScope ?? '']],
        redirectStartPage: redirectUrl,
      });
    } else {
      loginWithRedirect({
        appState: {
          returnTo: redirectUrl,
        },
      });
    }
  };

  return { isAuthenticated, account, isInProgress, login, logout };
};
