import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import AreaMeasurement2D from '@arcgis/core/widgets/AreaMeasurement2D';
import AreaMeasurement3D from '@arcgis/core/widgets/AreaMeasurement3D';
import VisualisationStore from '../VisualisationStore';

import WidgetBase, { IWidgetBase } from './WidgetBase';

import Style from './styles/WidgetBase.module.scss';

const MeasurementAreaWidget: FC = () => {
  return VisualisationStore.mapView.type === '2d'
    ? WidgetBase(
        (properties: Partial<IWidgetBase>) =>
          new AreaMeasurement2D({
            visible: true,
            unit: 'square-meters',
            container: properties.container,

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            view: properties.view as any,
          }),
        Style.measurementWidgetWrapper
      )
    : WidgetBase(
        (properties: Partial<IWidgetBase>) =>
          new AreaMeasurement3D({
            visible: true,
            unit: 'square-meters',
            container: properties.container,

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            view: properties.view as any,
          }),
        Style.measurementWidgetWrapper
      );
};

export default observer(MeasurementAreaWidget);
