import React, { FC, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from './Icon';
import Style from './styles/DropDownMenu.module.scss';
import { classNames } from '../../utils/miscUtils';
import { getScrollParent } from '../../utils/getScrollParent';

export interface IDropDownMenuProps {
  icon: string;
  iconClassName?: string;
  itemsContainerClassName?: string;
  menuClassName?: string;
  label?: string;
  disabled?: boolean;
  showAbove?: boolean;
}

const DropDownMenu: FC<IDropDownMenuProps> = ({
  icon,
  iconClassName,
  itemsContainerClassName,
  menuClassName,
  label,
  disabled,
  children,
  showAbove,
}) => {
  const [showMenuAbove, setShowMenuAbove] = useState(showAbove || false);
  const containerRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);
  const itemsContainerRef = useRef<HTMLDivElement>(null);

  const hover = () => {
    if (!containerRef.current || !itemsContainerRef.current || !iconRef.current || showAbove !== undefined) return;
    const parentRect = getScrollParent(containerRef.current.parentNode).getBoundingClientRect() as DOMRect;
    const iconRect = iconRef.current.getBoundingClientRect();
    const itemsContainerRect = itemsContainerRef.current.getBoundingClientRect();
    // -10 is to account for a horizontal scroll bar
    setShowMenuAbove(
      iconRect.bottom + itemsContainerRect.height > parentRect.top + parentRect.height - 10 &&
        iconRect.top - itemsContainerRect.height > parentRect.top
    );
  };

  return (
    <div
      className={classNames(Style.menu, menuClassName, [!!disabled, Style.disabled])}
      onMouseOver={hover}
      ref={containerRef}>
      {label && <span>{label}</span>}
      <div className={classNames(Style.icon, iconClassName)} ref={iconRef}>
        <Icon name={icon} />
      </div>
      <div
        className={classNames(Style.itemsContainer, itemsContainerClassName, [showMenuAbove, Style.menuAbove])}
        ref={itemsContainerRef}>
        <div className={Style.items}>{children}</div>
      </div>
    </div>
  );
};

export default observer(DropDownMenu);
