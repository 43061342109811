import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/projectManagement/AddProject.module.scss';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import { Checkbox, ComboBox, FormInput, Grid } from '@aurecon-creative-technologies/styleguide';
import classNames from 'classnames';
import { IAddNewProjectModel } from './Models/addNewProjectModel';
import UserSelectorExpandable from '../../shared/UserSelectorExpandable';
import { IUser } from '../../shared/UserSelector';
import ProjectManagementStore from '../../../stores/settings/projectManagement/ProjectManagementStore';
import Loading from '../../shared/Loading';
import { ProjectFields, AddProjectValidator } from './Models/addNewProjectValidator';
import ErrorModal from '../../shared/ErrorModal';
import LimitedCharactersLeft from '../../shared/LimitedCharactersLeft';
import FormControlError from '../../shared/FormControlError';

interface IAddProjectProps {
  programmeName: string;
  programmeId: number;
  onSave: (newProjectNumber: string) => void;
  onCancel: (programmeName: string) => void;
}

const AddProject: FC<IAddProjectProps> = (props) => {
  const [formDirty, setFormDirty] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchSystemUsersAsync = async () => {
      await ProjectManagementStore.getAllSystemUsers();
    };
    fetchSystemUsersAsync();
  }, []);

  const handleSaveBtnClick = async () => {
    setSaving(true);
    await ProjectManagementStore.addNewProject(project);
    props.onSave(project.projectNumber);
    setSaving(false);
  };

  const [project, setProject] = useState<IAddNewProjectModel>({
    programmeId: props.programmeId,
    projectNumber: '',
    projectPhase: '',
    projectTitle: '',
    projectAdminUserIds: [],
    description: '',
    projectOwnerUserId: null,
    displayApps: true,
    displayFiles: true,
    displayTasks: true,
    displayTransmittals: true,
    displayVisualisation: false,
    allowCollaborationOtherTaskTeams: null,
  });
  useEffect(() => {
    if (props.programmeId === project.programmeId) return;
    const initialState = {
      ...project,
      [ProjectFields.programmeId]: props.programmeId,
      [ProjectFields.projectNumber]: '',
      [ProjectFields.projectPhase]: '',
      [ProjectFields.projectTitle]: '',
      [ProjectFields.projectAdminUserIds]: [],
      [ProjectFields.projectOwnerUserId]: null,
      [ProjectFields.description]: '',
      [ProjectFields.displayFiles]: true,
      [ProjectFields.displayTasks]: true,
      [ProjectFields.displayTransmittals]: true,
      [ProjectFields.displayVisualisation]: false,
    };
    setProject(initialState);
    setSelectedUsers([]);
    setFormDirty(false);
  }, [project, props.programmeId]);
  const disabledSave =
    !formDirty ||
    (errors &&
      (errors[ProjectFields.programmeId] ||
        errors[ProjectFields.projectNumber] ||
        errors[ProjectFields.projectTitle] ||
        errors[ProjectFields.projectAdminUserIds]));

  const onFormInputChanged = (fieldName: string, value) => {
    if (fieldName === ProjectFields.description) {
      if (value.length > 200) {
        value = value.substring(0, 200);
      }
    }
    if (fieldName === ProjectFields.projectNumber) {
      if (value.length > 50) {
        value = value.substring(0, 50);
      }
    }
    const newProject = { ...project, [fieldName]: value };
    let errorMessage = validateForm(newProject);

    const projectTitleError = ProjectManagementStore.validateDuplicateProjectTitle(newProject.projectTitle);
    if (projectTitleError) {
      errorMessage = { ...errorMessage, projectTitle: projectTitleError };
    }

    const projectNumberError = ProjectManagementStore.validateDuplicateProjectNumber(newProject.projectNumber);
    if (projectNumberError) {
      errorMessage = { ...errorMessage, projectNumber: projectNumberError };
    }

    setErrors({ ...errorMessage });
    setProject(newProject);
    setFormDirty(true);
  };

  const validateForm = (newProject: IAddNewProjectModel) => {
    const errorMessage = { ...errors };
    Object.keys(AddProjectValidator).forEach((key) => (errorMessage[key] = AddProjectValidator[key](newProject[key])));
    return errorMessage;
  };

  const onProjectAdminUsersSelected = (users: IUser[]) => {
    setSelectedUsers(users);
    onFormInputChanged(
      ProjectFields.projectAdminUserIds,
      users.map((x) => x.id)
    );
  };

  if (ProjectManagementStore.loading) return <Loading isLoading={ProjectManagementStore.loading} />;

  return (
    <div className={Style.fromWrapper}>
      <Grid row={true} md={12}>
        <Grid item md={12}>
          <div className={Style.header}>
            <span>Project Details</span>
            <div className={Style.addProjectActions}>
              <SecondaryButton onClick={() => props.onCancel(props.programmeName)}>Cancel</SecondaryButton>
              <PrimaryButton
                disabled={disabledSave}
                loading={saving}
                onClick={() => {
                  void (async () => {
                    await handleSaveBtnClick();
                  })();
                }}>
                Save
              </PrimaryButton>
            </div>
          </div>
        </Grid>

        <Grid item gap={8}>
          <Grid item md={6}>
            <FormInput
              label="Programme"
              value={props.programmeName}
              placeholder="Programme Name"
              required
              readonly={true}
            />
          </Grid>
          <Grid item md={6}>
            <FormInput
              label="Project Name"
              placeholder="Enter a Project Name..."
              required
              disabled={saving}
              value={project.projectTitle || ''}
              onChange={(value) => onFormInputChanged(ProjectFields.projectTitle, value)}
            />
            <LimitedCharactersLeft maxLength={100} value={project.projectTitle} />
            <FormControlError message={errors[ProjectFields.projectTitle]} formDirty={formDirty} showIcon={true} />
          </Grid>
          <Grid item md={6}>
            <FormInput
              label="Project Number"
              placeholder="Enter Project Number..."
              required
              disabled={saving}
              value={project.projectNumber || ''}
              onChange={(value) => onFormInputChanged(ProjectFields.projectNumber, value)}
            />
            <LimitedCharactersLeft maxLength={50} value={project.projectNumber} />
            <FormControlError message={errors[ProjectFields.projectNumber]} formDirty={formDirty} showIcon={true} />
          </Grid>
          <Grid item md={6}>
            <FormInput
              label="Project Phase"
              placeholder="Select Project Phase"
              required
              disabled={true}
              showIcon={false}
              type="dropdown"
              onChange={(value) => onFormInputChanged(ProjectFields.projectPhase, value)}
            />
          </Grid>
          <Grid item md={6}>
            <div className={Style.formItemWrapper}>
              <div className={Style.formControlLabel}>
                Project Admins <span className={Style.required}>*</span>
              </div>
              <UserSelectorExpandable
                required
                searchPlaceholder="Enter Name..."
                isMultiUser
                disabled={saving}
                className={Style.searchBox}
                getUsers={(searchText) => ProjectManagementStore.searchUsers(searchText)}
                onSelectedUsersUpdated={onProjectAdminUsersSelected}
                defaultSelectedUsers={selectedUsers}
              />
              <FormControlError
                message={errors[ProjectFields.projectAdminUserIds]}
                formDirty={formDirty}
                showIcon={true}
              />
            </div>
          </Grid>
          <Grid item md={6}>
            <div className={Style.formItemWrapper}>
              <ComboBox
                label="Project Owner"
                placeholder="Enter Project Owner..."
                showIcon={false}
                disabled={saving}
                options={ProjectManagementStore.systemUsers.map((v) => ({
                  id: v.id,
                  value: `${v.name} (${v.email})` ?? '',
                }))}
                selected={project.projectOwnerUserId?.toString()}
                onSelect={(value) => onFormInputChanged(ProjectFields.projectOwnerUserId, value?.id.toString())}
                onClear={() => onFormInputChanged(ProjectFields.projectOwnerUserId, null)}
              />
              <FormControlError
                message={errors[ProjectFields.projectOwnerUserId]}
                formDirty={formDirty}
                showIcon={true}
              />
            </div>
          </Grid>
          <Grid item md={12}>
            <FormInput
              label="Description"
              value={project.description}
              onChange={(value) => onFormInputChanged('description', value)}
              placeholder="Enter description.."
              multiline={true}
              disabled={saving}
            />
            <LimitedCharactersLeft maxLength={200} value={project.description} />
          </Grid>
          <Grid item md={12}>
            <Grid item md={12}>
              <div className={Style.formDisplayField}>
                <div className={Style.formControlLabel}>Temporary Access</div>
                <Grid item md={12}>
                  <Checkbox
                    label="Allow collaboration with other task teams"
                    checked={project.allowCollaborationOtherTaskTeams || false}
                    cssClass={Style.checkbox}
                    disabled={saving}
                    onChange={() =>
                      onFormInputChanged(
                        ProjectFields.allowCollaborationOtherTaskTeams,
                        !project.allowCollaborationOtherTaskTeams
                      )
                    }
                  />
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid row md={12} cssClass={classNames(Style.breakLine)}></Grid>

        <Grid row md={12} cssClass={classNames(Style.gridGroup)}>
          <div className={classNames(Style.header, Style.settingGroup)}>
            <span>Project Defaults</span>
          </div>
          <Grid gap={16} item md={12}>
            <p className={Style.settingNoticed}>
              By default, left menu bar displays 4 main features and allows user to switch features from anywhere. Admin
              can hide feature and it will not available for users.
            </p>
          </Grid>
          <Grid row md={12} gap={8}>
            <Grid item md={12}>
              <Checkbox
                label="Display Apps"
                checked={project.displayApps}
                cssClass={Style.checkbox}
                disabled={saving}
                onChange={() => onFormInputChanged(ProjectFields.displayApps, !project.displayApps)}
              />
            </Grid>
            <Grid item md={12}>
              <Checkbox
                label="Display Files"
                checked={project.displayFiles}
                cssClass={Style.checkbox}
                disabled={saving}
                onChange={() => onFormInputChanged(ProjectFields.displayFiles, !project.displayFiles)}
              />
            </Grid>
            <Grid item md={12}>
              <Checkbox
                label="Display Task"
                cssClass={Style.checkbox}
                checked={project.displayTasks}
                disabled={saving}
                onChange={() => onFormInputChanged(ProjectFields.displayTasks, !project.displayTasks)}
              />
            </Grid>
            <Grid item md={12}>
              <Checkbox
                label="Display Visualisation"
                cssClass={Style.checkbox}
                disabled={saving}
                checked={project.displayVisualisation}
                onChange={() => onFormInputChanged(ProjectFields.displayVisualisation, !project.displayVisualisation)}
              />
            </Grid>
            <Grid item md={12}>
              <Checkbox
                label="Display Transmittals"
                cssClass={Style.checkbox}
                disabled={saving}
                checked={project.displayTransmittals}
                onChange={() => onFormInputChanged(ProjectFields.displayTransmittals, !project.displayTransmittals)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {ProjectManagementStore.showError && (
        <ErrorModal
          closeModal={() => ProjectManagementStore.setShowErrorModal(false)}
          errorCode={ProjectManagementStore.errorCode}
          errorMessage={ProjectManagementStore.errorMessage}
        />
      )}
    </div>
  );
};

export default observer(AddProject);
