import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Loader } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/PdfViewer.module.scss';
import { getProjectFileDownloadUrl } from '../../api/authenticated/cms/getProjectFileDownloadUrl';
import { useLocation } from 'react-router-dom';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PdfViewer: FC = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fileUrl, setFileUrl] = useState<string>('');

  const location = useLocation();
  useEffect(() => {
    const downloadFile = async () => {
      setIsLoading(true);
      const params = new URLSearchParams(location.search);
      const transmittalId = params.get('transmittalId');
      const transmittalMessageId = params.get('transmittalMessageId');
      const response = await getProjectFileDownloadUrl({
        projectNumber: params.get('projectNumber') ?? '',
        transmittalId: transmittalId ? (Number(transmittalId) as number | null) : null,
        transmittalMessageId: transmittalMessageId ? (Number(transmittalMessageId) as number | null) : null,
        downloadFiles: [
          {
            fileContainerId: (Number(params.get('id')) as number | null) ?? 0,
            fileRevisionId: (Number(params.get('fileRevisionId')) as number | null) ?? 0,
            containerFileId: (Number(params.get('containerFileId')) as number | null) ?? 0,
            releasedFileId: (Number(params.get('releasedFileId')) as number | null) ?? 0,
          },
        ],
      });

      if (response && response[0].url && !response[0].errorMessage) setFileUrl(response[0].url);
      setIsLoading(false);
    };

    downloadFile();
  }, [location]);

  return (
    <div className={Style.modalBody}>
      <div className={Style.pdfContainer}>
        {isLoading || !fileUrl ? (
          <div className={Style.loader}>
            <Loader label="Loading PDF file..." />
          </div>
        ) : (
          <Worker workerUrl="/pdf.worker.min.js">
            <Viewer fileUrl={fileUrl} plugins={[defaultLayoutPluginInstance]} />
          </Worker>
        )}
      </div>
    </div>
  );
};

export default observer(PdfViewer);
