import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/SelectedFilesLabel.module.scss';
import FilesStore from './FilesStore';
import { Icon } from '@aurecon-creative-technologies/styleguide';
import AppStore from '../../stores/AppStore';
import CreateTransmittalStore from '../transmittals/CreateTransmittalStore';
import { FromModuleEnum } from '../../enums/FromModuleEnum';
import AddTransmittalMessageStore from '../transmittalDetails/AddTransmittalMessage/AddTransmittalMessageStore';

const SelectedFilesLabel: FC = () => {
  const selectLabel = () => {
    if (FilesStore.fromModule !== FromModuleEnum.TRANSMITTALS) {
      if (FilesStore.isFilesLimitExceeded() && FilesStore.isAnySelectedFileLocked) {
        return (
          <div className={Style.warning}>
            <Icon type={'warning'} /> {AppStore.client?.fileBatchLimit} files limit. Files must not be in a workflow
          </div>
        );
      }
      if (FilesStore.isFilesLimitExceeded() && !FilesStore.isAnySelectedFileLocked) {
        return (
          <div className={Style.warning}>
            <Icon type={'warning'} /> {AppStore.client?.fileBatchLimit} files limit
          </div>
        );
      }
    }

    if (
      FilesStore.isAnySelectedFileLocked &&
      !(CreateTransmittalStore.isOpenFiles || AddTransmittalMessageStore.isOpenFiles)
    ) {
      return (
        <div className={Style.warning}>
          <Icon type={'warning'} /> Files must not be in a workflow
        </div>
      );
    }
    return <div>{`${FilesStore.filesSelectedCount()} selected`}</div>;
  };
  return (
    <span className={Style.label}>
      {selectLabel()}{' '}
      {(FilesStore.hasSelectedFile() || FilesStore.showSelectedFiles) && (
        <a className={Style.linkLabel} onClick={() => FilesStore.toggleShowSelectedFiles()}>
          {!FilesStore.showSelectedFiles ? 'Show Selected' : 'Show All'}
        </a>
      )}
    </span>
  );
};

export default observer(SelectedFilesLabel);
