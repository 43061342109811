import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/DropDownAdjustableColumns.module.scss';
import { Dropdown, IDropdownItemProps } from '@aurecon-creative-technologies/styleguide';
import { classNames } from '../../utils/miscUtils';

interface IDropDownAdjustableColumnsProps {
  items: IDropdownItemProps[];
  selectedItems: string[];
  onSelected: (items: string[]) => void;
  className?: string;
  disabled?: boolean;
}

const DropDownAdjustableColumns: FC<IDropDownAdjustableColumnsProps> = ({
  items,
  selectedItems,
  onSelected,
  className,
  disabled,
}) => {
  return (
    <div className={classNames(Style.container, className)}>
      <Dropdown
        items={items}
        selectedMultipleItems={selectedItems}
        multiple
        toggleAllItem={'Select All'}
        optionsHeight={200}
        optionsWidth={200}
        iconOptions={{
          icon: 'view_column',
          optionsDisplayFromRightToLeft: true,
        }}
        onSelectMultipleItems={(i) => onSelected(i as string[])}
        disabled={disabled ? disabled : false}
      />
    </div>
  );
};

export default observer(DropDownAdjustableColumns);
