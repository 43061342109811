import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/teamManagement/DeliveryTeamDetails.module.scss';
import classnames from 'classnames';
import { NodeType, NodeTypes } from './TeamManagement';
import EditTeamModal from './EditTeamModal';
import { ITeamModel } from './models/ITeamManagementModel';
import EditTaskTeamModal from './EditTaskTeamModal';
import ConfirmationModal from '../../shared/ConfirmationModal';
import TeamManagementStore from '../../../stores/settings/teamManagement/TeamManagementStore';
import PrimaryIconButton from '../../shared/PrimaryIconButton';

interface ITeamDetailsHeaderProps {
  team: ITeamModel;
  teamType: NodeTypes;
  projectNumber: string;
}

const TeamDetailsHeader: FC<ITeamDetailsHeaderProps> = (props) => {
  const { team, teamType, projectNumber } = props;
  const [teamLabel, setTeamLabel] = useState('');
  const [showEditTeamModal, setShowEditTeamModal] = useState(false);
  const [teamName, setTeamName] = useState(team.title);
  const [onConfirmLoading, setOnConfirmLoading] = useState(false);
  const [canDeactiveTaskTeam, setCanDeactiveTaskTeam] = useState<boolean>(false);
  const [showDeactiveTaskTeamModal, setShowDeactiveTaskTeamModal] = useState(false);
  const [activeTaskTeam, setActiveTaskTeam] = useState<boolean>(false);
  const [showReactiveTaskTeamModal, setShowReactiveTaskTeamModal] = useState(false);
  const [canDeactiveDeliveryTeam, setCanDeactiveDeliveryTeam] = useState<boolean>(false);
  const [deliveryTeamName, setDeliveryTeamName] = useState('');
  const [programmeName, setProgrammeName] = useState('');
  const [showDeactiveDeliveryTeamModal, setShowDeactiveDeliveryTeamModal] = useState(false);
  const [activeDeliveryTeam, setActiveDeliveryTeam] = useState<boolean>(false);
  const [showReactiveDeliveryTeamModal, setShowReactiveDeliveryTeamModal] = useState(false);

  useEffect(() => {
    let deliveryTeam;
    const setLabelByTeamType = () => {
      switch (teamType) {
        case NodeType.DeliveryTeam:
          deliveryTeam = TeamManagementStore.clientTeams?.programmes
            .flatMap((pr) => pr.projects)
            .flatMap((p) => p.deliveryTeams)
            .find((dt) => dt.id === team.id);
          setTeamLabel('Delivery');
          break;
        case NodeType.TaskTeam:
          deliveryTeam = TeamManagementStore.clientTeams?.programmes
            .flatMap((pr) => pr.projects)
            .flatMap((p) => p.deliveryTeams)
            .find((tt) => tt.taskTeams.find((x) => x.id === team.id));
          setTeamLabel('Task');
          break;
        default:
          break;
      }

      if (deliveryTeam) {
        const programme = TeamManagementStore.clientTeams?.programmes.find((pg) =>
          pg.projects.some((pj) => pj.deliveryTeams.some((dt) => dt.id === deliveryTeam.id))
        );
        if (programme) setProgrammeName(programme.title);

        setDeliveryTeamName(deliveryTeam.title);
        setActiveDeliveryTeam(deliveryTeam.active);
        setCanDeactiveDeliveryTeam(deliveryTeam.canDeactivate);

        const taskTeam = deliveryTeam.taskTeams.find((x) => x.id === team.id);
        setActiveTaskTeam(!!taskTeam?.active);
        setCanDeactiveTaskTeam(!!taskTeam?.canDeactivate);
      }
    };
    setLabelByTeamType();
  }, [team.id, teamType]);

  useEffect(() => {
    setTeamName(team.title);
  }, [team.title]);

  const onConfirmDeactiveTaskTeam = async () => {
    setOnConfirmLoading(true);
    await TeamManagementStore.deactiveTaskTeam(team.id, projectNumber);
    setActiveTaskTeam(false);
    setShowDeactiveTaskTeamModal(false);
    setOnConfirmLoading(false);
  };
  const onConfirmReactiveTaskTeam = async () => {
    setOnConfirmLoading(true);
    await TeamManagementStore.reactiveTaskTeam(team.id, projectNumber);
    setActiveTaskTeam(true);
    setShowReactiveTaskTeamModal(false);
    setOnConfirmLoading(false);
  };

  const onConfirmDeactiveDeliveryTeam = async () => {
    setOnConfirmLoading(true);
    await TeamManagementStore.deactiveDeliveryTeam(team.id, projectNumber);
    setActiveDeliveryTeam(false);
    setShowDeactiveDeliveryTeamModal(false);
    setOnConfirmLoading(false);
  };
  const onConfirmReactiveDeliveryTeam = async () => {
    setOnConfirmLoading(true);
    await TeamManagementStore.reactiveDeliveryTeam(team.id, projectNumber);
    setActiveDeliveryTeam(true);
    setShowReactiveDeliveryTeamModal(false);
    setOnConfirmLoading(false);
  };

  const deactiveTaskTeamModalText = {
    heading: 'Deactivate Task Team?',
    message: `This action will deactivate task team from the delivery team of ${deliveryTeamName}. Are you sure you want to deactivate ${teamName}?`,
  };

  const reactiveTaskTeamModalText = {
    heading: 'Reactivate Task Team?',
    message: `This action will reactivate task team. Are you sure you want to reactivate ${teamName}?`,
  };

  const deactiveDeliveryTeamModalText = {
    heading: 'Deactivate Delivery Team?',
    message: `This action will deactivate this delivery team from the programme of ${programmeName}. Are you sure you want to deactivate ${deliveryTeamName}?`,
  };

  const reactiveDeliveryTeamModalText = {
    heading: 'Reactivate Delivery Team?',
    message: `This action will reactivate delivery team. Are you sure you want to reactivate ${deliveryTeamName}?`,
  };

  const renderEditTeamModal = () => {
    const props = {
      projectNumber: projectNumber,
      teamName: teamName,
      abbreviation: team.code,
      onSave: (updatedTeamName) => {
        setTeamName(updatedTeamName);
        setShowEditTeamModal(false);
      },
      onCancel: () => setShowEditTeamModal(false),
    };
    switch (teamType) {
      case NodeType.DeliveryTeam:
        return showEditTeamModal && <EditTeamModal deliveryTeamId={team.id} {...props} />;
      case NodeType.TaskTeam:
        return showEditTeamModal && <EditTaskTeamModal taskTeamId={team.id} {...props} />;
      default:
        break;
    }
  };

  return (
    <div className={Style.header}>
      <div className={Style.heading}>
        <div className={Style.title}>{teamLabel} Team Details</div>
        {((teamType === NodeType.TaskTeam && activeTaskTeam) ||
          (teamType === NodeType.DeliveryTeam && activeDeliveryTeam)) && (
          <div className={Style.buttons}>
            <div className={Style.buttons}>
              <PrimaryIconButton
                icon="delete"
                size="medium"
                className={Style.actionButton}
                disabled={
                  (teamType === NodeType.TaskTeam && !canDeactiveTaskTeam) ||
                  (teamType === NodeType.DeliveryTeam && !canDeactiveDeliveryTeam)
                }
                onClick={() =>
                  teamType === NodeType.TaskTeam
                    ? setShowDeactiveTaskTeamModal(true)
                    : setShowDeactiveDeliveryTeamModal(true)
                }></PrimaryIconButton>
            </div>
          </div>
        )}

        {((teamType === NodeType.TaskTeam && !activeTaskTeam && activeDeliveryTeam) ||
          (teamType === NodeType.DeliveryTeam && !activeDeliveryTeam)) && (
          <div className={Style.buttons}>
            <div className={Style.buttons}>
              <PrimaryIconButton
                icon="redo"
                size="medium"
                className={Style.actionButton}
                onClick={() => {
                  teamType === NodeType.TaskTeam
                    ? setShowReactiveTaskTeamModal(true)
                    : setShowReactiveDeliveryTeamModal(true);
                }}></PrimaryIconButton>
            </div>
          </div>
        )}
      </div>

      <div className={Style.teamInfo}>
        <div className={classnames(Style.row, Style.label)}>
          {teamLabel} Team
          <PrimaryIconButton
            icon="edit"
            size="medium"
            className={Style.actionButton}
            onClick={() => {
              setShowEditTeamModal(true);
            }}></PrimaryIconButton>
        </div>
        <div className={classnames(Style.row)}>
          {teamName} ({team.code})
        </div>
      </div>
      <ConfirmationModal
        showModal={showDeactiveTaskTeamModal || showDeactiveDeliveryTeamModal}
        heading={
          teamType === NodeType.TaskTeam ? deactiveTaskTeamModalText.heading : deactiveDeliveryTeamModalText.heading
        }
        message={
          teamType === NodeType.TaskTeam ? deactiveTaskTeamModalText.message : deactiveDeliveryTeamModalText.message
        }
        confirmText="Yes"
        cancelText="No"
        loading={onConfirmLoading}
        onConfirm={teamType === NodeType.TaskTeam ? onConfirmDeactiveTaskTeam : onConfirmDeactiveDeliveryTeam}
        onCancel={
          teamType === NodeType.TaskTeam
            ? () => setShowDeactiveTaskTeamModal(false)
            : () => setShowDeactiveDeliveryTeamModal(false)
        }
      />
      <ConfirmationModal
        showModal={showReactiveTaskTeamModal || showReactiveDeliveryTeamModal}
        heading={
          teamType === NodeType.TaskTeam ? reactiveTaskTeamModalText.heading : reactiveDeliveryTeamModalText.heading
        }
        message={
          teamType === NodeType.TaskTeam ? reactiveTaskTeamModalText.message : reactiveDeliveryTeamModalText.message
        }
        confirmText="Yes"
        cancelText="No"
        loading={onConfirmLoading}
        onConfirm={teamType === NodeType.TaskTeam ? onConfirmReactiveTaskTeam : onConfirmReactiveDeliveryTeam}
        onCancel={
          teamType === NodeType.TaskTeam
            ? () => setShowReactiveTaskTeamModal(false)
            : () => setShowReactiveDeliveryTeamModal(false)
        }
      />
      {renderEditTeamModal()}
    </div>
  );
};

export default observer(TeamDetailsHeader);
