import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TaskInformation.module.scss';
import TaskInformationStore from './TaskInformationStore';
import TaskInformationDetails from './TaskInformationDetails';
import TaskInformationActions from './TaskInformationActions';
import { Loader, TabContent, TabMenu } from '@aurecon-creative-technologies/styleguide';
import TaskInformationHistory from './TaskInformationHistory';
import TaskInformationFiles from './TaskInformationFiles';
import TaskStatusIndicator from '../shared/TaskStatusIndicator';
import { useNavigate, useParams } from 'react-router-dom';
import GoBackButton from '../shared/GoBackButton';
import PageHeading from '../shared/PageHeading';
import FilesStore from '../files/FilesStore';
import FileInformation from '../files/fileInformation/FileInformation';
import { Pages } from '../../common/constants/Pages';
import TasksStore from '../tasks/TasksStore';
import { getAppInsightsInstance } from '../../utils/appInsights';
import { AppInsightPageView } from '../../common/constants/AppInsightPageView';
import { FromModuleFilesList } from '../../common/enums/FromModuleFilesList';
import TaskAttachmentFiles from './TaskAttachmentFiles';
import ActionBar from '../shared/ActionBar';
import ActionBarSection from '../shared/ActionBarSection';
import SecondaryButton from '../shared/SecondaryButton';
import SupportingFilesUploadModal from './supportingFilesUpload/SupportingFilesUploadModal';
import ErrorModal from '../shared/ErrorModal';

const TaskInformation: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [showUploadAttachmentFilesModal, setShowUploadAttachmentFilesModal] = useState(false);
  const [uploadAttachmentErrorMessage, setUploadAttachmentErrorMessage] = useState('');

  useEffect(() => {
    const appInsights = getAppInsightsInstance();
    if (appInsights)
      appInsights.trackPageView({
        name: AppInsightPageView.TASK_DETAILS,
        isLoggedIn: true,
        uri: `${Pages.Tasks.Route}/${params.id}`,
      });

    TaskInformationStore.init(Number(params.taskId));
  }, [params]);

  const onCloseUploadFileModal = (uploadSucceeded, errorMessage) => {
    setShowUploadAttachmentFilesModal(false);
    if (uploadSucceeded) {
      TaskInformationStore.loadTask();
    }
    if (errorMessage) {
      setUploadAttachmentErrorMessage(errorMessage);
    }
  };

  const canTaskSupportingFiles = TaskInformationStore.task && TaskInformationStore.task.canUploadDocuments;

  return (
    <>
      <GoBackButton
        onClick={() => {
          if (TaskInformationStore.isRefresh) {
            TaskInformationStore.setIsRefresh(false);
            TasksStore.refreshTasks();
          }

          navigate(Pages.Tasks.Route);
        }}
      />
      {TaskInformationStore.task ? (
        <>
          <PageHeading subPage>Task Information</PageHeading>
          <div className={Style.detailsPanel}>
            <div className={Style.taskDetails}>
              <div className={Style.panel}>
                <h2 className={Style.panelHeading}>
                  #{TaskInformationStore.task.id}
                  <TaskStatusIndicator
                    className={Style.taskStatus}
                    taskStatusId={TaskInformationStore.task.taskStatusId}
                    displayTaskStatus={TaskInformationStore.task.displayTaskStatus}
                  />
                </h2>
                <div className={Style.panelBody}>
                  <TaskInformationDetails />
                  <TaskInformationActions />
                </div>
              </div>

              <TabMenu
                cssClass={Style.tab}
                type="line"
                tabs={[
                  {
                    id: 1,
                    label: `Files (${
                      TaskInformationStore.task.taskFileContainers.length +
                      TaskInformationStore.task.attachmentFiles.length
                    })`,
                  },
                  { id: 2, label: `History` },
                ]}>
                <TabContent for={1}>
                  <div className={Style.tabContent}>
                    <ActionBar className={Style.actionBar}>
                      <ActionBarSection>
                        <SecondaryButton
                          disabled={!canTaskSupportingFiles}
                          onClick={() => setShowUploadAttachmentFilesModal(true)}>
                          Add Supporting File
                        </SecondaryButton>
                      </ActionBarSection>
                    </ActionBar>
                    <TaskInformationFiles />
                    {!!TaskInformationStore.task.attachmentFiles.length && <TaskAttachmentFiles />}
                  </div>
                </TabContent>
                <TabContent for={2}>
                  <div className={Style.tabContent}>
                    <TaskInformationHistory />
                  </div>
                </TabContent>
              </TabMenu>
            </div>
            {FilesStore.isShowingFileInformation && (
              <div className={Style.fileDetails}>
                <FileInformation fromModule={FromModuleFilesList.Task} />
              </div>
            )}
          </div>
        </>
      ) : TaskInformationStore.isLoadingTask ? (
        <Loader label="Loading task..." />
      ) : (
        <div>Task not found</div>
      )}
      {showUploadAttachmentFilesModal && TaskInformationStore.task && (
        <SupportingFilesUploadModal
          taskId={TaskInformationStore.task.id}
          projectNumber={TaskInformationStore.task.projectNumber}
          closeModal={(uploadSucceeded, errorMessage) =>
            onCloseUploadFileModal(uploadSucceeded, errorMessage)
          }></SupportingFilesUploadModal>
      )}
      {uploadAttachmentErrorMessage && (
        <ErrorModal
          closeModal={() => setUploadAttachmentErrorMessage('')}
          errorCode={null}
          errorMessage="Upload attachment file error"
        />
      )}
    </>
  );
};

export default observer(TaskInformation);
