import * as React from 'react';
import { observer } from 'mobx-react-lite';
import Icon from '../../shared/Icon';
import NavBarSelectorItemContainer from '../../shared/NavBarSelectorItemContainer';
import { TaskTeamItem } from './ItemType';
import Style from './styles/NavBarSelectorTaskTeam.module.scss';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';
import { useLocation } from 'react-router';
import { Pages } from '../../../common/constants/Pages';
import AppStore from '../../../stores/AppStore';
import classNames from 'classnames';

export interface INavBarSelectorTaskTeamProps {
  item: TaskTeamItem;
}

const NavBarSelectorTaskTeam: React.FC<INavBarSelectorTaskTeamProps> = ({ item }) => {
  const location = useLocation();

  return (
    <div className={Style.wrapContainer}>
      <NavBarSelectorItemContainer
        className={classNames(Style.container, {
          [Style.disableTaskTeam]:
            location.pathname === `${Pages.Transmittals.Route}/new` &&
            item.project.projectNumber !== AppStore.selectedProjectNumber,
        })}>
        <Icon className={Style.icon} name="folder" />
        <span className={Style.selectedProgramme}>{item.programme.title}</span>
        <span>|</span>
        <span className={Style.selectedProject}>{item.project.title}</span>
        <span className={Style.selectedTaskTeam}>
          {item.taskTeam.isTemporaryAccessible ? (
            <Icon className={Style.icon} name="group_off" outlined />
          ) : (
            <Icon className={Style.icon} name="people" />
          )}
          <span>{item.taskTeam.deliveryTeamTitle}</span>
          <span>|</span>
          <span className={Style.taskTeamTitle}>{item.taskTeam.title}</span>
        </span>
      </NavBarSelectorItemContainer>
      {item.taskTeam.isTemporaryAccessible && (
        <Tooltip show={`Temporary Access`} cssClass={Style.tooltipIconSchedule} defaultUp>
          <Icon className={Style.icon} name="schedule" outlined />
        </Tooltip>
      )}
    </div>
  );
};

export default observer(NavBarSelectorTaskTeam);
