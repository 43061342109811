import { uniq, uniqBy } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import {
  IReleasedFile,
  ITransmittal,
  ITransmittalMessage,
  IAttachmentFile,
} from '../../api/authenticated/transmittals/getTransmittal';
import {
  ISearchTransmittal,
  ISearchTransmittalListItem,
  ISearchTransmittalRequest,
  ITransmittalSummary,
  searchTransmittals,
} from '../../api/authenticated/transmittals/searchTransmittal';
import { TransmittalSortOptionText } from '../../common/constants/TransmittalSortOptionText';
import { SortType, SortTypes } from '../../common/enums/SortType';
import { TransmittalSortOption } from '../../common/enums/TransmittalSortOption';
import { IPagedResponse, IPageMetaData } from '../../common/models/IPagedResponse';
import config from '../../config';
import { FILE_CREATED_DATE, dateTimeFormat } from '../../utils/dateUtils';
import { PagingArray } from '../../utils/pagingArray';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import { TabFilter, TabFilterText } from './Types';
import convertHTMLtoText from '../../utils/convertHTMLtoText';
import { getSelectedItems } from '../../utils/getSelectedItems';
import { getAllTransmittals, ITransmittalDetails } from '../../api/authenticated/transmittals/getAllTransmittals';
import { ITransmittalCSVData } from './TransmittalsExportCSVLink';
import { IDateRangeFilter, ITableColumn } from '../../common/interfaces/TableColumn';
import { TransmittalFlaggedText } from '../../common/constants/TransmittalFlaggedText';
import { TransmittalStatusText } from '../../common/constants/TransmittalStatusText';
import { getTransmittalReasons, IReason } from '../../api/authenticated/transmittals/getTransmittalReasons';
import { getTransmittalTypes, IType } from '../../api/authenticated/transmittals/getTransmittalTypes';
import { IAdjustableTableColumn, IVisibilityColumn } from '../../common/interfaces/AdjustableTableColumn';
import ActionBarStore from './ActionBarStore';
import AppStore from '../../stores/AppStore';
import { getTransmittalDeliveryTeam } from '../../api/authenticated/transmittals/getTransmittalDeliveryTeam';
import { getAllTransmittalDeliveryTeam } from '../../api/authenticated/transmittals/getAllTransmittalDeliveryTeam';
import {
  getTransmittalsExportByFile,
  ITransmittalExportByFileResponse,
} from '../../api/authenticated/transmittals/getTransmittalsExportByFile';
import { ITransmittalExportByFileCSVData } from './ExportByFileModal';
import { TaskTeamItem } from './navBarSelector/ItemType';

export const tableHeaderKeyValueFields = {
  transmittalID: {
    label: 'Transmittal ID',
    value: 'title',
  },
  flagged: {
    label: 'Flag',
    value: 'flagged',
  },
  subject: {
    label: 'Subject',
    value: 'transmittalMessages',
  },
  status: {
    label: 'Status',
    value: 'status',
  },
  lastUpdated: {
    label: 'Last Updated',
    value: 'lastUpdated',
  },
  dueDate: {
    label: 'Due Date',
    value: 'dueDate',
  },
  initiatedDate: {
    label: 'Initiated Date',
    value: 'createdDate',
  },
  type: {
    label: 'Type',
    value: 'transmittalTypeTitle',
  },
  reason: {
    label: 'Reason',
    value: 'transmittalReasonTitle',
  },
  initiator: {
    label: 'Initiator',
    value: 'createdByName',
  },
  taskTeamOriginator: {
    label: 'Task Team Originator',
    value: 'taskTeamTitle',
  },
  totalResponses: {
    label: 'Total Responses',
    value: 'totalResponses',
  },
  totalFiles: {
    label: 'Total Files',
    value: 'totalFiles',
  },
};

const CheckBoxColumn = 'checkBoxColumn';
const ActionColumn = 'actionColumn';

export const ActionColumns = [CheckBoxColumn, ActionColumn];
export interface ITransmittalSearchListItem extends ISearchTransmittal {
  checked: boolean;
}
export class TransmittalsStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _fileTableColumnsStorageKey = 'transmittal-table-columns';
  public _tableColumnsForForShowHide: IVisibilityColumn[] = [];
  public searchTransmittals: ITransmittalSearchListItem[] = [];
  public exportAllTransmittals: ITransmittalDetails[] = [];
  public exportByFileTransmittals: ITransmittalExportByFileResponse[] = [];
  public selectedIds: number[] = [];
  public selectedItems: ITransmittalSearchListItem[] = [];
  public showSelectedItems = false;
  public transmittalSummary: ITransmittalSummary = {
    notificationToMe: 0,
    initiatedByMe: 0,
    visibleToMe: 0,
  };
  public allDeliveryTeamTotalCount = 0;
  public currentTab: TabFilter = TabFilter.MyNotifications;
  public isLoading = false;

  public isSearchedOrFiltered = false;
  public pageNumber = 1;
  public pagingMetaData: IPageMetaData = {
    pageNumber: 0,
    pageCount: 0,
  } as IPageMetaData;
  public searchPayload: ISearchTransmittalRequest = {
    sortColumn: TransmittalSortOptionText[TransmittalSortOption.InitiatedDate],
    sortDirection: SortTypes.DESC,
    projectNumber: null,
    page: 1,
    pageSize: config.defaultPageSize,
  };
  public tableColumns: ITableColumn[] = [];
  public showSelectCheckbox = false;
  private lastSelectedIndexes: number[] = [];
  public transmittalTypes: IType[] = [];
  public allReasons: IReason[] | [] = [];
  public abortController: AbortController = new AbortController();

  public setShowSelectCheckbox(isShow: boolean) {
    runInAction(() => {
      this.showSelectCheckbox = isShow;
    });
  }

  public setPagingMetaData(res: IPagedResponse<ISearchTransmittal>) {
    const paginatedListObject = new PagingArray(config.defaultPageSize, this.pageNumber, res.totalCount, res.pageCount);
    runInAction(() => {
      this.pagingMetaData = paginatedListObject.getMetaData();
    });
  }

  public async setCurrentTab(value: TabFilter) {
    runInAction(() => {
      this.currentTab = value;
      this.pageNumber = 1;
    });

    this.abortRequests();
    this.resetTransmittalContent();
    this.resetFilterParameters();
    this.resetSearchParameters();
    this.fetchTransmittalsData();
  }

  public resetTransmittalContent() {
    runInAction(() => {
      this.selectedIds = [];
      this.searchTransmittals = [];
      this.showSelectedItems = false;
    });
  }

  public resetSearchParameters() {
    this.searchPayload.page = this.pageNumber;
  }

  public resetFilterParameters() {
    runInAction(() => {
      this.tableColumns.forEach((c) => {
        c.dateFilter = undefined;
        c.listFilter = undefined;
        c.textFilter = undefined;
        c.sort = undefined;
      });
      this.loadTableColumns();
      const sortcolumn =
        this.currentTab === TabFilter.AllDeliveryTeam
          ? TransmittalSortOptionText[TransmittalSortOption.LastUpdated]
          : TransmittalSortOptionText[TransmittalSortOption.InitiatedDate];

      this.searchPayload = {
        sortColumn: sortcolumn,
        sortDirection: SortTypes.DESC,
        projectNumber: null,
        page: 1,
        pageSize: config.defaultPageSize,
      };
    });
  }

  public setTabFilterCondition() {
    runInAction(() => {
      this.searchPayload.notificationToMe = this.currentTab === TabFilter.MyNotifications;
      this.searchPayload.initiatedByMe = this.currentTab === TabFilter.InitiatedByMe;
      this.searchPayload.visibleToMe = this.currentTab === TabFilter.VisibleToMe;
    });
  }

  public handleFilterChange(conditions: ISearchTransmittalRequest) {
    runInAction(() => {
      this.pageNumber = 1;
      this.searchPayload = { ...conditions, page: 1 };
    });
    if (!this.isLoading) {
      this.fetchTransmittalsData();
    }
  }

  public async setCurrentPage(page) {
    if (page === this.pageNumber) return;
    this.pageNumber = page;
    this.searchPayload.page = this.pageNumber;
    this.searchTransmittals = [];
    this.searchPayload.pageSize = config.defaultPageSize;
    await this.fetchTransmittalsData();
  }

  public checkIsSearchedOrFiltered(conditions: ISearchTransmittalRequest) {
    runInAction(() => {
      if (
        (conditions.transmittalTypeIds && conditions.transmittalTypeIds.length > 0) ||
        (conditions.transmittalReasonIds && conditions.transmittalReasonIds.length > 0) ||
        conditions.transmittalTitle ||
        conditions.closed ||
        conditions.flagged ||
        conditions.overdue ||
        conditions.lastUpdated1 ||
        conditions.lastUpdated2 ||
        conditions.lastUpdatedOperator ||
        conditions.dueDate1 ||
        conditions.dueDate2 ||
        conditions.dueDateOperator ||
        conditions.initiatedDate1 ||
        conditions.initiatedDate2 ||
        conditions.initiatedDateOperator ||
        conditions.initiator ||
        conditions.taskTeamOriginator ||
        conditions.subject ||
        (conditions.sortColumn &&
          conditions.sortColumn !== TransmittalSortOptionText[TransmittalSortOption.InitiatedDate]) ||
        conditions.sortDirection === SortTypes.ASC
      ) {
        this.isSearchedOrFiltered = true;
      } else {
        this.isSearchedOrFiltered = false;
      }
    });
  }

  public get totalSelected() {
    return this.selectedIds.length;
  }

  public selectAllTransmittal() {
    this.searchTransmittals.forEach((t) => {
      t.checked = true;
    });
    const pageSelectedIds = this.searchTransmittals.map((x) => x.id);
    this.selectedIds = uniq([...this.selectedIds, ...pageSelectedIds]);
    this.selectedItems = uniqBy([...this.selectedItems, ...this.searchTransmittals], 'id');
  }

  public clearTransmittalSelected() {
    runInAction(() => {
      this.searchTransmittals.forEach((t) => {
        t.checked = false;
      });
      this.selectedIds = [];
      this.selectedItems = [];
    });
  }

  public getCSVData() {
    return {
      filename: `Transmittals_${NavBarSelectorStore.selectedItem?.project.projectNumber}_${
        TabFilterText[this.currentTab]
      }_${dateTimeFormat(new Date(), FILE_CREATED_DATE)}.csv`,
      headers: [
        { label: 'Transmittal ID', key: 'title' },
        { label: 'Subject', key: 'subject' },
        { label: 'Last Updated', key: 'lastUpdated' },
        { label: 'Initiator', key: 'initiator' },
        { label: 'Notified', key: 'notified' },
        { label: 'Visible to', key: 'visibleTo' },
        { label: 'Type', key: 'type' },
        { label: 'Reason', key: 'reason' },
        { label: 'Initiated Date', key: 'initiatedDate' },
        { label: 'Due Date', key: 'dueDate' },
        { label: 'Message', key: 'message' },
        { label: 'Number of Responses', key: 'totalResponses' },
        { label: 'Number of Content Files', key: 'totalContentFiles' },
        { label: 'Number of Supporting Files', key: 'totalSupportingFiles' },
        { label: 'Status', key: 'status' },
      ],
      data: this.selectedItems.map((t) => {
        return {
          title: t.title,
          subject: t.subject,
          lastUpdated: dateTimeFormat(t.lastUpdated),
          initiator: t.createdByEmail,
          type: t.transmittalTypeTitle,
          reason: t.transmittalReasonTitle,
          initiatedDate: dateTimeFormat(t.createdDate),
          dueDate: t.dueDate ? dateTimeFormat(t.dueDate) : '',
          totalResponses: t.totalResponses,
          totalFiles: t.totalFiles,
          status: this.getTransmittalStatus(t),
        };
      }),
    };
  }
  private getTransmittalStatus(t) {
    let status = `${t.flagged ? 'Flagged' : ''}`;
    if (status) {
      status += ', ';
    }
    status += `${t.overdue && !t.closed ? 'Overdue' : t.closed ? 'Closed' : ''}`;
    return status;
  }

  public async getExportAllTransmittals() {
    try {
      let response;
      if (this.currentTab === TabFilter.AllDeliveryTeam) {
        response = await getAllTransmittalDeliveryTeam({
          projectNumber: NavBarSelectorStore.selectedItem?.project?.projectNumber ?? null,
          deliveryTeamId: AppStore.selectedDeliveryTeamId ?? AppStore.deliveryTeamId ?? null,
        });
      } else {
        response = await getAllTransmittals({
          projectNumber: NavBarSelectorStore.selectedItem?.project?.projectNumber ?? null,
          notificationToMe: this.searchPayload.notificationToMe ?? null,
          initiatedByMe: this.searchPayload.initiatedByMe ?? null,
          visibleToMe: this.searchPayload.visibleToMe ?? null,
        });
      }

      this.exportAllTransmittals = response;
    } catch {
      runInAction(() => {
        this.exportAllTransmittals = [];
      });
    }
  }

  public async getExportByFileTransmittals(lastUpdatedFrom, lastUpdatedTo, lastUpdatedOperator) {
    try {
      const param = {
        projectNumber: NavBarSelectorStore.selectedItem?.project?.projectNumber ?? null,
        deliveryTeamId: AppStore.selectedDeliveryTeamId ?? AppStore.deliveryTeamId ?? null,
        lastUpdatedFrom: lastUpdatedFrom ?? null,
        lastUpdatedTo: lastUpdatedTo ?? null,
        lastUpdatedOperator: lastUpdatedOperator ?? null,
      };
      const response = await getTransmittalsExportByFile(param);

      this.exportByFileTransmittals = response;
    } catch {
      runInAction(() => {
        this.exportByFileTransmittals = [];
      });
    }
  }

  public mappingCSVData(exportDataSet: ITransmittalDetails[]) {
    return {
      filename: `Transmittals_${NavBarSelectorStore.selectedItem?.project.projectNumber}_${
        TabFilterText[this.currentTab]
      }_${dateTimeFormat(new Date(), FILE_CREATED_DATE)}.csv`,
      headers: [
        { label: 'Transmittal ID', key: 'title' },
        { label: 'Subject', key: 'subject' },
        { label: 'Last Updated', key: 'lastUpdated' },
        { label: 'Initiator', key: 'initiator' },
        { label: 'Notified', key: 'notified' },
        { label: 'Visible to', key: 'visibleTo' },
        { label: 'Type', key: 'type' },
        { label: 'Reason', key: 'reason' },
        { label: 'Initiated Date', key: 'initiatedDate' },
        { label: 'Due Date', key: 'dueDate' },
        { label: 'Message', key: 'message' },
        { label: 'Number of Responses', key: 'totalResponses' },
        { label: 'Number of Content Files', key: 'totalContentFiles' },
        { label: 'Number of Supporting Files', key: 'totalSupportingFiles' },
        { label: 'Status', key: 'status' },
      ],
      data: exportDataSet.map((t) => {
        return {
          title: t.title,
          subject: t.lastSubject,
          lastUpdated: t.lastUpdated ? dateTimeFormat(t.lastUpdated) : '',
          initiator: t.createdByEmail,
          notified: t.notifyToUserNames,
          visibleTo: t.visibleUserNames,
          type: t.transmittalTypeTitle,
          reason: t.transmittalReasonTitle,
          initiatedDate: dateTimeFormat(t.createdDate),
          dueDate: t.dueDate ? dateTimeFormat(t.dueDate) : '',
          message: convertHTMLtoText(t.lastMessage),
          totalResponses: t.totalMessage,
          totalContentFiles: t.totalAttachmentFiles,
          totalSupportingFiles: t.totalReleasedFiles,
          status: this.getTransmittalStatus(t),
        };
      }),
    } as ITransmittalCSVData;
  }

  public mappingCSVExportByFile(exportDataSet: ITransmittalExportByFileResponse[]) {
    return {
      filename: `Transmittals_${NavBarSelectorStore.selectedItem?.project.projectNumber}_${
        (NavBarSelectorStore.selectedItem as TaskTeamItem)?.taskTeam?.deliveryTeamCode
      }_${dateTimeFormat(new Date(), FILE_CREATED_DATE)}.csv`,
      headers: [
        { label: 'Filename', key: 'title' },
        { label: 'Original Filename', key: 'originalFilename' },
        { label: 'Title/Description', key: 'fileContainerDescription' },
        { label: 'Shared Date', key: 'sharedDate' },
        { label: 'Shared Suitability', key: 'sharedSuitability' },
        { label: 'Published Date', key: 'publishedDate' },
        { label: 'Published Suitability', key: 'publishedSuitability' },
        { label: 'Transmittal ID', key: 'transmittalTitle' },
        { label: 'Transmittal Subject', key: 'transmittalSubject' },
        { label: 'Transmittal Date', key: 'lastUpdated' },
        { label: 'Transmittal Type', key: 'transmittalTypeTitle' },
        { label: 'Transmittal Reason', key: 'transmittalReasonTitle' },
        { label: 'Initiator', key: 'createdByEmail' },
        { label: 'Task team originator', key: 'taskTeamTitle' },
      ],
      data: exportDataSet.map((t) => {
        return {
          ...t,
          sharedDate: t.sharedDate ? dateTimeFormat(new Date(t.sharedDate)).toString() : '',
          publishedDate: t.publishedDate ? dateTimeFormat(new Date(t.publishedDate)).toString() : '',
          lastUpdated: t.lastUpdated ? dateTimeFormat(new Date(t.lastUpdated)).toString() : '',
        };
      }),
    } as ITransmittalExportByFileCSVData;
  }

  public async fetchTransmittals() {
    this.setTabFilterCondition();
    if (!this.searchPayload.page || !this.searchPayload.pageSize) {
      this.searchPayload.page = this.pageNumber;
    }
    this.checkIsSearchedOrFiltered(this.searchPayload);
    runInAction(() => {
      this.isLoading = true;
    });
    try {
      const response = await searchTransmittals(
        {
          ...this.searchPayload,
          projectNumber: NavBarSelectorStore.selectedItem?.project?.projectNumber ?? null,
        },
        this.abortController.signal
      );
      this.transmittalSummary = response.transmittalSummary;

      runInAction(() => {
        if (this.currentTab !== TabFilter.AllDeliveryTeam) {
          this.searchTransmittals = response.pageResult.data?.map((t) => {
            return { ...t, checked: this.isSelectedItem(t.id) };
          });
          this.setPagingMetaData(response.pageResult);
        }
        this.isLoading = false;
      });
    } catch {
      runInAction(() => {
        this.selectedIds = [];
        this.selectedItems = [];
        this.searchTransmittals = [];
        this.transmittalSummary = {
          notificationToMe: 0,
          initiatedByMe: 0,
          visibleToMe: 0,
        };
      });
    }
  }

  public async fetchAllDeliveryTeamTransmittals() {
    if (!(AppStore.projectAdminister || AppStore.isSystemAdmin || this.currentTab === TabFilter.AllDeliveryTeam))
      return;
    const selectedDeliveryTeamId = AppStore.selectedDeliveryTeamId ?? AppStore.deliveryTeamId;
    if (!selectedDeliveryTeamId) return;

    if (!this.searchPayload.page || !this.searchPayload.pageSize) {
      this.searchPayload.page = this.pageNumber;
    }
    this.checkIsSearchedOrFiltered(this.searchPayload);
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const allDeliveryTeamTransmittal = await getTransmittalDeliveryTeam(
        {
          ...this.searchPayload,
          sortColumn: TransmittalSortOptionText[TransmittalSortOption.LastUpdated],
          lastUpdatedFrom: this.searchPayload.lastUpdated1,
          lastUpdatedTo: this.searchPayload.lastUpdated2,
          dueDateFrom: this.searchPayload.dueDate1,
          dueDateTo: this.searchPayload.dueDate2,
          initiatedDateFrom: this.searchPayload.initiatedDate1,
          initiatedDateTo: this.searchPayload.initiatedDate2,
          projectNumber: NavBarSelectorStore.selectedItem?.project?.projectNumber ?? null,
          deliveryTeamId: selectedDeliveryTeamId,
        },
        this.abortController.signal
      );
      runInAction(() => {
        this.allDeliveryTeamTotalCount = allDeliveryTeamTransmittal.allDeliveryTeamSummary;
        if (this.currentTab === TabFilter.AllDeliveryTeam) {
          this.searchTransmittals = [];
          this.searchTransmittals = allDeliveryTeamTransmittal.pageResult.data.map((t) => {
            return { ...t, checked: this.isSelectedItem(t.id) };
          });
          this.setPagingMetaData(allDeliveryTeamTransmittal.pageResult);
        }
        this.isLoading = false;
      });
    } catch {
      runInAction(() => {
        this.selectedIds = [];
        this.selectedItems = [];
        this.searchTransmittals = [];
        this.allDeliveryTeamTotalCount = 0;
      });
    }
  }

  public calFunc(transmittal: ITransmittal) {
    let totalDTUsers = 0;
    transmittal.transmittalVisibilityDeliveryTeams.forEach((x) => (totalDTUsers += x.userCount));

    let totalTTUsers = 0;
    transmittal.transmittalVisibilityTaskTeams.forEach((tt) => {
      totalTTUsers += tt.userCount ?? 0;
    });

    let totalAppUsers = 0;
    transmittal.transmittalVisibilityAppointingParties.forEach((x) => {
      totalAppUsers += x.userCount;
    });

    return (
      totalTTUsers +
      totalDTUsers +
      transmittal.transmittalVisibilityAllUsers.length +
      transmittal.transmittalVisibilityExternalUsers.length +
      transmittal.transmittalVisibilityTaskTeamUsers.length +
      totalAppUsers
    );
  }

  public get filteredTransmittals(): ISearchTransmittalListItem[] {
    if (this.showSelectedItems)
      return [...this.searchTransmittals.filter((f) => this.selectedItems.map((m) => m.id).includes(f.id))];
    return [...this.searchTransmittals];
  }

  public get getTableColumns(): ITableColumn[] {
    return [...this.tableColumns];
  }

  public async getTypes() {
    if (!NavBarSelectorStore.selectedItem) return;

    try {
      const transmittalTypes = await getTransmittalTypes(
        NavBarSelectorStore.selectedItem.project.projectNumber,
        this.abortController.signal
      );
      runInAction(() => {
        this.transmittalTypes = transmittalTypes || [];
      });
    } catch {
      runInAction(() => {
        this.transmittalTypes = [];
      });
    }
  }

  public async getReasons() {
    if (!NavBarSelectorStore.selectedItem?.project) return;

    try {
      const transmittalReasons = await getTransmittalReasons(
        NavBarSelectorStore.selectedItem.project.projectNumber,
        this.abortController.signal
      );

      runInAction(() => {
        this.allReasons = transmittalReasons || [];
      });
    } catch {
      runInAction(() => {
        this.allReasons = [];
      });
    }
  }

  public async loadTableColumns() {
    await this.getTypes();
    await this.getReasons();
    const sortColoumn =
      this.currentTab === TabFilter.AllDeliveryTeam
        ? TransmittalSortOptionText[TransmittalSortOption.LastUpdated]
        : TransmittalSortOptionText[TransmittalSortOption.InitiatedDate];

    const tableColumns: ITableColumn[] = [
      {
        label: '',
        valueField: CheckBoxColumn,
        onCheckbox: (checked) =>
          checked ? ActionBarStore.selectedAllTransmittals() : ActionBarStore.clearSelectionTransmittals(),
        checked: ActionBarStore.showSelectClear(),
        width: 35,
      },
      {
        label: tableHeaderKeyValueFields.transmittalID.label,
        valueField: tableHeaderKeyValueFields.transmittalID.value,
        textFilter: { filter: this.getTableColumn(tableHeaderKeyValueFields.transmittalID.label)?.textFilter?.filter },
        width: 150,
        minWidth: 150,
      },
      {
        label: tableHeaderKeyValueFields.flagged.label,
        valueField: tableHeaderKeyValueFields.flagged.value,
        listFilter: {
          fieldValues: [TransmittalFlaggedText.Yes, TransmittalFlaggedText.No],
          filter: this.getTableColumn(tableHeaderKeyValueFields.flagged.label)?.listFilter?.filter,
        },
        width: 50,
        minWidth: 50,
      },
      {
        label: tableHeaderKeyValueFields.subject.label,
        valueField: tableHeaderKeyValueFields.subject.value,
        textFilter: { filter: this.getTableColumn(tableHeaderKeyValueFields.subject.label)?.textFilter?.filter },
      },
      {
        label: tableHeaderKeyValueFields.status.label,
        valueField: tableHeaderKeyValueFields.status.value,
        listFilter: {
          fieldValues: [TransmittalStatusText.Open, TransmittalStatusText.Closed, TransmittalStatusText.Overdue],
          filter: this.getTableColumn(tableHeaderKeyValueFields.status.label)?.listFilter?.filter,
        },
        width: 70,
        minWidth: 70,
      },
      {
        label: tableHeaderKeyValueFields.lastUpdated.label,
        valueField: tableHeaderKeyValueFields.lastUpdated.value,
        dateFilter: {
          startDate: this.getTableColumn(tableHeaderKeyValueFields.lastUpdated.label)?.dateFilter?.startDate ?? null,
          endDate: this.getTableColumn(tableHeaderKeyValueFields.lastUpdated.label)?.dateFilter?.endDate ?? null,
          operator: this.getTableColumn(tableHeaderKeyValueFields.lastUpdated.label)?.dateFilter?.operator ?? 1,
        },
        sort: sortColoumn === TransmittalSortOptionText[TransmittalSortOption.LastUpdated] ? SortTypes.DESC : undefined,
        width: 250,
        minWidth: 250,
      },
      {
        label: tableHeaderKeyValueFields.dueDate.label,
        valueField: tableHeaderKeyValueFields.dueDate.value,
        dateFilter: {
          startDate: this.getTableColumn(tableHeaderKeyValueFields.dueDate.label)?.dateFilter?.startDate ?? null,
          endDate: this.getTableColumn(tableHeaderKeyValueFields.dueDate.label)?.dateFilter?.endDate ?? null,
          operator: this.getTableColumn(tableHeaderKeyValueFields.dueDate.label)?.dateFilter?.operator ?? 1,
        },
        width: 250,
        minWidth: 250,
      },
      {
        label: tableHeaderKeyValueFields.initiatedDate.label,
        valueField: tableHeaderKeyValueFields.initiatedDate.value,
        dateFilter: {
          startDate: this.getTableColumn(tableHeaderKeyValueFields.initiatedDate.label)?.dateFilter?.startDate ?? null,
          endDate: this.getTableColumn(tableHeaderKeyValueFields.initiatedDate.label)?.dateFilter?.endDate ?? null,
          operator: this.getTableColumn(tableHeaderKeyValueFields.initiatedDate.label)?.dateFilter?.operator ?? 1,
        },
        sort:
          sortColoumn === TransmittalSortOptionText[TransmittalSortOption.InitiatedDate] ? SortTypes.DESC : undefined,
        width: 250,
        minWidth: 250,
      },
      {
        label: tableHeaderKeyValueFields.type.label,
        valueField: tableHeaderKeyValueFields.type.value,
        textFilter: { filter: this.getTableColumn(tableHeaderKeyValueFields.type.label)?.textFilter?.filter },
        listFilter: {
          fieldValues: this.transmittalTypes.map((i) => i.title),
          filter: this.getTableColumn(tableHeaderKeyValueFields.type.label)?.listFilter?.filter,
        },
      },
      {
        label: tableHeaderKeyValueFields.reason.label,
        valueField: tableHeaderKeyValueFields.reason.value,
        textFilter: { filter: this.getTableColumn(tableHeaderKeyValueFields.reason.label)?.textFilter?.filter },
        listFilter: {
          fieldValues: uniqBy(this.allReasons, (t) => [t.namingCode, t.title].join())
            .map((t) => ({
              id: t.namingCode,
              label: t.title,
            }))
            .map((i) => i.label),
          filter: this.getTableColumn(tableHeaderKeyValueFields.reason.label)?.listFilter?.filter,
        },
      },
      {
        label: tableHeaderKeyValueFields.initiator.label,
        valueField: tableHeaderKeyValueFields.initiator.value,
        textFilter: { filter: this.getTableColumn(tableHeaderKeyValueFields.initiator.label)?.textFilter?.filter },
      },
      {
        label: tableHeaderKeyValueFields.taskTeamOriginator.label,
        valueField: tableHeaderKeyValueFields.taskTeamOriginator.value,
        textFilter: {
          filter: this.getTableColumn(tableHeaderKeyValueFields.taskTeamOriginator.label)?.textFilter?.filter,
        },
      },
      {
        label: tableHeaderKeyValueFields.totalResponses.label,
        valueField: tableHeaderKeyValueFields.totalResponses.value,
      },
      {
        label: tableHeaderKeyValueFields.totalFiles.label,
        valueField: tableHeaderKeyValueFields.totalFiles.value,
      },
    ];

    const _defaultColumnWidth = 100;
    const memorizedTableColumns = this.getAdjustableTableColumns();
    const fileTableColumns = memorizedTableColumns.length
      ? memorizedTableColumns
      : tableColumns.map((m) => {
          return {
            id: m.valueField,
            label: m.label,
            width: m.width || _defaultColumnWidth,
            minWidth: m.minWidth || _defaultColumnWidth,
            visible: true,
          };
        });

    localStorage.setItem(
      `${this._fileTableColumnsStorageKey}-${TabFilterText[this.currentTab]}`,
      JSON.stringify(fileTableColumns)
    );

    runInAction(() => {
      const labelsToBeSorted = fileTableColumns.map((m) => m.id);
      this._tableColumnsForForShowHide = tableColumns.map((f) => ({ id: f.valueField, label: f.label }));
      this.tableColumns = tableColumns
        .sort((a, b) => {
          return labelsToBeSorted.indexOf(a.valueField) - labelsToBeSorted.indexOf(b.valueField);
        })
        .map((m) => {
          const column = fileTableColumns.find((f) => f.id === m.valueField);
          return {
            ...m,
            width: column?.width || _defaultColumnWidth,
            minWidth: column?.minWidth || _defaultColumnWidth,
          };
        });
    });
  }

  public getAdjustableTableColumns = () => {
    const memorizedTableColumns = localStorage.getItem(
      `${this._fileTableColumnsStorageKey}-${TabFilterText[this.currentTab]}`
    );
    return memorizedTableColumns ? (JSON.parse(memorizedTableColumns) as IAdjustableTableColumn[]) : [];
  };

  public setAdjustableTableColumns = (items: IAdjustableTableColumn[], reload?: boolean) => {
    runInAction(() => {
      localStorage.setItem(
        `${this._fileTableColumnsStorageKey}-${TabFilterText[this.currentTab]}`,
        JSON.stringify(items)
      );
      if (!reload) return;
      this.loadTableColumns();
    });
  };

  public applyFilter(column: string, filter: string | IDateRangeFilter) {
    runInAction(async () => {
      this.pageNumber = 1;
      const parameter = this.getFilterFromTableColumns(column, filter);
      this.searchPayload = {
        ...this.searchPayload,
        ...parameter,
        page: this.pageNumber,
      };
      await this.fetchTransmittalsData();
    });
  }
  public async fetchTransmittalsData() {
    if (this.currentTab === TabFilter.AllDeliveryTeam) {
      await this.fetchAllDeliveryTeamTransmittals();
    } else {
      await this.fetchTransmittals();
    }
  }
  public setSortColumn(sortedColumn?: string, direction?: SortType) {
    runInAction(() => {
      this.searchPayload.sortColumn =
        sortedColumn && direction !== SortTypes.NONE
          ? sortedColumn
          : TransmittalSortOptionText[TransmittalSortOption.InitiatedDate];
      this.searchPayload.sortDirection = direction !== SortTypes.NONE ? direction : SortTypes.DESC;
    });
  }

  public applySort(column?: string, direction?: SortType) {
    runInAction(async () => {
      this.pageNumber = 1;
      this.searchPayload.page = 1;
      this.setSortColumn(column, direction);
      this.tableColumns.forEach((c) => {
        if (c.label === column) {
          c.sort = direction;
        } else {
          c.sort = SortTypes.NONE;
        }
      });
      await this.fetchTransmittalsData();
    });
  }

  private getFilterFromTableColumns(column: string, filter: string | IDateRangeFilter): ISearchTransmittalRequest {
    const parameter: { [k: string]: string | IDateRangeFilter | number[] } = {};

    this.tableColumns.forEach((c) => {
      if (c.valueField === column) {
        if (c.dateFilter) c.dateFilter = filter as IDateRangeFilter;
        if (c.textFilter) c.textFilter.filter = filter as string;
        if (c.listFilter) c.listFilter.filter = filter as string;
      }
      if (c.dateFilter) parameter[c.valueField] = c.dateFilter;
      if (c.textFilter) parameter[c.valueField] = c.textFilter.filter!;
      if (c.listFilter) {
        if (c.valueField === tableHeaderKeyValueFields.type.value) {
          parameter[c.valueField] = this.transmittalTypes
            .filter((f) => f.title === c.listFilter!.filter)
            ?.map((m) => Number(m.id));
        }
        if (c.valueField === tableHeaderKeyValueFields.reason.value) {
          parameter[c.valueField] = this.allReasons
            .filter((f) => f.title === c.listFilter!.filter)
            ?.map((m) => Number(m.id));
        }

        if (
          c.valueField === tableHeaderKeyValueFields.status.value ||
          c.valueField === tableHeaderKeyValueFields.flagged.value
        ) {
          parameter[c.valueField] = c.listFilter!.filter ?? '';
        }
      }
    });

    const isOpen = parameter[tableHeaderKeyValueFields.status.value] === TransmittalStatusText.Open;
    return {
      transmittalTitle: parameter[tableHeaderKeyValueFields.transmittalID.value],
      subject: parameter[tableHeaderKeyValueFields.subject.value],
      closed:
        isOpen || parameter[tableHeaderKeyValueFields.status.value] === TransmittalStatusText.Closed
          ? parameter[tableHeaderKeyValueFields.status.value] === TransmittalStatusText.Closed
          : null,
      overdue:
        isOpen || parameter[tableHeaderKeyValueFields.status.value] === TransmittalStatusText.Overdue
          ? parameter[tableHeaderKeyValueFields.status.value] === TransmittalStatusText.Overdue
          : null,
      flagged: parameter[tableHeaderKeyValueFields.flagged.value]
        ? parameter[tableHeaderKeyValueFields.flagged.value] === TransmittalFlaggedText.Yes
        : null,
      lastUpdated1:
        (parameter[tableHeaderKeyValueFields.lastUpdated.value] as IDateRangeFilter)?.startDate?.toISOString() ?? null,
      lastUpdated2:
        (parameter[tableHeaderKeyValueFields.lastUpdated.value] as IDateRangeFilter)?.endDate?.toISOString() ?? null,
      lastUpdatedOperator:
        (parameter[tableHeaderKeyValueFields.lastUpdated.value] as IDateRangeFilter)?.operator ?? null,
      dueDate1:
        (parameter[tableHeaderKeyValueFields.dueDate.value] as IDateRangeFilter)?.startDate?.toISOString() ?? null,
      dueDate2:
        (parameter[tableHeaderKeyValueFields.dueDate.value] as IDateRangeFilter)?.endDate?.toISOString() ?? null,
      dueDateOperator: (parameter[tableHeaderKeyValueFields.dueDate.value] as IDateRangeFilter)?.operator ?? null,
      initiatedDate1:
        (parameter[tableHeaderKeyValueFields.initiatedDate.value] as IDateRangeFilter)?.startDate?.toISOString() ??
        null,
      initiatedDate2:
        (parameter[tableHeaderKeyValueFields.initiatedDate.value] as IDateRangeFilter)?.endDate?.toISOString() ?? null,
      initiatedDateOperator:
        (parameter[tableHeaderKeyValueFields.initiatedDate.value] as IDateRangeFilter)?.operator ?? null,
      initiator: parameter[tableHeaderKeyValueFields.initiator.value] ?? null,
      taskTeamOriginator: parameter[tableHeaderKeyValueFields.taskTeamOriginator.value] ?? null,
      transmittalTypeIds: parameter[tableHeaderKeyValueFields.type.value] as number[],
      transmittalReasonIds: parameter[tableHeaderKeyValueFields.reason.value] as number[],
    } as ISearchTransmittalRequest;
  }

  private getTableColumn(label: string) {
    return this.tableColumns.find((tableColumn) => tableColumn.label === label);
  }

  public setSelectedTransmittal(transmittal: ITransmittalSearchListItem, selected: boolean, shiftOn: boolean) {
    runInAction(() => {
      const currentSelectedItemIds = [...this.selectedIds];
      const selectedItemIds = getSelectedItems(
        this.searchTransmittals,
        transmittal.id,
        selected,
        shiftOn,
        currentSelectedItemIds,
        this.lastSelectedIndexes
      ) as number[];

      const selectedItems = this.searchTransmittals.filter((f) => selectedItemIds.some((s) => f.id === s));
      const nonSelectedItems = this.selectedItems.filter((v) => !selectedItemIds.some((s) => v.id === s));

      nonSelectedItems.forEach((nonSelectedFile) => {
        nonSelectedFile.checked = false;
        const selectedIdIndex = this.selectedIds.findIndex((t) => t === nonSelectedFile.id);
        if (selectedIdIndex > -1) this.selectedIds.splice(selectedIdIndex, 1);

        const selectedItemIndex = this.selectedItems.findIndex((t) => t.id === nonSelectedFile.id);
        if (selectedItemIndex > -1) this.selectedItems.splice(selectedItemIndex, 1);
      });

      selectedItems.forEach((selectedFile) => {
        selectedFile.checked = true;
        const isExistSelectedId = this.selectedIds.some((t) => t === selectedFile.id);
        if (!isExistSelectedId) this.selectedIds.push(selectedFile.id);

        const isExistSelectedItem = this.selectedItems.some((t) => t.id === selectedFile.id);
        if (!isExistSelectedItem) this.selectedItems.push(selectedFile);
      });

      const selectedIndex = this.searchTransmittals.findIndex((t) => t.id === transmittal.id);
      this.lastSelectedIndexes.push(selectedIndex);
    });
  }

  public countLinkedAndAttachmentFilesFromMessages(transmittalMessages: ITransmittalMessage[]) {
    let attachmentFiles: IAttachmentFile[] = [];
    let linkedFiles: IReleasedFile[] = [];

    transmittalMessages.forEach((m) => {
      attachmentFiles = attachmentFiles.concat(m.transmittalMessageAttachmentFiles);
      linkedFiles = linkedFiles.concat(m.transmittalMessageReleasedFiles);
    });

    const totalLinkedFiles = attachmentFiles.length;
    const totalAttachmentFiles = linkedFiles.length;

    return totalLinkedFiles + totalAttachmentFiles;
  }

  public toggleShowSelectedItems() {
    this.showSelectedItems = !this.showSelectedItems;
  }

  public get getTableColumnsForShowHide(): IVisibilityColumn[] {
    return this._tableColumnsForForShowHide;
  }

  private isSelectedItem(id: number) {
    return this.selectedIds.some((x) => x === id);
  }

  public abortRequests() {
    this.abortController.abort();
    this.abortController = new AbortController();
  }
}

export default new TransmittalsStore();
