import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ITab, TabContent } from '@aurecon-creative-technologies/styleguide';
import PageHeading from '../../components/shared/PageHeading';
import PageTab from '../../components/shared/PageTab';
import { SettingTabIds, SettingTabLabels } from '../../components/settings/Types';
import TeamManagement from '../../components/settings/teamManagement/TeamManagement';
import { getAppInsightsInstance } from '../../utils/appInsights';
import { AppInsightPageView } from '../../common/constants/AppInsightPageView';
import ProjectManagement from '../../components/settings/projectManagement/ProjectManagement';
import Styles from '../../styles/pages/settings/Settings.module.scss';
import AppStore from '../../stores/AppStore';
import { ClientUserRole } from '../../common/enums/ClientUserRole';
import SystemAdminSettings from '../../components/settings/systemAdminSettings/SystemAdminSettings';
import GoBackButton from '../../components/shared/GoBackButton';
import { useNavigate } from 'react-router-dom';
import TemporaryAccessLog from '../../components/settings/temporaryAccessLog/TemporaryAccessLog';

const Settings: FC = () => {
  const [selectedTabId, setSelectedTabId] = useState(SettingTabIds.SYSTEM_SETTINGS);
  const navigate = useNavigate();

  const systemAdminTabs: ITab[] =
    AppStore.client?.user.clientUserRole === ClientUserRole.SystemAdmin
      ? [
          { id: SettingTabIds.SYSTEM_SETTINGS, label: SettingTabLabels.SYSTEM_SETTINGS },
          { id: SettingTabIds.SYSTEM_USERS, label: SettingTabLabels.SYSTEM_USERS },
        ]
      : [];

  const SettingTabs: ITab[] = [
    ...systemAdminTabs,
    { id: SettingTabIds.PROJECT_MANAGEMENT, label: SettingTabLabels.PROJECT_MANAGEMENT },
    { id: SettingTabIds.TEAM_MANAGEMENT, label: SettingTabLabels.TEAM_MANAGEMENT },
    { id: SettingTabIds.TEMPORARY_ACCESS_LOG, label: SettingTabLabels.TEMPORARY_ACCESS_LOG },
  ];

  useEffect(() => {
    const appInsights = getAppInsightsInstance();
    if (appInsights) {
      appInsights.trackPageView({ name: AppInsightPageView.SYSTEM_SETTINGS, isLoggedIn: true });
    }
  }, []);

  const onSelectTab = (id: number) => {
    setSelectedTabId(id);
  };

  const renderTabContent = (tabId) => {
    switch (tabId) {
      case SettingTabIds.SYSTEM_SETTINGS:
      case SettingTabIds.SYSTEM_USERS:
        return <SystemAdminSettings tabId={tabId} />;
      case SettingTabIds.PROJECT_MANAGEMENT:
        return <ProjectManagement />;
      case SettingTabIds.TEAM_MANAGEMENT:
        return <TeamManagement />;
      case SettingTabIds.TEMPORARY_ACCESS_LOG:
        return <TemporaryAccessLog />;
      default:
        break;
    }
  };

  return (
    <div className={Styles.settingPage}>
      <PageHeading cssClass={Styles.header}>
        <GoBackButton className={Styles.goBackBtn} onClick={() => navigate(-1)} />
      </PageHeading>
      <PageTab defaultActiveTab={selectedTabId} tabs={SettingTabs} onSelectTab={onSelectTab}>
        {SettingTabs.map((tab) => (
          <TabContent key={tab.id} for={tab.id}>
            {renderTabContent(tab.id)}
          </TabContent>
        ))}
      </PageTab>
    </div>
  );
};

export default observer(Settings);
