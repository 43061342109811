import React, { useEffect, useState } from 'react';
import { ISuggestion } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import NavBarSelectorStore from './NavBarSelectorStore';
import NavBarSelectorProgramme from './NavBarSelectorProgramme';
import NavBarSelectorProject from './NavBarSelectorProject';
import NavBarSelectorTaskTeam from './NavBarSelectorTaskTeam';
import { Item, TaskTeamItem, ProjectItem, NavigationItemTypes } from '../../../common/models/ItemType';
import { sortBy } from 'lodash';
import { SortTypes } from '../../../common/enums/SortType';
import TopNavBarSelector from '../../shared/TopNavBarSelector';
import { useLocation } from 'react-router';
import { Pages } from '../../../common/constants/Pages';
import AppStore from '../../../stores/AppStore';

interface INavBarSelectorProp {
  disable: boolean;
}

const getItemsSearchBox = (items: Item[]): ISuggestion[] => {
  return items.map((item) => {
    if (item.type === 'Programme')
      return { id: item.id, value: item.id, display: <NavBarSelectorProgramme item={item} /> };
    if (item.type === 'Project') return { id: item.id, value: item.id, display: <NavBarSelectorProject item={item} /> };
    return { id: item.id, value: item.id, display: <NavBarSelectorTaskTeam item={item} /> };
  });
};

const NavBarSelector: React.FC<INavBarSelectorProp> = (props) => {
  const [suggestions, setSuggestions] = useState<ISuggestion[]>(getItemsSearchBox(NavBarSelectorStore.items));
  const location = useLocation();
  useEffect(() => {
    NavBarSelectorStore.initSelector();
    setSuggestions(getItemsSearchBox(NavBarSelectorStore.items));
  }, []);

  const handleOnSelectItem = (id: string | number): void => {
    const item = NavBarSelectorStore.items.find((p) => p.id === id);
    if (
      location.pathname === `${Pages.Transmittals.Route}/new` &&
      item &&
      item.type !== NavigationItemTypes.Programme &&
      item.project.projectNumber !== AppStore.selectedProjectNumber
    )
      return;

    NavBarSelectorStore.setSelectedSelectorItem(id.toString());
  };

  const onChangeSearchBox = (searchText = '') => {
    searchText = searchText.toLowerCase();
    if (!searchText) {
      setSuggestions(getItemsSearchBox(NavBarSelectorStore.items));
      return;
    }

    const programme = NavBarSelectorStore.items.filter(
      (prm) =>
        prm.type === 'Programme' &&
        (prm?.programme?.title?.toLowerCase()?.includes(searchText) ||
          prm?.programme?.projects.some(
            (prj) =>
              `${prj.projectNumber} ${prj.title}`?.toLowerCase()?.includes(searchText) ||
              prj.taskTeams.some((tt) => `${tt?.deliveryTeamTitle} ${tt?.title}`?.toLowerCase()?.includes(searchText))
          ))
    );

    const projects = (NavBarSelectorStore.items as ProjectItem[]).filter(
      (prj) =>
        prj.type === 'Project' &&
        (prj?.programme.title.toLowerCase().includes(searchText) ||
          `${prj?.project?.projectNumber} ${prj?.project?.title}`?.toLowerCase()?.includes(searchText) ||
          prj?.project?.taskTeams.some((tt) =>
            `${tt?.deliveryTeamTitle} ${tt?.title}`?.toLowerCase()?.includes(searchText)
          ))
    );

    const taskTeams = (NavBarSelectorStore.items as TaskTeamItem[]).filter(
      (t) =>
        t.type === 'TaskTeam' &&
        (t?.programme?.title?.toLowerCase()?.includes(searchText) ||
          t?.project?.title?.toLowerCase()?.includes(searchText) ||
          t.taskTeam?.deliveryTeamTitle?.toLowerCase()?.includes(searchText) ||
          t?.taskTeam?.title?.toLowerCase()?.includes(searchText))
    );

    const data = getItemsSearchBox(sortBy([...programme, ...projects, ...taskTeams] || [], 'key', SortTypes.ASC));
    setSuggestions(data);
  };

  return (
    <TopNavBarSelector
      disable={props.disable}
      handleOnSelectItem={handleOnSelectItem}
      suggestions={suggestions}
      onChangeSearchBox={onChangeSearchBox}
      selectedItemId={NavBarSelectorStore.selectedItem?.id}
    />
  );
};

export default observer(NavBarSelector);
