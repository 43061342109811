import React, { FC } from 'react';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Pages } from './common/constants/Pages';
import Layout from './components/layout/Layout';
import PageNotFound from './components/shared/PageNotFound';
import Files from './components/files/Files';
import Tasks from './components/tasks/Tasks';
import Visualisation from './components/visualisation/Visualisation';
import TaskInformation from './components/taskInformation/TaskInformation';
import SupportHub from './components/login/SupportHub';
import { Toast, ToastProvider } from '@aurecon-creative-technologies/styleguide';
import AppStore from './stores/AppStore';
import Transmittals from './components/transmittals/Transmittals';
import TransmittalDetails from './components/transmittalDetails/TransmittalDetails';
import Error from './components/error/Error';
import AddTransmittalMessage from './components/transmittalDetails/AddTransmittalMessage/AddTransmittalMessage';
import CreateTransmittal from './components/transmittals/CreateTransmittal';
import TermsConditions from './components/shared/TermsConditions';
import PrivacyPolicy from './components/shared/PrivacyPolicy';
import Settings from './pages/settings/Settings';
import PdfViewer from './components/shared/PdfViewer';
import ProjectGuard from './components/shared/ProjectGuard';
import { ProjectSettingNames } from './common/constants/ProjectSettingNames';
import TermsCookiesModal from './components/shared/TermsCookiesModal';
import CookiesPolicy from './components/shared/CookiesPolicy';
import { PageNames } from './common/constants/PageNames';
import SystemGuard from './components/shared/SystemGuard';
import SplashPage from './components/shared/SplashPage';
import UnauthenticatedRoutes from './routes/UnauthenticatedRoutes';

interface IAppRoutingProps {
  isAuthenticated: boolean;
}
const AppRouting: FC<IAppRoutingProps> = ({ isAuthenticated }) => {
  const getDefaultNavigation = () => {
    if (AppStore.clientEnableSplashPage) return Pages.Apps.Route;
    return AppStore.isProjectExternalUser ? Pages.Transmittals.Route : Pages.Files.Route;
  };

  return (
    <HashRouter>
      {!isAuthenticated ? (
        <UnauthenticatedRoutes />
      ) : (
        <>
          <TermsCookiesModal />
          <ToastProvider>
            <Routes>
              <Route element={<Layout />}>
                {(AppStore.client && (!AppStore.client.programmes.length || !AppStore.projects.length)) ||
                AppStore.hasClientInfoError ? (
                  <>
                    <Route path={Pages.SupportHub.Route} element={<SupportHub />} />
                    <Route
                      path="*"
                      element={
                        <Navigate
                          to={{
                            pathname: Pages.SupportHub.Route,
                          }}
                          replace
                        />
                      }
                    />
                  </>
                ) : (
                  <>
                    <Route
                      path={Pages.Login.Route}
                      element={<Navigate to={{ pathname: Pages.Home.Route }} replace />}
                    />
                    <Route
                      path={Pages.Home.Route}
                      element={
                        <Navigate
                          to={{
                            pathname: getDefaultNavigation(),
                          }}
                          replace
                        />
                      }
                    />
                    <Route
                      path={Pages.Apps.Route}
                      element={
                        <SystemGuard
                          element={<SplashPage />}
                          enable={AppStore.clientEnableSplashPage ?? false}
                          pageName={PageNames.APPS}
                        />
                      }
                    />
                    {!AppStore.isProjectExternalUser && (
                      <>
                        <Route
                          path={Pages.Files.Route}
                          element={
                            <ProjectGuard
                              element={<Files />}
                              settingName={ProjectSettingNames.DISPLAY_FILES}
                              pageName={PageNames.FILES}
                            />
                          }
                        />
                        <Route
                          path={`${Pages.Files.Route}/:projectNumber/:deliveryTeamCode/:taskTeamCode/:stateId/:fileName`}
                          element={
                            <ProjectGuard
                              element={<Files />}
                              settingName={ProjectSettingNames.DISPLAY_FILES}
                              pageName={PageNames.FILES}
                            />
                          }
                        />
                        <Route
                          path={`${Pages.Tasks.Route}/:taskId`}
                          element={
                            <ProjectGuard
                              element={<TaskInformation />}
                              settingName={ProjectSettingNames.DISPLAY_TASKS}
                              pageName={PageNames.TASKS}
                            />
                          }
                        />
                        <Route
                          path={Pages.Tasks.Route}
                          element={
                            <ProjectGuard
                              element={<Tasks />}
                              settingName={ProjectSettingNames.DISPLAY_TASKS}
                              pageName={PageNames.TASKS}
                            />
                          }
                        />
                        <Route
                          path={Pages.Visualisation.Route}
                          element={
                            <ProjectGuard
                              element={<Visualisation />}
                              settingName={ProjectSettingNames.DISPLAY_VISUALISATION}
                              pageName={PageNames.VISUALISATION}
                            />
                          }
                        />
                        <Route path={Pages.Settings.Route} element={<Settings />} />
                      </>
                    )}
                    <Route
                      path={Pages.Transmittals.Route}
                      element={
                        <ProjectGuard
                          element={<Transmittals />}
                          settingName={ProjectSettingNames.DISPLAY_TRANSMITTALS}
                          pageName={PageNames.TRANSMITTALS}
                        />
                      }
                    />
                    <Route
                      path={`${Pages.Transmittals.Route}/:transmittalTitle/:projectNumber`}
                      element={
                        <ProjectGuard
                          element={<TransmittalDetails />}
                          settingName={ProjectSettingNames.DISPLAY_TRANSMITTALS}
                          pageName={PageNames.TRANSMITTALS}
                        />
                      }
                    />
                    <Route
                      path={`${Pages.Transmittals.Route}/:transmittalTitle`}
                      element={
                        <ProjectGuard
                          element={<TransmittalDetails />}
                          settingName={ProjectSettingNames.DISPLAY_TRANSMITTALS}
                          pageName={PageNames.TRANSMITTALS}
                        />
                      }
                    />
                    <Route
                      path={`${Pages.Transmittals.Route}/new`}
                      element={
                        <ProjectGuard
                          element={<CreateTransmittal />}
                          settingName={ProjectSettingNames.DISPLAY_TRANSMITTALS}
                          pageName={PageNames.TRANSMITTALS}
                        />
                      }
                    />
                    <Route
                      path={`${Pages.Transmittals.Route}/:transmittalTitle/AddResponse`}
                      element={
                        <ProjectGuard
                          element={<AddTransmittalMessage />}
                          settingName={ProjectSettingNames.DISPLAY_TRANSMITTALS}
                          pageName={PageNames.TRANSMITTALS}
                        />
                      }
                    />
                    <Route path="*" element={<PageNotFound />} />
                    <Route path={Pages.TermsConditions.Route} element={<TermsConditions />} />
                    <Route path={Pages.PrivacyPolicy.Route} element={<PrivacyPolicy />} />
                    <Route path={`${Pages.Error.Route}/:errorCode`} element={<Error />} />
                  </>
                )}
              </Route>
              <Route path={Pages.PdfViewer.Route} element={<PdfViewer />} />
              <Route path={Pages.CookiePolicy.Route} element={<CookiesPolicy />} />
            </Routes>
            <Toast />
          </ToastProvider>
        </>
      )}
    </HashRouter>
  );
};

export default observer(AppRouting);
