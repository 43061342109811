import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Icon, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide';
import TaskInformationStore from './TaskInformationStore';
import SecondaryButton from '../shared/SecondaryButton';
import Style from './styles/TaskInformationFiles.module.scss';
import TaskInformationFileAction from './TaskInformationFileAction';
import DownloadModal from '../shared/DownloadModal';
import { getFileSizeDetails } from '../../utils/miscUtils';
import ForgeViewerModal from '../visualisation/forgeViewer/ForgeViewerModal';
import ForgeViewerStore from '../visualisation/forgeViewer/ForgeViewerStore';
import Loading from '../shared/Loading';
import FileInformationStore from '../files/fileInformation/FileInformationStore';

const TaskInformationFiles: FC = () => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const { totalFileSize, isOverLimit } = getFileSizeDetails(TaskInformationStore.taskFilesSize);
  return (
    <div className={Style.cardWrapper}>
      <div className={Style.cardHeader}>
        <Icon outlined type="description" className={Style.cardHeaderIcon} />
        <span>Content Files ({TaskInformationStore.task?.taskFileContainers.length})</span>
      </div>
      <Table
        headers={[{ label: 'Filename' }, { label: 'Original Filename' }, { label: 'Action', align: 'right' }]}
        breakpoint={10}
        hoverable>
        {TaskInformationStore.task?.taskFileContainers.map((taskFile, i) => {
          const nativeFile = taskFile.containerFiles.some((tf) => tf.native)
            ? taskFile.containerFiles.find((tf) => tf.native)
            : taskFile.containerFiles[0];
          const key = `fileContainer-${taskFile.fileContainerId}-${i}`;
          return (
            <>
              <TableRow
                key={key}
                onClick={() => {
                  if (FileInformationStore.file?.id === taskFile.fileContainerId) {
                    FileInformationStore.close();
                    return;
                  }
                  TaskInformationStore.openFileInfo(taskFile);
                }}>
                <TableCell>{taskFile.fileTitle}</TableCell>
                <TableCell>{nativeFile?.originalFilename}</TableCell>
                <TaskInformationFileAction taskFile={taskFile} containerFile={nativeFile} />
              </TableRow>
              {taskFile.containerFiles.map((f) => (
                <TableRow key={`${key}-${f.containerFileId}`}>
                  <TableCell></TableCell>
                  <TableCell>
                    <Icon type="shortcut" cssClass={Style.fileIcon} />
                    {f.originalFilename} {f.native && <Icon type={'star_outline'} className={Style.native} />}
                  </TableCell>
                  <TaskInformationFileAction taskFile={taskFile} containerFile={f} />
                </TableRow>
              ))}
            </>
          );
        })}
      </Table>
      <div className={Style.cardFooter}>
        <SecondaryButton
          onClick={() => {
            if (isOverLimit) {
              setShowDownloadModal(true);
            } else {
              TaskInformationStore.downloadAllTaskFiles();
            }
          }}
          disabled={TaskInformationStore.anyTaskFilesBusy}>
          Download all files
        </SecondaryButton>
      </div>
      <DownloadModal
        closeModal={() => setShowDownloadModal(false)}
        showModal={showDownloadModal}
        downloadAction={() => {
          TaskInformationStore.downloadAllTaskFiles();
          setShowDownloadModal(false);
        }}
        fileSize={totalFileSize}
      />
      <ForgeViewerModal
        closeModal={() => ForgeViewerStore.forgeViewerModalClose()}
        showModal={ForgeViewerStore.showForgeViewerModal}></ForgeViewerModal>
      <Loading isLoading={ForgeViewerStore.isLoading}></Loading>
    </div>
  );
};

export default observer(TaskInformationFiles);
