import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/ClearFilterLink.module.scss';

export interface IClearFilterLinkProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  customizedStyle?: string;
  disabled?: boolean;
}

const ClearFilterLink: FC<IClearFilterLinkProps> = ({ onClick, customizedStyle, disabled }) => {
  return (
    <Button
      cssClass={customizedStyle ? customizedStyle : Style.button}
      onClick={onClick}
      type="text"
      label="Clear Filter"
      disabled={disabled ? disabled : false}
    />
  );
};

export default observer(ClearFilterLink);
