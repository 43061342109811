import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/SelectedTransmittalsLabel.module.scss';
import TransmittalsStore from './TransmittalsStore';
import ActionBarStore from './ActionBarStore';

const SelectedTransmittalsLabel: FC = () => {
  const selectLabel = () => {
    return (
      !!ActionBarStore.getTotalTransmittalSelected && (
        <div className={Style.totalSelected}>{ActionBarStore.getTotalTransmittalSelected} items selected</div>
      )
    );
  };
  return (
    <span className={Style.label}>
      {selectLabel()}{' '}
      {(!!ActionBarStore.getTotalTransmittalSelected || TransmittalsStore.showSelectedItems) && (
        <a className={Style.linkLabel} onClick={() => TransmittalsStore.toggleShowSelectedItems()}>
          {!TransmittalsStore.showSelectedItems ? 'Show Selected' : 'Show All'}
        </a>
      )}
    </span>
  );
};

export default observer(SelectedTransmittalsLabel);
