import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Styles from '../../../../styles/components/settings/systemAdminSettings/systemSettings/GeneralSettings.module.scss';
import { Checkbox } from '@aurecon-creative-technologies/styleguide';
import classNames from 'classnames';
import { SystemConfigs } from '../../../../common/constants/SystemConfigs';

interface ITransmittalsSettings {
  enableSupportingFiles: boolean;
  allowEnableDisableProjectSupportingFiles: boolean;
}
interface ITransmittalsSettingsProps {
  defaultValue: ITransmittalsSettings;
  onSettingsChanged: (settingName: string, value: boolean) => void;
}
const TransmittalsSettings: FC<ITransmittalsSettingsProps> = (props) => {
  const { defaultValue, onSettingsChanged } = props;

  const [transmittalsSetting, seTransmittalsSetting] = useState<ITransmittalsSettings>(defaultValue);

  const onChangeTransmittalsSetting = (fieldName, value) => {
    const setting = { ...transmittalsSetting };
    if (fieldName === SystemConfigs.EnableSupportingFiles && !value) {
      setting.allowEnableDisableProjectSupportingFiles = false;
    }
    setting[fieldName] = value;
    onSettingsChanged(fieldName, setting[fieldName]);
    seTransmittalsSetting(setting);
  };

  return (
    <div className={Styles.settingsContainer}>
      <p className={classNames(Styles.headingLabel, Styles.header)}>Transmittals</p>
      <div>
        <p className={classNames(Styles.settingLabel, Styles.first)}>Change defaults for Transmittals</p>
        <Checkbox
          cssClass={Styles.checkboxSetting}
          label={'Enable Supporting Files in Transmittals'}
          onChange={(checked) => onChangeTransmittalsSetting(SystemConfigs.EnableSupportingFiles, checked)}
          checked={transmittalsSetting.enableSupportingFiles}
        />
        <Checkbox
          cssClass={Styles.checkboxSetting}
          label={'Allow Project Admins to Enable/Disable Supporting Files in Transmittals for each Project'}
          onChange={(checked) =>
            onChangeTransmittalsSetting(SystemConfigs.AllowEnableDisableProjectSupportingFiles, checked)
          }
          checked={transmittalsSetting.allowEnableDisableProjectSupportingFiles}
          disabled={true}
        />
      </div>
    </div>
  );
};

export default observer(TransmittalsSettings);
