import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import TransmittalDetailsStore from './TransmittalDetailsStore';
import {
  Accordion,
  AccordionPanel,
  Table,
  TableCell,
  TableRow,
  Button,
  Container,
} from '@aurecon-creative-technologies/styleguide';
import { ReactComponent as FileIcon } from './images/FileIcon.svg';
import DropDownMenuForTableCell from '../shared/DropDownMenuForTableCell';
import DropDownMenuButton from '../shared/DropDownMenuButton';
import { getFileSizeDetails } from '../../utils/miscUtils';
import Styles from './styles/TransmittalDetailsFiles.module.scss';
import FilesStore from '../files/FilesStore';
import { IAttachmentFile, ITransmittalContentFile } from '../../api/authenticated/transmittals/getTransmittal';
import DownloadModal from '../shared/DownloadModal';
import TransmittalDetailsFilesStore from './TransmittalDetailsFilesStore';
import ForgeViewerStore from '../visualisation/forgeViewer/ForgeViewerStore';
import ErrorModal from '../shared/ErrorModal';
import ForgeViewerModal from '../visualisation/forgeViewer/ForgeViewerModal';
import CentreOnPage from '../shared/CentreOnPage';
import Overlay from '../shared/Overlay';
import Modal from '../shared/Modal';
import SecondaryButton from '../shared/SecondaryButton';
import Icon from '../shared/Icon';
import ModalActions from '../shared/ModalActions';
import FileInformation from '../files/fileInformation/FileInformation';
import TransmittalContentFileAction from '../transmittals/ContentFileUpload/TransmittalContentFileAction';
import FileInformationStore from '../files/fileInformation/FileInformationStore';

interface ITransmittalDetailsFilesModalProps {
  messageId: number;
  onClose: () => void;
}
const TransmittalDetailsFilesModal: FC<ITransmittalDetailsFilesModalProps> = (props) => {
  const { onClose, messageId } = props;
  const [downloadingContentFile, setDownloadingContentFile] = useState(false);
  const [downloadingSupportFile, setDownloadingSupportFile] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [totalFileSize, setTotalFileSize] = useState('');
  const [contentFile] = useState<ITransmittalContentFile | null>(null);
  const [attachmentFile, setAttachmentFile] = useState<IAttachmentFile | null>(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const transmittal = TransmittalDetailsStore.transmittal;

  const transmittalErrorCode = TransmittalDetailsFilesStore.errorCode;
  const fileErrorCode = FilesStore.errorCode;

  if (!transmittal) return null;

  const files = TransmittalDetailsFilesStore.getTransmittalReleasedFiles(transmittal.id, messageId);
  const attachmentFiles = TransmittalDetailsFilesStore.getTransmittalAttachmentFiles(transmittal.id, messageId);

  const onDownLoadContentFiles = async () => {
    setDownloadingContentFile(true);
    await TransmittalDetailsFilesStore.downloadFiles(
      transmittal.id,
      files[0].transmittalMessageId,
      files.map((releasedFile) => releasedFile)
    );
    setDownloadingContentFile(false);
  };

  const onDownloadSupportFiles = async () => {
    setDownloadingSupportFile(true);
    await TransmittalDetailsFilesStore.downloadAttachmentFiles(
      transmittal.id,
      attachmentFiles[0].transmittalMessageId,
      attachmentFiles.map((m) => m)
    );
    setDownloadingSupportFile(false);
  };

  const handleAttachmentFileDownload = (transmittalId: number, transmittalMessageId: number, file: IAttachmentFile) => {
    const { totalFileSize, isOverLimit } = getFileSizeDetails(file.uploadedSize);

    if (isOverLimit) {
      setAttachmentFile(file);
      setTotalFileSize(totalFileSize);
      setShowDownloadModal(true);
    } else {
      TransmittalDetailsFilesStore.downloadAttachmentFile(transmittalId, transmittalMessageId, file);
    }
  };

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Styles.detailFilesModalContainer}>
        <div className={Styles.wrapper}>
          <div className={Styles.tableFiles}>
            <Container cssClass={Styles.headerTitle}>
              <Icon outlined name="description" className={Styles.headerIcon} />
              <span>View Files ({files.flatMap((x) => x.containerFiles).length + attachmentFiles.length})</span>
            </Container>
            <Container cssClass={Styles.accordionWrapper}>
              <Accordion
                activePanelIds={Array.from(TransmittalDetailsStore.openPanelIds)}
                onPanelToggle={TransmittalDetailsStore.panelToggle}
                verticalPanelGap="24px"
                headingBgColour="#FAFAFA"
                headingFontColour="#121212"
                panelBgColour="#FAFAFA"
                headingIconColour="#27524E">
                <AccordionPanel
                  key="content-files"
                  label={
                    <div className={Styles.accordionHeading}>
                      <FileIcon />
                      {`Content Files (${files.flatMap((x) => x.containerFiles).length})`}
                    </div>
                  }
                  panelId="content-files">
                  <div className={Styles.contentFiles}>
                    <Table
                      headers={[
                        { label: 'Filename' },
                        { label: 'Original Filename' },
                        { label: 'File Size' },
                        { label: 'Action', align: 'right' },
                      ]}
                      breakpoint={10}
                      hoverable>
                      {files.map((releasedFile) => {
                        const file =
                          releasedFile.containerFiles?.length > 0 &&
                          releasedFile.containerFiles?.some((tf) => tf.native)
                            ? releasedFile.containerFiles?.find((tf) => tf.native)
                            : releasedFile.containerFiles[0];
                        const fileContainer = TransmittalDetailsFilesStore.mapToFile(releasedFile, file!);

                        return (
                          <>
                            <TableRow
                              key={releasedFile.id}
                              onClick={() => {
                                if (FileInformationStore.file?.id === fileContainer.id) {
                                  FileInformationStore.close();
                                  return;
                                }
                                TransmittalDetailsFilesStore.showFileInformation(fileContainer);
                              }}>
                              <TableCell>{releasedFile.title}</TableCell>
                              <TableCell>{releasedFile.originalFilename}</TableCell>
                              <TableCell></TableCell>
                              <TableCell>
                                <TransmittalContentFileAction
                                  file={fileContainer}
                                  containerFile={null}
                                  showInfo
                                  fileRevisionId={releasedFile.fileRevisionId}
                                  releasedFileId={releasedFile.releasedFileId}
                                  transmittalId={transmittal.id}
                                  transmittalMessageId={messageId}
                                />
                              </TableCell>
                            </TableRow>
                            {!!releasedFile.containerFiles &&
                              releasedFile.containerFiles?.map((conFile) => (
                                <TableRow key={conFile.fileContainerId}>
                                  <TableCell></TableCell>
                                  <TableCell>
                                    <div className={Styles.fileCell}>
                                      <Icon name="shortcut" className={Styles.fileIcon} />
                                      {conFile.originalFilename}
                                      {conFile.native && <Icon name="star_outline" className={Styles.native} />}
                                    </div>
                                  </TableCell>
                                  <TableCell>{getFileSizeDetails(conFile.uploadedSize).totalFileSize}</TableCell>
                                  <TableCell>
                                    <TransmittalContentFileAction
                                      file={TransmittalDetailsFilesStore.mapToFile(releasedFile, conFile)}
                                      containerFile={null}
                                      showInfo={false}
                                      fileRevisionId={releasedFile.fileRevisionId}
                                      releasedFileId={releasedFile.releasedFileId}
                                      transmittalId={transmittal.id}
                                      transmittalMessageId={messageId}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                          </>
                        );
                      })}
                    </Table>
                    <Button
                      type="secondary"
                      cssClass={Styles.downloadAllButton}
                      disabled={downloadingContentFile || !files.length}
                      loading={downloadingContentFile}
                      label={`Download All (${
                        getFileSizeDetails(
                          files
                            .flatMap((f) => f.containerFiles)
                            .reduce((fileSize, file) => fileSize + file.uploadedSize, 0)
                        ).totalFileSize
                      })`}
                      onClick={onDownLoadContentFiles}
                    />
                  </div>
                </AccordionPanel>
                <AccordionPanel
                  key="support-files"
                  label={
                    <div className={Styles.accordionHeading}>
                      <FileIcon />
                      {`Support Files (${attachmentFiles.length})`}
                    </div>
                  }
                  panelId="support-files">
                  <Table
                    headers={[{ label: 'Filename' }, { label: 'File Size' }, { label: 'Action', align: 'right' }]}
                    breakpoint={10}
                    hoverable>
                    {attachmentFiles.map((attachmentFile) => (
                      <TableRow key={attachmentFile.id}>
                        <TableCell>{attachmentFile.originalFilename}</TableCell>
                        <TableCell>
                          <span className={Styles.fileSize}>
                            {getFileSizeDetails(attachmentFile.uploadedSize).totalFileSize}
                          </span>
                        </TableCell>
                        <DropDownMenuForTableCell>
                          <DropDownMenuButton
                            onClick={() =>
                              handleAttachmentFileDownload(
                                attachmentFile.transmittalId,
                                attachmentFile.transmittalMessageId,
                                attachmentFile
                              )
                            }
                            disabled={TransmittalDetailsFilesStore.fileBusy[attachmentFile.id]}>
                            Download
                          </DropDownMenuButton>
                        </DropDownMenuForTableCell>
                      </TableRow>
                    ))}
                  </Table>
                  <Button
                    type="secondary"
                    cssClass={Styles.downloadAllButton}
                    disabled={downloadingSupportFile || !attachmentFiles.length}
                    loading={downloadingSupportFile}
                    label={`Download All (${
                      getFileSizeDetails(attachmentFiles.reduce((fileSize, file) => fileSize + file.uploadedSize, 0))
                        .totalFileSize
                    })`}
                    onClick={onDownloadSupportFiles}
                  />
                </AccordionPanel>
              </Accordion>
              <ForgeViewerModal
                closeModal={() => ForgeViewerStore.forgeViewerModalClose()}
                showModal={ForgeViewerStore.showForgeViewerModal}></ForgeViewerModal>
              <DownloadModal
                fileSize={totalFileSize}
                showModal={showDownloadModal && !!contentFile}
                closeModal={() => setShowDownloadModal(false)}
                downloadAction={() => {
                  contentFile &&
                    TransmittalDetailsFilesStore.downloadFile(
                      transmittal.id,
                      attachmentFiles[0].transmittalMessageId,
                      contentFile
                    );
                  setShowDownloadModal(false);
                }}
              />
              <DownloadModal
                fileSize={totalFileSize}
                showModal={showDownloadModal && !!attachmentFile}
                closeModal={() => setShowDownloadModal(false)}
                downloadAction={() => {
                  attachmentFile &&
                    TransmittalDetailsFilesStore.downloadAttachmentFile(
                      transmittal.id,
                      transmittal.transmittalMessages[0].id,
                      attachmentFile
                    );
                  setShowDownloadModal(false);
                }}
              />
              {showErrorModal && (
                <ErrorModal
                  closeModal={() => setShowErrorModal(false)}
                  errorCode={transmittalErrorCode ?? fileErrorCode}
                />
              )}
            </Container>
          </div>
          {FilesStore.isShowingFileInformation && (
            <div className={Styles.fileDetails}>
              <FileInformation showFiles={false} />
            </div>
          )}
        </div>
        <ModalActions className={Styles.modalActions}>
          <SecondaryButton onClick={onClose}>Close</SecondaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(TransmittalDetailsFilesModal);
