import React, { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, FormInput, Grid, Modal } from '@aurecon-creative-technologies/styleguide';
import { isValidRegex } from '../../../utils/miscUtils';
import TeamManagementStore from '../../../stores/settings/teamManagement/TeamManagementStore';
import Style from '../../../styles/components/settings/teamManagement/EditTeamModel.module.scss';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';

interface IEditTeamModalProps {
  deliveryTeamId: number;
  projectNumber: string;
  teamName: string;
  abbreviation: string;
  onCancel: () => void;
  onSave: (updatedTeamName: string) => void;
}

const EditTeamModal: FC<IEditTeamModalProps> = ({
  deliveryTeamId,
  projectNumber,
  teamName,
  abbreviation,
  onCancel,
  onSave,
}) => {
  const [teamNameError, setTeamNameError] = useState<string>('');
  const [updatedTeamName, setUpdatedTeamName] = useState(teamName);
  const [saving, setSaving] = useState(false);

  const validateTeamName = useCallback(() => {
    if (!updatedTeamName) {
      setTeamNameError('Team name is required');
      return;
    }

    if (updatedTeamName.length > 100) {
      setTeamNameError('Team name is longer than 100 characters');
      return;
    }

    if (!isValidRegex(updatedTeamName, { allowSpace: true, allowMacron: true })) {
      setTeamNameError('Team name must be only alphanumerical');
      return;
    }

    if (
      TeamManagementStore.clientTeams?.programmes.some((p) =>
        p.projects.some(
          (pr) =>
            pr.projectNumber === projectNumber &&
            pr.deliveryTeams.some((dt) => dt.title.toLowerCase().trim() === updatedTeamName.toLowerCase().trim())
        )
      )
    ) {
      setTeamNameError('Team name already exists');
      return;
    }

    setTeamNameError('');
  }, [updatedTeamName, projectNumber]);

  useEffect(() => {
    if (teamName !== updatedTeamName) validateTeamName();
  }, [validateTeamName, teamName, updatedTeamName]);

  const onTeamSaved = async () => {
    setSaving(true);
    const result = await TeamManagementStore.editDeliveryTeam(deliveryTeamId, projectNumber, updatedTeamName);
    setSaving(false);

    if (result) onSave(updatedTeamName);
  };
  return (
    <Modal shouldOverlayClose={false} isShowing={true} onClose={onCancel}>
      <>
        <h1>Edit Delivery Team</h1>
        <p>You can rename Team Name.</p>
        <Grid row>
          <Grid md={6}>
            <FormInput
              type="text"
              label="Team Name"
              placeholder="Enter team name..."
              required
              cssClass={Style.cellInputLeft}
              error={teamNameError}
              value={updatedTeamName}
              onChange={setUpdatedTeamName}
            />
          </Grid>
          <Grid md={6}>
            <FormInput
              disabled={true}
              type="text"
              label="Abbreviation"
              placeholder="Enter abbreviation..."
              required
              cssClass={Style.cellInputRight}
              value={abbreviation}
            />
          </Grid>
        </Grid>
        <div className={Style.modalActions}>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          {!saving && (
            <PrimaryButton disabled={saving || teamName === updatedTeamName} onClick={onTeamSaved}>
              Save
            </PrimaryButton>
          )}
          {saving && <Button label="Save" loading={saving} />}
        </div>
      </>
    </Modal>
  );
};

export default observer(EditTeamModal);
