import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/SupersedeFile.module.scss';
import Icon from '../../shared/Icon';
import { dateTime12HFormat } from '../../../utils/dateUtils';
import { classNames } from '../../../utils/miscUtils';
import SearchBar from '../../shared/SearchBar';
import FilesStore from '../FilesStore';
import { IFileContainer } from '../../../api/authenticated/cms/FileContainerModel';
import SupersedeFileStore, { ActionColumns } from './SupersedeFileStore';
import {
  Checkbox,
  IColumnMoving,
  IHeader,
  Loader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  Tooltip,
} from '@aurecon-creative-technologies/styleguide';
import { IDateRangeFilter, ITableColumn } from '../../../common/interfaces/TableColumn';
import UserTextFilter from '../../shared/UserTextFilter';
import DateFilter from '../../shared/DateFilter';
import ReviewSupersedeFileModal from './ReviewSupersedeFileModal';
import { ISupersedeFileContainerBase } from '../../../api/authenticated/cms/getSupersedeFiles';
import Loading from '../../shared/Loading';
import DropDownAdjustableColumns from '../../shared/DropDownAdjustableColumns';

const SupersedeFile: FC = () => {
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);

  useEffect(() => {
    const init = async () => {
      await SupersedeFileStore.init();
    };
    setLoadingSearch(true);
    init();
    setLoadingSearch(false);
  }, []);

  const getFilterComponent = (tableColumn: ITableColumn) => {
    if (tableColumn.textFilter) {
      return (
        <UserTextFilter
          textFilter={tableColumn.textFilter}
          disabled={FilesStore.showSelectedFiles}
          key={tableColumn.valueField}
          onChange={(value) => filterChangeHandler(tableColumn, value)}
        />
      );
    }

    if (tableColumn.dateFilter) {
      return (
        <DateFilter
          dateFilter={tableColumn.dateFilter}
          disabled={FilesStore.showSelectedFiles}
          key={tableColumn.valueField}
          onChange={(value) => filterChangeHandler(tableColumn, value)}
        />
      );
    }
  };

  const headers: IHeader[] = [
    ...SupersedeFileStore.tableColumns
      .filter((x) => x.visible)
      .map((tableColumn) => {
        const isActionColumn = ActionColumns.includes(tableColumn.valueField);
        return {
          label: tableColumn.label,
          filter: getFilterComponent(tableColumn),
          draggable: !isActionColumn,
          resizable: !isActionColumn,
          width: tableColumn.width,
          minWidth: tableColumn.minWidth,
        } as IHeader;
      }),
  ];

  const hasSelectedFileContainer = (fileContainerId: number) => {
    return SupersedeFileStore.selectedDuplicateFileContainers.some(
      (s) => s.fileContainerId === fileContainerId && s.selectedAtPage !== SupersedeFileStore.pagingMetaData.pageNumber
    );
  };

  const filterChangeHandler = (tableColumn: ITableColumn, filter?: string | IDateRangeFilter) => {
    SupersedeFileStore.applyFilter(tableColumn.valueField, filter);
  };

  const isLockContent = (f: ISupersedeFileContainerBase) => {
    return f.isLocked || hasSelectedFileContainer(f.fileContainerId);
  };

  const renderSupersedeFilesRow = (fileContainer: ISupersedeFileContainerBase) => {
    return fileContainer.supersedeFiles.map((m) => (
      <TableRow key={m.fileId}>
        {headers.map((h, i) => {
          if (h.label === 'Original Filename') {
            return (
              <TableCell key={`${h.label}-${i}`}>
                <div className={Style.fileWrapper}>
                  <Icon name="shortcut" className={Style.fileIcon}></Icon>
                  <span>{m.originalFilename}</span>
                </div>
              </TableCell>
            );
          }

          if (h.label === 'Action') {
            return (
              <TableCell key={`${h.label}-${i}`}>
                <div
                  onClick={() =>
                    FilesStore.downloadFile({
                      fileContainers: [
                        {
                          id: fileContainer.fileContainerId,
                          containerFileId: m.fileId,
                          fileRevisionId: fileContainer.fileContainerRevisionId,
                          releasedFileId: fileContainer.releasedFileContainerId,
                        } as IFileContainer,
                      ],
                    })
                  }>
                  <Icon className={Style.previewIcon} name="open_in_new" />
                </div>
              </TableCell>
            );
          }
          return <TableCell key={`${h.label}-${i}`}></TableCell>;
        })}
      </TableRow>
    ));
  };

  const reorder = (data: IColumnMoving) => {
    const moveColumn = SupersedeFileStore.tableColumns[data.fromIndex];
    if (moveColumn) {
      SupersedeFileStore.tableColumns.splice(data.fromIndex, 1);
      SupersedeFileStore.tableColumns.splice(data.toIndex, 0, moveColumn);
    }
  };

  const renderSupersedeSelectionTable = () => {
    if (loadingSearch) return <Loader size="extra small" />;
    if (!SupersedeFileStore.supersedeFileContainers.length && SupersedeFileStore.searchKeyword)
      return <div className={Style.searchMessage}>There are no results that match your search.</div>;

    return (
      <>
        <Table cssClass={Style.filesTable} headers={headers} hoverable onReorderColumn={reorder}>
          {SupersedeFileStore.supersedeFileContainers.map((f) => (
            <>
              <TableRow key={f.fileContainerId}>
                {headers.map((h, i) => {
                  if (h.label === '') {
                    return (
                      <TableCell key={`${f.fileContainerId}-${i}`}>
                        <Checkbox
                          onChange={(checked) => SupersedeFileStore.setSelectedSupersedeFileContainer(f, checked)}
                          checked={f.fileContainerId === SupersedeFileStore.selectedDuplicateFileContainerId}
                          disabled={isLockContent(f)}
                        />
                      </TableCell>
                    );
                  }
                  if (h.label === 'Action') {
                    return <TableCell key={`${f.fileContainerId}-${i}`}></TableCell>;
                  }

                  if (h.label === 'Filename') {
                    return (
                      <TableCell key={`${f.fileContainerId}-${i}`}>
                        <div className={Style.filenameCell}>
                          <span className={classNames([isLockContent(f), Style.lockedContent])}>
                            {f.fileContainerName}
                          </span>
                          {f.isLocked && <Icon className={Style.icon} name="lock" />}
                          {hasSelectedFileContainer(f.fileContainerId) && (
                            <Tooltip show="Currently the file is ready for supersede" defaultUp>
                              <Icon className={Style.icon} outlined name="info" />
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                    );
                  }
                  if (h.label === 'Original Filename') {
                    return (
                      <TableCell key={`${f.fileContainerId}-${i}`}>
                        <div className={Style.filenameCell}>
                          <span className={classNames([isLockContent(f), Style.lockedContent])}>
                            {f.fileContainerName}
                          </span>
                          {hasSelectedFileContainer(f.fileContainerId) && (
                            <Tooltip show="Currently the file is ready for supersede" defaultUp>
                              <Icon className={Style.icon} outlined name="info" />
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                    );
                  }
                  if (h.label === 'Uploaded') {
                    return (
                      <TableCell key={`${f.fileContainerId}-${i}`}>
                        <span className={classNames([isLockContent(f), Style.lockedContent])}>
                          {f.supersedeFiles[0].uploadedDate && dateTime12HFormat(f.supersedeFiles[0].uploadedDate)}
                        </span>
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
              {renderSupersedeFilesRow(f)}
            </>
          ))}
        </Table>
        {SupersedeFileStore.supersedeFileContainers && (
          <div className={Style.paginationWrapper}>
            <div className={Style.paginationContainer}>
              <Pagination
                page={SupersedeFileStore.pagingMetaData.pageNumber}
                pageCount={SupersedeFileStore.pagingMetaData.pageCount}
                onChange={(page: number) => {
                  SupersedeFileStore.setCurrentPage(page);
                }}
              />
              <div className={Style.paginationFlexGrow}>
                {`${
                  !SupersedeFileStore.pagingMetaData.totalCount
                    ? 0
                    : SupersedeFileStore.pagingMetaData.firstItemOnPage + 1
                } - ${SupersedeFileStore.pagingMetaData.lastItemOnPage + 1} of Files`}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderSearchArea = () => {
    return (
      <div className={Style.searchContentWrapper}>
        <>
          <SearchBar
            searchValue={SupersedeFileStore.searchKeyword ?? ''}
            onSearch={(keyword) => SupersedeFileStore.onSearch(keyword)}
            placeHolderText="Search for filename, original filename"
          />
          {loadingSearch && <Loader size="extra small" />}
        </>
      </div>
    );
  };

  if (SupersedeFileStore.isLoading) return <Loading isLoading={SupersedeFileStore.isLoading} label="File Loading..." />;

  return (
    <div className={Style.container}>
      {SupersedeFileStore.currentFileContainer && (
        <>
          <div className={Style.header}>
            <h1>Supersede</h1>
            <Tooltip
              show="A file with similar name already exists. Choose a file you would like to supersede and overwrite existing file data. Skipping supersede will allocate a unique name to the file you want to upload."
              cssClass={Style.supersedeHeaderTooltip}
              defaultUp>
              <Icon className={Style.supersedeHeaderIcon} outlined name="info" />
            </Tooltip>
            {renderSearchArea()}
            <DropDownAdjustableColumns
              className={Style.dropDownAdjustableColumns}
              items={SupersedeFileStore.getTableColumnsForShowHide.filter((col) => !ActionColumns.includes(col.id))}
              selectedItems={SupersedeFileStore.tableColumns
                .filter((col) => !ActionColumns.includes(col.valueField) && col.visible)
                .map((x) => x.valueField)}
              onSelected={SupersedeFileStore.setVisibleSelectedFileColumns}
            />
          </div>
          {renderSupersedeSelectionTable()}
        </>
      )}
      {SupersedeFileStore.openReviewSupersede && <ReviewSupersedeFileModal />}
    </div>
  );
};

export default observer(SupersedeFile);
