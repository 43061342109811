import React, { FC, useState, useRef, useEffect, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { ContentSelection } from '../ContentSelection';
import FilesStore from '../FilesStore';
import Style from './styles/AddContent.module.scss';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import UploadStore from './UploadStore';
import { classNames } from '../../../utils/miscUtils';
import UploadUnsavedModal from './UploadUnsavedModal';
import UploadConfirmation from './UploadConfirmation';
import { FileContainerState } from '../../../common/enums/FileContainerState';
import AppStore from '../../../stores/AppStore';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';
import { NavigationItemTypes } from '../../../common/models/ItemType';
import DropDownMenu from '../../shared/DropDownMenu';
import DropDownMenuButton from '../../shared/DropDownMenuButton';
import { ReactComponent as WordDocumentIcon } from '../../../assets/documentIcons/word.svg';
import { ReactComponent as PowerPointIcon } from '../../../assets/documentIcons/powerpoint.svg';
import { ReactComponent as ExcelIcon } from '../../../assets/documentIcons/excel.svg';
import AddUnbrandedFileModal from './AddUnbrandedFileModal';
import { FileExt } from '../../../common/constants/FileExt';
import { appInsightsTrackEvent } from '../../../utils/appInsights';
import { AppInsightEvent } from '../../../common/constants/AppInsightEvent';

export const fileTypes = [
  {
    id: 1,
    name: 'File Upload',
  },
];

const UnbrandedDocumentPaths = [
  {
    type: FileExt.DOCX,
    path: './unbrandedDocuments/BlankDocument.docx',
  },
  {
    type: FileExt.XLSX,
    path: './unbrandedDocuments/BlankSpreadsheet.xlsx',
  },
  {
    type: FileExt.PPTX,
    path: './unbrandedDocuments/BlankPresentation.pptx',
  },
];

interface IDropdownItemProps {
  children: ReactNode;
  leftIcon?: ReactNode;
  onClick: () => void;
}

const AddContent: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showUploadUnsavedModal, setShowUploadUnsavedModal] = useState(false);
  const [showUploadConfirmationModal, setShowUploadConfirmationModal] = useState(false);
  const [showAddUnbrandedFileModal, setShowAddUnbrandedFileModal] = useState(false);
  const [unbrandedFileSeqNumber, setUnbrandedFileSeqNumber] = useState(0);
  const [selectedDocumentType, setSelectedDocumentType] = useState('');
  const [selectedDocumentPath, setSelectedDocumentPath] = useState<string | null>(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      setUnbrandedFileSeqNumber(0);
    };
  }, []);

  const onAddButtonClick = (): void => setIsOpen(!isOpen);
  const onCancelButtonClick = async () => {
    appInsightsTrackEvent(AppInsightEvent.FILE_UPLOAD_CANCEL);

    await UploadStore.unlockFileContainers();
    setShowUploadUnsavedModal(false);
    FilesStore.setSelectedSection(FilesStore.getContentSelection(FilesStore.selectedTabId));
    UploadStore.clear();
    setUnbrandedFileSeqNumber(0);
  };

  const handleCancelButtonClick = () => {
    UploadStore.hasUnsavedChanges ? setShowUploadUnsavedModal(true) : onCancelButtonClick();
  };

  const onUploadButtonClick = async () => {
    setShowUploadConfirmationModal(false);
    await UploadStore.uploadFiles();
    setUnbrandedFileSeqNumber(0);
    setSelectedDocumentType('');
    setSelectedDocumentPath('');
  };

  const handleUploadButtonClick = () => {
    if (FilesStore.fileContainerStateId === FileContainerState.Wip) {
      onUploadButtonClick();
    } else {
      setShowUploadConfirmationModal(true);
    }
  };
  const closeTabEvent = (ev) => {
    ev.preventDefault();
    if (UploadStore.hasUnsavedChanges) {
      ev.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
      UploadStore.unlockFileContainers();
    }

    return ev.returnValue;
  };

  useEffect(() => {
    window.addEventListener('popstate', closeTabEvent);
    window.addEventListener('beforeunload', closeTabEvent);
    return () => {
      window.removeEventListener('beforeunload', closeTabEvent);
      window.removeEventListener('popstate', closeTabEvent);
    };
  }, []);

  useEffect(() => {
    const handleClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
        setIsOpen(!isOpen);
      }
    };

    // If the item is active (ie open) then listen for clicks
    if (isOpen) {
      window.addEventListener('click', handleClickEvent);
    }

    return () => {
      window.removeEventListener('click', handleClickEvent);
    };
  }, [isOpen]);

  const onDropdownItemSelect = (selected: string) => {
    UploadStore.clear();
    UploadStore.getFileData();

    if (selected === 'File Upload') {
      const eventName =
        FilesStore.fileContainerStateId === FileContainerState.Shared
          ? AppInsightEvent.FILE_ADD_SHARE
          : FilesStore.fileContainerStateId === FileContainerState.Published
          ? AppInsightEvent.FILE_ADD_PUBLISHED
          : AppInsightEvent.FILE_ADD_WIP;
      appInsightsTrackEvent(eventName);
      FilesStore.setSelectedSection(ContentSelection.UploadFile);
    } else if (selected === 'WordFile') FilesStore.setSelectedSection(ContentSelection.AddTemplateDocument);
    else FilesStore.setSelectedSection(ContentSelection.AddBlankDocument, selected);
  };

  const DropdownItem = ({ onClick, children, leftIcon }: IDropdownItemProps) => {
    return (
      <ul className={classNames(Style.dropdownMenuList, Style.dropdownSubmenuList)} onClick={onClick}>
        <li className={Style.dropdownItem}>
          <span className={Style.iconButton}>{leftIcon}</span>
          {children}
        </li>
      </ul>
    );
  };

  const isToggleButton = !(
    FilesStore.selectedSection === ContentSelection.WIP ||
    FilesStore.selectedSection === ContentSelection.Published ||
    FilesStore.selectedSection === ContentSelection.Shared
  );

  const showAddBtn = () => {
    if (!NavBarSelectorStore.selectedItem || NavBarSelectorStore.selectedItem.type !== NavigationItemTypes.TaskTeam) {
      return false;
    }
    return (
      !NavBarSelectorStore.selectedItem.taskTeam.isTemporaryAccessible &&
      (AppStore.projectAdminister || FilesStore.fileContainerStateId === FileContainerState.Wip)
    );
  };

  const handleOnSetOriginalFileNameForTemplate = async (fileName: string, fileType: string, templateId: number) => {
    const originalFilename = `${fileName}.${fileType}`;
    await UploadStore.addUnbrandedFile(originalFilename, null, templateId);

    setShowAddUnbrandedFileModal(false);
  };

  const handleOnSetOriginalFileNameForUnbranded = async (fileName: string, fileType: string, filePath: string) => {
    const originalFilename = `${fileName}.${fileType}`;
    const response = await fetch(filePath);
    const blob = await response.blob();
    const file = new File([blob], originalFilename, {
      type: blob.type,
    });
    await UploadStore.addUnbrandedFile(originalFilename, file);

    setShowAddUnbrandedFileModal(false);
  };

  const handleOpenAddUnbrandedFileModal = (
    fileType: string,
    filePath: string | null = null,
    templateId: number | null = null
  ) => {
    setSelectedDocumentType(fileType);
    setSelectedDocumentPath(filePath);
    setSelectedTemplateId(templateId);
    setUnbrandedFileSeqNumber(unbrandedFileSeqNumber + 1);
    setShowAddUnbrandedFileModal(true);
  };

  const renderDocIcons = (type: string, docName?: string) => {
    switch (type) {
      case FileExt.DOCX:
        return (
          <>
            <WordDocumentIcon className={Style.dropdownButtonIcon} />{' '}
            <span>{!docName ? 'Word Document' : docName}</span>
          </>
        );

      case FileExt.XLSX:
        return (
          <>
            <ExcelIcon className={Style.dropdownButtonIcon} /> <span>{!docName ? 'Excel Spreadsheet' : docName}</span>
          </>
        );
      case FileExt.PPTX:
        return (
          <>
            <PowerPointIcon className={Style.dropdownButtonIcon} />
            <span>{!docName ? 'PowerPoint Presentation' : docName}</span>
          </>
        );
      default:
        null;
    }
  };

  const renderUnbrandedDocumentDropdownOptions = () => {
    return UnbrandedDocumentPaths.map((m, i, arr) => {
      return (
        <DropDownMenuButton
          key={m.path}
          className={classNames(Style.dropdownOptionItem, [arr.length - 1 === i, Style.lastItem])}
          onClick={() => {
            handleOpenAddUnbrandedFileModal(m.type, m.path, null);
          }}>
          {renderDocIcons(m.type)}
        </DropDownMenuButton>
      );
    });
  };

  const renderTemplateDocumentDropdownOptions = () => {
    return UploadStore.uploadTemplates.map((m) => {
      return (
        <DropDownMenuButton
          key={m.path}
          className={Style.dropdownOptionItem}
          onClick={() => {
            handleOpenAddUnbrandedFileModal(m.extension, null, m.id);
          }}>
          {renderDocIcons(m.extension, m.templateFileName)}
        </DropDownMenuButton>
      );
    });
  };

  return (
    <div className={Style.dropdown}>
      {isToggleButton ? (
        <>
          <PrimaryButton onClick={handleUploadButtonClick} disabled={UploadStore.isUploadDisabled()}>
            Upload
          </PrimaryButton>
          {showUploadConfirmationModal && (
            <UploadConfirmation
              message={
                UploadStore.selectedFileContainers.length > 1
                  ? 'I confirm that the files have been reviewed in the source system'
                  : 'I confirm that the file has been reviewed in the source system.'
              }
              closeModal={() => setShowUploadConfirmationModal(false)}
              redirect={onUploadButtonClick}></UploadConfirmation>
          )}
          <SecondaryButton onClick={handleCancelButtonClick}>Cancel</SecondaryButton>
          {showUploadUnsavedModal && (
            <UploadUnsavedModal
              closeModal={() => setShowUploadUnsavedModal(false)}
              redirect={onCancelButtonClick}></UploadUnsavedModal>
          )}
          <div className={Style.expandBtn}>
            <DropDownMenu
              label="Create"
              icon="expand_more"
              iconClassName={Style.expandBtnIcon}
              itemsContainerClassName={Style.itemsContainer}
              menuClassName={Style.btnMenu}>
              {renderUnbrandedDocumentDropdownOptions()}
              <div className={Style.divider}></div>
              {renderTemplateDocumentDropdownOptions()}
            </DropDownMenu>

            {showAddUnbrandedFileModal && (
              <AddUnbrandedFileModal
                selectedDocumentPath={selectedDocumentPath}
                templateId={selectedTemplateId}
                selectedType={selectedDocumentType}
                currentSeqNumber={unbrandedFileSeqNumber}
                onCancel={() => setShowAddUnbrandedFileModal(false)}
                onSet={(fileName: string, fileType: string, filePath: string, templateId: number | null) => {
                  if (templateId) {
                    handleOnSetOriginalFileNameForTemplate(fileName, fileType, templateId);
                    return;
                  }
                  handleOnSetOriginalFileNameForUnbranded(fileName, fileType, filePath);
                }}></AddUnbrandedFileModal>
            )}
          </div>
        </>
      ) : (
        showAddBtn() && <PrimaryButton onClick={onAddButtonClick}>Add</PrimaryButton>
      )}
      <div>
        {isOpen && (
          <div ref={dropdownRef} className={Style.dropdownMenu}>
            {fileTypes.map((fileType) => (
              <DropdownItem key={fileType.id} onClick={() => onDropdownItemSelect(fileType.name)}>
                {fileType.name}
              </DropdownItem>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(AddContent);
