import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import Style from '../../../styles/components/settings/teamManagement/DeliveryTeamPermission.module.scss';
import classnames from 'classnames';
import PrimaryButton from '../../shared/PrimaryButton';
import TeamManagementStore from '../../../stores/settings/teamManagement/TeamManagementStore';
import {
  Checkbox,
  ComboBox,
  Grid,
  IOption,
  Table,
  TableCell,
  TableRow,
} from '@aurecon-creative-technologies/styleguide';
import SearchBar from '../../shared/SearchBar';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import { ISharePermission, defaultSharePermissions } from './models/IDeliveryTeamPermissionModel';
import ConfirmationModal from '../../shared/ConfirmationModal';
import SecondaryButton from '../../shared/SecondaryButton';

interface IDeliveryTeamPermissionProps {
  projectNumber: string;
  programmeId: number | null;
  deliveryTeamId: number;
}

const DeliveryTeamPermission: FC<IDeliveryTeamPermissionProps> = (props) => {
  const { programmeId, projectNumber, deliveryTeamId } = props;
  const [searchText, setSearchText] = useState('');
  const [selectedDeliveryTeam, setSelectedDeliveryTeam] = useState<IOption | null>();
  const [permissions, setPermissions] = useState<ISharePermission[]>(defaultSharePermissions);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    TeamManagementStore.resetDTToAccessList();
    setSelectedDeliveryTeam(null);

    const effectAsync = async () => {
      await TeamManagementStore.getDeliveryTeamAccessible(projectNumber, deliveryTeamId);
      TeamManagementStore.getDeliveryTeams(programmeId, projectNumber, deliveryTeamId);
    };
    effectAsync();

    setPermissions(
      defaultSharePermissions.map((x) => {
        return { ...x, canAccess: false };
      })
    );
  }, [programmeId, projectNumber, deliveryTeamId]);

  const addDeliveryTeamPermission = () => {
    if (!selectedDeliveryTeam) return;
    const deliveryTeam = {
      accessibleDeliveryTeamId: selectedDeliveryTeam.id ? Number(selectedDeliveryTeam.id) : 0,
      accessibleDeliveryTeamTitle: selectedDeliveryTeam.value ?? '',
      permissions: permissions,
    };
    TeamManagementStore.disableSelectedOption(selectedDeliveryTeam);
    TeamManagementStore.addDTToAccessList(deliveryTeam);
    setSelectedDeliveryTeam(null);
    setPermissions(
      defaultSharePermissions.map((x) => {
        return { ...x, canAccess: false };
      })
    );
  };

  const enabledGrantAccessBtn = permissions.some((x) => x.canAccess) && selectedDeliveryTeam && editMode;

  const handleComboboxChange = (option: IOption | null) => {
    setSelectedDeliveryTeam(option);
  };

  const handleCheckBoxPermissionChange = (s, p) => {
    const newPermissions: ISharePermission[] = permissions.map((x) => {
      if (x.permissionId === p) {
        x.canAccess = s;
      }
      return x;
    });
    setPermissions(newPermissions);
  };

  const handledConfirm = async () => {
    setLoading(true);
    setShowConfirmModal(false);
    await TeamManagementStore.grantSharePermission(projectNumber, deliveryTeamId);
    setPermissions(
      defaultSharePermissions.map((x) => {
        return { ...x, canAccess: false };
      })
    );
    setLoading(false);
    setEditMode(false);
  };
  const handleCancelEditPermission = async () => {
    TeamManagementStore.resetDTToAccessList();
    setSelectedDeliveryTeam(null);

    await TeamManagementStore.getDeliveryTeamAccessible(projectNumber, deliveryTeamId);
    TeamManagementStore.getDeliveryTeams(programmeId, projectNumber, deliveryTeamId);

    setPermissions(
      defaultSharePermissions.map((x) => {
        return { ...x, canAccess: false };
      })
    );
    setEditMode(false);
  };
  return (
    <>
      <div className={Style.pageWrapper}>
        <div className={Style.grantingAccessBox}>
          <div className={Style.headingWrapper}>
            <div className={Style.heading}>Granting Access to other team</div>
            <div className={Style.save}>
              {editMode ? (
                <>
                  <SecondaryButton onClick={handleCancelEditPermission}>Cancel</SecondaryButton>
                  <PrimaryButton onClick={() => setShowConfirmModal(true)}>Save</PrimaryButton>
                </>
              ) : (
                <SecondaryButton onClick={() => setEditMode(true)}>Edit</SecondaryButton>
              )}
            </div>
          </div>
          <div className={classnames(Style.addPermissionContainer)}>
            <div className={Style.grantingAccess}>
              <Grid item gap={16}>
                <Grid item md={6}>
                  <div className={Style.dtControlWrapper}>
                    <ComboBox
                      label="Delivery team to grant access"
                      placeholder="Select delivery team"
                      cssClass={Style.accessibleTeamCombobox}
                      disabled={!editMode}
                      options={TeamManagementStore.teamOptions.filter((x) => !x.disabled)}
                      onSelect={handleComboboxChange}
                      onClear={() => handleComboboxChange(null)}
                      selected={selectedDeliveryTeam?.toString()}
                    />
                  </div>
                </Grid>
                <Grid item md={6}>
                  <div className={Style.permissionWrapper}>
                    {permissions.map((p) => (
                      <Checkbox
                        key={p.permissionId}
                        cssClass={Style.combobox}
                        checked={p.canAccess}
                        label={p.title}
                        disabled={!editMode}
                        onChange={(s) => handleCheckBoxPermissionChange(s, p.permissionId)}
                      />
                    ))}
                    <PrimaryButton disabled={!enabledGrantAccessBtn} onClick={addDeliveryTeamPermission}>
                      Grant Access
                    </PrimaryButton>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className={Style.teamAccessibleTable}>
          <div className={Style.quickSearch}>
            <SearchBar
              searchValue={searchText}
              cssClass={Style.searchUserBox}
              onSearch={(keyword) => {
                setSearchText(keyword);
              }}
              placeHolderText="Quick search..."
            />
          </div>

          <Table headers={TeamManagementStore.sharePermissionTableColumns}>
            {TeamManagementStore.deliveryTeamAccesses
              .filter((u) =>
                u.accessibleDeliveryTeamTitle
                  .toLowerCase()
                  .includes((searchText ? searchText : u.accessibleDeliveryTeamTitle).toLowerCase())
              )
              .map((d) => (
                <TableRow key={d.accessibleDeliveryTeamTitle}>
                  <TableCell>{d.accessibleDeliveryTeamTitle}</TableCell>
                  {d.permissions.map((p) => (
                    <TableCell key={p.permissionId}>
                      <Checkbox
                        checked={p.canAccess}
                        disabled={!editMode}
                        onChange={() => (p.canAccess = !p.canAccess)}
                      />
                    </TableCell>
                  ))}
                  <TableCell align="center">
                    <PrimaryIconButton
                      icon="delete"
                      onClick={() => TeamManagementStore.deleteDeliveryTeamAccesses(d.accessibleDeliveryTeamId)}
                      disabled={!editMode}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </Table>
        </div>
      </div>
      <ConfirmationModal
        showModal={showConfirmModal}
        heading="Granting Access to other team?"
        message="This action wil grant access to content produced by a specific delivery team, at either the Shared or the Published state, to members of a delivery team"
        confirmText="Yes"
        cancelText="No"
        loading={loading}
        onConfirm={handledConfirm}
        onCancel={() => setShowConfirmModal(false)}></ConfirmationModal>
    </>
  );
};
export default observer(DeliveryTeamPermission);
