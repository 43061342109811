import { IOption } from '@aurecon-creative-technologies/styleguide';
import { cloneDeep, groupBy, orderBy, startCase, toLower } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import { IUserDetail } from '../../api/authenticated/transmittals/createTransmittal';
import {
  getDeliveryTeamsTaskTeamsUsers,
  IDeliveryTeam,
  ITaskTeam,
} from '../../api/authenticated/um/getDeliveryTeamsTaskTeamsUsers';
import { getExternalUsers } from '../../api/authenticated/um/getExternalUsers';
import { IUser } from '../../api/authenticated/um/interfaces/user.interface';
import { multiGroupBy } from '../../utils/miscUtils';
import { DeliveryTeamRole } from '../../common/enums/DeliveryTeamRole';
import {
  ITeamChartDeliveryTeamUser,
  IDTUser,
  ITeamChartExternalUser,
  ITaskTeamUserTeamChart,
  ITeamChartTaskTeamUser,
  ITeamChartTaskTeamPill,
  ITeamChartDeliveryTeamPill,
  IDeliveryTeamTeamChart,
  ITeamChartProjectTaskTeam,
  IGetTeamChartDataParameters,
} from './interface/TeamChart.interface';
import { TaskTeamRole } from '../../common/enums/TaskTeamRole';
import { sort } from '../../utils/sortHelper';
import { SortTypes } from '../../common/enums/SortType';
import { getUserNameInitials } from '../../utils/pipe';

export class ProjectTeamSelectorStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
  public selectedProjectNumber = 0;
  public selectedDeliveryTeamId?: number;
  public selectedTaskTeamId?: number;
  public keywords = '';
  public projectDeliveryTeams: IDeliveryTeam[] = [];
  public projectExternalUsers: IUser[] = [];
  public deliveryTeamOptions: IOption[] = [];
  public selectedDeliveryTeam?: IDeliveryTeam;
  public allProjectTaskTeams: ITeamChartProjectTaskTeam[] = [];

  public activeDTPanelIds = new Set<string>();
  public activeExUserPanelIds = new Set<string>();
  public activeTTUserPanelIds = new Set<string>();

  public activeTTAllApproversIds = new Set<number>();
  public activeTTAllMembersIds = new Set<number>();
  public activeDTAllMembersIds = new Set<number>();

  public selectedTaskTeamUsers: IDTUser[] = [];
  public selectedDeliveryTeamUsers: IDTUser[] = [];

  public deliveryTeamPillItems: ITeamChartDeliveryTeamPill[] = [];
  public taskTeamPillItems: ITeamChartTaskTeamPill[] = [];
  public externalPillItems: IUser[] = [];

  public projectDeliveryTeam: IDeliveryTeamTeamChart[] = [];
  public projectDTTaskTeam: ITaskTeamUserTeamChart[] = [];
  public externalUsers: ITeamChartExternalUser[] = [];

  public filteredProjectDeliveryTeamUsers: IDeliveryTeamTeamChart[] = [];
  public filteredProjectTaskTeamUsers: ITaskTeamUserTeamChart[] = [];
  public filteredExternalUsers: ITeamChartExternalUser[] = [];
  public inputSelectedUsers: IUserDetail[] = [];

  public resetForm() {
    runInAction(() => {
      this.selectedDeliveryTeamId = undefined;
      this.selectedTaskTeamId = undefined;
      this.keywords = '';
      this.projectDeliveryTeams = [];
      this.projectExternalUsers = [];
      this.deliveryTeamOptions = [];
      this.selectedDeliveryTeam = undefined;
      this.activeDTPanelIds = new Set<string>();
      this.activeExUserPanelIds = new Set<string>();
      this.activeTTUserPanelIds = new Set<string>();
      this.activeTTAllApproversIds = new Set<number>();
      this.activeTTAllMembersIds = new Set<number>();
      this.activeDTAllMembersIds = new Set<number>();
      this.selectedTaskTeamUsers = [];
      this.selectedDeliveryTeamUsers = [];
      this.externalUsers = [];
      this.deliveryTeamPillItems = [];
      this.taskTeamPillItems = [];
      this.externalPillItems = [];
      this.projectDeliveryTeam = [];
      this.projectDTTaskTeam = [];
      this.allProjectTaskTeams = [];
      this.filteredProjectDeliveryTeamUsers = [];
      this.filteredProjectTaskTeamUsers = [];
      this.filteredExternalUsers = [];
      this.inputSelectedUsers = [];
    });
  }

  public setInputSelected(selectedUsers?: IUserDetail[]) {
    runInAction(() => {
      this.inputSelectedUsers = selectedUsers ?? [];
    });
  }

  public setSearchText(keywords: string) {
    runInAction(() => {
      this.keywords = keywords;
      this.filterData();
    });
  }

  public resetFilterData() {
    runInAction(() => {
      this.filteredProjectDeliveryTeamUsers = [];
      this.filteredProjectTaskTeamUsers = [];
      this.filteredExternalUsers = [];
    });
  }

  public filterData() {
    this.resetFilterData();
    this.filterTaskTeams();
    this.filterDeliveryTeams();
    this.filterExternalUsers();
  }

  public setSelectedDeliveryId(deliveryTeamId?: number) {
    runInAction(() => {
      this.selectedDeliveryTeamId = deliveryTeamId;
      this.activeDTPanelIds = new Set<string>();
      this.selectedTaskTeamId = undefined;
      this.selectDeliveryTeamUser();
      if (this.getTaskTeamDropdownOptions.length > 0) {
        this.onTTUserPanelToggle(`${deliveryTeamId}-${this.getTaskTeamDropdownOptions[0].id}`);
      }
    });
    this.filterData();
  }

  public setSelectedTaskTeamId(taskTeamId?: number) {
    runInAction(() => {
      this.selectedTaskTeamId = taskTeamId;
      if (taskTeamId) {
        this.activeTTUserPanelIds = new Set<string>();
        this.activeDTPanelIds = new Set<string>();
        const selectedTaskTeam = this.allProjectTaskTeams.find((x) => x.id === taskTeamId);
        if (selectedTaskTeam) {
          this.selectedDeliveryTeamId = selectedTaskTeam.deliveryTeamId;
          this.onDTPanelToggle(`DT_${this.selectedDeliveryTeamId}`);
          this.onTTUserPanelToggle(`${selectedTaskTeam.deliveryTeamId}-${selectedTaskTeam.id}`);
        }
      }
    });
    this.filterData();
  }

  public selectDeliveryTeamUser() {
    runInAction(() => {
      this.selectedDeliveryTeam = this.projectDeliveryTeams.find((x) => x.id === this.selectedDeliveryTeamId);
      if (this.selectedDeliveryTeam) {
        this.onDTPanelToggle(`DT_${this.selectedDeliveryTeam.id}`);
      }
    });
  }

  public getAllDeliveryTeamUsers() {
    const allDTUsers = this.projectDeliveryTeams.map((deliveryTeam) => {
      return {
        deliveryTeamId: deliveryTeam.id,
        deliveryTeamTitle: deliveryTeam.title,
        deliveryTeamCode: deliveryTeam.transmittalCode,
        deliveryTeamUsers: this.groupSelectedDTUsers(deliveryTeam),
        check: false,
      } as IDeliveryTeamTeamChart;
    });

    runInAction(() => {
      this.projectDeliveryTeam = [...allDTUsers];
    });
  }

  private isMatchedKeywords(user, keywords: string): boolean {
    return (
      user.name.toLowerCase().includes(keywords.toLowerCase()) ||
      user.email.toLowerCase().includes(keywords.toLowerCase())
    );
  }

  public getAllProjectTaskTeamUser() {
    const groupsTaskTeam: ITaskTeamUserTeamChart[] = [];
    this.projectDeliveryTeams.forEach((dTeam) => {
      groupsTaskTeam.push(...this.setGroupDTTaskTeamUsers(dTeam));
    });
    runInAction(() => {
      this.projectDTTaskTeam = [...groupsTaskTeam.filter((x) => !!x.taskTeamUsers.length)];
    });
  }

  public filterDeliveryTeams() {
    const tmpArr = cloneDeep(this.projectDeliveryTeam);
    const results = tmpArr.filter(
      (x) =>
        (!this.selectedDeliveryTeamId || x.deliveryTeamId === this.selectedDeliveryTeamId) &&
        (!this.keywords.length ||
          (!!this.keywords.length && x.deliveryTeamUsers.some((u) => this.isMatchedKeywords(u, this.keywords))))
    );

    if (!this.keywords.length) {
      runInAction(() => {
        this.filteredProjectDeliveryTeamUsers = [...results];
      });
      return;
    }

    const newIds = new Set<string>();
    for (const dt of results) {
      newIds.add(`DT_${dt.deliveryTeamId}`);
    }

    runInAction(() => {
      this.filteredProjectDeliveryTeamUsers = [...results];
      this.activeDTPanelIds = newIds;
    });
  }

  public filterTaskTeams(): ITaskTeamUserTeamChart[] {
    const tmpArr = cloneDeep(this.projectDTTaskTeam);
    let results: ITaskTeamUserTeamChart[] = [
      ...tmpArr.filter(
        (x) =>
          (!this.selectedDeliveryTeamId ||
            this.selectedDeliveryTeamId <= 0 ||
            this.selectedDeliveryTeamId === x.deliveryTeamId) &&
          (!this.selectedTaskTeamId || x.taskTeamId === this.selectedTaskTeamId)
      ),
    ];
    if (!this.keywords.length) {
      runInAction(() => {
        this.filteredProjectTaskTeamUsers = [...results];
      });
    }
    const predicate = (values: ITeamChartTaskTeamUser[]) =>
      values.some((u) => this.isMatchedKeywords(u, this.keywords));
    results = [...results.filter((x) => predicate(x.taskTeamUsers))];
    for (const x of results) {
      x.taskTeamUsers = [...x.taskTeamUsers];
    }

    const newIds = new Set<string>();
    for (const taskTeam of results) {
      newIds.add(`${taskTeam.deliveryTeamId}-${taskTeam.taskTeamId}`);
    }

    runInAction(() => {
      this.activeTTUserPanelIds = newIds;
      this.filteredProjectTaskTeamUsers = [...results];
    });
    return results;
  }

  public filterExternalUsers() {
    const tmpArr = cloneDeep(this.externalUsers);
    let results: ITeamChartExternalUser[] = [];

    if (!this.keywords) {
      results = [...tmpArr];
    }
    if (this.keywords) {
      results = [...tmpArr].filter((u) => this.isMatchedKeywords(u, this.keywords));
    }
    runInAction(() => {
      this.filteredExternalUsers = [...results];
      const newIds = new Set<string>();
      newIds.add('exPanel');
      this.activeExUserPanelIds = newIds;
    });
  }

  public groupSelectedDTUsers(deliveryTeam?: IDeliveryTeam) {
    if (!deliveryTeam) return [];
    const users = deliveryTeam.users.map((user) => {
      return {
        id: user.id,
        email: user.email,
        name: startCase(toLower(user.name)),
        initials: getUserNameInitials(user.name),
        deliveryTeamRoleTitle: user.deliveryTeamRoleTitle,
        type: 'DeliveryUser',
        check: false,
      };
    });
    return users.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  public setGroupDTTaskTeamUsers(deliveryTeam: IDeliveryTeam) {
    const taskTeamTeamChart: ITaskTeamUserTeamChart[] = deliveryTeam.taskTeams.map((team: ITaskTeam) => {
      return {
        taskTeamId: team.id,
        taskTeamTitle: team.title,
        taskTeamCode: team.transmittalCode,
        deliveryTeamId: deliveryTeam.id,
        deliveryTeamTitle: deliveryTeam.title,
        deliveryTeamCode: deliveryTeam.transmittalCode,
        isFilterApprovers: false,
        taskTeamUsers: this.getTeamChartTaskTeamUsers(team),
      };
    });

    return [...orderBy(taskTeamTeamChart, 'taskTeamId', 'asc')];
  }

  private getTeamChartTaskTeamUsers(taskTeam: ITaskTeam): ITeamChartTaskTeamUser[] {
    const users = taskTeam.users.map((user) => {
      return {
        id: user.id,
        email: user.email,
        taskTeamRoleIds: user.taskTeamRoleIds,
        taskTeamId: taskTeam.id,
        taskTeamTitle: taskTeam.title,
        transmittalCode: taskTeam.transmittalCode,
        check: false,
        type: 'TaskTeamUser',
        name: startCase(toLower(user.name)),
        initials: getUserNameInitials(user.name),
        disabled: false,
      } as ITeamChartTaskTeamUser;
    });
    return users.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  public getTeamChartExternalUsers() {
    if (!this.projectExternalUsers) return [];
    const users = this.projectExternalUsers.map<ITeamChartExternalUser>((user) => {
      return {
        id: user.id,
        email: user.email,
        check: false,
        type: 'ExternalUser',
        name: startCase(toLower(user.name)),
        initials: getUserNameInitials(user.name),
        disabled: false,
      };
    });
    const sortUserList = sort(users || [], 'name', SortTypes.ASC);
    runInAction(() => {
      this.externalUsers = [...sortUserList];
    });
  }

  public selectAllAuthoriserUserInDT(deliveryTeamId: number) {
    runInAction(() => {
      this.setAllAuthoriserUserInDTToSelected(deliveryTeamId);
      this.setAllAuthoriserUserInDTDisplayToSelected(deliveryTeamId);
    });
    this.setDisableItem();
  }

  private setAllAuthoriserUserInDTDisplayToSelected(deliveryTeamId: number) {
    const selectedDT = this.filteredProjectDeliveryTeamUsers.find((x) => x.deliveryTeamId === deliveryTeamId);
    selectedDT?.deliveryTeamUsers.forEach((user) => {
      if (!user.disabled && user.deliveryTeamRoleTitle) {
        user.check = true;
      }
    });
  }

  private setAllAuthoriserUserInDTToSelected(deliveryTeamId: number) {
    const selectedDT = this.projectDeliveryTeam.find((x) => x.deliveryTeamId === deliveryTeamId);
    selectedDT?.deliveryTeamUsers.forEach((user) => {
      if (!user.disabled && user.deliveryTeamRoleTitle) {
        user.check = true;
        this.addItemToSelectedDeliveryTeamUsers(selectedDT, user);
      }
    });
    this.activeDTAllMembersIds.add(deliveryTeamId);
    this.setDeliveryTeamUserPillItems();
  }

  public addOrRemoveDeliveryTeamUserById(selectUser: ITeamChartDeliveryTeamUser, deliveryTeamId: number) {
    if (selectUser.disabled) return;

    this.addOrRemoveSelectedUserByIdForFilteredDT(selectUser.id, deliveryTeamId);
    const selectedDT = this.projectDeliveryTeam.find((x) => x.deliveryTeamId === deliveryTeamId);
    const user = selectedDT?.deliveryTeamUsers.find((x) => x.id === selectUser.id);
    if (user) {
      user.check = !user.check;
      runInAction(() => {
        if (user.check) {
          this.addItemToSelectedDeliveryTeamUsers(selectedDT, user);
          if (
            selectedDT?.deliveryTeamUsers.filter((a) => a.deliveryTeamRoleTitle && !a.disabled).length ===
            this.selectedDeliveryTeamUsers.filter((b) => !!b.deliveryTeamRoleTitle!).length
          ) {
            this.activeDTAllMembersIds.add(deliveryTeamId);
          }
        } else {
          this.removeItemToSelectedDeliveryTeamUsers(selectedDT, user);

          if (user.deliveryTeamRoleTitle) {
            this.activeDTAllMembersIds.delete(deliveryTeamId);
          }
        }
      });
      this.setDeliveryTeamUserPillItems();
      this.setDisableItem();
    }
  }

  private addOrRemoveSelectedUserByIdForFilteredDT(id: number, deliveryTeamId: number) {
    const selectedDT = this.filteredProjectDeliveryTeamUsers.find((x) => x.deliveryTeamId === deliveryTeamId);
    const user = selectedDT?.deliveryTeamUsers.find((x) => x.id === id);
    if (user) {
      user.check = !user.check;
    }
  }

  private addItemToSelectedDeliveryTeamUsers(
    deliveryTeam: IDeliveryTeamTeamChart | undefined,
    user: ITeamChartDeliveryTeamUser
  ) {
    if (!deliveryTeam) return;
    if (
      !this.selectedDeliveryTeamUsers.some((x) => x.id === user.id && x.deliveryTeamId === deliveryTeam.deliveryTeamId)
    ) {
      this.selectedDeliveryTeamUsers.push({
        ...user,
        deliveryTeamCode: deliveryTeam.deliveryTeamCode,
        deliveryTeamId: deliveryTeam.deliveryTeamId,
        deliveryTeamTitle: deliveryTeam.deliveryTeamTitle,
        deliveryTeamRoleTitle: user.deliveryTeamRoleTitle,
        taskTeamId: undefined,
        taskTeamTitle: undefined,
        taskTeamCode: undefined,
        type: 'DeliveryUser',
      });
    }
  }

  private removeItemToSelectedDeliveryTeamUsers(
    deliveryTeam: IDeliveryTeamTeamChart | undefined,
    user: ITeamChartDeliveryTeamUser
  ) {
    const index = this.selectedDeliveryTeamUsers.findIndex(
      (x) => x.deliveryTeamId === deliveryTeam?.deliveryTeamId && user.id === x.id
    );
    if (index >= 0) {
      this.selectedDeliveryTeamUsers.splice(index, 1);
    }
  }

  public setDeliveryTeamUserPillItems() {
    const users = groupBy(this.selectedDeliveryTeamUsers, 'deliveryTeamId');
    const groups = Object.keys(users).map((i) => ({
      deliveryTeamId: i,
      users: users[i],
    }));

    runInAction(() => {
      this.deliveryTeamPillItems = [];
      groups.forEach((group) => {
        const deliveryTeam = this.projectDeliveryTeams.find((dt) => {
          return dt.id === Number(group.deliveryTeamId);
        });

        if (!deliveryTeam) return;

        const deliveryTeamUsers = this.selectedDeliveryTeamUsers.filter((user) => {
          return user.deliveryTeamId === deliveryTeam.id;
        });

        this.deliveryTeamPillItems.push({
          deliveryTeamId: deliveryTeam.id,
          deliveryTeamCode: deliveryTeam.transmittalCode,
          deliveryTeamTitle: deliveryTeam.title,
          users: deliveryTeamUsers,
          count: deliveryTeamUsers.length,
        });
      });
    });
  }

  public setGroupTaskTeamPillItems() {
    const users = multiGroupBy(this.selectedTaskTeamUsers, ['deliveryTeamId', 'taskTeamId']);
    const groups = Object.keys(users).map((dt) => ({
      deliveryTeamId: dt,
      taskTeams: users[dt],
    }));
    const tGroups = groups.map((dtGroup) => {
      return {
        deliveryTeamId: dtGroup.deliveryTeamId,
        taskTeams: Object.keys(dtGroup.taskTeams).map((tt) => ({
          taskTeamId: tt,
          users: dtGroup.taskTeams[tt],
        })),
      };
    });
    const pills: ITeamChartTaskTeamPill[] = [];
    tGroups.forEach((dtGroup) => {
      const tPills = dtGroup.taskTeams.map((tg) => {
        return {
          taskTeamId: Number(tg.taskTeamId),
          taskTeamCode: tg.users[0].taskTeamCode,
          deliveryTeamTitle: tg.users[0].deliveryTeamTitle,
          deliveryTeamId: tg.users[0].deliveryTeamId,
          deliveryTeamCode: tg.users[0].deliveryTeamCode,
          count: tg.users.length,
          users: tg.users,
        };
      });
      pills.push(...tPills);
    });

    runInAction(() => {
      this.taskTeamPillItems = [...pills];
    });
  }

  public addOrRemoveExUserById(id: number) {
    const user = this.filteredExternalUsers.find((x) => x.id === id);
    if (user) {
      runInAction(() => {
        user.check = !user.check;
        if (user.check) {
          if (!this.externalPillItems.some((x) => x.id === user.id)) {
            this.externalPillItems.push({ ...user });
          }
        } else {
          const index = this.externalPillItems.findIndex((x) => x.id === user.id);
          if (index >= 0) {
            this.externalPillItems.splice(index, 1);
          }
        }
      });
    }
  }

  public setFilterApprovers(deliveryTeamId: number, taskTeamId: number) {
    const taskTeam = this.filteredProjectTaskTeamUsers.find(
      (x) => x.taskTeamId === taskTeamId && x.deliveryTeamId === deliveryTeamId
    );
    if (taskTeam) {
      runInAction(() => {
        taskTeam.isFilterApprovers = !taskTeam.isFilterApprovers;
      });
    }
  }

  public selectAllTaskTeamApprovers(deliveryTeamId: number, taskTeamId: number) {
    this.selectAllFilterTaskTeamApprovers(deliveryTeamId, taskTeamId);
    const taskTeam = this.projectDTTaskTeam.find(
      (x) => x.taskTeamId === taskTeamId && x.deliveryTeamId === deliveryTeamId
    );
    if (!taskTeam) return;
    runInAction(() => {
      taskTeam.taskTeamUsers.forEach((user) => {
        if (!user.disabled && !!user.taskTeamRoleIds.length) {
          user.check = true;
          this.addSelectedTaskTeamUsers(user, taskTeam);
        }
      });
      this.activeTTAllApproversIds.add(taskTeamId);
    });
    this.setGroupTaskTeamPillItems();
  }

  private selectAllFilterTaskTeamApprovers(deliveryTeamId: number, taskTeamId: number) {
    const taskTeam = this.filteredProjectTaskTeamUsers.find(
      (x) => x.taskTeamId === taskTeamId && x.deliveryTeamId === deliveryTeamId
    );
    if (!taskTeam) return;
    runInAction(() => {
      taskTeam.taskTeamUsers.forEach((user) => {
        if (!user.disabled && !!user.taskTeamRoleIds.length) {
          user.check = true;
        }
      });
      this.activeTTAllApproversIds.add(taskTeamId);
    });
  }

  public selectAllTaskTeamMembers(deliveryTeamId: number, taskTeamId: number) {
    this.selectAllFilterTaskTeamMembers(deliveryTeamId, taskTeamId);
    const taskTeam = this.projectDTTaskTeam.find(
      (x) => x.taskTeamId === taskTeamId && x.deliveryTeamId === deliveryTeamId
    );
    if (!taskTeam) return;
    runInAction(() => {
      taskTeam.taskTeamUsers.forEach((user) => {
        if (!user.taskTeamRoleIds.length) {
          user.check = true;
          this.addSelectedTaskTeamUsers(user, taskTeam);
        }
      });
      this.activeTTAllMembersIds.add(taskTeamId);
      this.activeTTAllApproversIds.add(taskTeamId);
    });
    this.setGroupTaskTeamPillItems();
    this.setDisableItem();
  }

  private selectAllFilterTaskTeamMembers(deliveryTeamId: number, taskTeamId: number) {
    const taskTeam = this.filteredProjectTaskTeamUsers.find(
      (x) => x.taskTeamId === taskTeamId && x.deliveryTeamId === deliveryTeamId
    );
    if (!taskTeam) return;
    runInAction(() => {
      taskTeam.taskTeamUsers.forEach((user) => {
        if (!user.taskTeamRoleIds.length) {
          user.check = true;
        }
      });
      this.activeTTAllMembersIds.add(taskTeamId);
      this.activeTTAllApproversIds.add(taskTeamId);
    });
    this.setDisableItem();
  }

  private addSelectedTaskTeamUsers(user, taskTeam) {
    if (
      !this.selectedTaskTeamUsers.some(
        (x) => x.id === user.id && x.taskTeamId === user.taskTeamId && x.deliveryTeamId === taskTeam.deliveryTeamId
      )
    ) {
      this.selectedTaskTeamUsers.push({ ...user, ...taskTeam, type: 'TaskTeamUser' });
    }

    const taskTeamLength = taskTeam.taskTeamUsers.length;
    const taskTeamApproversLength = taskTeam.taskTeamUsers.filter((user) => user.taskTeamRoleIds.length > 0)?.length;
    const selectedTaskTeamApproversLength = this.selectedTaskTeamUsers.filter((selectedUser) => {
      return taskTeam.taskTeamUsers.find((ttUser) => ttUser.id == selectedUser.id)?.taskTeamRoleIds?.length > 0;
    })?.length;

    if (this.selectedTaskTeamUsers.length === taskTeamLength) {
      this.activeTTAllMembersIds.add(taskTeam.taskTeamId);
      this.activeTTAllApproversIds.add(taskTeam.taskTeamId);
    } else if (taskTeamApproversLength === selectedTaskTeamApproversLength) {
      this.activeTTAllApproversIds.add(taskTeam.taskTeamId);
    }
  }

  private removeSelectedTaskTeamUsers(user, taskTeam) {
    const index = this.selectedTaskTeamUsers.findIndex(
      (x) => x.id === user.id && x.taskTeamId === user.taskTeamId && x.deliveryTeamId === taskTeam.deliveryTeamId
    );
    this.selectedTaskTeamUsers.splice(index, 1);
    this.activeTTAllMembersIds.delete(taskTeam.taskTeamId);

    if (user.taskTeamRoleIds.length > 0) {
      this.activeTTAllApproversIds.delete(taskTeam.taskTeamId);
    }

    this.setDisableItem();
  }

  public addOrRemoveTaskTeamUserById(deliveryTeamId: number, taskTeamId: number, selectUser: ITeamChartTaskTeamUser) {
    if (selectUser.disabled) return;

    this.addOrRemoveFilterTaskTeamUserById(deliveryTeamId, taskTeamId, selectUser.id);
    const taskTeam = this.projectDTTaskTeam.find(
      (x) => x.taskTeamId === taskTeamId && x.deliveryTeamId === deliveryTeamId
    );
    if (!taskTeam) return;
    const user = taskTeam.taskTeamUsers.find((user) => user.id === selectUser.id);
    if (user) {
      runInAction(() => {
        user.check = !user.check;
        if (user.check) {
          this.addSelectedTaskTeamUsers(user, taskTeam);
        } else {
          this.removeSelectedTaskTeamUsers(user, taskTeam);
        }
      });
    }
    this.setGroupTaskTeamPillItems();
    this.setDisableItem();
  }

  private addOrRemoveFilterTaskTeamUserById(deliveryTeamId: number, taskTeamId: number, uId: number) {
    const taskTeam = this.filteredProjectTaskTeamUsers.find(
      (x) => x.taskTeamId === taskTeamId && x.deliveryTeamId === deliveryTeamId
    );
    if (!taskTeam) return;
    const user = taskTeam.taskTeamUsers.find((user) => user.id === uId);
    if (user) {
      runInAction(() => {
        user.check = !user.check;
      });
    }
  }

  public clearAllSelectedUsers() {
    runInAction(() => {
      this.externalPillItems = [];
      this.deliveryTeamPillItems = [];
      this.selectedDeliveryTeamUsers = [];
      this.taskTeamPillItems = [];
      this.selectedTaskTeamUsers = [];

      this.externalUsers.forEach((user) => {
        user.check = false;
      });
      this.filteredExternalUsers.forEach((user) => {
        user.check = false;
      });
      this.projectDTTaskTeam.forEach((tt) => {
        tt.taskTeamUsers.forEach((user) => (user.check = false));
      });
      this.filteredProjectTaskTeamUsers.forEach((tt) => {
        tt.taskTeamUsers.forEach((user) => (user.check = false));
      });
      this.projectDeliveryTeam.forEach((dg) => {
        dg.deliveryTeamUsers.forEach((u) => {
          u.check = false;
        });
      });
      this.filteredProjectDeliveryTeamUsers.forEach((dg) => {
        dg.deliveryTeamUsers.forEach((u) => {
          u.check = false;
        });
      });
      this.setDisableItem();
    });
  }

  public get isShowSelectedUser() {
    return !!this.taskTeamPillItems.length || !!this.deliveryTeamPillItems.length || !!this.externalPillItems.length;
  }

  public isShowFilterResult() {
    return this.selectedDeliveryTeamId || !!this.keywords.length;
  }

  public removeSelectedGroupTaskTeamUsers(taskUser: ITeamChartTaskTeamPill) {
    this.removeSelectedFilterGroupTaskTeamUsers(taskUser);
    const taskTeam = this.projectDTTaskTeam.find(
      (x) => x.taskTeamId === taskUser.taskTeamId && x.deliveryTeamId === taskUser.deliveryTeamId
    );
    if (!taskTeam) return;
    runInAction(() => {
      taskTeam.taskTeamUsers.forEach((user) => {
        user.check = false;
      });
      const selectedItems = this.selectedTaskTeamUsers.map((x) => ({
        ...x,
        isDelete: x.deliveryTeamId === taskUser.deliveryTeamId && x.taskTeamId === taskUser.taskTeamId,
      }));

      this.selectedTaskTeamUsers = [...selectedItems.filter((x) => !x.isDelete)];
      this.activeTTAllMembersIds.delete(taskTeam.taskTeamId);
      this.activeTTAllApproversIds.delete(taskTeam.taskTeamId);
      this.setGroupTaskTeamPillItems();
      this.setDisableItem();
    });
  }

  private removeSelectedFilterGroupTaskTeamUsers(taskUser: ITeamChartTaskTeamPill) {
    const taskTeam = this.filteredProjectTaskTeamUsers.find(
      (x) => x.taskTeamId === taskUser.taskTeamId && x.deliveryTeamId === taskUser.deliveryTeamId
    );
    if (!taskTeam) return;
    runInAction(() => {
      taskTeam.taskTeamUsers.forEach((user) => {
        user.check = false;
      });
    });
  }

  public removeSelectedExUsers(exU) {
    const updateUser = this.externalUsers.find((x) => x.id == exU.id);
    if (updateUser) {
      runInAction(() => {
        updateUser.check = false;
        this.externalPillItems = this.externalPillItems.filter((x) => x.id !== updateUser.id);
      });
    }
  }
  public removeSelectedDTUsers(deliveryTeamPill: ITeamChartDeliveryTeamPill) {
    this.removeSelectedFilterDTUsers(deliveryTeamPill);
    runInAction(() => {
      const deliveryGroup = this.projectDeliveryTeam.find((x) => x.deliveryTeamId === deliveryTeamPill.deliveryTeamId);
      deliveryGroup?.deliveryTeamUsers.forEach((user) => {
        user.check = false;
      });
      this.selectedDeliveryTeamUsers = this.selectedDeliveryTeamUsers.filter(
        (user) => user.deliveryTeamId !== deliveryTeamPill.deliveryTeamId
      );
      this.activeDTAllMembersIds.delete(deliveryTeamPill.deliveryTeamId);
      this.setDeliveryTeamUserPillItems();
      this.setDisableItem();
    });
  }

  private removeSelectedFilterDTUsers(dtUser: ITeamChartDeliveryTeamPill) {
    runInAction(() => {
      const deliveryGroup = this.filteredProjectDeliveryTeamUsers.find(
        (user) => user.deliveryTeamId === dtUser.deliveryTeamId
      );
      deliveryGroup?.deliveryTeamUsers.forEach((user) => {
        user.check = false;
      });
    });
  }

  public async getData(parameter: IGetTeamChartDataParameters) {
    try {
      let deliveryTeams = await getDeliveryTeamsTaskTeamsUsers(
        parameter.projectNumber,
        parameter.fileContainerStateId,
        !parameter.allowSelectAnotherProjectTeam ? parameter.taskTeamId : null
      );
      const exUsers = await getExternalUsers(parameter.projectNumber);
      const allTaskTeams: ITeamChartProjectTaskTeam[] = [];

      if (parameter.allowSelectAnotherProjectTeam === false && parameter.isCollaborateSharePublish === false) {
        deliveryTeams = [...deliveryTeams.filter((x) => x.isCurrentLogInUserAccessible)];
      }

      const filteredDeliveryTeams = this.deliveryTeamOptionMapping(deliveryTeams, parameter);
      for (const dt of filteredDeliveryTeams) {
        const taskTeams = dt.taskTeams.map((t) => {
          return {
            id: t.id,
            title: t.title,
            transmittalCode: t.transmittalCode,
            deliveryTeamId: dt.id,
            deliveryTeamCode: dt.transmittalCode,
            deliveryTeamTitle: dt.title,
            hasTeamMember: !!t.users.length,
          } as ITeamChartProjectTaskTeam;
        });
        allTaskTeams.push(...taskTeams);
      }

      runInAction(() => {
        this.deliveryTeamOptions = [
          ...orderBy(
            filteredDeliveryTeams.map((dt) => {
              return {
                id: dt.id.toString(),
                value: `${dt.title} (${dt.transmittalCode})`,
                disabled: false,
              };
            }),
            'value',
            'asc'
          ),
        ];
        this.allProjectTaskTeams = [...allTaskTeams];
        this.projectDeliveryTeams = filteredDeliveryTeams;
        this.projectExternalUsers = exUsers;
        this.getAllDeliveryTeamUsers();
        this.getAllProjectTaskTeamUser();
        this.getTeamChartExternalUsers();
        if (this.inputSelectedUsers.length) {
          this.setDefaultSelected();
        }
      });
    } catch {
      runInAction(() => {
        this.resetForm();
      });
    }
  }

  private taskTeamOptionMapping(deliveryTaskTeams: ITaskTeam[], parameter: IGetTeamChartDataParameters) {
    return deliveryTaskTeams
      .filter(
        (f) =>
          parameter.allowSelectAnotherProjectTeam ||
          (!parameter.allowSelectAnotherProjectTeam && (!parameter.taskTeamId || f.id === parameter.taskTeamId))
      )
      .map((t) => {
        return {
          ...t,
          users: t.users.filter(
            (f) =>
              (parameter.anyoneInTaskTeam || f.taskTeamRoleIds.includes(TaskTeamRole.Approver)) &&
              (!parameter.excludedUsers.length ||
                !parameter.excludedUsers.map((m) => m.toLowerCase()).includes(f.email.toLowerCase()))
          ),
        };
      });
  }

  private deliveryTeamOptionMapping(deliveryTeams: IDeliveryTeam[], parameter: IGetTeamChartDataParameters) {
    return deliveryTeams
      .filter(
        (f) =>
          parameter.allowSelectAnotherProjectTeam ||
          (!parameter.allowSelectAnotherProjectTeam && (!parameter.deliveryTeamId || f.id === parameter.deliveryTeamId))
      )
      .map((m) => {
        return {
          ...m,
          users: m.users.filter(
            (f) =>
              (parameter.anyoneInDeliverTeam || f.deliveryTeamRoleIds.includes(DeliveryTeamRole.Authoriser)) &&
              (!parameter.excludedUsers.length ||
                !parameter.excludedUsers.map((m) => m.toLowerCase()).includes(f.email.toLowerCase()))
          ),
          taskTeams: this.taskTeamOptionMapping(m.taskTeams, parameter),
        };
      });
  }

  private setExUserSelected() {
    const inputSelectedEXUsers = this.inputSelectedUsers.filter((x) => x.isExternal);
    if (!inputSelectedEXUsers.length) return;

    this.externalUsers.forEach((u) => {
      if (inputSelectedEXUsers.some((x) => x.userId === u.id)) {
        u.check = true;
        if (!this.externalPillItems.some((p) => p.id === u.id)) {
          this.externalPillItems.push({ ...u });
        }
      }
    });
  }

  private setTTUserSelected() {
    this.projectDTTaskTeam.forEach((taskTeamGroup) => {
      const inputSelectedTTUsers = this.inputSelectedUsers.filter(
        (x) => x.deliveryTeamId === taskTeamGroup.deliveryTeamId && x.taskTeamId === taskTeamGroup.taskTeamId
      );
      if (!inputSelectedTTUsers.length) return;
      taskTeamGroup.taskTeamUsers.forEach((user) => {
        if (inputSelectedTTUsers.some((su) => su.userId === user.id)) {
          user.check = true;
          const dtUser: IDTUser = {
            id: user.id,
            name: user.name,
            email: user.email,
            deliveryTeamId: user.deliveryTeamId,
            deliveryTeamRoleTitle: user.deliveryTeamRoleTitle,
            deliveryTeamCode: taskTeamGroup.deliveryTeamCode,
            deliveryTeamTitle: taskTeamGroup.deliveryTeamTitle,
            taskTeamId: user.taskTeamId,
            taskTeamTitle: user.taskTeamTitle,
            type: 'TaskTeamUser',
          };
          this.addSelectedTaskTeamUsers(user, taskTeamGroup);
          const existedPill = this.taskTeamPillItems.find(
            (dp) => dp.taskTeamId === taskTeamGroup.taskTeamId && dp.deliveryTeamId === taskTeamGroup.deliveryTeamId
          );
          if (!existedPill) {
            const newG: ITeamChartTaskTeamPill = {
              taskTeamId: taskTeamGroup.taskTeamId,
              taskTeamCode: taskTeamGroup.taskTeamCode,
              deliveryTeamId: taskTeamGroup.deliveryTeamId,
              deliveryTeamCode: taskTeamGroup.deliveryTeamCode,
              deliveryTeamTitle: taskTeamGroup.deliveryTeamTitle,
              count: 1,
              users: [dtUser],
            };
            this.taskTeamPillItems.push(newG);
          } else {
            existedPill.users.push(dtUser);
            existedPill.count += 1;
          }
        }
      });
    });
  }

  private setDefaultSelected() {
    this.setExUserSelected();
    this.setTTUserSelected();
    this.setDisableItem();
  }

  public get getTaskTeamDropdownOptions() {
    if (!this.selectedDeliveryTeamId) {
      return [
        ...orderBy(
          this.allProjectTaskTeams
            .filter((x) => x.hasTeamMember)
            .map((tt) => {
              return {
                id: tt.id.toString(),
                value: `${tt.title} (${tt.transmittalCode})`,
                disabled: false,
              };
            }),
          'value',
          'asc'
        ),
      ];
    } else {
      return [
        ...orderBy(
          this.allProjectTaskTeams
            .filter((tt) => tt.deliveryTeamId === this.selectedDeliveryTeamId && tt.hasTeamMember)
            .map((tt) => {
              return {
                id: tt.id.toString(),
                value: `${tt.title} (${tt.transmittalCode})`,
                disabled: false,
              };
            }),
          'value',
          'asc'
        ),
      ];
    }
  }

  public getSelectedData() {
    return {
      deliveryUsers: this.selectedDeliveryTeamUsers ?? [],
      taskTeamUsers: this.selectedTaskTeamUsers ?? [],
      externalUsers: this.externalPillItems ?? [],
    };
  }

  public onDTPanelToggle(id: string) {
    const newIds = new Set<string>(this.activeDTPanelIds);
    newIds.has(id) ? newIds.delete(id) : newIds.add(id);
    runInAction(() => {
      this.activeDTPanelIds = newIds;
    });
  }

  public onExUserPanelToggle(id: string) {
    const newIds = new Set<string>(this.activeExUserPanelIds);
    newIds.has(id) ? newIds.delete(id) : newIds.add(id);

    runInAction(() => {
      this.activeExUserPanelIds = newIds;
    });
  }

  public onTTUserPanelToggle(id: string) {
    const newIds = new Set<string>(this.activeTTUserPanelIds);
    newIds.has(id) ? newIds.delete(id) : newIds.add(id);

    runInAction(() => {
      this.activeTTUserPanelIds = newIds;
    });
  }

  public collapseAll() {
    runInAction(() => {
      this.activeTTUserPanelIds = new Set<string>();
    });
  }

  public expandAll() {
    const newIds = new Set<string>();
    for (const taskTeam of this.filteredProjectTaskTeamUsers) {
      newIds.add(`${taskTeam.deliveryTeamId}-${taskTeam.taskTeamId}`);
    }
    runInAction(() => {
      this.activeTTUserPanelIds = newIds;
    });
  }

  private isUserSelected(userId: number) {
    return (
      this.externalPillItems.some((u) => u.id === userId) ||
      this.selectedDeliveryTeamUsers.some((u) => u.id === userId) ||
      this.selectedTaskTeamUsers.some((u) => u.id === userId)
    );
  }

  private setDisableItem() {
    for (const deliveryTeam of this.projectDeliveryTeam) {
      this.setDeliveryTeamUserDisabled(deliveryTeam);
    }

    for (const deliveryTeamUser of this.filteredProjectDeliveryTeamUsers) {
      this.setDeliveryTeamUserDisabled(deliveryTeamUser);
    }

    for (const taskTeam of this.projectDTTaskTeam) {
      this.setTaskTeamUserDisabled(taskTeam);
    }

    for (const taskTeamUser of this.filteredProjectTaskTeamUsers) {
      this.setTaskTeamUserDisabled(taskTeamUser);
    }
  }

  private setDeliveryTeamUserDisabled(deliveryTeam: IDeliveryTeamTeamChart) {
    for (const user of deliveryTeam.deliveryTeamUsers) {
      const isSelected = this.selectedDeliveryTeamUsers.some(
        (selectedUser) =>
          selectedUser.deliveryTeamId === deliveryTeam.deliveryTeamId &&
          selectedUser.id === user.id &&
          !selectedUser.taskTeamId
      );
      if (!isSelected && this.isUserSelected(user.id)) {
        user.disabled = true;
      } else {
        user.disabled = false;
      }
    }
  }

  private setTaskTeamUserDisabled(taskTeam: ITaskTeamUserTeamChart) {
    for (const user of taskTeam.taskTeamUsers) {
      const isSelected = this.selectedTaskTeamUsers.some(
        (selectedUser) =>
          selectedUser.deliveryTeamId === taskTeam.deliveryTeamId &&
          selectedUser.id === user.id &&
          selectedUser.taskTeamId === user.taskTeamId
      );
      if (!isSelected && this.isUserSelected(user.id)) {
        user.disabled = true;
      } else {
        user.disabled = false;
      }
    }
  }
}

export default new ProjectTeamSelectorStore();
