import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import SupersedeFileStore from './SupersedeFileStore';
import ActionBarSection from '../../shared/ActionBarSection';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import ClearFilterLink from '../../shared/ClearFilterLink';
import Style from './styles/SupersedeActionBar.module.scss';
import ActionBar from '../../shared/ActionBar';
import FilesStore from '../FilesStore';
import FileStatus from './FileStatus';
import Icon from '../../shared/Icon';

const SupersedeActionBar: FC = () => {
  const renderFileInfo = () => {
    return (
      <>
        {SupersedeFileStore.currentFileContainer && (
          <div className={Style.fileWrapper}>
            <div className={Style.fileNameWrapper}>
              {SupersedeFileStore.currentFileContainer.groupedContainerName && (
                <span>{`${SupersedeFileStore.currentFileContainer.groupedContainerName} | `}</span>
              )}
              <span>{SupersedeFileStore.currentFileContainer.files[0].fileName}</span>
            </div>
            <div className={Style.fileStatusWrapper}>
              {SupersedeFileStore.currentFileContainer.files.length > 1 && (
                <div className={Style.fileIconWrapper}>
                  <Icon outlined name="description" />
                  <span>{SupersedeFileStore.currentFileContainer.files.length}</span>
                </div>
              )}

              <FileStatus statuses={SupersedeFileStore.currentFileContainer.statuses} />
            </div>
          </div>
        )}
      </>
    );
  };
  return (
    <ActionBar>
      {renderFileInfo()}
      <ActionBarSection>
        {SupersedeFileStore.selectedFileContainers.length > 1 && (
          <p className={Style.filePage}>
            {SupersedeFileStore.currentPage} of {SupersedeFileStore.selectedFileContainers.length}
          </p>
        )}
        <>
          <PrimaryButton
            disabled={!SupersedeFileStore.selectedDuplicateFileContainerId}
            onClick={SupersedeFileStore.confirmReview}>
            {SupersedeFileStore.currentPage === SupersedeFileStore.selectedFileContainers.length ? 'Confirm' : 'Next'}
          </PrimaryButton>
          {SupersedeFileStore.currentPage === SupersedeFileStore.selectedFileContainers.length && (
            <>
              <SecondaryButton onClick={SupersedeFileStore.skipAndConfirm}>Skip and Confirm</SecondaryButton>
            </>
          )}
          {SupersedeFileStore.currentPage !== SupersedeFileStore.selectedFileContainers.length && (
            <>
              <SecondaryButton onClick={SupersedeFileStore.skipAndNext}>Skip and Next</SecondaryButton>
              <SecondaryButton onClick={() => FilesStore.setOpenSupersedeFile(false)}>Cancel</SecondaryButton>
            </>
          )}
        </>
        <ClearFilterLink onClick={SupersedeFileStore.ClearFilter}></ClearFilterLink>
      </ActionBarSection>
    </ActionBar>
  );
};

export default observer(SupersedeActionBar);
