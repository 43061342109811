import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/files/upload/AddUnbrandedFileModal.module.scss';
import ModalActions from '../../shared/ModalActions';
import PrimaryButton from '../../shared/PrimaryButton';
import { FormInput, Grid } from '@aurecon-creative-technologies/styleguide';
import SecondaryButton from '../../shared/SecondaryButton';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import Modal from '../../shared/Modal';
import CloseButton from '../../shared/CloseButton';

const maxCharacterCount = 200;

interface IAddUnbrandedFileModalProps {
  selectedDocumentPath: string | null;
  selectedType: string;
  currentSeqNumber: number;
  templateId: number | null;
  onCancel: () => void;
  onSet: (fileName: string, fileType: string, filePath, templateId) => void;
}

const AddUnbrandedFileModal: FC<IAddUnbrandedFileModalProps> = (props) => {
  const { selectedDocumentPath, templateId, selectedType, currentSeqNumber, onCancel, onSet } = props;
  const [originalFileNameError, setOriginalFileNameError] = useState<string>('');
  const [originalFileName, setOriginalFileName] = useState(`Document${currentSeqNumber}`);
  const [fileNameCharacterCount, setFileNameCharacterCount] = useState<number>(maxCharacterCount);
  const inputRef = useRef<HTMLInputElement>(null);

  const validateOriginalFileName = useCallback(() => {
    const error = !originalFileName.trim()
      ? 'Please input the original filename'
      : originalFileName.length > maxCharacterCount
      ? `Original name is longer than ${maxCharacterCount} characters`
      : '';
    setOriginalFileNameError(error);
    setFileNameCharacterCount(maxCharacterCount - originalFileName.length);
  }, [originalFileName]);

  useEffect(() => {
    validateOriginalFileName();
  }, [validateOriginalFileName]);

  const handleOriginalFileNameChanged = (text: string) => {
    if (text.length > maxCharacterCount) return;
    setOriginalFileName(text);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <CloseButton onClick={onCancel} />
        <h2>Set Original Filename</h2>
        <Grid row>
          <Grid md={12}>
            <FormInput
              ref={inputRef}
              type="text"
              label="Original Filename"
              placeholder="Enter Original Filename..."
              required
              cssClass={Style.cellInputLeft}
              error={originalFileNameError}
              value={originalFileName}
              onChange={(text) => handleOriginalFileNameChanged(text)}
            />
            <div className={Style.fileTypeWrapper}>
              <span className={`${originalFileNameError ? Style.hasError : ''}`}>.{selectedType}</span>
            </div>
          </Grid>
          {!originalFileNameError && (
            <Grid md={12}>
              <p className={Style.charNum}>{fileNameCharacterCount} characters left</p>
            </Grid>
          )}
        </Grid>

        <ModalActions>
          <SecondaryButton onClick={() => onCancel()}>Cancel</SecondaryButton>
          <PrimaryButton
            onClick={() => onSet(originalFileName.trim(), selectedType, selectedDocumentPath, templateId)}
            disabled={!!originalFileNameError.length}>
            Set
          </PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(AddUnbrandedFileModal);
