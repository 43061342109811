import { makeAutoObservable, runInAction } from 'mobx';
import Layer from '@arcgis/core/layers/Layer';
import { getProjectArcGisSettings } from '../../api/authenticated/config/getProjectArcGisSettings';
import { IProject } from '../../api/authenticated/config/getClientInfo';
import AppStore from '../../stores/AppStore';

import Portal from '@arcgis/core/portal/Portal';
import IdentityManager from '@arcgis/core/identity/IdentityManager';
import esriConfig from '@arcgis/core/config';
import OAuthInfo from '@arcgis/core/identity/OAuthInfo';
import { IProjectArcGisSettings } from './models/IProjectArcGisSettingsModel';
import { IChainageAttributes } from './models/IChainageAttributes';

export const MAP_TRANSPARENT = 100;
export const MAP_OPAQUE = 0;

export class VisualisationStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private esriId = IdentityManager;

  public isLoadingArcGisProjectSettings = false;
  public arcGisApiKey = '';
  public arcGisClientId = '';
  public arcGisPortalUrl = '';
  public arcGisIsLoggingIn = false;
  public arcGisIsAuthenticated = false;
  public arcGisErrorLoggingIn = false;
  public projectArcGisSettings: IProjectArcGisSettings = {
    webSceneId: null,
    baseMap: null,
    toggleMap: null,
    chainageLayerId: null,
  };
  public isWebScene = true;
  public webScene;
  public mapView;
  public mapLayers: Layer[] = [];
  public selectedLayers: string[] = [];
  public buildingLayers: Layer[] = [];
  public pointCloudLayers: Layer[] = [];
  public chainageLayerData: IChainageAttributes[] = [];
  public selectedMeasurementType = 1;
  public mapOpacity = 70;
  public pointCloudSize = 5;
  public mapQuality = 2;

  public async loadProjectArcGisSettings(project: IProject) {
    runInAction(() => {
      this.isLoadingArcGisProjectSettings = true;
    });

    if (!AppStore.client?.arcGisUseOauth)
      runInAction(() => {
        this.arcGisApiKey = AppStore.client?.arcGisApiKey ? AppStore.client?.arcGisApiKey : '';
      });
    else {
      runInAction(() => {
        this.arcGisClientId = AppStore.client?.arcGisClientId ? AppStore.client?.arcGisClientId : '';
      });
    }

    runInAction(() => {
      this.arcGisPortalUrl = AppStore.client?.arcGisPortalUrl ? AppStore.client?.arcGisPortalUrl : '';
    });

    try {
      const data = await getProjectArcGisSettings(project.projectNumber);
      runInAction(() => {
        this.projectArcGisSettings = {
          webSceneId: data.webSceneId,
          baseMap: data.baseMap,
          toggleMap: data.toggleMap,
          chainageLayerId: data.chainageLayerId,
        };
      });
    } finally {
      runInAction(() => {
        this.isLoadingArcGisProjectSettings = false;
      });
    }
  }

  public setWebScene(value) {
    runInAction(() => {
      this.webScene = value;
    });
  }

  public setMapView(value) {
    runInAction(() => {
      this.mapView = value;
    });
  }

  public setMapLayers(value: Layer[]) {
    runInAction(() => {
      this.mapLayers = value;
    });
  }

  public addToSelectedLayers(value: string) {
    runInAction(() => {
      const selLayers = this.selectedLayers;
      if (selLayers.includes(value)) return;

      selLayers.push(value);
      this.selectedLayers = selLayers;
    });
  }

  public removeFromSelectedLayers(value: string) {
    runInAction(() => {
      const selLayers = this.selectedLayers.filter((l) => l != value);
      this.selectedLayers = selLayers;
    });
  }

  public setSelectedLayers(value: string[]) {
    runInAction(() => {
      this.selectedLayers = value;
    });
  }

  public clearSelectedLayers() {
    runInAction(() => {
      this.selectedLayers = [];
    });
  }

  public isLayerSelected(value: string) {
    return this.selectedLayers.find((l) => l === value) != null;
  }

  public setBuildingLayers(value: Layer[]) {
    runInAction(() => {
      this.buildingLayers = value;
    });
  }

  public setPointCloudLayers(value: Layer[]) {
    runInAction(() => {
      this.pointCloudLayers = value;
    });
  }

  public setChainageLayerData(value: IChainageAttributes[]) {
    runInAction(() => {
      this.chainageLayerData = value;
    });
  }

  public setSelectedMeasurementType(value: number) {
    runInAction(() => {
      this.selectedMeasurementType = value;
    });
  }

  public setMapOpacity(value: number) {
    runInAction(() => {
      this.mapOpacity = value;
    });

    if (!this.webScene) return;

    const map = this.webScene;
    if (map) {
      map.ground.navigationConstraint = { type: value !== MAP_OPAQUE ? 'none' : 'stay-above' };
      map.ground.opacity = (MAP_TRANSPARENT - value) / MAP_TRANSPARENT;
    }
  }

  public setPointCloudSize(value: number) {
    runInAction(() => {
      this.pointCloudSize = value;
    });

    if (!this.webScene) return;

    const pcLayers = this.webScene.layers.filter((l) => l.type === 'point-cloud');

    if (pcLayers && pcLayers.length > 0) {
      pcLayers.forEach((layer) => {
        const renderer = layer.renderer;
        renderer.pointSizeAlgorithm.scaleFactor = value;
        renderer.pointsPerInch = value * 25;
        layer.renderer = renderer;
      });
    }
  }

  public setMapQuality(value: number) {
    runInAction(() => {
      this.mapQuality = value;

      if (!this.mapView) return;

      this.mapView.qualityProfile = value === 0 ? 'low' : value === 1 ? 'medium' : 'high';
    });
  }

  private setArcGisLoginStatus(isLoggingIn, isAuthenticated, errorLoggingIn) {
    runInAction(() => {
      this.arcGisIsLoggingIn = isLoggingIn;
      this.arcGisIsAuthenticated = isAuthenticated;
      this.arcGisErrorLoggingIn = errorLoggingIn;
    });
  }

  public async setupArcGis() {
    if (this.arcGisErrorLoggingIn || this.arcGisIsAuthenticated) {
      return;
    }

    this.setArcGisLoginStatus(true, false, false);

    esriConfig.portalUrl = this.arcGisPortalUrl;

    if (!AppStore.client?.arcGisUseOauth) {
      esriConfig.apiKey = this.arcGisApiKey;
      this.setArcGisLoginStatus(false, true, false);
    } else {
      const info = new OAuthInfo({
        appId: this.arcGisClientId,
        portalUrl: this.arcGisPortalUrl,
        popup: true,
        popupCallbackUrl: `${window.location.origin}/esri/oauth-callback.html`,
      });

      this.esriId.registerOAuthInfos([info]);

      await this.initAuth();
    }
  }

  private async checkUserDetails() {
    this.setArcGisLoginStatus(false, true, false);

    const portal = new Portal();
    portal.url = this.arcGisPortalUrl;
    portal.authMode = 'immediate';

    await portal.load();
  }

  private async login() {
    try {
      const credentialResult = await this.esriId.getCredential(this.arcGisPortalUrl + '/sharing', {
        oAuthPopupConfirmation: false,
      });

      this.setArcGisLoginStatus(false, true, false);

      return credentialResult;
    } catch {
      this.setArcGisLoginStatus(false, false, true);

      return null;
    }
  }

  private async initAuth() {
    try {
      await this.esriId.checkSignInStatus(this.arcGisPortalUrl + '/sharing');
      await this.checkUserDetails();
    } catch {
      try {
        const loginResult = await this.login();

        if (loginResult) await this.checkUserDetails();
      } catch {
        this.setArcGisLoginStatus(false, false, true);
      }
    }
  }
}

export default new VisualisationStore();
