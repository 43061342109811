import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/HelpWidget.module.scss';
import { ReactComponent as MouseNavigation } from '../images/mouseNavigation.svg';
import { ReactComponent as KeyboardNavigation } from '../images/keyboardNavigation.svg';
import { ReactComponent as EnterFullscreen } from '../images/enterFullscreen.svg';
import { ReactComponent as ReturnDefaultMapView } from '../images/returnDefaultMapView.svg';
import { ReactComponent as ResetMapOrientation } from '../images/resetMapOrientation.svg';
import { ReactComponent as ToggleBasemap } from '../images/toggleBasemap.svg';
import { ReactComponent as OnScreenZoomIn } from '../images/onScreenZoomIn.svg';
import { ReactComponent as OnScreenZoomOut } from '../images/onScreenZoomOut.svg';
import { ReactComponent as KeyboardLeft } from '../images/keyboardLeft.svg';
import { ReactComponent as KeyboardRight } from '../images/keyboardRight.svg';
import { ReactComponent as KeyboardUp } from '../images/keyboardUp.svg';
import { ReactComponent as KeyboardDown } from '../images/keyboardDown.svg';
import { ReactComponent as KeyboardZoomIn } from '../images/keyboardZoomIn.svg';
import { ReactComponent as KeyboardZoomOut } from '../images/keyboardZoomOut.svg';
import GoBackButton from '../../shared/GoBackButton';

const HelpWidget: FC = () => {
  const [showNavigationMenu, setShowNavigationMenu] = useState<'mouse' | 'keyboard' | 'on-screen' | ''>('');
  const renderMouseNavigation = () => {
    return (
      <>
        <h4>
          <strong>Mouse Navigation</strong>
        </h4>
        <div className={Style.mouseNavigationWrapper}>
          <div className={Style.left}>
            <div className={Style.textWrapper}>
              <span>
                <strong>Move around</strong>
              </span>
              <span>Left click and drag to pan around the view.</span>
            </div>
            <div className={Style.textWrapper}>
              <span>
                <strong>Zoom in and out</strong>
              </span>
              <span>Use the wheel button to tilt the view or rotate around the point you clicked</span>
            </div>
            <div className={Style.textWrapper}>
              <span>
                <strong>Tilt and Rotate</strong>
              </span>
              <span>Right click and drag to zoom in and out continuously.</span>
            </div>
          </div>
          <div className={Style.right}>
            <MouseNavigation />
          </div>
        </div>
      </>
    );
  };

  const renderKeyBoardNavigation = () => {
    return (
      <>
        <h4>
          <strong>Keyboard Navigation</strong>
        </h4>
        <div className={Style.navigationWrapper}>
          <div className={Style.left}>
            <div className={Style.textWrapper}>
              <span>
                <strong>Look perpendicular</strong>
              </span>
              <span>Press P and the view automatically shifts</span>
            </div>
            <div className={Style.textWrapper}>
              <span>
                <strong>Point north</strong>
              </span>
              <span>Press N to reset the orientation to point north</span>
            </div>
            <div className={Style.textWrapper}>
              <span>
                <strong>Tilt camera: Up</strong>
                <KeyboardUp className={Style.keyboardIcon} />
              </span>
              <span>Press W</span>
            </div>
            <div className={Style.textWrapper}>
              <span>
                <strong>Tilt camera: Down</strong>
                <KeyboardDown className={Style.keyboardIcon} />
              </span>
              <span>Press S</span>
            </div>
            <div className={Style.textWrapper}>
              <span>
                <strong>Tilt camera: Left</strong>
                <KeyboardLeft className={Style.keyboardIcon} />
              </span>
              <span>Press A</span>
            </div>
            <div className={Style.textWrapper}>
              <span>
                <strong>Tilt camera: Right</strong>
                <KeyboardRight className={Style.keyboardIcon} />
              </span>
              <span>Press D</span>
            </div>
            <div className={Style.textWrapper}>
              <span>
                <strong>Zoom in</strong>
              </span>
              <span>
                Shift + Press <KeyboardZoomIn className={Style.keyboardIcon} />
              </span>
            </div>
            <div className={Style.textWrapper}>
              <span>
                <strong>Zoom out</strong>
              </span>
              <span>
                Press <KeyboardZoomOut className={Style.keyboardIcon} />
              </span>
            </div>
          </div>
          <div className={Style.right}>
            <KeyboardNavigation />
          </div>
        </div>
      </>
    );
  };

  const renderOnScreenNavigation = () => {
    return (
      <>
        <h4>
          <strong>On-Screen Navigation</strong>
        </h4>
        <div className={Style.navigationWrapper}>
          <div className={Style.left}>
            <div className={Style.textWrapper}>
              <span>
                <EnterFullscreen className={Style.onScreenIcon} />
                <strong>Enter fullscreen</strong>
              </span>
            </div>
            <div className={Style.textWrapper}>
              <span>
                <ResetMapOrientation className={Style.onScreenIcon} />
                <strong>Reset map orientation</strong>
              </span>
            </div>
            <div className={Style.textWrapper}>
              <span>
                <ToggleBasemap className={Style.onScreenIcon} />
                <strong>Toggle basemap</strong>
              </span>
            </div>
            <div className={Style.textWrapper}>
              <span>
                <ReturnDefaultMapView className={Style.onScreenIcon} />
                <strong>Return to default map view</strong>
              </span>
            </div>
            <div className={Style.textWrapper}>
              <span>
                <OnScreenZoomIn className={Style.onScreenIcon} />
                <strong>Zoom In</strong>
              </span>
            </div>
            <div className={Style.textWrapper}>
              <span>
                <OnScreenZoomOut className={Style.onScreenIcon} />
                <strong>Zoom Out</strong>
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderNavigation = () => {
    switch (showNavigationMenu) {
      case 'mouse':
        return renderMouseNavigation();
      case 'keyboard':
        return renderKeyBoardNavigation();
      case 'on-screen':
        return renderOnScreenNavigation();
      default:
        return;
    }
  };

  const renderNavigationMenu = () => {
    return (
      <div className={Style.navigationMenuWrapper}>
        {!showNavigationMenu ? (
          <>
            <h4
              className={Style.heading}
              onClick={() => {
                setShowNavigationMenu('mouse');
              }}>
              Mouse Navigation
            </h4>
            <h4
              className={Style.heading}
              onClick={() => {
                setShowNavigationMenu('keyboard');
              }}>
              Keyboard Navigation
            </h4>
            <h4
              className={Style.heading}
              onClick={() => {
                setShowNavigationMenu('on-screen');
              }}>
              On-screen Navigation
            </h4>
          </>
        ) : (
          <>
            <GoBackButton onClick={() => setShowNavigationMenu('')} className={Style.goBackButton} />
            {renderNavigation()}
          </>
        )}
      </div>
    );
  };
  return <div className={Style.helpWidgetContainer}>{renderNavigationMenu()}</div>;
};

export default observer(HelpWidget);
