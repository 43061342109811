import React, { FC, useEffect, useState, useCallback, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { FormInput, Grid, Table, Tooltip } from '@aurecon-creative-technologies/styleguide';
import Style from '../../../../styles/components/settings/systemAdminSettings/systemSettings/FileTemplatesSettings.module.scss';
import PrimaryIconButton from '../../../shared/PrimaryIconButton';
import SecondaryButton from '../../../shared/SecondaryButton';
import PrimaryButton from '../../../shared/PrimaryButton';
import { v4 as uuidv4 } from 'uuid';
import { classNames } from '../../../../utils/miscUtils';
import { updateClientFileTemplates } from '../../../../api/authenticated/config/updateClientFileTemplates';
import LayoutStore from '../../../layout/LayoutStore';
import { sort } from '../../../../utils/sortHelper';
import { SortType, SortTypes } from '../../../../common/enums/SortType';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import Icon from '../../../shared/Icon';
import FileTemplatesUploadModal from './fileTemplatesUpload/FileTemplatesUploadModal';
import { getClientFileTemplates } from '../../../../api/authenticated/config/getClientFileTemplates';
import { getClientFileTemplateDownloadUrls } from '../../../../api/authenticated/config/getClientFileTemplateDownloadUrls';
import { deleteClientFileTemplate } from '../../../../api/authenticated/config/deleteClientFileTemplate';
import ConfirmationModal from '../../../shared/ConfirmationModal';
import FileTemplatesUploadStore from './fileTemplatesUpload/FileTemplatesUploadStore';

export const maxTemplateFileNameLength = 200;

const tableHeaderKeyValueFields = {
  dragIcon: {
    label: '',
    fieldName: 'dragIcon',
  },
  templateName: {
    label: 'Template name',
    fieldName: 'templateFileName',
  },
  originalFilename: {
    label: 'Original Filename',
    fieldName: 'originalFileName',
  },
  remove: {
    label: 'Remove',
    fieldName: 'remove',
  },
  download: {
    label: 'Download',
    fieldName: 'download',
  },
};

interface IClonedClientFileTemplate {
  id: number;
  tempId: string;
  templateFileName: string;
  originalFileName: string;
  path: string;
  order: number;
  errors: { templateFileName: string };
}

interface IDeleteClientFileTemplate {
  id: number;
  templateFileName: string;
  showModalConfirm: boolean;
}

const FileTemplatesSettings: FC = () => {
  const [editMode, setEditMode] = useState(false);
  const [fileTemplates, setFileTemplates] = useState<IClonedClientFileTemplate[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [sortType, setSortType] = useState<SortType>('none');
  const [sortColumn, setSortColumn] = useState<string>();
  const [showUploadFileTemplatesModal, setShowUploadFileTemplatesModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState<IDeleteClientFileTemplate | null>();

  const loadTemplateFiles = useCallback(async () => {
    const result = await getClientFileTemplates();
    setFileTemplates([
      ...result.map((m) => {
        return {
          ...m,
          tempId: uuidv4(),
          errors: { templateFileName: '' },
        };
      }),
    ]);
  }, []);

  useEffect(() => {
    loadTemplateFiles();
  }, [loadTemplateFiles]);

  const getHeaders = () => {
    return [
      ...(editMode
        ? [
            {
              label: tableHeaderKeyValueFields.dragIcon.label,
              style: { width: '2%' },
            },
          ]
        : []),
      {
        label: tableHeaderKeyValueFields.templateName.label,
        style: { width: '39%' },
        sort: sortColumn === tableHeaderKeyValueFields.templateName.fieldName ? sortType : 'none',
        onSort: (sort) => handleSortTemplates(tableHeaderKeyValueFields.templateName.fieldName, sort as SortType),
      },
      {
        label: tableHeaderKeyValueFields.originalFilename.label,
        style: { width: '49%' },
        sort: sortColumn === tableHeaderKeyValueFields.originalFilename.fieldName ? sortType : 'none',
        onSort: (sort) => handleSortTemplates(tableHeaderKeyValueFields.originalFilename.fieldName, sort as SortType),
      },
      {
        label: tableHeaderKeyValueFields.download.label,
        style: { width: editMode ? '5%' : '6%' },
      },
      {
        label: tableHeaderKeyValueFields.remove.label,
        style: { width: editMode ? '5%' : '6%' },
      },
    ];
  };

  const getColumnWidth = (column: string) => {
    return getHeaders().find((f) => f.label === column)?.style.width;
  };

  const handleCancel = () => {
    loadTemplateFiles();
    setSortType('none');
    setEditMode(false);
  };

  const handleSave = async () => {
    const results = await FileTemplatesUploadStore.checkTemplateFileNamesDuplicated(
      fileTemplates.map((t) => ({ id: t.id, templateName: t.templateFileName }))
    );
    if (results?.some((t) => t.duplicated)) {
      const duplicatedItems = results.filter((t) => t.duplicated);
      if (duplicatedItems) {
        const clientFileTemplates = await getClientFileTemplates();
        const newFileTemplates = [
          ...clientFileTemplates.map((m) => {
            return {
              ...m,
              tempId: uuidv4(),
              errors: { templateFileName: '' },
            };
          }),
        ];
        duplicatedItems.forEach((item) => {
          const index = newFileTemplates.findIndex((t) => t.id == item.id);
          if (index > -1) {
            newFileTemplates[index].templateFileName = item.templateName;
            newFileTemplates[index].errors.templateFileName = 'Filename already exists. Please enter another one.';
          }
        });
        setFileTemplates(newFileTemplates);
      }

      return;
    }

    setIsSaving(true);
    try {
      await updateClientFileTemplates({
        clientFileTemplates: [
          ...fileTemplates.map((m) => {
            return {
              id: m.id,
              templateFileName: m.templateFileName,
              order: m.order,
            };
          }),
        ],
      });
      setSortType('none');
      await loadTemplateFiles();
      LayoutStore.displayToast('success', 'Templates have been successfully saved.');
    } catch {
      LayoutStore.displayToast('error', 'Templates can not be saved.');
    }

    setIsSaving(false);
    setEditMode(false);
  };

  const handleUpdateData = (
    value: string,
    tempId: string,
    fieldName: string,
    validationCallback: (value, id) => string
  ) => {
    if (value.length > maxTemplateFileNameLength) return;

    const current = fileTemplates.find((f) => f.tempId === tempId);
    if (current) {
      current[fieldName] = value;
      current.errors[fieldName] = validationCallback(value, current.id);
      setFileTemplates([...fileTemplates]);
    }
  };

  const hasErrors = () => {
    return fileTemplates.some((s) => s.errors.templateFileName);
  };

  const renderActions = () => {
    return (
      <>
        {editMode ? (
          <div className={Style.actionButtonsWrapper}>
            <SecondaryButton disabled={isSaving} onClick={handleCancel}>
              Cancel
            </SecondaryButton>
            <PrimaryButton disabled={hasErrors()} loading={isSaving} onClick={handleSave}>
              Save
            </PrimaryButton>
          </div>
        ) : (
          <PrimaryIconButton
            icon="edit"
            size="large"
            className={Style.actionButton}
            onClick={() => setEditMode(true)}></PrimaryIconButton>
        )}
      </>
    );
  };

  const moveItemsArray = (array, fromIndex, toIndex) => {
    array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
  };
  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    moveItemsArray(fileTemplates, result.source.index, result.destination.index);
    fileTemplates.reverse().forEach((t, i) => (t.order = i));

    const sorted = sort([...fileTemplates], 'order', SortTypes.DESC);
    setFileTemplates([...sorted]);
  };

  const handleSortTemplates = (sortColumn: string, sortType: SortType) => {
    setSortType(sortType);
    setSortColumn(sortColumn);
    const sorted = sort(
      [...fileTemplates],
      sortColumn,
      sortType === 'asc' ? SortTypes.ASC : SortTypes.DESC
    ) as IClonedClientFileTemplate[];
    sorted.forEach((f, i) => (f.order = +i));
    setFileTemplates([...sorted]);
  };

  const onCloseUploadFileModal = (uploadSucceeded) => {
    setShowUploadFileTemplatesModal(false);
    if (uploadSucceeded) {
      loadTemplateFiles();
    }
  };

  const validateTemplateFileName = useCallback(
    (value, id) => {
      value = value?.trim() ?? '';
      if (!value) return 'Please input the template name.';
      if (value.length > maxTemplateFileNameLength)
        return `Template name can not exceed ${maxTemplateFileNameLength} characters.`;
      if (
        value &&
        fileTemplates
          .filter((t) => t.id !== id)
          .map((t) => t.templateFileName)
          .includes(value)
      )
        return `Filename already exists. Please enter another one.`;

      return '';
    },
    [fileTemplates]
  );
  const refs = useRef<HTMLTableCellElement[]>([]);
  const [showTooltips, setShowTooltips] = useState<string[]>([]);

  const handleShowTooltip = (label: string) => {
    const items: string[] = [];

    const element = refs.current[label];
    if (element && element.scrollWidth > element.clientWidth) items.push(label);

    setShowTooltips(items);
  };

  const hasTooltip = (label: string) => {
    return showTooltips.includes(label);
  };
  const renderTemplateRow = (m: IClonedClientFileTemplate) => {
    return (
      <>
        {editMode && (
          <div
            style={{ width: getColumnWidth(tableHeaderKeyValueFields.dragIcon.label) }}
            className={classNames(Style.row, Style.dragIcon)}>
            <Icon name="drag_indicator" />
          </div>
        )}
        {editMode ? (
          <div
            style={{ width: getColumnWidth(tableHeaderKeyValueFields.templateName.label) }}
            className={classNames(Style.row)}>
            <div className={Style.wrapTemplateName}>
              <FormInput
                value={m.templateFileName}
                placeholder="Enter template name..."
                error={m.errors.templateFileName}
                onChange={(value) => {
                  handleUpdateData(value, m.tempId, 'templateFileName', validateTemplateFileName);
                }}
              />
              {m.templateFileName && (
                <span className={Style.charactersLeft}>
                  {maxTemplateFileNameLength - m.templateFileName?.length ?? 0} characters left
                </span>
              )}
            </div>
          </div>
        ) : hasTooltip(m.templateFileName) ? (
          <Tooltip
            show={m.templateFileName}
            style={{
              width: getColumnWidth(tableHeaderKeyValueFields.templateName.label),
            }}
            cssClass={classNames(Style.row, Style.rowTemplateFileName, Style.tooltip, Style.textOverflow)}>
            <span ref={(el) => (refs.current[m.templateFileName] = el)} className={Style.templateFileName}>
              {m.templateFileName}
            </span>
          </Tooltip>
        ) : (
          <div
            onMouseOver={() => handleShowTooltip(m.templateFileName)}
            onMouseOut={() => handleShowTooltip(m.templateFileName)}
            style={{ width: getColumnWidth(tableHeaderKeyValueFields.templateName.label) }}
            className={classNames(Style.row, Style.rowTemplateFileName)}>
            <span ref={(el) => (refs.current[m.templateFileName] = el)} className={Style.templateFileName}>
              {m.templateFileName}
            </span>
          </div>
        )}

        <div
          style={{ width: getColumnWidth(tableHeaderKeyValueFields.originalFilename.label) }}
          className={classNames(Style.row, [editMode, Style.textDisabled])}>
          <span>{m.originalFileName}</span>
        </div>

        <div style={{ width: getColumnWidth(tableHeaderKeyValueFields.download.label) }} className={Style.row}>
          <SecondaryButton className={Style.downloadBtn} onClick={() => downloadClientFileTemplates(m.id)}>
            <Icon className={Style.downloadIcon} name={'download_2'} />
          </SecondaryButton>
        </div>
        <div style={{ width: getColumnWidth(tableHeaderKeyValueFields.remove.label) }} className={Style.row}>
          <SecondaryButton className={Style.removeBtn} onClick={() => onConfirmDelete(m.id, m.templateFileName)}>
            <Icon className={Style.removeIcon} name={'delete'} />
          </SecondaryButton>
        </div>
      </>
    );
  };

  const downloadClientFileTemplates = async (clientFileTemplateFileId?: number) => {
    if (!clientFileTemplateFileId || !fileTemplates || !fileTemplates.length) return;

    try {
      const urls = await getClientFileTemplateDownloadUrls({
        clientFileTemplateFileIds: [clientFileTemplateFileId],
      });

      if (urls)
        urls.forEach((url) => {
          if (!url.errorMessage) window.open(url.url);
        });
    } catch (err) {
      LayoutStore.displayToast('error', 'File template can not be download.');
    }
  };

  const onDeleteFileTemplate = async () => {
    if (!deleteModal?.id || !fileTemplates || !fileTemplates.length) return;

    setIsSaving(true);
    try {
      await deleteClientFileTemplate(deleteModal.id);
      await loadTemplateFiles();
      LayoutStore.displayToast('success', `File template has been successfully deleted.`);
    } catch (err) {
      LayoutStore.displayToast('error', `Deleting file template was unsuccessful.`);
    }
    setIsSaving(false);
    setDeleteModal(null);
  };

  const onConfirmDelete = async (id: number, templateFileName: string) => {
    setDeleteModal({ showModalConfirm: true, templateFileName, id });
  };

  const onCancelDelete = () => {
    setDeleteModal(null);
  };

  return (
    <>
      <Grid row md={12} cssClass={Style.gridGroupWrapper}>
        <div className={classNames(Style.header, Style.settingGroup)}>
          <span>File Template</span>
          {!!fileTemplates.length && renderActions()}
        </div>
        <h4 className={Style.subHeader}>
          Upload a file (.docx, .xlsx, .pptx) to create a template available for users in Work in Progress file upload.
        </h4>
        <div className={Style.uploadFileBtn}>
          <SecondaryButton onClick={() => setShowUploadFileTemplatesModal(true)}>Upload Template</SecondaryButton>
        </div>
      </Grid>
      {!!fileTemplates.length && (
        <Grid item md={12} cssClass={Style.gridTableWrapper}>
          <Table headers={getHeaders()} />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" isDropDisabled={!editMode}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className={Style.droppableWrapper}>
                  {fileTemplates.map((m, i) => (
                    <Draggable draggableId={m.tempId} index={i} key={m.tempId} isDragDisabled={!editMode}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={Style.appRowWrapper}>
                          {renderTemplateRow(m)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      )}
      {showUploadFileTemplatesModal && (
        <FileTemplatesUploadModal
          checkTemplateFileName={(value) => validateTemplateFileName(value, null)}
          closeModal={(uploadSucceeded) => onCloseUploadFileModal(uploadSucceeded)}
          loadTemplates={() => loadTemplateFiles()}
        />
      )}

      <ConfirmationModal
        showModal={deleteModal?.showModalConfirm ?? false}
        heading="Delete Template?"
        message={`This action will delete the template. Are you sure you want to delete ${deleteModal?.templateFileName}?`}
        confirmText="Yes"
        cancelText="No"
        loading={isSaving}
        onConfirm={onDeleteFileTemplate}
        onCancel={onCancelDelete}
      />
    </>
  );
};

export default observer(FileTemplatesSettings);
