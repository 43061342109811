import React, { useEffect, useState } from 'react';
import { ISuggestion } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import NavBarSelectorStore from './NavBarSelectorStore';
import NavBarSelectorProgramme from '../../shared/NavBarSelectorProgramme';
import NavBarSelectorProject from '../../shared/NavBarSelectorProject';
import NavBarSelectorTaskTeam from '../../shared/NavBarSelectorTaskTeam';
import TopNavBarSelector from '../../shared/TopNavBarSelector';
import Style from './styles/NavBarSelector.module.scss';
import Icon from '../../shared/Icon';
import { Item, TaskTeamItem, ProjectItem } from '../../../common/models/ItemType';
import { sortBy } from 'lodash';
import { SortTypes } from '../../../common/enums/SortType';

interface INavBarSelectorProp {
  disable: boolean;
}

const getItemsSearchBox = (items: Item[]): ISuggestion[] => {
  return items.map((item) => {
    if (item.type === 'Programme')
      return { id: item.id, value: item.id, display: <NavBarSelectorProgramme item={item} /> };
    if (item.type === 'Project') return { id: item.id, value: item.id, display: <NavBarSelectorProject item={item} /> };
    return { id: item.id, value: item.id, display: <NavBarSelectorTaskTeam item={item} /> };
  });
};

const NavBarSelector: React.FC<INavBarSelectorProp> = (props) => {
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);

  useEffect(() => {
    NavBarSelectorStore.initSelector();
    setSuggestions(getItemsSearchBox(NavBarSelectorStore.items));
  }, []);

  const handleOnSelectItem = (id: string | number): void => {
    NavBarSelectorStore.setSelectedSelectorItem(id.toString());
  };

  const buildSelectedRenderItem = (selected) => {
    return (
      <div className={Style.dropdownSelected}>
        <Icon name="folder" className={Style.icon}></Icon>
        <span className={Style.selectedProgramme}>{NavBarSelectorStore.selectedItem?.programme.title}</span>
        <span>|</span>
        <span className={Style.selectedProject}>{NavBarSelectorStore.selectedItem?.project.title}</span>
        {NavBarSelectorStore.selectedItem?.type === 'TaskTeam' && (
          <span className={Style.selectedTaskTeam}>{selected?.display}</span>
        )}
      </div>
    );
  };

  const onChangeSearchBox = (searchText = '') => {
    searchText = searchText.toLowerCase();
    if (!searchText) {
      setSuggestions(getItemsSearchBox(NavBarSelectorStore.items));
      return;
    }

    const programme = NavBarSelectorStore.items.filter(
      (prm) =>
        prm.type === 'Programme' &&
        (prm?.programme?.title?.toLowerCase()?.includes(searchText) ||
          prm?.programme?.projects.some(
            (prj) =>
              `${prj.projectNumber} ${prj.title}`?.toLowerCase()?.includes(searchText) ||
              prj.taskTeams.some(
                (tt) =>
                  tt?.deliveryTeamTitle?.toLowerCase()?.includes(searchText) ||
                  tt?.title?.toLowerCase()?.includes(searchText)
              )
          ))
    );

    const projects = (NavBarSelectorStore.items as ProjectItem[]).filter(
      (prj) =>
        prj.type === 'Project' &&
        (`${prj?.project?.projectNumber} ${prj?.project?.title}`?.toLowerCase()?.includes(searchText) ||
          prj?.project?.taskTeams.some(
            (tt) =>
              tt?.deliveryTeamTitle?.toLowerCase()?.includes(searchText) ||
              tt.title?.toLowerCase()?.includes(searchText)
          ))
    );

    const taskTeams = (NavBarSelectorStore.items as TaskTeamItem[]).filter(
      (t) =>
        t.type === 'TaskTeam' &&
        (t.taskTeam?.deliveryTeamTitle?.toLowerCase()?.includes(searchText) ||
          t?.taskTeam?.title?.toLowerCase()?.includes(searchText))
    );

    const data = getItemsSearchBox(sortBy([...programme, ...projects, ...taskTeams] || [], 'key', SortTypes.ASC));
    setSuggestions(data);
  };

  return (
    <TopNavBarSelector
      disable={props.disable}
      handleOnSelectItem={handleOnSelectItem}
      suggestions={suggestions}
      buildSelectedRenderItem={buildSelectedRenderItem}
      onChangeSearchBox={onChangeSearchBox}
      selectedItemId={NavBarSelectorStore.selectedItem?.id}
    />
  );
};

export default observer(NavBarSelector);
