import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ReactComponent as ReassignedIcon } from './images/ReassignedIcon.svg';
import { IHistoryItem } from '../../api/authenticated/tasks/getTaskHistory';
import Style from './styles/TaskInformationHistoryItem.module.scss';
import { formatDate } from '../../utils/dateUtils';
import UserCircleCollection from '../shared/UserCircleCollection';

export interface ITaskInformationHistoryReassignedProps {
  item: IHistoryItem;
}

const TaskInformationHistoryReassigned: FC<ITaskInformationHistoryReassignedProps> = ({ item }) => {
  return (
    <>
      <ReassignedIcon />
      <div className={Style.historyItemDetail}>
        <div className={Style.historyItemDetailTitle}>
          <span>
            <strong>Reassigned</strong> by <strong>{item.byUser.name}</strong> to
          </span>
          {item.toUsers && (
            <UserCircleCollection
              users={item.toUsers.map((u, i) => ({
                id: i,
                name: u.name,
                initials: u.initials,
              }))}
            />
          )}
        </div>
        <div className={Style.historyItemDetailDate}>{formatDate(item.eventDateTime)}</div>
        {item.message && <div className={Style.historyItemDetailMessage}>{item.message}</div>}
      </div>
    </>
  );
};

export default observer(TaskInformationHistoryReassigned);
