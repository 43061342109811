import React, { FC, useState, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from '../shared/Icon';
import { formatDate } from '../../utils/dateUtils';
import Dot from '../shared/Dot';
import SeeMoreLink from '../shared/SeeMoreLink';
import Styles from './styles/TransmittalDetails.module.scss';
import { ITransmittal } from '../../api/authenticated/transmittals/getTransmittal';
import { startCase } from 'lodash';
import UserTooltip from '../shared/UserTooltip';

interface ITransmittalHeaderProps {
  transmittal: ITransmittal;
  subject?: string;
}

const TransmittalHeader: FC<ITransmittalHeaderProps> = (props) => {
  const [showVisibleUser, setShowVisibleUser] = useState(false);
  const { transmittal, subject } = props;

  const getVisibleUsers = useCallback(() => {
    const allUniqueByKey = [
      ...new Map(transmittal.transmittalVisibilityAllUsers.map((item) => [item['userId'], item])).values(),
    ];
    const externalUsersUniqueByKey = [
      ...new Map(transmittal.transmittalVisibilityExternalUsers.map((item) => [item['id'], item])).values(),
    ];
    const teamUsersUniqueByKey = [
      ...new Map(transmittal.transmittalVisibilityTaskTeamUsers.map((item) => [item['id'], item])).values(),
    ];
    const appointingPartyUsersUniqueByKey = [
      ...new Map(transmittal.transmittalVisibilityAppointingPartyUsers.map((item) => [item['id'], item])).values(),
    ];

    const allUsers = allUniqueByKey
      .map((u) => {
        return startCase(u.userName.toLowerCase());
      })
      .concat(
        externalUsersUniqueByKey.map((u) => {
          return startCase(u.name.toLowerCase());
        })
      )
      .concat(
        teamUsersUniqueByKey.map((u) => {
          return startCase(u.name.toLowerCase());
        })
      )
      .concat(
        appointingPartyUsersUniqueByKey.map((u) => {
          return startCase(u.name.toLowerCase());
        })
      )
      .sort();

    return [...new Set(allUsers)];
  }, [
    transmittal.transmittalVisibilityAllUsers,
    transmittal.transmittalVisibilityAppointingPartyUsers,
    transmittal.transmittalVisibilityExternalUsers,
    transmittal.transmittalVisibilityTaskTeamUsers,
  ]);

  const totalVisibilityUsers = useMemo(() => {
    return getVisibleUsers().length;
  }, [getVisibleUsers]);

  return (
    <>
      <div className={Styles.heading}>
        <div className={Styles.transmittalSubject}>{subject}</div>
        <div className={Styles.transmittalTitle}>
          <label>Transmittal ID</label> <span>{transmittal.title}</span>
        </div>
      </div>
      <div className={Styles.transmittalHeader}>
        <div className={Styles.initialLine}>
          <div className={Styles.fieldValue}>
            <div className={Styles.label}>Initiator</div>
            <div className={Styles.initiatorName}>
              <UserTooltip
                initials={transmittal.createdByInitials}
                email={transmittal.createdByEmail}
                name={transmittal.createdByName}
              />
              <span>{transmittal.createdByName}</span>
            </div>
            {transmittal.projectNumber && transmittal.projectTitle && (
              <>
                {<Icon name="folder" className={Styles.detailIcon}></Icon>}
                <div>
                  {`${transmittal.projectTitle} | `}
                  <strong>{transmittal.projectNumber}</strong>
                </div>
              </>
            )}
            {transmittal.deliveryTeamTitle && transmittal.taskTeamTitle && (
              <>
                {<Icon className={Styles.detailIcon} name="people" />}
                <div>
                  {`${transmittal.deliveryTeamTitle} | `}
                  <strong>{transmittal.taskTeamTitle}</strong>
                </div>
              </>
            )}
          </div>
          <div className={Styles.right}>
            <div className={Styles.fieldValue}>
              <div className={Styles.label}>Initiated</div>
              <div>{formatDate(transmittal.createdDate)}</div>
            </div>
            {transmittal.dueDate && (
              <div className={Styles.fieldValue}>
                <div className={Styles.label}>Due</div>
                <div>{formatDate(transmittal.dueDate)}</div>
              </div>
            )}
          </div>
        </div>
        <div className={Styles.initialLine}>
          <div className={Styles.left}>
            {!!totalVisibilityUsers && (
              <>
                <Icon name="visibility" />
                <span className={Styles.totalVisibility}>{totalVisibilityUsers}</span>
                <SeeMoreLink
                  label={`${showVisibleUser ? 'See Less' : 'See More'}`}
                  onClick={() => setShowVisibleUser(!showVisibleUser)}
                />
              </>
            )}
            <span className={Styles.category}>{transmittal.transmittalTypeTitle}</span>
            <span className={Styles.category}>{transmittal.transmittalReasonTitle}</span>
          </div>
          <div className={Styles.right}>
            {transmittal.closed && (
              <div className={Styles.iconWrapper}>
                <Dot className={Styles.closed} />
                <small>Closed</small>
              </div>
            )}
            {transmittal.overdue && !transmittal.closed && (
              <div className={Styles.iconWrapper}>
                <Dot className={Styles.overdue} />
                <small>Overdue</small>
              </div>
            )}
            {transmittal.flagged && <Icon className={Styles.flagged} name="flag" />}
          </div>
        </div>
        {showVisibleUser && (
          <div className={Styles.initialLine}>
            <span className={Styles.visibilityUsers}>{getVisibleUsers().join('; ')}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default observer(TransmittalHeader);
