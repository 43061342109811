import { makeAutoObservable, runInAction } from 'mobx';
import { AxiosError } from 'axios';
import { SystemUsersTabIds } from '../../../../common/enums/SystemUserTabs';
import { ISystemUser, ISystemUserCSV } from '../../../../api/authenticated/um/interfaces/user.interface';
import { getSystemUsers } from '../../../../api/authenticated/um/getSystemUsers';
import { ITableColumn } from '../../../../components/settings/systemAdminSettings/systemUsers/systemUserTable/TableColumn';
import { SystemUsersFilter } from '../../../../api/authenticated/um/SystemUsersFilterClass';
import { IDropdownItemProps, ISuggestion } from '@aurecon-creative-technologies/styleguide';
import { ClientUserRole } from '../../../../common/enums/ClientUserRole';
import { updateClientUserRole } from '../../../../api/authenticated/um/updateClientUserRole';
import LayoutStore from '../../../../components/layout/LayoutStore';
import { addSystemUser, IAddSystemUser } from '../../../../api/authenticated/um/addSystemUser';
import { validateEmail } from '../../../../utils/miscUtils';
import { deactiveSystemUser } from '../../../../api/authenticated/um/deactiveSystemUser';
import { reactiveSystemUser } from '../../../../api/authenticated/um/reactiveSystemUser';
import config from '../../../../config';
import { IAdUser, getAdUsers } from '../../../../api/authenticated/um/getAdUsers';
import { SystemUserFieldValues } from '../../../../common/enums/SystemUsers';
import { removeSystemUser } from '../../../../api/authenticated/um/removeSystemUser';
import { FILE_CREATED_DATE, dateTimeFormat } from '../../../../utils/dateUtils';
import { ICSVData } from '../../../../common/interfaces/csvData';
import { SortTypes } from '../../../../common/enums/SortType';
import { orderBy } from 'lodash';
import { sort } from '../../../../utils/sortHelper';
import { SystemUsersTableHeaderKeyValueFields } from '../../../../common/enums/SystemUserTableHeader';

export class SystemUsersStore {
  constructor() {
    makeAutoObservable(this);
  }

  public systemUsers: ISystemUser[] = [];
  public allSystemUsers: ISystemUser[] = [];
  public clientUserRoles: IDropdownItemProps[] = [
    {
      id: ClientUserRole.User,
      label: 'User',
    },
    {
      id: ClientUserRole.SystemAdmin,
      label: 'System Admin',
    },
  ];

  public tableColumns: ITableColumn[] = [];
  public pageSize = config.defaultPageSize;
  public pageNumber = 1;

  public errorCode: number | null = null;
  public errorMessage: string | null = null;
  public showError = false;

  public systemUserFilters: SystemUsersFilter = new SystemUsersFilter();
  public isLoading = false;
  public isDeactivatingOrReactivating = false;
  public isRemovingUser = false;
  public loadingLabel = 'System users loading';
  public selectedTabId = SystemUsersTabIds.ACTIVE;

  public addSystemUser: IAddSystemUser = {
    userObjectId: '',
    email: '',
    displayName: '',
    userRoleId: ClientUserRole.User,
  };
  public addSystemUserEmailErrorMessage?: string;

  public filteredUsers?: ISuggestion[];
  public searchAdUserResult: IAdUser[] = [];

  public triggerCleanupSearchUser = 0;

  public searchText = '';

  public get filteredSystemUsers(): ISystemUser[] {
    if (this.searchText) {
      const search = this.searchText.toLowerCase();
      return this.systemUsers.filter(
        (t) => t.name.toLowerCase().includes(search) || t.email.toLowerCase().includes(search)
      );
    }
    return this.systemUsers;
  }

  public setSearchText(input: string) {
    runInAction(() => {
      this.searchText = input;
    });
  }

  public async setCurrentPage(page) {
    if (page === this.pageNumber) return;
    this.pageNumber = page;
    this.systemUsers = [];
    await this.loadSystemUsers();
  }

  public setSelectedTabId(tabId: number) {
    runInAction(() => {
      this.selectedTabId = tabId;
    });
  }

  public async loadSystemUsers() {
    this.setShowLoading(true, 'System users loading');

    try {
      const requestParam = this.systemUserFilters;
      requestParam.active = this.selectedTabId === SystemUsersTabIds.ACTIVE;
      const response = await getSystemUsers(requestParam);

      runInAction(() => {
        this.systemUsers = response;
        this.allSystemUsers = [];
      });
    } catch {
      this.cleanup();
    } finally {
      setTimeout(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      }, 500);
    }
  }

  public async searchAdUsers(keyword: string) {
    if (keyword.length < 3) {
      runInAction(() => {
        this.filteredUsers = [];
        this.searchAdUserResult = [];
      });
      this.onChangeCreateSystemUser(SystemUserFieldValues.EMAIL, keyword);
      return;
    }
    try {
      const response = await getAdUsers(keyword);
      runInAction(() => {
        this.searchAdUserResult = response;
        this.filteredUsers = response.map((x) => {
          return {
            id: x.id,
            value: x.mail,
            display: x.mail,
          };
        });
      });
      this.onChangeCreateSystemUser(SystemUserFieldValues.EMAIL, keyword);
    } catch {
      runInAction(() => {
        this.filteredUsers = [];
        this.searchAdUserResult = [];
      });
    }
  }

  public loadTableColumns() {
    let tableColumns: ITableColumn[] = [
      {
        label: SystemUsersTableHeaderKeyValueFields.UserName.label,
        valueField: SystemUsersTableHeaderKeyValueFields.UserName.value,
        sort: SortTypes.ASC,
      },
      {
        label: SystemUsersTableHeaderKeyValueFields.UserEmail.label,
        valueField: SystemUsersTableHeaderKeyValueFields.UserEmail.value,
        sort: SortTypes.NONE,
      },
    ];

    switch (this.selectedTabId) {
      case SystemUsersTabIds.ACTIVE:
        tableColumns = [
          ...tableColumns,
          {
            label: SystemUsersTableHeaderKeyValueFields.LastLogin.label,
            valueField: SystemUsersTableHeaderKeyValueFields.LastLogin.value,
            sort: SortTypes.NONE,
          },
          {
            label: SystemUsersTableHeaderKeyValueFields.UserRole.label,
            valueField: SystemUsersTableHeaderKeyValueFields.UserRole.value,
            sort: SortTypes.NONE,
          },
          {
            label: SystemUsersTableHeaderKeyValueFields.Action.label,
            valueField: SystemUsersTableHeaderKeyValueFields.Action.value,
            sort: SortTypes.NONE,
          },
        ];
        break;
      case SystemUsersTabIds.DEACTIVE:
        tableColumns.push({
          label: SystemUsersTableHeaderKeyValueFields.Reactivate.label,
          valueField: SystemUsersTableHeaderKeyValueFields.Reactivate.value,
          sort: SortTypes.NONE,
        });
        break;
      default:
        break;
    }

    runInAction(() => {
      this.tableColumns = tableColumns;
    });
  }

  public get getTableColumns(): ITableColumn[] {
    return [...this.tableColumns];
  }

  public setShowLoading(showLoading: boolean, loadingMessage: string) {
    runInAction(() => {
      this.isLoading = showLoading;
      this.loadingLabel = loadingMessage;
    });
  }

  public setError(error: AxiosError<string>) {
    runInAction(() => {
      this.errorCode = error?.response?.status ?? null;
      this.errorMessage = error?.response?.data ?? null;
    });
  }

  public clearError() {
    runInAction(() => {
      this.errorCode = null;
      this.errorMessage = null;
    });
  }

  public cleanup() {
    runInAction(() => {
      this.pageNumber = 1;
      this.systemUsers = [];
      this.systemUserFilters.setDefaultFilter();
      this.systemUserFilters.setDefaultSort();
    });
  }

  public async changeClientUserRole(selectedUser: ISystemUser, newRole: number | string) {
    this.clearError();

    try {
      if (selectedUser.clientUserRoleId !== newRole) {
        await updateClientUserRole({
          userId: Number(selectedUser.id),
          clientUserRoleId: Number(newRole),
        });
        await this.loadSystemUsers();
        LayoutStore.displayToast('success', 'User role has been changed successfully');
      }
    } catch (err) {
      this.setError(err as AxiosError<string>);
    }
  }

  public setShowAlreadyExists(value) {
    runInAction(() => {
      this.showError = value;
    });
  }

  public onChangeCreateSystemUser(fieldName, value) {
    if (fieldName === SystemUserFieldValues.EMAIL) {
      if (value === '') {
        this.addSystemUserEmailErrorMessage = undefined;
      } else {
        const checkEmail = validateEmail(value);
        if (!checkEmail) this.addSystemUserEmailErrorMessage = 'Please enter valid email';
        else this.addSystemUserEmailErrorMessage = undefined;
      }
    }

    runInAction(() => {
      this.addSystemUser[fieldName] = value;
    });
  }

  public setSelectedAdUser(selected: ISuggestion) {
    const adUser = this.searchAdUserResult.find((x) => x.id === selected.id);
    runInAction(() => {
      this.addSystemUser.userObjectId = adUser?.id.toString() || undefined;
      this.addSystemUser.displayName = adUser?.displayName || this.addSystemUser.displayName;
      this.addSystemUser.email = adUser?.mail || this.addSystemUser.email;
      this.filteredUsers = [];
      this.searchAdUserResult = [];
      this.addSystemUserEmailErrorMessage = undefined;
    });
  }

  public get disableAddButton(): boolean {
    if (!this.addSystemUser) return true;

    return (
      !this.addSystemUser.email ||
      !this.addSystemUser.displayName ||
      !this.addSystemUser.userRoleId ||
      !validateEmail(this.addSystemUser.email) ||
      this.isLoading
    );
  }

  public async onAddSystemUser() {
    this.clearError();
    this.setShowLoading(true, 'Add users loading');

    try {
      await addSystemUser(this.addSystemUser);
      await this.loadSystemUsers();
      this.clearCreateSystemUserForm();
      LayoutStore.displayToast('success', 'Add user has been successfully');
    } catch (err) {
      this.setShowAlreadyExists(true);
      this.setError(err as AxiosError<string>);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  public async deactiveOrReactiveUser(userId: number, isDeactive: boolean) {
    runInAction(() => (this.isDeactivatingOrReactivating = true));
    try {
      if (isDeactive) await deactiveSystemUser(userId);
      else await reactiveSystemUser(userId);

      await this.loadSystemUsers();
      LayoutStore.displayToast('success', `User has been successfully ${isDeactive ? 'deactived' : 'reactived'} `);
    } catch (err) {
      LayoutStore.displayToast(
        'error',
        `User cannot be ${isDeactive ? 'deactived' : 'reactived'} deactivated. Please try again later.`
      );
      this.setError(err as AxiosError<string>);
    }
    runInAction(() => (this.isDeactivatingOrReactivating = false));
  }

  public clearCreateSystemUserForm() {
    runInAction(() => {
      this.addSystemUser.userObjectId = '';
      this.addSystemUser.email = '';
      this.addSystemUser.displayName = '';
      this.addSystemUser.userRoleId = ClientUserRole.User;
      this.addSystemUserEmailErrorMessage = undefined;
      this.triggerCleanupSearchUser += 1;
    });
  }

  public async RemoveUser(id: number) {
    runInAction(() => (this.isRemovingUser = true));
    this.clearError();
    try {
      await removeSystemUser({ userId: id });
      await this.loadSystemUsers();
      LayoutStore.displayToast('success', 'User has been successfully removed');
    } catch (err) {
      LayoutStore.displayToast('error', 'Users cannot be removed. Please try again later.');
      this.setError(err as AxiosError<string>);
    }
    runInAction(() => (this.isRemovingUser = false));
  }

  public async getAllSystemUsers() {
    const requestParam = this.systemUserFilters;
    requestParam.active = true;
    const data = await getSystemUsers(requestParam);
    runInAction(() => {
      this.allSystemUsers = data;
    });
  }

  public mappingCSVData(users: ISystemUser[]) {
    const csvData = users.map((user) => {
      return {
        name: user.name,
        email: user.email,
        role: user.clientUserRoleName,
        active: user.active,
      };
    }) as ISystemUserCSV[];

    return {
      filename: `System_Users_${dateTimeFormat(new Date(), FILE_CREATED_DATE)}.csv`,
      headers: [
        { label: 'User Name', key: 'name' },
        { label: 'User Email', key: 'email' },
        { label: 'User Role', key: 'role' },
        { label: 'Active flag', key: 'active' },
      ],
      data: csvData,
    } as ICSVData<ISystemUserCSV>;
  }

  public applySort(columnName: string, direction: SortTypes) {
    if (direction != SortTypes.NONE) {
      if (columnName === 'role') {
        this.systemUsers = orderBy(this.systemUsers, (item) => item.clientUserRoleId, direction);
      }

      this.systemUsers = sort(this.systemUsers, columnName, direction);
    }

    this.tableColumns.forEach((c) => {
      if (c.valueField === columnName) {
        c.sort = direction;
      } else {
        c.sort = c.sort ?? 'none';
      }
    });
  }
}

export default new SystemUsersStore();
