import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TransmittalNotifiersModal.module.scss';
import CentreOnPage from '../shared/CentreOnPage';
import Overlay from '../shared/Overlay';
import Modal from '../shared/Modal';
import CloseButton from '../shared/CloseButton';
import Icon from '../shared/Icon';
import UserCircle from '../shared/UserCircle';
import { Accordion, AccordionPanel } from '@aurecon-creative-technologies/styleguide';
import { IGroupTeamUsers, IUser } from '../../api/authenticated/transmittals/getTransmittal';
import TransmittalDetailsStore from './TransmittalDetailsStore';
import ModalActions from '../shared/ModalActions';
import SecondaryButton from '../shared/SecondaryButton';

export interface ITransmittalInitiatorProps {
  data: {
    externalUsers: IUser[];
    taskTeams: IGroupTeamUsers[];
  };
  closeModal: () => void;
}

const TransmittalNotifiersModal: FC<ITransmittalInitiatorProps> = (props) => {
  const { data, closeModal } = props;
  const handleCloseModalClick = () => {
    TransmittalDetailsStore.modalClose();
    closeModal;
  };

  const externalGroup = [
    {
      taskTeamId: 'ExternalGroup',
      taskTeamTitle: 'External Users',
      users: data.externalUsers,
    },
  ];
  const userGroups = [...data.taskTeams, ...externalGroup];
  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <div className={Style.headerTitle}>
          <Icon name="notifications" className={Style.headerIcon} />
          <span>Notified</span>
        </div>
        <p>Notifying these team members will require their attention.</p>
        <div className={Style.modalContainer}>
          <div>
            <Accordion
              verticalPanelGap="24px"
              headingBgColour="#F2F2F2"
              headingFontColour="#000000"
              panelBgColour="#FAFAFA"
              headingIconColour="#000000"
              activePanelIds={Array.from(TransmittalDetailsStore.activeTaskTeamPanelIds)}
              onPanelToggle={TransmittalDetailsStore.handleTaskTeamPanelToggle}>
              {userGroups.map((team) => (
                <AccordionPanel
                  key={team.taskTeamId}
                  label={
                    <div className={Style.accordionPanelTitle}>
                      <Icon name="people" className={Style.teamIcon}></Icon>
                      <span>
                        <strong>{team.taskTeamTitle}</strong>
                      </span>
                    </div>
                  }
                  panelId={team.taskTeamId.toString()}
                  rightLabelContents={<div className={Style.countCircle}>{team.users.length}</div>}>
                  {team.users.map((user) => (
                    <div key={user.id}>
                      <div className={Style.userItem}>
                        <span className={Style.userCircle}>
                          <UserCircle initials={user.initials} />
                        </span>
                        <span className={Style.displayName}>{user.name}</span>
                      </div>
                    </div>
                  ))}
                </AccordionPanel>
              ))}
            </Accordion>
          </div>
        </div>
        <CloseButton onClick={handleCloseModalClick} disabled={false} />
        <ModalActions>
          <SecondaryButton onClick={handleCloseModalClick}>Close</SecondaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(TransmittalNotifiersModal);
