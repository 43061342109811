import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TableRow, TableCell, IHeader, Dropdown } from '@aurecon-creative-technologies/styleguide';
import SystemUsersStore from '../../../../../stores/settings/systemAdminSettings/systemUser/SystemUsersStore';
import { ITableColumn } from './TableColumn';
import ErrorModal from '../../../../shared/ErrorModal';
import { ISystemUser } from '../../../../../api/authenticated/um/interfaces/user.interface';
import { DropdownStyle } from '../../../../../common/constants/Dropdown';
import SecondaryButton from '../../../../shared/SecondaryButton';
import Icon from '../../../../shared/Icon';
import Style from '../../../../../styles/components/settings/systemAdminSettings/systemUsers/SystemUserTable.module.scss';
import { dateTime12HFormat } from '../../../../../utils/dateUtils';
import { SystemUsersTableHeaderKeyValueFields } from '../../../../../common/enums/SystemUserTableHeader';

export interface ISystemUserTableRowProps {
  headers: IHeader[];
  user: ISystemUser;
  tableColumns: ITableColumn[];
  onDeactiveOrActiveUser: () => void;
  onRemovingUser: () => void;
  totalRows?: number;
  rowIndex?: number;
}

const maxItemsInPage = 6;

const SystemUserTableRow: FC<ISystemUserTableRowProps> = (props) => {
  const { headers, user, tableColumns, onDeactiveOrActiveUser, onRemovingUser, totalRows, rowIndex } = props;
  const [showErrorModal, setShowErrorModal] = useState(false);

  const cellValue = (h: IHeader) => {
    const col = tableColumns.find((c) => c.label === h.label);
    if (!col) return '';

    return user[col.valueField];
  };

  return (
    <>
      <TableRow key={user.id}>
        {headers.map((h, i) => {
          const def = headers[i];
          if (def.label === SystemUsersTableHeaderKeyValueFields.LastLogin.label) {
            return (
              <TableCell align={def.align} key={i}>
                {user.lastLoginDate && dateTime12HFormat(user.lastLoginDate)}
              </TableCell>
            );
          }
          if (def.label === SystemUsersTableHeaderKeyValueFields.Action.label) {
            return (
              <TableCell align={def.align} key={i}>
                <SecondaryButton
                  disabled={SystemUsersStore.isDeactivatingOrReactivating}
                  className={Style.deactiveUserBtn}
                  onClick={user.canDelete ? onRemovingUser : onDeactiveOrActiveUser}>
                  <Icon className={Style.deactiveUserIcon} name={user.canDelete ? 'delete' : 'person_remove'} />
                </SecondaryButton>
              </TableCell>
            );
          }
          if (def.label === SystemUsersTableHeaderKeyValueFields.Reactivate.label) {
            return (
              <TableCell align={def.align} key={i}>
                <SecondaryButton
                  disabled={SystemUsersStore.isDeactivatingOrReactivating}
                  className={Style.deactiveUserBtn}
                  onClick={onDeactiveOrActiveUser}>
                  <Icon className={Style.deactiveUserIcon} name={'how_to_reg'} />
                </SecondaryButton>
              </TableCell>
            );
          }
          if (def.label === SystemUsersTableHeaderKeyValueFields.UserRole.label) {
            return (
              <TableCell align={def.align} key={i}>
                <Dropdown
                  placeholder="Select Client User Role"
                  items={SystemUsersStore.clientUserRoles}
                  selectedItem={user.clientUserRoleId}
                  onSelectItem={(value) => SystemUsersStore.changeClientUserRole(user, value)}
                  optionsHeight={DropdownStyle.defaultHeight}
                  open={totalRows && totalRows > maxItemsInPage && rowIndex === totalRows - 1 ? 'up' : undefined}
                />
              </TableCell>
            );
          }
          return (
            <TableCell align={def.align} key={i} checkbox>
              {cellValue(h)}
            </TableCell>
          );
        })}
      </TableRow>

      {showErrorModal && (
        <ErrorModal
          closeModal={() => setShowErrorModal(false)}
          errorCode={SystemUsersStore.errorCode}
          errorMessage={SystemUsersStore.errorMessage}
        />
      )}
    </>
  );
};

export default observer(SystemUserTableRow);
