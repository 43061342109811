import React, { FC, Fragment, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Table,
  TableRow,
  TableCell,
  Checkbox,
  IHeader,
  IColumnMoving,
} from '@aurecon-creative-technologies/styleguide';
import UploadStore, {
  ActionColumns,
  IFileContainerMetadataValue,
  IFileMetadataValue,
  IMetadataSelectedValue,
  TextFilterField,
} from './UploadStore';
import Style from './styles/FileUpload.module.scss';
import { ITableColumn } from '../../../common/interfaces/TableColumn';
import { SortType } from '../../../common/enums/SortType';
import FilesStore from '../FilesStore';
import classNames from 'classnames';
import { FileContainerState } from '../../../common/enums/FileContainerState';
import { MetadataFieldType } from '../../../common/enums/MetadataFieldType';
import DropDownMenuForTableCell from '../../shared/DropDownMenuForTableCell';
import DropDownMenuButton from '../../shared/DropDownMenuButton';
import { IMetadataField } from '../../../api/authenticated/cms/getProjectMetadata';
import SecondaryButton from '../../shared/SecondaryButton';
import SupersedeFileStore, { IUploadContainerFile } from './SupersedeFileStore';
import ListFilter from '../../shared/ListFilter';
import UserTextFilter from '../../shared/UserTextFilter';
import PrimaryButton from '../../shared/PrimaryButton';
import Icon from '../../shared/Icon';
import FileStatus from './FileStatus';
import { UploadFileStatusEnum } from '../../../common/enums/UploadFileStatus';
import MoveFileContainerModal from './MoveFileContainerModal';
import { ContainerFileMoveType } from '../../../common/enums/ContainerFileMoveType';
import DropDownAdjustableColumns from '../../shared/DropDownAdjustableColumns';

interface IFileSelectedProps {
  onFileListChange?: (files: File[]) => void;
  openSupersedeWindow: (open: boolean) => void;
}
export interface IFileMoveProps {
  fileIndex: number;
  tempId: string;
  type: ContainerFileMoveType;
}

const SelectedFiles: FC<IFileSelectedProps> = (props) => {
  const [showMoveFileContainerModal, setShowMoveFileContainerModal] = useState<boolean>(false);
  const [fileMove, setFileMove] = useState<IFileMoveProps | null>(null);

  const removeSelectedFileContainer = (value): void => {
    UploadStore.removeFileContainer(value);
    if (!props.onFileListChange) return;

    props.onFileListChange(
      UploadStore.selectedFileContainers
        .flatMap((f) => f.files)
        .filter((f) => f.file)
        .map((f) => f.file!)
    );
  };

  const getFileSizeString = (size: number): string => {
    const kb = 1024;
    const mb = kb * 1024;
    const gb = mb * 1024;

    if (size < mb) return `${(size / kb).toFixed(2)} KB`;

    if (size < gb) return `${(size / mb).toFixed(2)} MB`;

    return `${(size / gb).toFixed(2)} GB`;
  };

  const handleTableApplyFilter = (tableColumn: ITableColumn | null, value?: string) => {
    UploadStore.applyFilter(tableColumn, value);
    UploadStore.unCheckNotMatchFileAfterFiltered();
  };

  const getFilterComponent = (tableColumn: ITableColumn) => {
    if (tableColumn.valueField === TextFilterField.FileSize) return <span className={Style.fileSizeHeader}></span>;
    if (tableColumn.listFilter)
      return (
        <ListFilter
          onSelectItem={(value) => handleTableApplyFilter(tableColumn, value)}
          listFilter={tableColumn.listFilter}
        />
      );

    if (tableColumn.textFilter)
      return (
        <UserTextFilter
          textFilter={tableColumn.textFilter}
          onChange={(value) => handleTableApplyFilter(tableColumn, value ?? '')}
        />
      );
  };

  const headers: IHeader[] = [
    ...UploadStore.tableColumns
      .filter((x) => x.visible)
      .map((col) => {
        const sortDirection = col.sort ? col.sort : 'none';
        const isActionColumn = ActionColumns.includes(col.valueField);
        return {
          label: col.label,
          sort: isActionColumn ? undefined : sortDirection,
          title: col.valueField,
          filter: getFilterComponent(col),
          onSort: (sort) => (isActionColumn ? undefined : UploadStore.applySort(col, sort as SortType)),
          checked: UploadStore.filteredFileContainers.every((f) => f.isSelected),
          onCheckbox: col.onCheckbox,
          draggable: !isActionColumn,
          resizable: !isActionColumn,
          minWidth: col.minWidth,
          width: col.width,
        } as IHeader;
      }),
  ];

  const getMetadataValue = (metaData: IMetadataSelectedValue[], field: IMetadataField) => {
    if (!metaData || !field.dataType.fieldType) return null;
    const metaDataValue = metaData.find((mt) => mt.fieldValueIndex === field.fieldValueIndex);
    if (metaDataValue && field.dataType?.fieldType === MetadataFieldType.List) return metaDataValue?.title;
    if (metaDataValue && field.dataType?.fieldType === MetadataFieldType.UserText) return metaDataValue?.value;
  };

  const removeFileContainers = () => {
    const filesDeleted = UploadStore.selectedFileContainers.filter((f) => f.isSelected)?.length > 0;
    UploadStore.removeFileContainers();
    if (!filesDeleted || !props.onFileListChange) return;

    props.onFileListChange(
      UploadStore.selectedFileContainers
        .flatMap((f) => f.files)
        .filter((f) => f.file)
        .map((f) => f.file!)
    );
  };

  const handleMarkAsNative = (containerName: string, fileName: string) => {
    UploadStore.setNativeFile(containerName, fileName);
  };

  const supersedeFiles = (containers: IFileContainerMetadataValue[]) => {
    const files = containers
      .filter((f) => !f.files.flatMap((fm) => fm.statuses).includes(UploadFileStatusEnum.Supersede))
      .map((m) => ({
        tempFileContainerId: m.tempId,
        files: m.files.map((m) => {
          return {
            file: m.file,
            fileName: m.fileName,
            size: m.fileSize,
            templateId: m.templateId,
            isUnbranded: m.isUnbranded,
          } as IUploadContainerFile;
        }),

        groupedContainerName: m.groupedContainerName,
        statuses: m.files.flatMap((m) => m.statuses),
      }));

    SupersedeFileStore.setSelectedSupersedeFiles(files);
    props.openSupersedeWindow(true);
  };

  const showMultipleSupersedeButton =
    UploadStore.selectedFileContainers.some((s) => s.isSelected) &&
    (UploadStore.selectedFileContainers
      .filter((f) => f.isSelected)
      .every((s) => s.files.flatMap((f) => f.statuses).includes(UploadFileStatusEnum.Duplicate)) ||
      (FilesStore.fileContainerStateId === FileContainerState.Wip &&
        UploadStore.selectedFileContainers
          .filter((f) => f.isSelected)
          .every((s) => !s.files.flatMap((f) => f.statuses).includes(UploadFileStatusEnum.Supersede))));

  const showNewContainerButton =
    UploadStore.selectedFileContainers.filter((s) => s.isSelected).length > 1 &&
    UploadStore.selectedFileContainers.filter((s) => s.isSelected).every((e) => e.files.length === 1);

  const showUngroupContainerButton =
    UploadStore.selectedFileContainers.some((s) => s.isSelected) &&
    UploadStore.selectedFileContainers.filter((s) => s.isSelected).every((e) => e.files.length > 1) &&
    UploadStore.selectedFileContainers
      .flatMap((s) => s.files)
      .flatMap((f) => f.statuses)
      .every((e) => e !== UploadFileStatusEnum.Supersede);

  const reorder = (data: IColumnMoving) => {
    const moveColumn = UploadStore.tableColumns[data.fromIndex];
    if (moveColumn) {
      UploadStore.tableColumns.splice(data.fromIndex, 1);
      UploadStore.tableColumns.splice(data.toIndex, 0, moveColumn);
    }
  };

  const cellValue = (h: IHeader, file: IFileContainerMetadataValue) => {
    const col = UploadStore.tableColumns.find((c) => c.label === h.label);
    if (!col) {
      return '';
    }
    const fileMetaData = UploadStore.fileMetadata.find((f) => f.title === col.label);
    if (!fileMetaData) {
      return file[col.valueField] || '';
    }

    return getMetadataValue(file.metadata, fileMetaData);
  };

  const renderGroupedFilesRow = (
    files: IFileMetadataValue[],
    containerName: string,
    tempId: string,
    containerIndex: number
  ) => {
    const renderGroupedRow =
      files.length > 1 || files.every((e) => e.statuses.includes(UploadFileStatusEnum.Supersede));
    return (
      <>
        {renderGroupedRow &&
          files.map((m, index) => (
            <TableRow key={`${m.fileName}_${index}`}>
              {headers.map((h, i) => {
                if (i === 0) {
                  return <TableCell key={i}>{h.label}</TableCell>;
                }
                if (h.label === '') {
                  return (
                    <DropDownMenuForTableCell
                      key={i}
                      showAbove={containerIndex === 0 && index === 0 ? true : undefined}>
                      {FilesStore.fileContainerStateId === FileContainerState.Wip && !m.native && (
                        <DropDownMenuButton onClick={() => handleMarkAsNative(containerName, m.fileName)}>
                          Mark as native
                        </DropDownMenuButton>
                      )}
                      <DropDownMenuButton
                        onClick={() => {
                          setShowMoveFileContainerModal(true);
                          setFileMove({ fileIndex: index, tempId, type: ContainerFileMoveType.MoveToOtherContainer });
                          UploadStore.setMoveFileContainerSelectedId();
                        }}>
                        Move file to another container
                      </DropDownMenuButton>
                      <DropDownMenuButton onClick={() => UploadStore.moveFileOutContainer(m, tempId, index)}>
                        Move file out of container
                      </DropDownMenuButton>
                      <DropDownMenuButton onClick={() => UploadStore.removeContainerFile(tempId, index)}>
                        Remove
                      </DropDownMenuButton>
                    </DropDownMenuForTableCell>
                  );
                }
                if (h.label === 'Status') {
                  return (
                    <TableCell key={i}>
                      <FileStatus
                        statuses={m.statuses.filter(
                          (f) => f === UploadFileStatusEnum.Duplicate || f === UploadFileStatusEnum.MissingFileExtension
                        )}
                      />
                    </TableCell>
                  );
                }
                if (h.label === 'Original Filename') {
                  return (
                    <TableCell key={i}>
                      <div className={Style.fileWrapper}>
                        <Icon name="shortcut" className={Style.fileIcon}></Icon>
                        <span>
                          {m.fileName} {m.native && <Icon name={'star_outline'} className={Style.native} />}
                        </span>
                      </div>
                    </TableCell>
                  );
                }

                return <TableCell key={i}></TableCell>;
              })}
            </TableRow>
          ))}
      </>
    );
  };
  return (
    <div className={Style.uploadedFiles}>
      {!!UploadStore.selectedFileContainers.length && (
        <div className={Style.uploadedFilesContainer}>
          <div className={Style.uploadedFileHeading}>
            <div className={Style.numberUploadFiles}>Files to Upload ({UploadStore.selectedFileContainers.length})</div>
            <div className={Style.actionButtons}>
              {showUngroupContainerButton && (
                <PrimaryButton
                  onClick={() => {
                    UploadStore.ungroupFileFromContainer();
                  }}>
                  Ungroup Container
                </PrimaryButton>
              )}
              {showNewContainerButton && (
                <PrimaryButton
                  onClick={() => {
                    UploadStore.addFileToContainer();
                  }}>
                  New Container
                </PrimaryButton>
              )}
              {showMultipleSupersedeButton && (
                <SecondaryButton
                  onClick={() => {
                    supersedeFiles(UploadStore.selectedFileContainers.filter((f) => f.isSelected));
                  }}>
                  Supersede
                </SecondaryButton>
              )}
              <SecondaryButton onClick={removeFileContainers}>Remove</SecondaryButton>
              <DropDownAdjustableColumns
                className={Style.dropDownAdjustableColumns}
                items={UploadStore.getTableColumnsForShowHide.filter((col) => !ActionColumns.includes(col.id))}
                selectedItems={UploadStore.tableColumns
                  .filter((col) => !ActionColumns.includes(col.valueField) && col.visible)
                  .map((x) => x.valueField)}
                onSelected={UploadStore.setVisibleSelectedFileColumns}
              />
            </div>
          </div>
          <Table cssClass={Style.filesTable} headers={headers} breakpoint={10} hoverable onReorderColumn={reorder}>
            {UploadStore.filteredFileContainers.map((f, index) => (
              <Fragment key={f.tempId}>
                <TableRow>
                  {headers.map((h, i) => {
                    if (h.onCheckbox) {
                      return (
                        <TableCell key={i}>
                          <Checkbox
                            onChange={(checked) => UploadStore.onSelectedFileContainers(f.tempId, checked)}
                            checked={f.isSelected}
                          />
                        </TableCell>
                      );
                    }
                    if (h.label === '') {
                      return (
                        <DropDownMenuForTableCell key={i}>
                          {(f.files.flatMap((f) => f.statuses).includes(UploadFileStatusEnum.Duplicate) ||
                            (FilesStore.fileContainerStateId === FileContainerState.Wip &&
                              !f.files.flatMap((f) => f.statuses).includes(UploadFileStatusEnum.Supersede))) && (
                            <DropDownMenuButton onClick={() => supersedeFiles([f])}>Supersede</DropDownMenuButton>
                          )}
                          {f.files.length === 1 && (
                            <DropDownMenuButton
                              onClick={() => {
                                setShowMoveFileContainerModal(true);
                                setFileMove({
                                  fileIndex: index,
                                  tempId: f.tempId,
                                  type: ContainerFileMoveType.MoveToAContainer,
                                });
                                UploadStore.setMoveFileContainerSelectedId();
                              }}>
                              Move file to a container
                            </DropDownMenuButton>
                          )}
                          <DropDownMenuButton onClick={() => removeSelectedFileContainer(f.tempId)}>
                            Remove
                          </DropDownMenuButton>
                        </DropDownMenuForTableCell>
                      );
                    }
                    if (h.label === 'Status') {
                      return (
                        <TableCell key={i}>
                          <FileStatus statuses={f.files.flatMap((f) => f.statuses)} />
                        </TableCell>
                      );
                    }
                    if (h.label === 'Sequence Number') {
                      return <TableCell key={`${f.tempId}${i}`}>{f.sequenceNumber}</TableCell>;
                    }
                    if (h.title === TextFilterField.Pattern1) {
                      return <TableCell key={`${f.tempId}${i}`}>{f.apimsMetadata?.pattern1}</TableCell>;
                    }
                    if (h.title === TextFilterField.Pattern2) {
                      return <TableCell key={`${f.tempId}${i}`}>{f.apimsMetadata?.pattern2}</TableCell>;
                    }
                    if (h.label === 'Suitability') {
                      return (
                        <TableCell
                          key={i}
                          cellClass={classNames({
                            [Style.supersedeCell]: f.files
                              .flatMap((f) => f.statuses)
                              .includes(UploadFileStatusEnum.Supersede),
                          })}>
                          {f.suitabilityTitle && f.suitabilityCode && `${f.suitabilityCode} ${f.suitabilityTitle}`}
                        </TableCell>
                      );
                    }
                    if (h.label === 'File Size') {
                      return (
                        <TableCell key={i}>
                          <span>{getFileSizeString(f.fileContainerSize)}</span>
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell key={i} align={h.align} cellClass={Style.textCell}>
                        <div className={Style.overFlow}>{cellValue(h, f)}</div>
                      </TableCell>
                    );
                  })}
                </TableRow>
                {renderGroupedFilesRow(f.files, f.fileContainerName!, f.tempId, index)}
              </Fragment>
            ))}
          </Table>
          <div className={Style.uploadedFilesEnd}>
            <span className={Style.totalFiles}>
              {UploadStore.selectedFileContainers.length} files, total of (
              {getFileSizeString(
                UploadStore.selectedFileContainers.reduce((size, file) => size + file.fileContainerSize, 0)
              )}
              )
            </span>
          </div>
          {showMoveFileContainerModal && (
            <MoveFileContainerModal onCancel={() => setShowMoveFileContainerModal(false)} fileMove={fileMove} />
          )}
        </div>
      )}
    </div>
  );
};

export default observer(SelectedFiles);
