import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Icon, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide';
import FileInformationStore from './FileInformationStore';
import DropDownMenuForTableCell from '../../shared/DropDownMenuForTableCell';
import DropDownMenuButton from '../../shared/DropDownMenuButton';
import FilesStore from '../FilesStore';
import getFileExtension from '../../../utils/fileUtils';
import { FileExt } from '../../../common/constants/FileExt';
import { IContainerFile, IFileContainer } from '../../../api/authenticated/cms/FileContainerModel';
import ForgeViewerStore from '../../visualisation/forgeViewer/ForgeViewerStore';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';
import SecondaryButton from '../../shared/SecondaryButton';
import Style from './styles/Files.module.scss';
import DownloadModal from '../../shared/DownloadModal';
import { getFileSizeDetails } from '../../../utils/miscUtils';
import { FileContainerState } from '../../../common/enums/FileContainerState';
import { NavigationItemTypes } from '../../../common/models/ItemType';
import { FromModuleFilesList } from '../../../common/enums/FromModuleFilesList';
import ErrorModal from '../../shared/ErrorModal';
import LayoutStore from '../../layout/LayoutStore';
import { openViewer } from '../../../utils/pdfViewerUtils';
import { appInsightsTrackEvent } from '../../../utils/appInsights';
import { AppInsightEvent } from '../../../common/constants/AppInsightEvent';
export interface IFileProps {
  fromModule?: FromModuleFilesList;
}

const Files: FC<IFileProps> = ({ fromModule }) => {
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [totalFileSize, setTotalFileSize] = useState<string>('0');
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleForgeViewer = async (fileId: number) => {
    if (!fileId) return;
    await ForgeViewerStore.getForgeViewer(fileId, NavBarSelectorStore.selectedItem!.project.projectNumber);
    if (ForgeViewerStore.errorMessage !== null) {
      FilesStore.setError(ForgeViewerStore.error!);
      setShowErrorModal(true);
    }
  };

  const downloadAllFile = () => {
    FilesStore.downloadFile({
      fileContainers: [
        {
          ...FileInformationStore.file,
          releasedFileId:
            FilesStore.fileContainerStateId === FileContainerState.Wip
              ? null
              : FileInformationStore.file?.releasedFileId,
        } as IFileContainer,
      ],
      projectNumber: FileInformationStore.projectNumber,
      downloadAllContainerFile: true,
    });
  };

  const handleUpdateNativeContainerFile = async (
    fileContainerRevisionId: number | undefined,
    containerFileId: number | undefined
  ) => {
    if (!containerFileId || !fileContainerRevisionId) return;
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return;
    const taskTeamId = NavBarSelectorStore.selectedItem.taskTeam.id;
    await FileInformationStore.updateNativeFile(
      NavBarSelectorStore.selectedItem!.project.projectNumber,
      taskTeamId,
      fileContainerRevisionId,
      containerFileId
    );
  };

  const handleResendToForge = async (fileContainerRevisionId: number | null, containerFileId: number) => {
    await FilesStore.resendToForge(fileContainerRevisionId, containerFileId);
    if (FilesStore.errorMessage !== null) {
      setShowErrorModal(true);
      return;
    }
    LayoutStore.displayToast('success', 'The file was resent successfully.');
  };

  const downloadOnClick = (containerFile: IContainerFile) => {
    appInsightsTrackEvent(AppInsightEvent.FILE_DOWNLOAD);
    const { totalFileSize, isOverLimit } = getFileSizeDetails(containerFile.uploadedSize);
    if (isOverLimit) {
      setShowDownloadModal(true);
      setTotalFileSize(totalFileSize);
    } else {
      FilesStore.downloadFile({
        fileContainers: [
          {
            ...FileInformationStore.file,
            releasedFileId:
              FilesStore.fileContainerStateId === FileContainerState.Wip
                ? null
                : FileInformationStore.file?.releasedFileId,
            containerFileId: containerFile.containerFileId ?? FileInformationStore.file?.containerFileId,
          } as IFileContainer,
        ],
        projectNumber: FileInformationStore.projectNumber,
        sharePointReleasedFileId:
          fromModule && fromModule === FromModuleFilesList.Task
            ? containerFile.sharePointReleasedFileId ?? undefined
            : undefined,
      });
    }
  };

  return (
    <>
      <div className={Style.header}>
        <p className={Style.title}>File List</p>
        <SecondaryButton onClick={downloadAllFile}>Download all files</SecondaryButton>
      </div>

      <Table headers={[{ label: 'Filename' }, { label: 'Action', align: 'right' }]} breakpoint={10} hoverable>
        {(FileInformationStore.file?.containerFiles || []).map((containerFile, index) => (
          <TableRow key={index}>
            <TableCell cellClass={Style.fileNameCell}>
              {containerFile.originalFilename}{' '}
              {containerFile.native && <Icon type={'star_outline'} cssClass={Style.native} />}
            </TableCell>

            <DropDownMenuForTableCell key={index}>
              {FileInformationStore.file &&
                FileInformationStore.file.fileContainerStateId === FileContainerState.Wip &&
                !containerFile.native && (
                  <DropDownMenuButton
                    onClick={() =>
                      handleUpdateNativeContainerFile(
                        FileInformationStore.file?.fileRevisionId,
                        containerFile.containerFileId
                      )
                    }>
                    Mark as native
                  </DropDownMenuButton>
                )}
              <DropDownMenuButton
                onClick={() => downloadOnClick(containerFile)}
                disabled={FileInformationStore.file?.id ? FilesStore.fileBusy[FileInformationStore.file.id] : false}>
                Download
              </DropDownMenuButton>
              {containerFile.hasDocumentViewer && FileInformationStore.file && (
                <DropDownMenuButton
                  onClick={() =>
                    FilesStore.openFile(
                      {
                        ...FileInformationStore.file,
                        releasedFileId:
                          FilesStore.fileContainerStateId === FileContainerState.Wip
                            ? null
                            : FileInformationStore.file?.releasedFileId,
                        containerFileId: containerFile.containerFileId,
                      } as IFileContainer,
                      undefined,
                      fromModule && fromModule === FromModuleFilesList.Task
                        ? containerFile.sharePointReleasedFileId ?? undefined
                        : undefined
                    )
                  }
                  disabled={FileInformationStore.file?.id ? FilesStore.fileBusy[FileInformationStore.file.id] : false}>
                  Open File
                </DropDownMenuButton>
              )}
              {containerFile.isForgeFile && containerFile.containerFileId && (
                <DropDownMenuButton onClick={() => handleForgeViewer(containerFile.containerFileId as number)}>
                  Open File
                </DropDownMenuButton>
              )}
              {containerFile.canSubmitForge && FileInformationStore.file?.fileRevisionId && (
                <DropDownMenuButton
                  onClick={() =>
                    handleResendToForge(
                      FileInformationStore.file?.fileRevisionId || null,
                      containerFile.containerFileId as number
                    )
                  }>
                  Resend to Forge
                </DropDownMenuButton>
              )}
              {getFileExtension(containerFile.originalFilename)?.toLocaleLowerCase() === FileExt.PDF &&
                FileInformationStore.file?.fileRevisionId && (
                  <DropDownMenuButton
                    onClick={() => {
                      openViewer(
                        `projectNumber=${NavBarSelectorStore.selectedItem!.project.projectNumber}&id=${
                          FileInformationStore.file?.id
                        }&fileRevisionId=${FileInformationStore.file?.fileRevisionId}&containerFileId=${
                          containerFile.containerFileId
                        }&releasedFileId=${FileInformationStore.file?.releasedFileId}`
                      );
                    }}>
                    Open PDF File
                  </DropDownMenuButton>
                )}
            </DropDownMenuForTableCell>
          </TableRow>
        ))}
      </Table>

      <DownloadModal
        fileSize={totalFileSize}
        showModal={showDownloadModal}
        closeModal={() => setShowDownloadModal(false)}
        downloadAction={() => {
          downloadAllFile();
          setShowDownloadModal(false);
        }}
      />

      {showErrorModal && (
        <ErrorModal
          closeModal={() => setShowErrorModal(false)}
          errorCode={FilesStore.errorCode}
          errorMessage={FilesStore.errorMessage}
        />
      )}
    </>
  );
};

export default observer(Files);
