import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/projectManagement/AddProject.module.scss';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import ButtonSavingSpinner from '../../shared/ButtonSavingSpinner';
import CentreOnPage from '../../shared/CentreOnPage';
import ModalActions from '../../shared/ModalActions';
import Overlay from '../../shared/Overlay';
import Modal from '../../shared/Modal';
import { FormInput } from '@aurecon-creative-technologies/styleguide';
import FormControlError from '../../shared/FormControlError';
import ProjectManagementStore from '../../../stores/settings/projectManagement/ProjectManagementStore';
import { IProgrammeDetails } from '../../../api/authenticated/config/UpdateProgramme';

interface IUpdateProgrammeProps {
  programmeId: string | number | null;
  programmeName: string | null;
  onClose: (programmeName: string | null) => void;
}

const UpdateProgrammeModal: FC<IUpdateProgrammeProps> = (props) => {
  const { programmeId, programmeName } = props;
  const [formDirty, setFormDirty] = useState(false);
  const [error, setError] = useState('');
  const [programme, setProgramme] = useState<IProgrammeDetails>({ programmeId: 0, programmeTitle: '' });
  const [newprogrammeName, setProgrammeName] = useState('');
  const [saving, setSaving] = useState(false);
  const disabledCreateBtn = !formDirty || !!error || !newprogrammeName;

  useEffect(() => {
    if (!programmeId && !programmeName) return;
    const programme = {
      programmeId: programmeId,
      programmeTitle: programmeName,
    } as IProgrammeDetails;
    setProgramme(programme);
    setProgrammeName(programmeName ?? '');
  }, [programmeId, programmeName]);

  const onFormInputChanged = (value) => {
    setProgrammeName(value);
    const programmeNameError = ProjectManagementStore.validateDuplicateProgrammeName(value);
    setError(programmeNameError);
    setFormDirty(true);
  };
  const handleSaveBtnClick = async () => {
    setSaving(true);
    const newUpdatePrograme = { ...programme, programmeTitle: newprogrammeName };
    await ProjectManagementStore.updateProgramme(newUpdatePrograme);
    setSaving(false);
    if (ProjectManagementStore.showError) {
      props.onClose(null);
      return;
    }
    props.onClose(newprogrammeName);
  };
  const handleCloseBtnClick = () => {
    props.onClose(null);
  };
  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <h1>Edit Programme</h1>
        <div>
          <FormInput
            type="text"
            label="Edit the name for this Programme"
            placeholder="Enter new Programme Name..."
            required
            value={newprogrammeName || ''}
            onChange={(value) => onFormInputChanged(value)}
          />
          <FormControlError message={error} formDirty={formDirty} showIcon={true} />
        </div>
        <ModalActions>
          <ButtonSavingSpinner isSaving={false}>
            <SecondaryButton onClick={handleCloseBtnClick}>Cancel</SecondaryButton>
          </ButtonSavingSpinner>
          <PrimaryButton
            disabled={disabledCreateBtn}
            loading={saving}
            onClick={() => {
              void (async () => {
                await handleSaveBtnClick();
              })();
            }}>
            Save
          </PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(UpdateProgrammeModal);
